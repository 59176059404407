import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  iosPrivacyURL: {
    color: '#01B4DE',
    textDecoration: 'none',
    cursor: 'pointer'
  },
  privacyURL: {
    color: '#01b4de',
    marginLeft: '5px',
    cursor: 'pointer'
  },
  privacyText: {
    color: '#939598',
    textAlign: 'center',
    margin: theme.spacing(2.5)
  },
  fontBold: {
    fontWeight: 'bold'
  }
}));

export { useStyles };
