import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Grid,
  Button,
  TableBody,
  TableRow,
  TableCell,
  Table,
  TableContainer
} from '@material-ui/core';
import { Title } from '../../Texts';

const styles = theme => ({
  dialogTitle: {
    padding: 0,
    textAlign: 'right'
  },
  root: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2.5)
    },
    borderRadius: '10px'
  },
  container: {},
  button: {
    color: theme.palette.secondary.main,
    textTransform: 'none'
  },
  cancelbutton: {
    textTransform: 'none'
  },
  title: {
    padding: '20px'
  },
  heading: {
    color: '#545456'
  },
  boxWrapper: {
    border: 'solid 1px #c6c8ca',
    borderRadius: '10px',
    padding: '20px'
  },
  tdborder: {
    borderBottom: 'none',
    borderRight: '1px solid #d8dcde',
    textTransform: 'none',
    width: '50%',
    padding: '18px 0px'
  },
  tdnoborder: {
    borderBottom: '0px',
    width: '50%',
    padding: '18px 0px'
  },
  proceed: {
    color: '#01B4DE',
    textTransform: 'none'
  },
  actions: {
    padding: '0px'
  },
  redText: {
    fontWeight: 'bold',
    color: '#e05750'
  },
  yellowText: {
    fontWeight: 'bold',
    color: '#dfac34'
  },
  orangeText: {
    fontWeight: 'bold',
    color: '#e5762f'
  },
  purpleText: {
    fontWeight: 'bold',
    color: '#6e68b9'
  }
});

const EquityAndSmallResidentailDialog = props => {
  const scroll = 'paper';
  const { classes, handleClose, handleProceed, isInstaller, sgipBudgetEligibility, isIOS } = props;
  const dialogClasses = isIOS ? { root: 'rounded', container: classes.container } : { root: 'rounded' };
  return (
    <div>
      <Dialog
        open={true}
        onClose={props.handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        style={{ borderRadius: '10px' }}
        classes={dialogClasses}
      >
        <DialogTitle className={classes.title} id="customized-dialog-title" onClose={handleClose}>
          <Title className={classes.heading}>Important Notification</Title>
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'} className={classes.root}>
          <Box>
            <Grid>
              {sgipBudgetEligibility === 'Equity Budget' && (
                <Typography>
                  We are sorry to inform that requests for SGIP applications in the Equity Budget category are not being
                  processed right now due to over-subscription. We will notify you when this is available again and you
                  can resume your application from here.
                </Typography>
              )}

              {!isInstaller && (
                <Typography>
                  Please be informed that SGIP applications have significant waitlist. ​If the waitlist is not cleared,
                  we will help you to re-apply at no extra cost when the next SGIP budget is available. The turn around
                  time for the whole process depends on your Utility and may take upto 6 months.
                </Typography>
              )}
              {isInstaller && (
                <Typography>
                  Please be informed that SGIP applications have significant waitlist. ​If the waitlist is not cleared,
                  we will help you to re-apply at no extra cost when the next SGIP budget is available. The turn around
                  time for the whole process depends on the Utility and may take upto 6 months.
                </Typography>
              )}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell align="center" className={classes.tdborder}>
                    <Button onClick={props.handleClose} className={classes.cancelbutton}>
                      Cancel
                    </Button>
                  </TableCell>
                  <TableCell className={classes.tdnoborder} align="center">
                    <Button
                      onClick={handleProceed}
                      className={classes.proceed}
                      //disabled={sgipBudgetEligibility === 'Equity Budget'}
                    >
                      Proceed
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(EquityAndSmallResidentailDialog);
