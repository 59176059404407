import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  TextField,
  Box,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
  useMediaQuery,
  InputAdornment,
  Slide,
  Link
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import AttachmentOutlinedIcon from '@material-ui/icons/AttachmentOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import CloseIcon from '@material-ui/icons/Close';
import { useTheme } from '@material-ui/core/styles';
import { isEmpty, find, get, isEqual } from '../../../utils/lodash';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import scrollIntoView from 'scroll-into-view';
import Alert from '@material-ui/lab/Alert';
import { downloadFile } from '../../../utils/helper';
import { STATUS_UPDATE_MSG_SUBJECT } from '../constants';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'fixed',
    color: 'white'
  },
  listItem: {
    margin: theme.spacing(0.4),
    width: 'fit-content',
    borderRadius: theme.spacing(1),
    display: 'flex',
    pointerEvents: 'none',
    padding: theme.spacing(0.4, 1),
    flexDirection: 'column',
    color: '#545456',
    wordBreak: 'break-word'
  },
  listItemLeft: {
    backgroundColor: theme.palette.primary.white,
    border: 'solid 1px #c5c5c5',
    alignSelf: 'flex-start',
    marginRight: theme.spacing(6)
  },
  listItemRight: {
    border: 'solid 1px #f37321',
    backgroundColor: 'rgba(243, 115, 33, 0.05)',
    alignSelf: 'flex-end',
    marginLeft: theme.spacing(6)
  },
  chatList: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1)
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1),
    background: theme.palette.primary.main,
    color: theme.palette.primary.white
  },
  litstItemHeader: {
    fontSize: 'xx-small',
    flex: 'inherit'
  },
  listItemAdminHeader: {
    color: theme.palette.primary.main
  },
  listItemHeaderRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  senderListItemHeaderRow: {
    justifyContent: 'flex-end'
  },
  dateText: {
    marginLeft: theme.spacing(3)
  },
  contentText: {
    fontSize: 'small',
    display: 'flex',
    overflowWrap: 'anywhere',
    alignSelf: 'flex-start',
    whiteSpace: 'pre-line'
  },
  messageSection: {
    flexGrow: 1,
    margin: '0 !important'
  },
  messageAttachmentSection: {
    flexGrow: 1,
    margin: '0 !important',
    '& .MuiInputBase-root': {
      display: 'flex',
      flexDirection: 'column-reverse',
      'max-height': '120px',
      'min-height': '100px',
      paddingTop: theme.spacing(2.5)
    },
    '& .MuiInputAdornment-root': {
      marginBottom: theme.spacing(2.5)
    }
  },
  mainDialog: {
    // paddingTop: theme.spacing(1),
    '& .MuiDialog-paper': {
      minWidth: '30%',
      minHeight: '98%'
    }
  },
  webDialog: {
    '& .MuiDialog-paper': {
      maxWidth: '30%'
    }
  },
  fabProgress: {
    margin: 5
  },
  listItemAttachment: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-start'
  },
  fileName: {
    paddingLeft: theme.spacing(2),
    fontSize: 'xx-small',
    fontWeight: 'bold',
    overflowWrap: 'anywhere'
  },
  listItemAttachmentIcon: {
    pointerEvents: 'auto',
    cursor: 'pointer'
  },
  inputAdornment: {
    alignSelf: 'flex-start',
    width: '-webkit-fill-available',
    alignItems: 'flex-end'
  },
  selectedAttachment: {
    borderRadius: 0,
    justifyContent: 'space-between',
    flexGrow: 1,
    width: 'inherit',
    margin: '5px 0'
  },
  dialogContent: {
    padding: 0
  },
  chatNotes: {
    background: '#f6f6f6',
    padding: theme.spacing(1),
    color: '#939598',
    textAlign: 'center',
    fontSize: 'xx-small'
  },
  dialogActions: {
    alignItems: 'inherit',
    flexDirection: 'column'
  },
  messageInfo: {
    marginBottom: theme.spacing(1),
    backgroundColor: 'grey',
    boxShadow: 'none',
    padding: theme.spacing(0, 1),
    '& .MuiAlert-icon': {
      alignItems: 'center'
    }
  },
  messageTextFieldRow: {
    display: 'flex',
    margin: '0 !important'
  },
  messageTextFieldRowIOS: {
    display: 'flex',
    margin: '0 0 32px 0 !important'
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  alert: {
    background: 'grey',
    color: 'white',
    margin: theme.spacing(0.5),
    minHeight: 'fit-content'
  },
  listItemAttachmentWrapper: {
    display: 'flex',
    'flex-direction': 'column',
    'max-height': '50px',
    overflow: 'scroll',
    width: '100%'
  },
  chatBoxWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  }
}));

const FILE_UPLOAD_SIZE_LIMIT = 20;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ScrollDialog(props) {
  const [ messageBody, setMessageBody ] = useState('');
  const {
    messages = [],
    currentUserEmail = '',
    sendMessage,
    appId,
    fetchAllChats,
    getPresingedURL,
    uploadToS3,
    handleClose,
    updateChatStatus,
    unreadMessageCount,
    isIOS,
    isAndroid,
    isMobileView,
    isFromAdmin,
    isFromApplicationStatus = false
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dialogClassName = isMobileView ? classes.mainDialog : clsx(classes.mainDialog, classes.webDialog);

  const [ fileUploadStatus, setFileUploadStatus ] = useState('idle');
  const [ uploadingFileName, setUploadingFileName ] = useState([]);
  const [ uploadError, setUploadError ] = useState(false);
  const [ uploadErrorMsg, setUploadErrorMsg ] = useState('');
  const [ downloadedFiles, setDownloadedFiles ] = useState([]);
  const [ chatInfoEnabled, setChatInfoEnabled ] = useState(false);
  const [ sendMessageCount, setSendMessageCount ] = useState(0);
  const [ totalMessageCount, setTotalMessageCount ] = useState(-1);

  useEffect(
    () => {
      if (messages.length > 0) {
        const isMessageListUpdated = totalMessageCount !== messages.length;
        const lastMessage = messages[messages.length - 1];
        if (isMessageListUpdated) scrollIntoView(document.getElementById(lastMessage.id));
        if (unreadMessageCount > 0) updateChatStatus({ applicationId: appId, lastMessageId: lastMessage['id'] });
        setTotalMessageCount(messages.length);
      }
      const interval = setInterval(() => {
        fetchAllChats();
      }, 10000);
      return () => clearInterval(interval);
    },
    [ appId, fetchAllChats, messages, totalMessageCount, unreadMessageCount, updateChatStatus ]
  );

  const handleMessageSend = () => {
    setChatInfoEnabled(true);
    setSendMessageCount(sendMessageCount + 1);
    sendMessage({
      app_id: appId,
      mObj: { mBody: messageBody, mSubject: 'General update', requested_doc: false },
      fileName: uploadingFileName,
      returnMsgHistory: true
    });
    setMessageBody('');
    resetFileUploadStatus();
  };

  const handleMessageBody = event => {
    setMessageBody(event.target.value);
  };

  const handleFileUpload = event => {
    setFileUploadStatus('in_progress');
    const files = [ ...event.target.files ];

    const uploadFile = index => {
      if (index >= files.length) {
        return;
      }

      let qPDF = files[index];
      let fTypeArray = qPDF.name.split('.');
      let fType = fTypeArray[fTypeArray.length - 1];
      let fSizeInMb = qPDF.size / 1024 / 1024;
      let file_name = `${appId}_${'OTHERS'}_${moment().format('DDMMYYYY-hhmmss')}.${fType}`;
      if (fSizeInMb > FILE_UPLOAD_SIZE_LIMIT) {
        setUploadError(true);
        setUploadErrorMsg(`Max file size should be ${FILE_UPLOAD_SIZE_LIMIT} Mb`);
        setFileUploadStatus('idle');
      } else {
        getPresingedURL({
          file_name: file_name,
          http_method: 'PUT',
          successCb: presignedURL => {
            uploadToS3({
              preSignedS3Url: presignedURL,
              fName: file_name,
              fileObj: qPDF,
              isDownload: true,
              successCbS3: () => {
                setFileUploadStatus('success');
                setUploadingFileName(uploadingFileName => [ ...uploadingFileName, file_name ]);
                uploadFile(index + 1);
              },
              failureCbS3: () => {
                setUploadError(true);
                setUploadErrorMsg('We are facing some issues with file upload, please try later.');
                setFileUploadStatus('idle');
              }
            });
          },
          failureCb: () => {
            setUploadError(true);
            setUploadErrorMsg('We are facing some issues with file upload, please try later.');
            setFileUploadStatus('idle');
          }
        });
      }
    };

    uploadFile(0);
  };

  const resetFileUploadStatus = fileName => {
    if (fileName && uploadingFileName.length > 1) {
      setFileUploadStatus('success');
      setUploadingFileName(uploadingFileName.filter(name => name !== fileName));
    } else {
      setFileUploadStatus('idle');
      setUploadingFileName([]);
    }

    setUploadError(false);
  };

  const getSignedUrl = fName => {
    return new Promise((resolve, reject) => {
      getPresingedURL({
        file_name: fName,
        http_method: 'GET',
        fileType: 's3',
        successCb: presignedURL => resolve(presignedURL),
        failureCb: () => resolve(false)
      });
    });
  };

  const updateDownloadedFiles = params => {
    const { fileName, signedUrl = null, downloadStatus } = params;
    let existingDownloadedFiles = downloadedFiles;
    let fileObj = find(existingDownloadedFiles, { fileName });
    if (fileObj) {
      if (signedUrl) fileObj['signedUrl'] = signedUrl;
      if (fileName) fileObj['fileName'] = fileName;
      if (downloadStatus) fileObj['downloadStatus'] = downloadStatus;
    } else {
      existingDownloadedFiles.push({ fileName, signedUrl, downloadStatus });
    }
    setDownloadedFiles(existingDownloadedFiles);
    fetchAllChats();
  };

  const downloadCloudFile = async fileName => {
    const preSignedUrl = await getSignedUrl(fileName).then(res => {
      updateDownloadedFiles({ fileName, signedUrl: res, downloadStatus: 'downloaded' });
      return res;
    });
    downloadFile(preSignedUrl, isIOS, isAndroid);
  };

  const renderMessageAttachment = message => {
    const { file_names = [] } = message;
    if (isEmpty(file_names)) return null;
    return file_names.map((fileName, index) => {
      const fileState = find(downloadedFiles, [ 'fileName', fileName ]);
      const signedUrl = get(fileState, 'signedUrl');
      const isDownloading = isEqual(get(fileState, 'downloadStatus'), 'downloading');
      return (
        <Box className={classes.listItemAttachment} key={index}>
          {isDownloading && <CircularProgress size={20} className={classes.fabProgress} />}
          {signedUrl ? (
            <Link title={fileName} href="#" onClick={() => downloadFile(signedUrl, isIOS, isAndroid)}>
              <DescriptionOutlinedIcon fontSize="large" className={classes.listItemAttachmentIcon} />
            </Link>
          ) : (
            <CloudDownloadOutlinedIcon
              fontSize="large"
              onClick={() => downloadCloudFile(fileName)}
              title={fileName}
              className={classes.listItemAttachmentIcon}
            />
          )}
          <ListItemText primary={fileName} disableTypography className={classes.fileName} />
        </Box>
      );
    });
  };

  const getSenderInfo = (type, section = 'label') => {
    const senderTypes = [
      { type: 'enphase_admin', label: 'From Enphase', isAdmin: true },
      { type: 'sgip_admin', label: 'From Enphase', isAdmin: true },
      { type: 'sgip_admin_din', label: 'From Enphase', isAdmin: true },
      { type: 'installer', label: 'From Installer', isAdmin: false },
      { type: 'homeowner', label: 'From System Owner', isAdmin: false },
      { type: 'default', label: 'From Unknown', isAdmin: false }
    ];
    const item = find(senderTypes, { type }) || find(senderTypes, { type: 'default' });
    return item[section];
  };

  const isSnackbarEnabled = () => {
    return chatInfoEnabled && sendMessageCount === 1;
  };

  const fetchMessageContent = msg => {
    return isEqual(msg.subject, STATUS_UPDATE_MSG_SUBJECT) ? (
      <div dangerouslySetInnerHTML={{ __html: msg.body_content }} />
    ) : (
      msg.body_content || msg.subject
    );
  };

  const renderUploadedFiles = fileName => {
    return (
      <Chip
        label={fileName}
        onDelete={() => resetFileUploadStatus(fileName)}
        variant="outlined"
        size="small"
        className={classes.selectedAttachment}
      />
    );
  };

  const renderMessageContent = () => {
    return (
      <Fragment>
        {isSnackbarEnabled() && (
          <Alert severity="info" onClose={() => setChatInfoEnabled(false)} icon={false} className={classes.alert}>
            You can close this window after sending your message. We will notify you via email when there is a response
            available to your query.
          </Alert>
        )}
        <DialogContent className={classes.dialogContent}>
          <Box className={classes.chatNotes}>
            This section can be used to query regarding your SGIP application and keep track of your conversations.
          </Box>
          <List className={classes.chatList}>
            {messages.map((message, index) => {
              const messageContent = message.body_content || message.subject;
              const messageByCurrentUser = message.send_by === currentUserEmail;
              const listItemStyle = messageByCurrentUser
                ? clsx(classes.listItem, classes.listItemRight)
                : clsx(classes.listItem, classes.listItemLeft);
              return (
                <ListItem className={listItemStyle} key={index} disableGutters id={message.id}>
                  <Box
                    className={
                      messageByCurrentUser ? (
                        clsx(classes.listItemHeaderRow, classes.senderListItemHeaderRow)
                      ) : (
                        classes.listItemHeaderRow
                      )
                    }
                  >
                    {!messageByCurrentUser && (
                      <ListItemText
                        secondary={getSenderInfo(message.send_by_user_type)}
                        disableTypography
                        className={
                          getSenderInfo(message.send_by_user_type, 'isAdmin') ? (
                            clsx(classes.litstItemHeader, classes.listItemAdminHeader)
                          ) : (
                            classes.litstItemHeader
                          )
                        }
                      />
                    )}
                    <ListItemText
                      secondary={moment(message.message_date).format('DD MMM h:mm')}
                      disableTypography
                      className={clsx(classes.litstItemHeader, classes.dateText)}
                    />
                  </Box>
                  {!isEmpty(message.file_names) && renderMessageAttachment(message)}
                  <Box className={classes.listItemAttachment}>
                    <ListItemText
                      primary={fetchMessageContent(message)}
                      disableTypography
                      className={classes.contentText}
                    />
                  </Box>
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
      </Fragment>
    );
  };

  const renderFooterContent = () => {
    return (
      <DialogActions className={classes.dialogActions}>
        <Box className={isIOS ? classes.messageTextFieldRowIOS : classes.messageTextFieldRow}>
          <TextField
            size="small"
            variant="outlined"
            id="standard-start-adornment"
            className={fileUploadStatus === 'success' ? classes.messageAttachmentSection : classes.messageSection}
            onChange={event => handleMessageBody(event)}
            placeholder="Type message"
            value={messageBody}
            autoFocus
            multiline
            rowsMax="5"
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="start"
                  className={clsx(fileUploadStatus === 'success' ? classes.inputAdornment : {})}
                >
                  {uploadError && (
                    <Typography variant="caption" style={{ color: 'red' }}>
                      {uploadErrorMsg}
                    </Typography>
                  )}
                  {fileUploadStatus === 'in_progress' && <CircularProgress size={20} className={classes.fabProgress} />}
                  {fileUploadStatus === 'success' ? (
                    <Typography className={classes.listItemAttachmentWrapper}>
                      {uploadingFileName.map(renderUploadedFiles)}
                    </Typography>
                  ) : (
                    <IconButton
                      variant="outlined"
                      component="label"
                      style={{ fontSize: '10px', textTransform: 'none', width: '26%' }}
                      size="small"
                      disabled={fileUploadStatus === 'in_progress'}
                      onClick={() => resetFileUploadStatus()}
                    >
                      <AttachmentOutlinedIcon />
                      <input
                        type="file"
                        style={{ display: 'none' }}
                        id="fileUpload"
                        onChange={e => handleFileUpload(e)}
                        value=""
                        multiple
                      />
                    </IconButton>
                  )}
                </InputAdornment>
              )
            }}
          />
          {fileUploadStatus !== 'in_progress' &&
          (messageBody || fileUploadStatus === 'success') && (
            <Box className={classes.iconWrapper}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => handleMessageSend()}
                aria-label="close"
                size="small"
                style={{ marginLeft: 4 }}
              >
                <SendIcon color="primary" />
              </IconButton>
            </Box>
          )}
        </Box>
      </DialogActions>
    );
  };

  if (isFromAdmin || isFromApplicationStatus) {
    return (
      <Box className={classes.chatBoxWrapper}>
        <DialogTitle className={classes.dialogTitle} disableTypography>
          <Typography variant="subtitle1" component="div">
            Communicate with Enphase
          </Typography>
        </DialogTitle>
        {renderMessageContent()}
        {renderFooterContent()}
      </Box>
    );
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={true}
      onClose={() => {}}
      TransitionComponent={Transition}
      scroll={'paper'}
      className={dialogClassName}
    >
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography variant="subtitle1" component="div">
          Communicate with Enphase
        </Typography>
        <IconButton edge="start" color="inherit" onClick={() => {}} aria-label="close" size="small">
          <CloseIcon onClick={() => handleClose()} />
        </IconButton>
      </DialogTitle>
      {renderMessageContent()}
      {renderFooterContent()}
    </Dialog>
  );
}
