import { EQUITY, EQUITY_RESILIENCY, LARGE_SCALE_STORAGE } from '../containers/SgipApp/constants';

export const BILLS_TYPE = {
  UTILITY_BILLS: 'utilityBills',
  CONTRACT_DOCUMENTS: 'contractDocuments',
  INCOME_DOCUMENTS: 'incomeDocuments',
  APPLICATION_UPLOAD: 'applicationUpload',
  GREEN_BUTTON: 'greenButton',
  PUMP_WELL: 'pumpWell',
  TAX_RETURNS: 'taxReturns',
  PURCHASE_INVOICE: 'purchaseInvoice'
};

export const PAYMENT_METHOD = 'STRIPE'; // STORE or STRIPE

export const SGIP_UTILITY_COMPANY_MAP = {
  'Pacific Gas & Electric Company': 1,
  'San Diego Gas & Electric Company': 2,
  'Southern California Edison': 3,
  'Southern California Gas Company': 4
};

export const EQUITY_AND_ELIGIBILITY_LINK =
  'https://www.cpuc.ca.gov/-/media/cpuc-website/files/uploadedfiles/cpucwebsite/content/news_room/newsupdates/2020/sgip-residential-web-120420.pdf';
export const COMMON_MEDICAL_BASELINE_URL =
  'https://www.cpuc.ca.gov/consumer-support/financial-assistance-savings-and-discounts/medical-baseline';

export const STORE_REDIRECTION_MESSAGE =
  'You are being redirected to store.enphase.com to complete the checkout process.';
export const QA_MED_BASELINE_HO =
  'Have you already notified your utility about the reason you qualify for the Medical Baseline allowances under the ';
export const QA_MED_BASELINE_INST =
  'Has the System Owner already notified their utility about the reason they qualify for the Medical Baseline allowances under the ';
export const QA_BASELINE_CUST_HO = 'Are you a medical baseline customer as defined by CPUC';
export const QA_BASELINE_CUST_INST = 'Is the System Owner a baseline customer as defined by CPUC';
export const QA_QUALIFY_EQUITY_HO = 'Do you qualify for the Equity budget?';
export const QA_QUALIFY_EQUITY_INST = 'Does the System Owner qualify for the Equity budget?';

export const VALIDATE_EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,10}))$/;
export const RESILIENCY_NOT_APPLICABLE_CATEGORIES = [ EQUITY, EQUITY_RESILIENCY, LARGE_SCALE_STORAGE ];
