import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Card, Typography } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const styles = theme => ({
  root: {
    backgroundColor: '#5a5a5c',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20
  }
});

export class FileAttachementBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.deleteItem = this.deleteItem.bind(this);
  }

  componentDidMount() {}

  deleteItem(e) {
    this.props.onClose(this.props.display_file_name, this.props.fileName, this.props.fileType, this.props.fileSize);
  }

  render() {
    const { classes, display_file_name, fileName, fileSize } = this.props;
    return (
      <Fragment>
        <Card className={classes.fileCard}>
          <Typography className={classes.cardFileNameText} component="div">
            <AttachFileIcon className={classes.cardFileAttachmentIcon} />
            <Typography component="div" style={{ marginLeft: '5px', marginBottom: '5px' }}>
              <Typography className={classes.cardFileNameText}> {display_file_name}</Typography>
              <Typography className={classes.cardFileSizeText}> {fileSize} mb</Typography>
            </Typography>
            <HighlightOffIcon
              className={classes.cardFileCloseIcon}
              name={display_file_name}
              id={fileName}
              onClick={this.deleteItem}
            />
          </Typography>
        </Card>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = state => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FileAttachementBar));
