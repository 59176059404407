import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  TextareaAutosize,
  Box,
  Button,
  IconButton,
  Grid,
  Toolbar,
  Avatar,
  Slider
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from './style';
import { postSgipNpsFeedback, getSgipNpsFeedback } from '../../../containers/SgipApp/actions';
import clsx from 'clsx';
import HeaderImage from '../../Footer/Icons/footer_logo.png';
import { isFunction } from '../../../utils/lodash';
import { Title } from '../../Texts';

function NpsFeedbackDialog(props) {
  const classes = useStyles();
  const [ rating, setRating ] = useState(
    props.source === 'popup' && !props.isInstaller ? 5 : props.source === 'email' ? props.npsRatings : -1
  );
  const [ showDialog, setShowDialog ] = useState(true);
  const [ message, setMessage ] = useState('');
  const [ comments, setComments ] = useState('');

  useEffect(() => {
    $('.range-slider').click(() => {});
  }, []);

  let touchmove = false;

  let ratingDetails = [];

  const getClassName = index => {
    if (index < 3) {
      return 'red';
    } else if (index < 7) {
      return 'orange';
    } else if (index < 9) {
      return 'yellow';
    } else {
      return 'green';
    }
  };

  for (let i = 0; i <= 10; i++) {
    ratingDetails.push({
      label: `${i}`,
      value: i,
      className: getClassName(i),
      heading: i === 0 ? 'Poor' : i === 10 ? 'Great' : '',
      color: i === 0 ? '#E43E3D' : i === 10 ? '#5BAF2A' : ''
    });
  }

  const successCb = () => {
    setMessage('Your application has been submitted successfully.');
    setShowDialog(false);
  };
  const failureCb = () => {
    setMessage('There is a problem while submitting feedback, please try later.');
    setShowDialog(false);
  };

  const onClose = () => {
    const source = props.applicationStage === 1 ? 'popup' : props.source;
    source === 'popup'
      ? props.postSgipNpsFeedback({
          rating: -1,
          comment: '',
          applicationStage: props.applicationStage,
          applicationId: props.applicationId,
          source,
          email: props.email,
          successCb: successCb,
          failureCb: failureCb
        })
      : setShowDialog(false);
  };

  const onSubmit = () => {
    props.postSgipNpsFeedback({
      rating,
      comment: comments,
      applicationStage: props.applicationStage,
      applicationId: props.applicationId,
      source: props.applicationStage === 1 ? 'popup' : props.source,
      email: btoa(props.email),
      successCb: successCb,
      failureCb: failureCb
    });
  };

  const handleRatings = value => {
    setRating(parseInt(value));
  };

  const handleChange = event => {
    setComments(event.target.value);
  };

  const onSliderChange = (event, value) => {
    const { isIOS, isAndroid } = props;
    if (isAndroid || isIOS) {
      if (event.type === 'touchmove') {
        touchmove = true;
      }
      if (touchmove && event.type === 'mousedown') {
        touchmove = false;
        return;
      }
    }
    if (isFunction(onSliderChange)) {
      handleRatings(value);
    }
  };

  const renderScaleRange = obj => {
    return (
      <Typography variant="subtitle1" component="span" key={obj.label} className={classes.scaleReadings}>
        {obj.label}
      </Typography>
    );
  };

  return (
    <Box>
      {props.source === 'email' && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            background: '#FBFBFB',
            position: 'fixed',
            width: '100%',
            height: '77px'
          }}
        >
          <Toolbar className={clsx(classes.headerToolbar, classes.toolbar)}>
            <Avatar variant="square" alt="enphase logo" src={HeaderImage} className={classes.large} />
          </Toolbar>
        </div>
      )}
      {props.source === 'email' && (
        <Grid className={`${classes.top} ${classes.root}`} style={{ backgroundColor: 'none' }}>
          <Box style={{ backgroundColor: 'none', minHeight: '100vh' }} />
        </Grid>
      )}
      <Dialog open={showDialog} classes={{ scrollPaper: props.source === 'email' ? classes.dialogOverlay : '' }}>
        <DialogTitle>
          <Box className={classes.dialogTitle}>
            <Title className={classes.title}>Rate our SGIP Service</Title>
            <IconButton
              classes={{ root: classes.closeIcon }}
              edge="start"
              onClick={onClose}
              aria-label="close"
              size="small"
              disableRipple={true}
            >
              <CloseIcon classes={{ root: classes.closeIcon }} />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <Typography>
            On a scale of 0 to 10, how likely are you to recommend our SGIP service to a friend or colleague?
          </Typography>
          {props.source === 'popup' && !props.isInstaller ? (
            <Box className="fd__slider-wrapper">
              <Box className={classes.slider}>
                <Box className={`${classes.ratingRange} ${classes.homeownerPopRatingRange}`}>
                  {ratingDetails.map(renderScaleRange)}
                </Box>
                <Slider
                  classes={{
                    root: classes.rangeSlider,
                    rail: classes.sliderRail,
                    track: classes[`sliderTrack${rating}`],
                    thumb: classes.sliderThumb
                  }}
                  onChange={onSliderChange}
                  min={0}
                  max={10}
                  defaultValue={5}
                  value={rating}
                />
                <Box className={`${classes.rangeTitle} ${classes.homeownerTitleWidth}`}>
                  <Typography>Poor</Typography>
                  <Typography>Great</Typography>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box className={classes.ratingsWrapper}>
              <Box style={{ display: 'flex' }}>
                {ratingDetails.map(obj => (
                  <Box>
                    <Button
                      className={`${classes.ratingsButton} ${classes[obj.className]} ${classes.noBorder} ${rating >=
                      obj.value
                        ? classes[`${getClassName(rating)}Selected`]
                        : ''}`}
                      onClick={() => handleRatings(obj.value)}
                    >
                      {obj.label}
                    </Button>
                    {obj.heading && (
                      <Typography className={classes.heading} style={{ color: obj.color }}>
                        {obj.heading}
                      </Typography>
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
          )}

          <Typography style={{ margin: '22px 0 12px' }}>
            If you would like to share specific comments, please enter them below
          </Typography>
          <TextareaAutosize className={classes.textArea} onChange={handleChange} />
          <Box
            style={{ margin: '20px 0' }}
            className={props.isInstaller || props.source === 'email' ? '' : classes.buttonWrapper}
          >
            <Button
              variant="contained"
              color="primary"
              className={props.isInstaller || props.source === 'email' ? classes.submit : classes.sumbitHo}
              onClick={onSubmit}
              disabled={rating > -1 ? false : true}
            >
              Submit
            </Button>
            {(props.isInstaller || props.source === 'email') && (
              <Button className={classes.cancel} onClick={onClose}>
                Cancel
              </Button>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

const mapDispatchToProps = dispatch => ({
  postSgipNpsFeedback: payload => dispatch(postSgipNpsFeedback(payload)),
  getSgipNpsFeedback: payload => dispatch(getSgipNpsFeedback(payload))
});

const mapStateToProps = (state, ownProps) => ({
  applicationStage: state.appReducer.applicationStage || ownProps.applicationStage,
  applicationId: state.appReducer.applicationId || ownProps.applicationId,
  source: state.appReducer.applicationStage || state.appReducer.applicationId ? 'email' : 'popup',
  email: state.appReducer.email || ownProps.email,
  isIOS: state.appReducer.isIOS,
  isAndroid: state.appReducer.isAndroid,
  isInstaller: state.appReducer.isInstaller,
  npsRatings: state.appReducer.npsRatings || -1
});

export default connect(mapStateToProps, mapDispatchToProps)(NpsFeedbackDialog);
