import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import {
  Typography,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Card,
  CardContent,
  ListItem,
  List,
  CircularProgress
} from '@material-ui/core';
import moment from 'moment';
import { getNotifications } from '../../../containers/Admin/actions';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.dark
  },
  fontBold: {
    fontWeight: 'bold'
  },
  dataHeader: {
    color: '#246AB0'
  },
  panelHead: {
    background: '#f1f1f1',
    color: theme.palette.primary.mainText,
    minHeight: '40px !important',
    height: '40px',
    cursor: 'unset !important'
  },
  panelDetails: {
    flexDirection: 'column'
  },
  heading: {
    fontWeight: 'bold',
    flexBasis: '90%',
    flexShrink: 0
  },
  headingInfo: {
    color: 'darkgray !important'
  },
  dataSubHeader: {
    fontSize: '16px',
    color: 'grey'
  },
  fullWidth: {
    width: '100%'
  },
  listStyle: {
    width: '100%',
    maxHeight: '500px',
    minHeight: '500px',
    overflow: 'auto'
  }
});

export class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { appDetails } = this.props;
    this.props.getNotifications({ request_id: appDetails.application_id });
  }

  render() {
    const { classes, getNotificationsInProgress, notifications } = this.props;
    return (
      <Fragment>
        {' '}
        <Grid container direction="row">
          <Grid item xs={12}>
            <ExpansionPanel expanded={true}>
              <ExpansionPanelSummary aria-controls="sDetails-content" id="sDetails" className={classes.panelHead}>
                <Typography className={classes.heading}>Notifications</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ flexDirection: 'column' }}>
                {getNotificationsInProgress === true ? (
                  <div style={{ textAlign: 'center', minHeight: '518px' }}>
                    {' '}
                    <br />
                    <br />
                    <br />
                    <CircularProgress size={15} />
                  </div>
                ) : notifications.length > 0 ? (
                  <List className={classes.listStyle}>
                    {notifications.map((n, idx) => {
                      return (
                        <ListItem disableGutters={true} key={idx}>
                          <Card className={classes.fullWidth}>
                            <CardContent>
                              <Typography variant="body2" component="p">
                                {n.content}
                              </Typography>
                              <br />
                              <Typography style={{ fontSize: 14 }} color="textSecondary">
                                {moment(n.sent_date).format('MMM Do YYYY')}
                              </Typography>
                            </CardContent>
                          </Card>
                        </ListItem>
                      );
                    })}
                  </List>
                ) : (
                  <div style={{ textAlign: 'center', minHeight: '517px' }}>
                    <br />
                    <br />
                    <br />
                    <Typography variant="caption">** No Notifications Available **</Typography>
                  </div>
                )}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getNotifications: payload => dispatch(getNotifications(payload))
});

const mapStateToProps = state => ({
  getNotificationsInProgress: state.adminReducer.getNotificationsInProgress,
  notifications: state.adminReducer.notifications
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Notifications));
