export const SET_MOBILE_VIEW = 'SET_MOBILE_VIEW';
export const REQUEST_FAILURE = 'REQUEST_FAILURE';
export const SET_EMAIL_ID = 'SET_EMAIL_ID';
export const SET_SITE_ID = 'SET_SITE_ID';
export const SET_ENLIGHTEN_MANAGER_TOKEN = 'SET_ENLIGHTEN_MANAGER_TOKEN';
export const SET_ENLIGHTEN_MANAGER_USER_TYPE = 'SET_ENLIGHTEN_MANAGER_USER_TYPE';
export const SET_USER_OPERATING_SYSTEM = 'SET_USER_OPERATING_SYSTEM';
export const SET_APPLICATION_ID = 'SET_APPLICATION_ID';
export const SET_APPLICATION_STAGE = 'SET_APPLICATION_STAGE';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_NPS_RATINGS = 'SET_NPS_RATINGS';
export const SET_STORE_PAYMENT_STATUS = 'SET_STORE_PAYMENT_STATUS';
