export const GET_SGIP_INSTALLERS_REQUEST = 'GET_SGIP_INSTALLERS_REQUEST';
export const GET_SGIP_INSTALLERS_REQUEST_SUCCESS = 'GET_SGIP_INSTALLERS_REQUEST_SUCCESS';

export const GET_SGIP_PRIMARY_INSTALLERS_REQUEST = 'GET_SGIP_PRIMARY_INSTALLERS_REQUEST';
export const GET_SGIP_PRIMARY_INSTALLERS_SUCCESS = 'GET_SGIP_PRIMARY_INSTALLERS_SUCCESS';

export const GET_APPLICATION_NUMBER_REQUEST = 'GET_APPLICATION_NUMBER_REQUEST';
export const GET_APPLICATION_NUMBER_REQUEST_SUCCESS = 'GET_APPLICATION_NUMBER_REQUEST_SUCCESS';

export const GET_SGIP_REQUEST_SUCCESS = 'GET_SGIP_REQUEST_SUCCESS';
export const GET_SGIP_REQUEST_FAILURE = 'GET_SGIP_REQUEST_FAILURE';

export const GET_REQUEST_FAILURE = 'GET_REQUEST_FAILURE';
export const POST_REQUEST_FAILURE = 'POST_REQUEST_FAILURE';

export const GET_SGIP_DOC_S3_PRE_SIGNED_URL_REQUEST = 'GET_SGIP_DOC_S3_PRE_SIGNED_URL_REQUEST';
export const GET_SGIP_DOC_S3_PRE_SIGNED_URL_SUCCESS = 'GET_SGIP_DOC_S3_PRE_SIGNED_URL_SUCCESS';

export const PUT_SGIP_DOC_UPLOAD_TO_S3_REQUEST = 'PUT_SGIP_DOC_UPLOAD_TO_S3_REQUEST';
export const PUT_SGIP_DOC_UPLOAD_TO_S3_SUCCESS = 'PUT_SGIP_DOC_UPLOAD_TO_S3_SUCCESS';

export const GET_SGIP_DOC_FILE_FROM_S3_REQUEST = 'GET_SGIP_DOC_FILE_FROM_S3_REQUEST';
export const GET_SGIP_DOC_FILE_FROM_S3_SUCCESS = 'GET_SGIP_DOC_FILE_FROM_S3_SUCCESS';

export const POST_SGIP_PRIMARY_DETAILS_REQUEST = 'POST_SGIP_PRIMARY_DETAILS_REQUEST';
export const POST_SGIP_PRIMARY_DETAILS_SUCCESS = 'POST_SGIP_PRIMARY_DETAILS_SUCCESS';

export const POST_DEVELOPER_KEY_UPDATE_MAIL_REQUEST = 'POST_DEVELOPER_KEY_UPDATE_MAIL_REQUEST';
export const POST_DEVELOPER_KEY_UPDATE_MAIL_SUCCESS = 'POST_DEVELOPER_KEY_UPDATE_MAIL_SUCCESS';

export const POST_SGIP_NOTIFY_ME_DETAILS_REQUEST = 'POST_SGIP_NOTIFY_ME_DETAILS_REQUEST';
export const POST_SGIP_NOTIFY_ME_DETAILS_SUCCESS = 'POST_SGIP_NOTIFY_ME_DETAILS_SUCCESS';

export const POST_SGIP_PERSONAL_DETAILS_REQUEST = 'POST_SGIP_PERSONAL_DETAILS_REQUEST';
export const POST_SGIP_PERSONAL_DETAILS_SUCCESS = 'POST_SGIP_PERSONAL_DETAILS_SUCCESS';

export const POST_SGIP_QUESTIONNAIRE_REQUEST = 'POST_SGIP_QUESTIONNAIRE_REQUEST';
export const POST_SGIP_QUESTIONNAIRE_SUCCESS = 'POST_SGIP_QUESTIONNAIRE_SUCCESS';

export const POST_SGIP_APPLICATION_DOCUMENTS_REQUEST = 'POST_SGIP_APPLICATION_DOCUMENTS_REQUEST';
export const POST_SGIP_APPLICATION_DOCUMENTS_SUCCESS = 'POST_SGIP_APPLICATION_DOCUMENTS_REQUEST';

export const POST_SGIP_APPLICATION_TRACKER_UPLOAD_DOCUMENTS_REQUEST =
  'POST_SGIP_APPLICATION_TRACKER_UPLOAD_DOCUMENTS_REQUEST';
export const POST_SGIP_APPLICATION_TRACKER_UPLOAD_DOCUMENTS_SUCCESS =
  'POST_SGIP_APPLICATION_TRACKER_UPLOAD_DOCUMENTS_SUCCESS';

export const DELETE_SGIP_DOC_FILE_FROM_S3_REQUEST = 'DELETE_SGIP_DOC_FILE_FROM_S3_REQUEST';
export const DELETE_SGIP_DOC_FILE_FROM_S3_SUCCESS = 'DELETE_SGIP_DOC_FILE_FROM_S3_SUCCESS';

export const GET_SGIP_APPLICATION_DETAILS_BY_EMAIL_REQUEST = 'GET_SGIP_APPLICATION_DETAILS_BY_EMAIL_REQUEST';
export const GET_SGIP_APPLICATION_DETAILS_BY_EMAIL_SUCCESS = 'GET_SGIP_APPLICATION_DETAILS_BY_EMAIL_SUCCESS';

export const GET_SGIP_APPLICATION_DETAILS_BY_SITEID_REQUEST = 'GET_SGIP_APPLICATION_DETAILS_BY_SITEID_REQUEST';
export const GET_SGIP_APPLICATION_DETAILS_BY_SITEID_SUCCESS = 'GET_SGIP_APPLICATION_DETAILS_BY_SITEID_SUCCESS';
export const GET_SGIP_APPLICATION_DETAILS_BY_SITEID_FAILURE = 'GET_SGIP_APPLICATION_DETAILS_BY_SITEID_FAILURE';

export const POST_SGIP_PAYMENT_UPDATE_REQUEST = 'POST_SGIP_PAYMENT_UPDATE_REQUEST';
export const POST_SGIP_PAYMENT_UPDATE_SUCCESS = 'POST_SGIP_PAYMENT_UPDATE_SUCCESS';

export const POST_SGIP_PAYMENT_INTENT_REQUEST = 'POST_SGIP_PAYMENT_INTENT_REQUEST';
export const POST_SGIP_PAYMENT_INTENT_SUCCESS = 'POST_SGIP_PAYMENT_INTENT_SUCCESS';

export const GET_SGIP_STRIPE_PUBLISHABLE_KEY_REQUEST = 'GET_SGIP_STRIPE_PUBLISHABLE_KEY_REQUEST';
export const GET_SGIP_STRIPE_PUBLISHABLE_KEY_SUCCESS = 'GET_SGIP_STRIPE_PUBLISHABLE_KEY_SUCCESS';

export const GET_UTILITY_BILLS_PRE_SIGNED_URL_REQUEST = 'GET_UTILITY_BILLS_PRE_SIGNED_URL_REQUEST';
export const GET_UTILITY_BILLS_PRE_SIGNED_URL_SUCCESS = 'GET_UTILITY_BILLS_PRE_SIGNED_URL_SUCCESS';

export const GET_DOCUMENTS_TYPE_PRE_SIGNED_URL_REQUEST = 'GET_DOCUMENTS_TYPE_PRE_SIGNED_URL_REQUEST';
export const GET_DOCUMENTS_TYPE_PRE_SIGNED_URL_SUCCESS = 'GET_DOCUMENTS_TYPE_PRE_SIGNED_URL_SUCCESS';

export const GET_UPLOADED_DOCUMENTS_PRE_SIGNED_URL_REQUEST = 'GET_UPLOADED_DOCUMENTS_PRE_SIGNED_URL_REQUEST';
export const GET_UPLOADED_DOCUMENTS_PRE_SIGNED_URL_SUCCESS = 'GET_UPLOADED_DOCUMENTS_PRE_SIGNED_URL_SUCCESS';

export const POST_APPLICANT_NOTIFICATION_REQUEST = 'POST_APPLICANT_NOTIFICATION_REQUEST';
export const POST_APPLICANT_NOTIFICATION_SUCCESS = 'POST_APPLICANT_NOTIFICATION_SUCCESS';

export const POST_SIGNED_DOCUMENTS_REQUEST = 'POST_SIGNED_DOCUMENTS_REQUEST';
export const POST_SIGNED_DOCUMENTS_SUCCESS = 'POST_SIGNED_DOCUMENTS_SUCCESS';

export const GET_FILE_FROM_S3_REQUEST = 'GET_FILE_FROM_S3_REQUEST';

export const FILE_UPLOAD_SIZE = 20;

export const EQUITY = 'Equity Budget';
export const SMALL_RESIDENTIAL = 'Small Residential Budget';
export const EQUITY_RESILIENCY = 'Equity Resiliency Budget';
export const LARGE_SCALE_STORAGE = 'Large Scale Storage';

export const PACIFIC_GAS_AND_ELECTRIC_COMPANY = 'Pacific Gas & Electric Company';
export const SAN_DIEGO_GAS_AND_ELECTRIC_COMPANY = 'San Diego Gas & Electric Company';
export const SOUTHERN_CALIFORNIA_GAS_COMPANY = 'Southern California Gas Company';
export const SOUTHERN_CALIFORNIA_EDISON = 'Southern California Edison';

export const POST_FIELD_CHANGED_ACCEPT_NOTIFICATION_REQUEST = 'POST_FIELD_CHANGED_ACCEPT_NOTIFICATION_REQUEST';
export const POST_FIELD_CHANGED_ACCEPT_NOTIFICATION_SUCCESS = 'POST_FIELD_CHANGED_ACCEPT_NOTIFICATION_SUCCESS';
export const POST_FIELD_CHANGED_REJECT_NOTIFICATION_REQUEST = 'POST_FIELD_CHANGED_REJECT_NOTIFICATION_REQUEST';
export const POST_FIELD_CHANGED_REJECT_NOTIFICATION_SUCCESS = 'POST_FIELD_CHANGED_REJECT_NOTIFICATION_SUCCESS';

export const POST_PROMOCODE_REQUEST = 'POST_PROMOCODE_REQUEST';
export const POST_PROMOCODE_SUCCESS = 'POST_PROMOCODE_SUCCESS';

export const POST_SGIP_PROMOCODE_PAYMENT_SUCCESS = 'POST_SGIP_PROMOCODE_PAYMENT_SUCCESS';
export const POST_SGIP_PROMOCODE_PAYMENT_REQUEST = 'POST_SGIP_PROMOCODE_PAYMENT_REQUEST';

export const POST_SGIP_ATTESTATION_FORM_SUCCESS = 'POST_SGIP_ATTESTATION_FORM_SUCCESS';
export const POST_SGIP_ATTESTATION_FORM_REQUEST = 'POST_SGIP_ATTESTATION_FORM_REQUEST';

export const GET_SGIP_USER_SELECTION_REQUEST = 'GET_SGIP_USER_SELECTION_REQUEST';
export const GET_SGIP_USER_SELECTION_SUCCESS = 'GET_SGIP_USER_SELECTION_SUCCESS';

export const GET_SGIP_NPS_FEEDBACK_REQUEST = 'GET_SGIP_NPS_FEEDBACK_REQUEST';
export const GET_SGIP_NPS_FEEDBACK_SUCCESS = 'GET_SGIP_NPS_FEEDBACK_SUCCESS';

export const POST_SGIP_NPS_FEEDBACK_REQUEST = 'POST_SGIP_NPS_FEEDBACK_REQUEST';
export const POST_SGIP_NPS_FEEDBACK_SUCCESS = 'POST_SGIP_NPS_FEEDBACK_SUCCESS';
export const POST_SGIP_NPS_FEEDBACK_FAILURE = 'POST_SGIP_NPS_FEEDBACK_FAILURE';

export const GET_SITE_INFO_REQUEST = 'GET_SITE_INFO_REQUEST';
export const GET_SITE_INFO_SUCCESS = 'GET_SITE_INFO_SUCCESS';

export const GET_INCENTIVE_RATES_SGIP_REQUEST = 'GET_INCENTIVE_RATES_SGIP_REQUEST';
export const GET_INCENTIVE_RATES_SGIP_SUCCESS = 'GET_INCENTIVE_RATES_SGIP_SUCCESS';
export const GET_INCENTIVE_RATES_SGIP_FAILURE = 'GET_INCENTIVE_RATES_SGIP_FAILURE';

export const GET_SGIP_CONFIGS_REQUEST = 'GET_SGIP_CONFIGS_REQUEST';
export const GET_SGIP_CONFIGS_SUCCESS = 'GET_SGIP_CONFIGS_SUCCESS';
export const GET_SGIP_CONFIGS_FAILURE = 'GET_SGIP_CONFIGS_FAILURE';

export const GET_CPUC_URL_REQUEST = 'GET_CPUC_URL_REQUEST';

export const CREATE_SGIP_APPLICATION_REQUEST = 'CREATE_SGIP_APPLICATION_REQUEST';
export const CREATE_SGIP_APPLICATION_SUCCESS = 'CREATE_SGIP_APPLICATION_SUCCESS';
export const CREATE_SGIP_APPLICATION_FAILURE = 'CREATE_SGIP_APPLICATION_FAILURE';

export const ADD_TO_CART_REQUEST = 'ADD_TO_CART_REQUEST';
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_FAILURE = 'ADD_TO_CART_FAILURE';
