import * as React from 'react';
import { SvgIcon } from '@material-ui/core';

function CompletedIcon(props) {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" width="10.5" height="10.5" viewBox="0 0 10.5 10.5">
      <path
        style={{ fill: 'none' }}
        d="M5.336,10.673A5.336,5.336,0,1,0,0,5.336,5.336,5.336,0,0,0,5.336,10.673Zm0-.427A4.909,4.909,0,1,0,.427,5.336,4.909,4.909,0,0,0,5.336,10.246ZM5.123,2.561H5.55V6.4H5.123ZM5.55,5.977H8.965V6.4H5.55Z"
      />
      <g transform="translate(-108.75 12.25)">
        <g transform="translate(106.649 -13.996)">
          <circle
            style={{ fill: 'none', stroke: '#01aa3c', strokeMiterlimit: '10', strokeWidth: '0.5px' }}
            cx="5"
            cy="5"
            r="5"
            transform="translate(2.351 1.996)"
          />
          <path
            style={{ fill: 'none', stroke: '#01aa3c', strokeMiterlimit: '10', strokeWidth: '0.5px' }}
            d="M7.1,11.833l2.479,2.516L13.59,9.9"
            transform="translate(-3.171 -4.951)"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default CompletedIcon;
