import moment from 'moment';
import { BILLS_TYPE } from './constants';

export default function uploadFileDetails(obj, cb) {
  const {
    bills,
    event,
    setDocumentError,
    setStatus,
    billsFileSize,
    app_id,
    fileUploadSize,
    setFilesSize,
    getSgipDocumentPresignedURL,
    putSgipDocumentToS3,
    documentType
  } = obj;

  const files = [ ...event.target.files ];

  const recursiveUpload = index => {
    if (index >= files.length) {
      return;
    }

    let fileData = files[index];
    if (!fileData) {
      return;
    }

    let original_file_name = '';
    original_file_name = files[index].name;
    if (!original_file_name) {
      return;
    }

    let fileExtention = original_file_name.split('.').pop();
    let acceptedExtention =
      documentType === BILLS_TYPE.GREEN_BUTTON
        ? [ 'pdf', 'jpeg', 'jpg', 'png', 'csv', 'xlm', 'xml' ]
        : [ 'pdf', 'jpeg', 'jpg', 'png' ];
    if (acceptedExtention.indexOf(fileExtention.toLowerCase()) < 0) {
      const errorDetails = {
        error: false,
        msg:
          documentType === BILLS_TYPE.GREEN_BUTTON
            ? 'Only PDF/JPEG/JPG/PNG/CSV/XLM/XML file formats are supported'
            : 'Only PDF/JPEG/JPG/PNG file formats are supported'
      };
      setDocumentError(errorDetails, 'idle');
      return;
    }

    if (bills.length > 0) {
      let uploadedBills = bills.filter(uFile => uFile.original_file_name === original_file_name);
      if (uploadedBills && uploadedBills.length > 0) {
        const errorDetails = {
          error: false,
          msg: 'A file with same name is already uploaded. You can upload it again, after removing it.'
        };
        setDocumentError(errorDetails, 'idle');
        if (index + 1 >= files.length) {
          return;
        }
        recursiveUpload(index + 1);
      }
    }

    setStatus('processing');

    let fileName = original_file_name.split('.')[0];
    let turncatedFilename =
      fileName.length > 20 ? fileName.substring(0, 25) + '...' + fileExtention : original_file_name;

    let fileSizeInMb = fileData.size / 1024 / 1024;
    fileSizeInMb = fileSizeInMb.toFixed(2);
    var uTotalFizeSize = 0;

    if (
      documentType === BILLS_TYPE.UTILITY_BILLS ||
      documentType === BILLS_TYPE.PUMP_WELL ||
      documentType === BILLS_TYPE.CONTRACT_DOCUMENTS
    ) {
      uTotalFizeSize = parseFloat(billsFileSize);
      uTotalFizeSize = uTotalFizeSize + parseFloat(fileSizeInMb);
    }

    let updatedFileName = '';

    switch (documentType) {
      case BILLS_TYPE.UTILITY_BILLS:
        updatedFileName = app_id + '_utility_bill_' + (bills.length + 1) + '.' + turncatedFilename.split('.').pop();
        break;
      case BILLS_TYPE.CONTRACT_DOCUMENTS:
        updatedFileName =
          app_id + '_Contract_Document_' + (bills.length + 1) + '.' + turncatedFilename.split('.').pop();
        break;
      case BILLS_TYPE.INCOME_DOCUMENTS:
        updatedFileName = app_id + '_Income_Document.' + turncatedFilename.split('.').pop();
        break;
      case BILLS_TYPE.APPLICATION_UPLOAD:
        updatedFileName = app_id + '_document_' + (bills.length + 1) + '.' + turncatedFilename.split('.').pop();
        break;
      case BILLS_TYPE.GREEN_BUTTON:
        updatedFileName =
          app_id + '_Green_Button_Document_' + (bills.length + 1) + '.' + turncatedFilename.split('.').pop();
        break;
      case BILLS_TYPE.PUMP_WELL:
        updatedFileName =
          app_id + '_Pump_Well_Document_' + (bills.length + 1) + '.' + turncatedFilename.split('.').pop();
        break;
      case BILLS_TYPE.CHAT:
        updatedFileName = `${app_id}_${'OTHERS'}_${moment().format('DDMMYYYY-hhmmss')}.${fileName}`;
        break;
      case BILLS_TYPE.TAX_RETURNS:
        updatedFileName =
          app_id + '_Tax_Returns_Document_' + (bills.length + 1) + '.' + turncatedFilename.split('.').pop();
        break;
      case BILLS_TYPE.PURCHASE_INVOICE:
        updatedFileName =
          app_id + '_Purchase_Invoice_Document_' + (bills.length + 1) + '.' + turncatedFilename.split('.').pop();
        break;
      default:
        updatedFileName = '';
    }

    let uploadDate = moment().format('YYYY-MM-DD');
    let uploadStatus = 'Uploaded';

    const handleFailure = () => {
      const errorDetails = {
        error: true,
        msg: 'We are facing some issue with file upload, you can try after sometime.'
      };
      setDocumentError(errorDetails, 'idle');
    };

    if (fileSizeInMb > fileUploadSize) {
      const errorDetails = {
        error: true,
        msg: `Maximum files size allowed is ${fileUploadSize} mb`
      };
      setDocumentError(errorDetails, 'idle');
      recursiveUpload(index + 1);
    } else {
      getSgipDocumentPresignedURL({
        file_name: updatedFileName,
        http_method: 'PUT',
        fileType: 's3',
        successCb: async getSgipDocumentPresignedS3Url => {
          await putSgipDocumentToS3({
            fileObj: fileData,
            preSignedS3Url: getSgipDocumentPresignedS3Url,
            fName: updatedFileName,
            successCbS3: () => {
              setDocumentError({ error: false, msg: '' });
              bills.push({
                display_file_name: turncatedFilename,
                file_name: updatedFileName,
                file_size: fileSizeInMb,
                uploaded_date: uploadDate,
                status: uploadStatus,
                original_file_name: original_file_name
              });
              setStatus('success');
              if (
                documentType === BILLS_TYPE.UTILITY_BILLS ||
                documentType === BILLS_TYPE.PUMP_WELL ||
                documentType === BILLS_TYPE.CONTRACT_DOCUMENTS ||
                documentType === BILLS_TYPE.PURCHASE_INVOICE
              ) {
                setFilesSize(uTotalFizeSize);
              }
              recursiveUpload(index + 1);
            },
            failureCbS3: handleFailure
          });
        },
        failureCb: handleFailure
      });
    }
  };
  recursiveUpload(0);
  cb(bills);
}
