import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import { Box, Typography, Grid, Avatar } from '@material-ui/core';
import { FilledButton } from '../../Buttons';
import TickImage from '../../Payment/tickmark-green.png';

const styles = theme => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2.5)
    }
  },
  title: {
    padding: '20px'
  },
  heading: {
    color: '#545456'
  },
  okButton: {
    margin: '0 auto',
    width: 'auto'
  },
  value: {
    color: '#39cc67',
    marginTop: '15px',
    textAlign: 'center'
  },
  label: {
    color: '#545456',
    marginTop: '5px'
  }
});

const DocumentUploadDialog = props => {
  const scroll = 'paper';
  const { classes, handleOK } = props;
  return (
    <div>
      <Dialog
        open={true}
        onClose={props.handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        style={{ borderRadius: '10px' }}
        classes={{ root: 'rounded' }}
      >
        <DialogContent dividers={scroll === 'paper'} className={classes.root}>
          <Box>
            <Typography component="span" style={{ justifyContent: 'center', display: 'flex' }}>
              <Avatar variant="square" alt="enphase tick image" src={TickImage} className={classes.large} />
            </Typography>
            <Grid>
              <Typography variant="subtitle2" className={classes.value}>
                Upload Successful
              </Typography>
              <Typography variant="body1" className={classes.label}>
                We have received your document. We are reviewing it and will be proceeding with the next steps soon.
              </Typography>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <FilledButton className={classes.okButton} onClick={handleOK}>
            OK
          </FilledButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(DocumentUploadDialog);
