import * as React from 'react';
import { SvgIcon } from '@material-ui/core';

function RejectedIcon(props) {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" width="10.5" height="10.5" viewBox="0 0 10.5 10.5">
      <g transform="translate(-0.75 -1.105)">
        <circle style={{ fill: 'none', stroke: '#d90000', 'stroke-linecap': 'round', 'stroke-linejoin': 'round', 'stroke-width': '0.5px' }} class="a" cx="5" cy="5" r="5" transform="translate(1 1.355)"/>
        <line style={{ fill: 'none', stroke: '#d90000', 'stroke-linecap': 'round', 'stroke-linejoin': 'round', 'stroke-width': '0.5px' }} class="a" y1="4" x2="4" transform="translate(4 4.355)"/>
        <line style={{ fill: 'none', stroke: '#d90000', 'stroke-linecap': 'round', 'stroke-linejoin': 'round', 'stroke-width': '0.5px' }} class="a" x2="4" y2="4" transform="translate(4 4.355)"/>
      </g>
    </SvgIcon>
  );
}

export default RejectedIcon;