import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Grid,
  Button,
  TableBody,
  TableRow,
  TableCell,
  Table,
  TableContainer
} from '@material-ui/core';

const styles = theme => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2.5)
    },
    borderRadius: '10px'
  },
  button: {
    color: theme.palette.secondary.main,
    textTransform: 'none'
  },
  cancelbutton: {
    textTransform: 'none'
  },

  title: {
    padding: '20px'
  },
  heading: {
    color: '#545456'
  },
  boxWrapper: {
    border: 'solid 1px #c6c8ca',
    borderRadius: '10px',
    padding: '20px'
  },
  tdborder: {
    borderBottom: 'none',
    borderRight: '1px solid #d8dcde',
    textTransform: 'none',
    width: '50%',
    padding: '18px 0px'
  },
  tdnoborder: {
    borderBottom: '0px',
    color: theme.palette.primary.mainText,
    width: '50%',
    padding: '18px 0px'
  },
  actions: {
    padding: '0px'
  },
  listWrapper: {
    paddingLeft: '16px'
  },
  list: {
    marginBottom: '7px'
  }
});

const ContractDocumentDialog = props => {
  const scroll = 'paper';
  const { classes, handleClose, handleSelectFile } = props;
  return (
    <div>
      <Dialog
        open={true}
        onClose={props.handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        style={{ borderRadius: '10px' }}
        classes={{ root: 'rounded' }}
      >
        <DialogTitle className={classes.title} id="customized-dialog-title" onClose={handleClose}>
          <Typography variant="subtitle2" className={classes.heading}>
            Contract Document Guidelines
          </Typography>
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'} className={classes.root}>
          <Box>
            <Typography variant="body1">
              {
                <ul className={classes.listWrapper}>
                  <li className={classes.list}>
                    Must be fully executed (signed by both parties). If electronically signed (e.g. Docusign), please
                    include the e-signature certificate page.
                  </li>
                  <li className={classes.list}>
                    If solar PV and energy storage system are on the same contract, please provide cost breakdown
                    between both (e.g. $20,000 for batteries and $25,000 for solar PV system).
                  </li>
                  <li className={classes.list}>
                    Please include exact quantity &amp; make/model name of batteries (e.g. x2 Enphase IQ Battery 10
                    batteries).
                  </li>
                  <li className={classes.list}>
                    Must include the following warranty language: Contractor provides a 10-year labor &amp; workmanship
                    warranty for the installation of the energy storage system. Additionally, customer will have full
                    access and assignment of the 10 year manufacturer warranty for the energy storage system which
                    covers a guaranteed energy capacity and continued performance over the warranty period.
                  </li>
                </ul>
              }
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell align="center" className={classes.tdborder}>
                    <Button onClick={props.handleClose} className={classes.cancelbutton}>
                      Cancel
                    </Button>
                  </TableCell>
                  <TableCell className={classes.tdnoborder} align="center">
                    <Button color="secondary" component="label" className={classes.cancelbutton}>
                      Select File
                      <input
                        type="file"
                        style={{ display: 'none' }}
                        id="contractDocument"
                        name="contractDocument"
                        onChange={handleSelectFile}
                        multiple
                      />
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(ContractDocumentDialog);
