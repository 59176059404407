import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Loader from '../../../components/Loader';

import {
  Typography,
  Grid,
  Link,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Paper,
  CircularProgress,
  List,
  IconButton,
  FormControlLabel,
  Checkbox,
  Box
} from '@material-ui/core';
import clsx from 'clsx';
import Snackbar from '../../../components/SnakBar';
import ApplicantDetails from '../../../components/Admin/RequestDetails/applicantDetails';
import StatusDetails from '../../../components/Admin/RequestDetails/statusDetails';
import Notifcations from '../../../components/Admin/RequestDetails/notifications';
import ApplicationDocuments from '../../../components/Admin/RequestDetails/applicationDocuments';
import SendIcon from '@material-ui/icons/Send';
import CloseIcon from '@material-ui/icons/Close';
import SGIPChats from '../Chats';
import {
  getSGIPRequestDetails,
  updateAppId,
  getAppNotes,
  createAppNote,
  updateAppNote,
  deleteAppNote,
  sendMessage,
  getMessageHistoryForRequest,
  downloadBatteryReport,
  saveInstallerSecondaryEmail
} from '../actions';
import { get, isEqual } from 'lodash';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.dark
  },
  fontBold: {
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(0.5)
    }
  },
  dataHeader: {
    color: '#246AB0'
  },
  acceptButton: {
    color: theme.palette.primary.white
  },
  sgipChatWrapper: {
    height: '100%',
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    borderRadius: theme.spacing(0.5)
  },
  sgipChatContainer: {
    maxHeight: '540px',
    overflow: 'scroll',
    minHeight: '516px',
    height: '100%'
  },
  sgipChatPaper: {
    height: '98%'
  },
  displayFlex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  dialogHeader: {
    fontSize: 25,
    fontWeight: 700,
    color: '#335977',
    margin: theme.spacing(0)
  },
  dialogPadding: {
    padding: theme.spacing(2)
  },
  dialogSubHeader: {
    fontSize: 12
  },
  dashboardButton: {
    fontSize: '14px',
    textTransform: 'none',
    color: '#3685D1'
  },
  editBtn: {
    color: theme.palette.primary.blue,
    marginLeft: theme.spacing(1),
    fontSize: 14,
    cursor: 'pointer'
  },
  textField: {
    width: '90%'
  },
  columnFlex: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'start'
    }
  },
  divWrapper: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start'
    }
  },
  divWrapperTwo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start'
    }
  },
  alignText: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'start'
    }
  },
  smallButton: {
    '& .MuiButton-label': {
      justifyContent: 'start'
    }
  },
  linkStyle: {
    fontSize: '12px',
    textTransform: 'none',
    color: '#3685D1'
  }
});

export class RequestDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewUpdateAppId: false,
      application_number: '',
      appNumError: false,
      appNumHelpText: "Characters Allowed [A-Z][a-z] and '-'",
      viewApplicaitonNotes: false,
      noteText: '',
      noteError: false,
      noteErrorText: '',
      noteAddInProgress: false,
      noteDeleteInProgress: false,
      uNote: {},
      editNote: false,
      requestDocumentCheckbox: false,
      requestDocumentError: false,
      mObj: { mSubject: 'Document requested', requested_doc: true, mBody: '' },
      openRequestDocumentDialog: false,
      secondaryEmailEditable: false,
      secondaryEmail: '',
      secondaryEmailError: false,
      secondaryEmailHelperText: 'Please provide a valid email address'
    };
  }

  componentDidMount() {
    const { request_id } = this.props.match.params;
    this.props.getSGIPRequestDetails({ application_id: request_id });
  }
  saveInstallerSecondaryEmail = () => {
    const emailFormat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,10}))$/;
    if (this.state.secondaryEmail.match(emailFormat) || this.state.secondaryEmail === '') {
      this.props.saveInstallerSecondaryEmail({
        applicationId: get(this.props.sgip_application, 'application_id', ''),
        email: this.state.secondaryEmail || ''
      });
      this.setState({ secondaryEmailEditable: false, secondaryEmailError: false });
    } else {
      this.setState({ secondaryEmailEditable: true, secondaryEmailError: true });
    }
  };

  editInstallerSecondaryEmail = () => {
    this.setState({
      secondaryEmail: get(this.props.sgip_application, 'secondary_installation_poc', '') || '',
      secondaryEmailEditable: true
    });
  };

  updateApplicationId() {
    this.setState({ viewUpdateAppId: true, application_number: this.props.sgip_application.application_number || '' });
  }
  hideUpdateAppId() {
    this.setState({ viewUpdateAppId: false });
  }

  updateAppId() {
    this.setState({ viewUpdateAppId: false });
    this.props.updateAppId({
      app_id: this.props.sgip_application.application_id,
      app_number: this.state.application_number
    });
  }

  setApplicationNumber(event) {
    let value = event.target.value;
    let err = false;
    for (let i = 0; i < value.length; i++) {
      const char = value[i];
      if (!this.validateChar(char)) {
        err = true;
        break;
      }
    }
    if (err) {
      this.setState({ application_number: event.target.value, appNumError: true });
    } else {
      this.setState({ application_number: event.target.value, appNumError: false });
    }
  }

  hideSuccessDialog() {
    const { request_id } = this.props.match.params;
    this.props.getSGIPRequestDetails({ application_id: request_id });
  }

  validateChar(char) {
    if (/[A-Z]/.test(char) || /[a-z]/.test(char) || /[0-9]/.test(char) || /-/.test(char)) {
      return true;
    } else {
      return false;
    }
  }

  openApplicationNotes() {
    this.setState({ viewApplicaitonNotes: true });
    this.props.getAppNotes({ applicationId: this.props.match.params.request_id });
  }

  onStage(stage) {
    return this.props.sgip_application.current_application_status.id >= stage;
  }

  downloadBatteryReport() {
    const currMoment = moment();
    const endDate = currMoment.subtract(1, 'days').format('l');
    const past7Days = currMoment.subtract(6, 'days').format('l');
    this.props.downloadBatteryReport({
      siteId: this.props.sgip_application.site_id,
      startDate: past7Days,
      endDate: endDate
    });
  }

  hideApplicationNotes() {
    this.setState({ viewApplicaitonNotes: false });
  }

  addNoteText(e, eType) {
    if (eType === 'edit') {
      let uNote = this.state.uNote;
      uNote.body_content = e.target.value;
      this.setState({ uNote: uNote });
    } else {
      this.setState({ noteText: e.target.value });
    }
  }

  addNoteToApplication() {
    this.setState({ noteAddInProgress: true });
    this.props.createAppNote({
      applicationId: this.props.match.params.request_id,
      bContent: this.state.noteText,
      successCb: () => {
        this.setState({ noteAddInProgress: false, noteError: false, noteErrorText: '', noteText: '' });
        this.props.getAppNotes({ applicationId: this.props.match.params.request_id });
      },
      failureCb: () => {
        this.setState({
          noteAddInProgress: false,
          noteError: true,
          noteErrorText: 'We are not able to created a new note at this moment, please try later.'
        });
      }
    });
  }

  deleteNote(noteObj) {
    this.setState({ noteDeleteInProgress: true, uNote: noteObj });
    this.props.deleteAppNote({
      noteId: noteObj.id,
      successCb: () => {
        this.setState({ noteDeleteInProgress: false, uNote: {} });
        this.props.getAppNotes({ applicationId: this.props.match.params.request_id });
      },
      failureCb: () => {
        this.setState({ noteDeleteInProgress: false, uNote: {} });
      }
    });
  }

  editNote(noteObj) {
    let lObj = {
      id: noteObj.id,
      application_id: noteObj.application_id,
      body_content: noteObj.body_content
    };
    this.setState({ editNote: true, uNote: lObj });
  }

  cancelEdit() {
    this.setState({ editNote: false, uNote: {} });
  }

  updateApplicationNote() {
    this.setState({ noteEditInProgress: true });
    this.props.updateAppNote({
      noteId: this.state.uNote.id,
      bContent: this.state.uNote.body_content,
      application_id: this.state.uNote.application_id,
      successCb: () => {
        this.setState({ editNote: false, uNote: {}, noteEditInProgress: false });
        this.props.getAppNotes({ applicationId: this.props.match.params.request_id });
      },
      failureCb: () => {
        this.setState({
          noteEditInProgress: false,
          noteError: true,
          noteErrorText: 'We are not able to created a new note at this moment, please try later.'
        });
      }
    });
  }

  handleCheckboxChange = e => {
    this.setState({ requestDocumentCheckbox: e.target.checked });
    if (e.target.checked) {
      this.setState({ openRequestDocumentDialog: true });
    }
  };

  handleRequestCheckboxMessageChange = e => {
    this.setState({ mObj: { ...this.state.mObj, mBody: e.target.value } });
    if (e.target.value !== '') {
      this.setState({ requestDocumentError: false });
    } else {
      this.setState({ requestDocumentError: true });
    }
  };
  sendMessageToHO() {
    if (this.state.mObj.mBody !== '') {
      const { sgip_application } = this.props;
      this.props.sendMessage({
        app_id: sgip_application.application_id,
        mObj: this.state.mObj,
        fileName: '',
        successCb: () => {
          this.setState({ openRequestDocumentDialog: false, requestDocumentCheckbox: false }, () => {
            this.props.getMessageHistoryForRequest({ request_id: sgip_application.application_id });
          });
        }
      });
      this.setState({ mObj: { ...this.state.mObj, mBody: '' } });
    } else {
      this.setState({ requestDocumentError: true });
    }
  }

  handleRequestDocumentDialogClose = e => {
    this.setState({ openRequestDocumentDialog: false, requestDocumentError: false, requestDocumentCheckbox: false });
  };

  render() {
    const {
      classes,
      currentlySending,
      message,
      messageType,
      messageTitle,
      sgip_application,
      showMessageDialog,
      duplicate_app_number_present,
      installerView,
      app_notes_inprogress,
      app_notes,
      unread_msg_count,
      ...remainingProps
    } = this.props;
    const {
      viewUpdateAppId,
      application_number,
      appNumError,
      appNumHelpText,
      viewApplicaitonNotes,
      noteText,
      noteError,
      noteErrorText,
      noteAddInProgress,
      noteDeleteInProgress,
      uNote,
      editNote,
      noteEditInProgress
    } = this.state;
    const { request_id } = this.props.match.params;
    return (
      <div className={classes.root}>
        <Typography variant="h5" component="h5" className={classes.header}>
          {'Application Details'}
        </Typography>
        {isEqual(sgip_application, {}) || currentlySending === true ? (
          <Loader loading={currentlySending} />
        ) : (
          <Fragment>
            <Grid container direction="row">
              <Grid item xs={12} sm={6} md={3}>
                <Typography className={classes.fontBold}>
                  Enphase Id -{' '}
                  <span className={classes.dataHeader}>{get(sgip_application, 'application_id') || 'NA'}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <div className={classes.divWrapperTwo}>
                  <Typography className={classes.fontBold}>
                    Application Number -{' '}
                    <span className={classes.dataHeader}>{get(sgip_application, 'application_number') || 'NA'}</span>
                  </Typography>
                  {!installerView && (
                    <Fragment>
                      <Button size="small" onClick={() => this.updateApplicationId()}>
                        <Link style={{ fontSize: '12px', textTransform: 'none', color: '#3685D1' }}>
                          Update Application No
                        </Link>
                      </Button>
                    </Fragment>
                  )}
                </div>
                {duplicate_app_number_present && (
                  <Fragment>
                    <br />
                    <Typography variant="caption" style={{ paddingTop: '0px', color: 'crimson' }}>
                      This Application Number already exists with another application
                    </Typography>
                  </Fragment>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <div className={classes.columnFlex}>
                  <Typography component="div" className={classes.divWrapper}>
                    {this.state.secondaryEmailEditable ? (
                      <Fragment>
                        <TextField
                          value={this.state.secondaryEmail}
                          onChange={e => this.setState({ secondaryEmail: e.target.value || '' })}
                          className={classes.textField}
                          label="Secondary Installer Contact "
                          error={this.state.secondaryEmailError}
                          helperText={this.state.secondaryEmailError ? this.state.secondaryEmailHelperText : ''}
                        />
                      </Fragment>
                    ) : (
                      <Fragment>
                        <span className={classes.fontBold}>
                          {' '}
                          Secondary Installer Contact -{' '}
                          <span className={classes.dataHeader}>
                            {get(sgip_application, 'secondary_installation_poc', '--') || '--'}
                          </span>
                        </span>
                      </Fragment>
                    )}
                  </Typography>
                  <div className={classes.alignText}>
                    {this.state.secondaryEmailEditable ? (
                      <Button size="small" onClick={this.saveInstallerSecondaryEmail} className={classes.smallButton}>
                        <Link className={classes.linkStyle}>Save</Link>
                      </Button>
                    ) : (
                      <Button size="small" onClick={this.editInstallerSecondaryEmail} className={classes.smallButton}>
                        <Link className={classes.linkStyle}>Edit</Link>
                      </Button>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={2} style={{ textAlign: 'right' }}>
                <div className={classes.divWrapperTwo}>
                  {this.onStage(1) && (
                    <Button size="small" onClick={() => this.downloadBatteryReport()}>
                      <Link className={classes.dashboardButton}>Download Battery Report</Link>
                    </Button>
                  )}
                  <Button size="small" onClick={() => this.openApplicationNotes()}>
                    <Link className={classes.dashboardButton}>Application Notes</Link>
                  </Button>
                </div>
              </Grid>
            </Grid>
            <br />
            <ApplicantDetails appDetails={sgip_application} request_id={request_id} installerView={installerView} />
            <br />
            {installerView === true ? (
              <Grid container direction="row" spacing={1}>
                <Grid item xs={12} md={4}>
                  <StatusDetails appDetails={sgip_application} installerView={installerView} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Notifcations appDetails={sgip_application} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <ApplicationDocuments appDetails={sgip_application} />
                </Grid>
              </Grid>
            ) : (
              <Grid container direction="row" spacing={1}>
                <Grid item xs={12} md={3}>
                  <StatusDetails appDetails={sgip_application} installerView={installerView} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <ApplicationDocuments isFromAdmin appDetails={sgip_application} />
                </Grid>
                <Grid item xs={12} md={5}>
                  <Grid container direction="row" className={classes.sgipChatWrapper}>
                    <Grid item xs={12} className={classes.sgipChatContainer}>
                      <Paper className={classes.sgipChatPaper}>
                        <SGIPChats isFromAdmin {...remainingProps} />
                      </Paper>
                    </Grid>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.requestDocumentCheckbox}
                          onChange={e => this.handleCheckboxChange(e)}
                        />
                      }
                      label="Request document from the HO"
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Dialog
              onClose={() => this.hideUpdateAppId()}
              aria-labelledby="customized-dialog-title"
              open={viewUpdateAppId}
              maxWidth={'md'}
              id="viewUpdateAppId"
            >
              <DialogTitle id="customized-dialog-title" onClose={() => this.hideUpdateAppId()}>
                Update Application No
              </DialogTitle>
              <DialogContent>
                <TextField
                  id="appId"
                  label="Application Number"
                  variant="outlined"
                  value={application_number}
                  onChange={e => {
                    this.setApplicationNumber(e);
                  }}
                  inputProps={{
                    maxLength: 25
                  }}
                  error={appNumError}
                  helperText={appNumHelpText}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.hideUpdateAppId()} variant="contained">
                  Close
                </Button>
                {application_number !== null &&
                application_number !== '' &&
                appNumError === false && (
                  <Button
                    onClick={() => this.updateAppId()}
                    color="primary"
                    variant="contained"
                    className={classes.acceptButton}
                  >
                    Update
                  </Button>
                )}
              </DialogActions>
            </Dialog>
            <Dialog
              onClose={() => this.hideSuccessDialog()}
              aria-labelledby="customized-dialog-title"
              open={showMessageDialog}
              maxWidth={'md'}
              id="successMsg"
            >
              <DialogTitle id="customized-dialog-title" onClose={() => this.hideSuccessDialog()}>
                {messageTitle}
              </DialogTitle>
              <DialogContent>
                <Typography>{message}</Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => this.hideSuccessDialog()}
                  color="primary"
                  variant="contained"
                  className={classes.acceptButton}
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              onClose={() => this.hideApplicationNotes()}
              aria-labelledby="customized-dialog-title"
              open={viewApplicaitonNotes}
              maxWidth={'lg'}
              fullWidth={true}
              id="appNotes"
            >
              <DialogTitle id="customized-dialog-title" onClose={() => this.hideApplicationNotes()}>
                Application Notes
              </DialogTitle>
              <DialogContent>
                {app_notes_inprogress === true ? (
                  <div style={{ textAlign: 'center' }}>
                    <br />
                    <CircularProgress size={30} />
                    <br />
                  </div>
                ) : app_notes.length === 0 ? (
                  <div style={{ textAlign: 'center' }}>
                    <br />
                    <Typography variant="caption">** No Notes Available **</Typography>
                    <br />
                  </div>
                ) : (
                  <div style={{ maxHeight: '250px', overflow: 'scroll' }}>
                    <List style={{ padding: '10px' }}>
                      {app_notes.map(aNote => {
                        return (
                          <Paper key={aNote.id} style={{ padding: '10px' }} variant="outlined">
                            <div style={{ display: 'flex', alignItems: 'inherit' }}>
                              <Typography variant="caption">Created:</Typography>&nbsp;&nbsp;
                              <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                                {moment(aNote.created_at).format('Do MMM YYYY')}
                              </Typography>&nbsp;&nbsp;
                              <IconButton
                                aria-label="delete"
                                color="primary"
                                style={{ padding: '0px' }}
                                onClick={() => this.deleteNote(aNote)}
                                disabled={noteDeleteInProgress}
                              >
                                {noteDeleteInProgress === true && uNote.id === aNote.id ? (
                                  <CircularProgress size={10} />
                                ) : (
                                  <DeleteIcon fontSize="small" />
                                )}
                              </IconButton>&nbsp;&nbsp;
                              <IconButton
                                aria-label="delete"
                                color="primary"
                                style={{ padding: '0px' }}
                                onClick={() => this.editNote(aNote)}
                                disabled={editNote}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </div>
                            <br />
                            <Typography>{aNote.body_content}</Typography>
                          </Paper>
                        );
                      })}
                    </List>
                  </div>
                )}

                <br />
                {editNote === true ? (
                  <Paper style={{ padding: '10px' }} elevation={3}>
                    <Typography style={{ fontWeight: 'bold' }}>Edit Note</Typography>
                    <br />
                    <TextField
                      label="Note Content"
                      value={uNote.body_content}
                      fullWidth={true}
                      error={noteError}
                      helperText={noteErrorText}
                      onChange={e => this.addNoteText(e, 'edit')}
                      disabled={noteEditInProgress}
                    />
                    <br />
                    <br />
                    <Button
                      onClick={() => this.updateApplicationNote()}
                      color="primary"
                      variant="contained"
                      className={classes.acceptButton}
                      disabled={uNote.body_content === '' || noteEditInProgress}
                      style={{ textTransform: 'none' }}
                    >
                      {noteEditInProgress === true ? <CircularProgress size={20} /> : <span>Update</span>}
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                      onClick={() => this.cancelEdit()}
                      color="default"
                      variant="contained"
                      style={{ textTransform: 'none', color: 'black' }}
                    >
                      Cancel
                    </Button>
                  </Paper>
                ) : (
                  <Paper style={{ padding: '10px' }} elevation={3}>
                    <Typography style={{ fontWeight: 'bold' }}>Create New Note</Typography>
                    <br />
                    <TextField
                      label="Note Content"
                      value={noteText}
                      fullWidth={true}
                      error={noteError}
                      helperText={noteErrorText}
                      onChange={e => this.addNoteText(e, 'create')}
                      disabled={noteAddInProgress}
                    />
                    <br />
                    <br />
                    <Button
                      onClick={() => this.addNoteToApplication()}
                      color="primary"
                      variant="contained"
                      className={classes.acceptButton}
                      disabled={noteText === '' || noteAddInProgress}
                      style={{ textTransform: 'none' }}
                    >
                      {noteAddInProgress === true ? <CircularProgress size={20} /> : <span>Save</span>}
                    </Button>
                  </Paper>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => this.hideApplicationNotes()}
                  color="primary"
                  variant="contained"
                  className={classes.acceptButton}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </Fragment>
        )}
        {message && messageType && messageType === 'error' && <Snackbar severity={messageType} message={message} />}
        {this.state.openRequestDocumentDialog && (
          <Dialog
            open={this.state.openRequestDocumentDialog}
            onClose={e => this.handleRequestDocumentDialogClose(e)}
            fullWidth
            maxWidth="xs"
          >
            <Box className={clsx(classes.displayFlex, classes.dialogPadding)}>
              <div>
                <Typography className={classes.dialogHeader}>Message Body</Typography>
                <Typography className={classes.dialogSubHeader}>
                  This message will be sent to the homeowner and the installer along with document request.
                </Typography>
              </div>

              <IconButton>
                <CloseIcon onClick={e => this.handleRequestDocumentDialogClose(e)} />
              </IconButton>
            </Box>
            <DialogActions className={clsx(classes.displayFlex, classes.dialogPadding)}>
              <TextField
                error={this.state.requestDocumentError}
                value={this.state.mObj.mBody}
                onChange={e => {
                  this.handleRequestCheckboxMessageChange(e);
                }}
                fullWidth
                label="Add Message"
                helperText={this.state.requestDocumentError && 'Message is mandatory'}
              />
              <IconButton edge="start" color="inherit" onClick={() => this.sendMessageToHO()} size="small">
                <SendIcon color="primary" />
              </IconButton>
            </DialogActions>
          </Dialog>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getSGIPRequestDetails: payload => dispatch(getSGIPRequestDetails(payload)),
  updateAppId: payload => dispatch(updateAppId(payload)),
  getAppNotes: payload => dispatch(getAppNotes(payload)),
  createAppNote: payload => dispatch(createAppNote(payload)),
  updateAppNote: payload => dispatch(updateAppNote(payload)),
  deleteAppNote: payload => dispatch(deleteAppNote(payload)),
  sendMessage: payload => dispatch(sendMessage(payload)),
  getMessageHistoryForRequest: payload => dispatch(getMessageHistoryForRequest(payload)),
  downloadBatteryReport: payload => dispatch(downloadBatteryReport(payload)),
  saveInstallerSecondaryEmail: payload => dispatch(saveInstallerSecondaryEmail(payload))
});

const mapStateToProps = state => ({
  currentlySending: state.adminReducer.currentlySending,
  sgip_application: state.adminReducer.sgip_application,
  duplicate_app_number_present: state.adminReducer.duplicate_app_number_present,
  showMessageDialog: state.adminReducer.showMessageDialog,
  message: state.adminReducer.message,
  messageTitle: state.adminReducer.messageTitle,
  messageType: state.adminReducer.messageType,
  app_notes_inprogress: state.adminReducer.app_notes_inprogress,
  app_notes: state.adminReducer.app_notes,
  unread_msg_count: state.adminReducer.unread_msg_count
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RequestDetails));
