import React from 'react';
import DefaultText from './DefaultText';

const Text = props => {
  const { children, isBold = false, className, ...remainingProps } = props;
  return (
    <DefaultText component="div" variant={isBold ? 'body2' : 'body1'} className={className} {...remainingProps}>
      {children}
    </DefaultText>
  );
};

export default Text;
