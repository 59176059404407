import getConfig from '../config/env';

class Settings {
  constructor(config) {
    this.config = config;
  }

  getConfig() {
    return this.config;
  }

  getApiUrl(action) {
    return `${this.config.api}/${action}`;
  }

  sgipCoreUrl(url) {
    return `${this.config.sgip_core_url}/api/v1/sgip/${url}`;
  }

  enlightenUrl(url) {
    return `${this.config.enlighten_url}/${url}`;
  }

  getTokenCookieName() {
    return `${this.config.enlighten_token}`;
  }
}

export default new Settings(getConfig(process.env.NODE_ENV));
