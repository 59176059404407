import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControlLabel,
  Checkbox,
  Typography,
  Link,
  Box,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import { FilledButton } from '../../../Buttons';
import settings from '../../../../utils/settings';
import clsx from 'clsx';
import { STORE_REDIRECTION_MESSAGE } from '../../../../utils/constants';
import { forwardTo } from '../../../../utils/helper';

const useStyles = makeStyles(theme => ({
  storePayment: {
    width: '100%'
  },
  privacyText: {
    paddingBottom: '10px',
    cursor: 'default'
  },
  policyText: {
    marginRight: '0px'
  },
  link: {
    color: '#01b4de',
    fontWeight: 'bold',
    marginLeft: '5px',
    cursor: 'pointer'
  },
  currency: {
    height: theme.spacing(5),
    width: theme.spacing(36),
    display: 'block',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  amount: {
    marginLeft: theme.spacing(1)
  },
  loader: {
    color: theme.palette.primary.white,
    marginRight: theme.spacing(1),
    position: 'relative',
    top: theme.spacing(0.5)
  },
  sectionFooter: {
    backgroundColor: '#f0f0f0',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  footerText: {
    fontWeight: 600
  }
}));

const ConfirmationDialog = props => {
  const classes = useStyles();
  const { open, handleClose = () => {}, dialogText = STORE_REDIRECTION_MESSAGE } = props;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className={classes.dialogHeader} disableTypography>
        <Typography variant="h6">Confirmation</Typography>
      </DialogTitle>
      <Fragment>
        <DialogContent>
          <DialogContentText>{dialogText}</DialogContentText>
        </DialogContent>
      </Fragment>
    </Dialog>
  );
};

const StorePayment = props => {
  const [ termsPolicyCheck, setTermsPolicyCheck ] = useState(false);
  const [ paymentInProgress, setPaymentInProgress ] = useState(false);
  const classes = useStyles();
  const { serviceFee, sgipBudgetElligibility, applicationId, siteId, addToCart = () => {} } = props;

  const handlePayment = () => {
    setPaymentInProgress(true);
    addToCart({
      applicationId: applicationId,
      body: [ sgipBudgetElligibility ],
      successCbk: cartUrl => {
        let currentUrl = settings.enlightenUrl('manager/dashboard/sgip-incentive/applications');
        currentUrl = currentUrl.split('?')[0];
        const redirectUrl = currentUrl + `?siteId=${siteId}`;
        const urlForStore = getUrlToStoreWithRedirectOptions(cartUrl, redirectUrl);
        window.open(urlForStore, '_parent');
      },
      failureCbk: () => {
        forwardTo('/paymentFailed');
      }
    });
  };

  const getUrlToStoreWithRedirectOptions = (cartUrl, redirectUrl) => {
    const redirectAPI = settings.sgipCoreUrl(`payments/${applicationId}/paymentdetails`);
    return `${cartUrl}&redirect_api=${encodeURIComponent(redirectAPI)}&redirect_url=${encodeURIComponent(redirectUrl)}`;
  };

  return (
    <Box className={classes.storePayment}>
      <FormControlLabel
        className={classes.privacyText}
        control={
          <Checkbox
            name="privacy_terms"
            checked={termsPolicyCheck}
            onChange={() => setTermsPolicyCheck(!termsPolicyCheck)}
          />
        }
        label={
          <Typography className={classes.policyText}>
            I have read and understood Enphase’s
            <Link className={classes.link} target="_blank" href="https://enphase.com/en-us/legal/privacy-policy">
              Privacy Policy
            </Link>{' '}
            and
            <Link
              className={classes.link}
              target="_blank"
              href="https://enphase.com/en-us/legal/SGIP-incentive-assistance"
            >
              Terms of Service.
            </Link>
          </Typography>
        }
      />
      <Paper elevation={0} className={clsx(classes.sectionFooter)}>
        <Typography variant="caption" display="block" gutterBottom className={classes.footerText}>
          Total Amount to Pay: ${Number(serviceFee).toFixed(2)}
        </Typography>
      </Paper>
      <FilledButton disabled={!termsPolicyCheck} onClick={() => handlePayment()} className={classes.currency}>
        Proceed to Payment
      </FilledButton>
      <ConfirmationDialog open={paymentInProgress} />
    </Box>
  );
};

export default StorePayment;
