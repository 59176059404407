import { getValue, setValue } from './localStorage';
import history from '../routes/history';
import scrollIntoView from 'scroll-into-view';
import { isIOS, isMobileSafari } from 'react-device-detect';
import $ from 'jquery';
import { isEmpty } from './lodash';

export const getToken = value => {
  if (value) {
    return getValue(value);
  }
  return getValue('_enlighten_manager_token');
};

export const setToken = value => setValue(`_enlighten_manager_token`, value);

export const getSiteId = () => getValue('_site_id');

export const setSiteId = value => setValue('_site_id', value);

export const getUserType = () => getValue('_user_type');

export const setUserType = value => setValue('_user_type', value);

export const forwardTo = location => history.push(location);
export const applicationStage = (stepcompleted, paymentStatus = '') => {
  if (stepcompleted === 1) {
    forwardTo('personalDetails');
  } else if (stepcompleted === 2) {
    forwardTo('personalDetailsQuestions');
  } else if (stepcompleted === 3) {
    forwardTo('uploadDocuments');
  } else if (stepcompleted === 4) {
    if (!isEmpty(paymentStatus) && paymentStatus === 'failure') {
      forwardTo('paymentFailed');
    } else {
      forwardTo('serviceCharge');
    }
  } else if (stepcompleted === 5) {
    if (!isEmpty(paymentStatus) && paymentStatus === 'success') {
      forwardTo('paymentSuccess');
    } else {
      forwardTo('applicationTrack');
    }
  }
};
export const applicationPageTitle = stepcompleted => {
  let pageTitle = '';
  if (stepcompleted === 1) {
    pageTitle = 'Personal Details';
  } else if (stepcompleted === 2) {
    pageTitle = 'Personal Details Questions';
  } else if (stepcompleted === 3) {
    pageTitle = 'Upload Documents';
  } else if (stepcompleted === 4) {
    pageTitle = 'Pay and Submit';
  } else if (stepcompleted === 5) {
    pageTitle = 'Application Status';
  }
  return pageTitle;
};

export const applicationState = stepcompleted => {
  let status = 0;
  if (stepcompleted === 1 || stepcompleted === 2) {
    status = 1;
  } else if (stepcompleted === 3) {
    status = 2;
  } else if (stepcompleted === 4) {
    status = 3;
  } else if (stepcompleted === 5) {
    status = 4;
  }
  return status;
};

export const numberWithCommas = number => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export function scrollElementIntoView() {
  $('body').addClass('fixed');
  setTimeout(() => {
    const heightWithoutKeyboard = $(document.body).outerHeight() / 2 - 30;
    const activeElementOffset = $(document.activeElement).offset();
    const activeElementHeight = activeElementOffset.top - 114;
    if (heightWithoutKeyboard <= activeElementHeight) {
      scrollIntoView(document.activeElement);
    }
    $('body').removeClass('fixed');
  }, 250);
}
export function addInputEventListener() {
  if (isIOS && !isMobileSafari) {
    $('body').on('focus', 'input', () => {
      scrollElementIntoView();
    });
    $('body').on('focus', 'textarea', () => {
      scrollElementIntoView();
    });
  }
}

export const downloadFile = (fileUrl, isIOS, isAndroid) => {
  if (isAndroid || isIOS) {
    let download = document.createElement('a');
    const encodedFileUrl = encodeURIComponent(fileUrl);
    download.href = 'enphase://user?download=' + encodedFileUrl;
    download.download = fileUrl;
    download.target = '_self';
    document.body.appendChild(download);
    download.click();
    document.body.removeChild(download);
  } else {
    const myWindow = window.open(fileUrl, '_blank');
    setTimeout(function () {
      myWindow.close();
    }, 4000);
  }
};

export const links = {
  'San Diego Gas & Electric Company':
    'https://www.sdge.com/green-button/download-my-green-button-data',
  'Pacific Gas & Electric Company':
    'https://www.energy.gov/sites/prod/files/Using%20Green%20Button%20Download.pdf',
  'Southern California Edison':
    'https://www.sce.com/residential/rebates-savings/budget-assistant-and-you/Share-My-Data',
};

// These are Enlighten Manager Analytics code. Don't change this.
// As we are passing Measurement ID of SGIP, it will be get tracked under SGIP Services.
export const analyticCode = {
  production: 'UA-2434165-3',
  qa2: 'UA-2434165-9',
  development: 'UA-155727801-1',
};

export const measurementId = {
  production: 'G-HQDBGWJJGL',
  qa2: 'G-P6C6SGMX90',
  development: 'G-P6C6SGMX90',
};

export const getEnlightenOrigin = () => {
  const hostname = window.location.hostname;
  let origin;
  if (hostname.search('sgip.enphaseenergy') >= 0) {
    origin = 'https://enlighten.enphaseenergy.com';
  } else if (hostname.search('sgipstg') >= 0) {
    origin = 'https://qa2.enphaseenergy.com';
  } else if (hostname.search('sgipdev') >= 0) {
    origin = 'https://enlighten-qa4.enphaseenergy.com';
  } else if (window.location.port === '8000') {
    origin = 'http://localhost:7000';
  } else {
    origin = 'http://localhost:7000';
  }
  return origin;
};

export const trackEvents = (eventname, datalayer = {}) => {
  if (window.parent.location !== window.location) {
    window.parent.postMessage(
      { message: 'event', eventName: eventname, dataLayer: datalayer },
      getEnlightenOrigin()
    );
  }
};

export const constructQueryString = (queryObj, ignoreEmptyParams = false) => {
  let queryString = '';
  Object.keys(queryObj).forEach((queryKey, index) => {
    const queryConnector = queryString === '' ? '?' : '&';
    if (!ignoreEmptyParams || !isEmpty(queryObj[queryKey])) {
      queryString =
        queryString + `${queryConnector}${queryKey}=${queryObj[queryKey]}`;
    }
  });
  return queryString;
};

export const parseParams = string => {
  const params = new URLSearchParams(string);
  const entries = params.entries();
  const result = {};
  Array.from(entries).forEach(([key, value]) => {
    if (value) {
      result[key] = value;
    }
  });
  return result;
};

export const isValidToken = token => {
  return !(!token || token === '' || token === -1 || token === '-1');
};
