import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  InputLabel,
  Button,
  IconButton,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { DIN_INSTALLER_LIST_COLUMNS } from '../../../containers/Admin/constants';
import { debounce, get, isEmpty } from '../../../utils/lodash';
import {
  getSGIPDinInstallersList,
  updateSGIPDinInstallerList,
  deleteSGIPDinInstaller,
} from '../../../containers/Admin/actions';
import moment from 'moment';
import { getSGIPInstallers } from '../../../containers/SgipApp/actions';
import InstallersDropdown from '../InstallersDropdown';

const styles = theme => ({
  container: {
    maxHeight: 440,
    minHeight: 440,
  },
  tableHeader: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.yellow,
    border: 'none',
  },
  pagination: {
    backgroundColor: '#335977',
    color: '#fff',
  },
  dialogTitle: {
    color: theme.palette.primary.dark,
  },
  formLabel: {
    width: '40%',
  },
  searchInstallerWrapper: {
    marginTop: '10px',
    boxShadow: '0px 0px 6px #00000029',
    padding: '15px 20px',
  },
  wrapper: {
    border: '1px solid #707070',
    borderOpacity: '0.3',
    padding: '12px',
  },
  addButton: {
    height: '32px',
    fontSize: '16px',
    alignSelf: 'flex-end',
    color: '#fff',
    textTransform: 'capitalize',
  },
  closeIcon: {
    justifyContent: 'flex-end',
    '&:hover': {
      backgroundColor: theme.palette.primary.white,
    },
    color: 'rgba(112, 112, 112, 1)',
  },
  label: {
    fontSize: '14px',
    color: '#000000DE',
  },
  circularProgress: {
    margin: '20px',
  },
  dialogTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tableWrapper: {
    paddingRight: '10px',
    maxWidth: '100%',
    flexBasis: '100%',
  },
  searchInstallerHeading: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  inputContainer: {
    width: '60%',
    marginTop: '10px',
  },
  inputWrapper: { display: 'flex', justifyContent: 'space-between' },
});

export class DinInstallerList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      selectedInstaller: '',
      disableAddButton: true,
      installersList: [],
      installerNameText : '',
    };
    this.pageSize = 10;
    this.handleChangeInstaller = this.handleChangeInstaller.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
  }

  componentDidMount() {
    this.loadInstallersList();
  }

  componentDidUpdate(prevProps) {
    const { showDialog, sgip_installers, dinInstallers } = this.props;
    if (showDialog !== prevProps.showDialog) {
      this.setState({
        page: 0,
        selectedInstaller: '',
        disableAddButton: true,
      });
    }
    if (
      sgip_installers !== prevProps.sgip_installers ||
      dinInstallers !== prevProps.dinInstallers
    ) {
      this.setInstallersList();
    }
  }

  setInstallersList() {
    if (!isEmpty(this.props.sgip_installers)) {
      const installersList = this.props.sgip_installers.filter(obj => {
        return !this.props.dinInstallers.some(list => list.name === obj.name);
      });
      this.setState({ installersList });
    }
  }

  loadInstallersList() {
    this.props.getSGIPDinInstallersList({
      pageNumber: this.state.page === 0 ? this.state.page : this.state.page - 1,
      pageSize: this.pageSize,
      successCb: () => {},
    });
  }

  handleChangePage(event, newPage) {
    this.setState({ page: newPage }, this.loadInstallersList);
  }

  handleChangeInstaller = debounce((value, reason) => {
    if (reason === 'input' && value.length >= 3) {
      this.props.getSGIPInstallers({
        source: 'ADMIN',
        installerName: value,
      });
    }
  }, 1000);

  handleSelectInstaller = (value, reason) => {
    const { installersList } = this.state;
    let disableAddButton = this.state.disableAddButton;
    if (installersList.length > 0 && reason === 'reset') {
      const selectedInstaller = installersList.find(obj => obj.name === value);
      if (!isEmpty(selectedInstaller)) {
        disableAddButton = false;
      } else {
        disableAddButton = true;
      }
    } else {
      disableAddButton = true;
    }
    this.setState({ selectedInstaller: value, disableAddButton });
  };

  handleAddClick() {
    const { installersList } = this.state;
    const selectedInstaller = installersList.find(
      obj => obj.name === this.state.selectedInstaller
    );
    if (!isEmpty(selectedInstaller)) {
      this.props.updateSGIPDinInstallerList({
        installerDetails: {
          installer_id: selectedInstaller.company_id,
          name: this.state.selectedInstaller,
        },
        successCb: () => {
          this.setState({ selectedInstaller: '', installerNameText : '' }, this.loadInstallersList);
        },
      });
    }
  }

  handleDeleteInstaller(obj) {
    const { totalDinInstallersCount } = this.props;

    this.props.deleteSGIPDinInstaller({
      installer_id: obj.installer_id,
      successCb: () => {
        const count = totalDinInstallersCount - 1;
        const newPageCount = Math.ceil(count / this.pageSize);
        let page = this.state.page;
        if (newPageCount < this.state.page) {
          page = newPageCount;
        }
        this.setState({ page }, this.loadInstallersList);
      },
    });
  }

  render() {
    const {
      classes,
      showDialog,
      onClose,
      totalDinInstallersCount,
      dinInstallers,
      isAdmin,
    } = this.props;
    const { selectedInstaller, disableAddButton, installersList, installerNameText } = this.state;

    return (
      <Dialog open={showDialog} onClose={onClose} id="din_installer_list">
        <DialogTitle>
          <Box className={classes.dialogTitleWrapper}>
            <Typography className={classes.dialogTitle}>
              SGIP Status Dashboard
            </Typography>
            <Pagination
              count={Math.ceil(totalDinInstallersCount / this.pageSize)}
              shape="rounded"
              onChange={(event, newPage) =>
                this.handleChangePage(event, newPage)
              }
            />
            <IconButton
              classes={{ root: classes.closeIcon }}
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
              size="small"
              disableRipple={true}
            >
              <CloseIcon className={{ root: classes.closeIcon }} />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box className={classes.wrapper}>
            <Fragment>
              <Grid container direction="row">
                <Grid item xs={12} md={9} className={classes.tableWrapper}>
                  <Paper>
                    <TableContainer className={classes.container}>
                      <Table
                        stickyHeader
                        style={{ borderCollapse: 'collapse' }}
                      >
                        <TableHead>
                          <TableRow>
                            {DIN_INSTALLER_LIST_COLUMNS.map(column => {
                              if (column.id === 'actions' && !isAdmin) {
                                return null;
                              }
                              return (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{
                                    minWidth: column.minWidth,
                                    padding: '10px',
                                  }}
                                  className={classes.tableHeader}
                                >
                                  {column.label}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        </TableHead>
                        {isEmpty(dinInstallers) ? (
                          <TableBody>
                            <TableRow>
                              <TableCell
                                colSpan={DIN_INSTALLER_LIST_COLUMNS.length}
                                style={{ textAlign: 'center' }}
                              >
                                <Typography variant="caption">
                                  ** No Requests Available **
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        ) : (
                          <TableBody>
                            {dinInstallers.map((row, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell>
                                    {get(row, 'name') || 'NA'}
                                  </TableCell>
                                  <TableCell>
                                    {get(row, 'added_date')
                                      ? moment(get(row, 'added_date')).format(
                                          'DD MMMM YYYY'
                                        )
                                      : 'NA'}
                                  </TableCell>
                                  {isAdmin && (
                                    <TableCell>
                                      <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={() => {
                                          this.handleDeleteInstaller(row);
                                        }}
                                        aria-label="close"
                                        size="small"
                                        disableRipple={true}
                                      >
                                        <DeleteIcon
                                          style={{
                                            color: 'rgba(112, 112, 112, 1)',
                                          }}
                                        />
                                      </IconButton>
                                    </TableCell>
                                  )}
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
              </Grid>
            </Fragment>
            {isAdmin && (
              <Box className={classes.searchInstallerWrapper}>
                <Typography className={classes.searchInstallerHeading}>
                  Add Installer:
                </Typography>
                <Box className={classes.inputContainer}>
                  <div className={classes.inputWrapper}>
                    <div style={{ flexBasis: '60%' }}>
                      <InputLabel className={classes.label} id="installer_name">
                        Search Installer
                      </InputLabel>
                      <InstallersDropdown
                        installers={installersList}
                        installerNameText={installerNameText}
                        utilityInstaller={selectedInstaller}
                        onSelectInstaller={(value, reason) => {
                          this.setState({installerNameText : value})
                          if (value.length >= 3 && reason === 'input') {
                            this.handleChangeInstaller(value, reason);
                          } else {
                            this.handleSelectInstaller(value, reason);
                          }
                        }}
                        isInstaller={false}
                      />
                    </div>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.addButton}
                      onClick={this.handleAddClick}
                      disabled={disableAddButton}
                    >
                      Add
                    </Button>
                  </div>
                </Box>
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getSGIPDinInstallersList: payload =>
    dispatch(getSGIPDinInstallersList(payload)),
  getSGIPInstallers: payload => dispatch(getSGIPInstallers(payload)),
  updateSGIPDinInstallerList: payload =>
    dispatch(updateSGIPDinInstallerList(payload)),
  deleteSGIPDinInstaller: payload => dispatch(deleteSGIPDinInstaller(payload)),
});

const mapStateToProps = state => ({
  dinInstallers: state.adminReducer.dinInstallers,
  totalDinInstallersCount: state.adminReducer.totalDinInstallersCount,
  currentlySending: state.adminReducer.currentlySending,
  sgip_installers: state.sgipAppReducer.sgip_installers,
  getSGIPRequetsInProgress: state.adminReducer.getSGIPRequetsInProgress,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DinInstallerList));
