import React from 'react';
import { Provider } from 'react-redux';
import AppTheme from './appTheme';
import configureStore from './store';

const App = () => {
  return (
    <Provider store={configureStore()}>
      <AppTheme />
    </Provider>
  );
};

export default App;
