/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import {
  forwardTo,
  setToken,
  getToken,
  parseParams,
  isValidToken,
} from '../../utils/helper';
import {
  setMobileView,
  setEnlightenManagerToken,
  setEnlightenManagerUserType,
  setSiteIdValue,
  setUserOperatingSystem
} from './actions';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Breadcrubs from '../../components/Breadcrubs';
import settings from '../../utils/settings';
import { getAccountDetails } from '../Admin/actions';
import analytics from '../../utils/analytics';
import { includes } from 'lodash';
import { getValue, setValue } from '../../utils/localStorage';

let isTokenMissing = false;

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    height: 'calc(100% - 220px)'
  }
}));

const Layout = ({ children, isMobileView, loading, matchProps, ...rest }) => {
  const classes = useStyles();
  const { search } = window.location;
  const { platform = '' } = parseParams(search);
  const fromITK = platform === 'itk' || includes(window.location.href, 'platform=itk');

  return (
    <Fragment>
      <Header isMobileView={isMobileView} />
      <Breadcrubs matchProps={matchProps} />
      <Box className={classes.root}>{children}</Box>
      <a id="login_screen" href={settings.enlightenUrl('login')} referrerPolicy="no-referrer-when-downgrade" />
      {!fromITK && <Footer />}
    </Fragment>
  );
};

class AdminLayout extends React.Component {
  constructor(props) {
    super(props);
    const { search, pathname } = props.location;
    const cookieName =settings.getTokenCookieName();
    let cookieToken = getToken(cookieName); // token from cookie
    let { token } = parseParams(search);
    const { os } = parseParams(search);
    const { siteId } = parseParams(search);
    const { utype } = parseParams(search);

    if(!isValidToken(cookieToken) && (cookieName === 'enlighten_manager_token_qa2' || cookieName === 'enlighten_manager_token_rel')) {
      if (cookieName === 'enlighten_manager_token_qa2') cookieToken = getToken('enlighten_manager_token_rel')
      if (cookieName === 'enlighten_manager_token_rel') cookieToken = getToken('enlighten_manager_token_qa2')
    }
    if (isValidToken(cookieToken)) {
      token = cookieToken;
    }

    if (siteId) this.props.setSiteIdValue(siteId);
    if (os) this.props.setUserOperatingSystem(os);
    if (token){
      if (!getValue('referrer')) {
        setValue('referrer', document.referrer);
      }
      this.props.setEnlightenManagerToken(token);
    }
    if (utype) this.props.setEnlightenManagerUserType(utype);

    if (token && pathname) {
      setToken(token);
      forwardTo(pathname);
    }
    this.gaAnalytics = new analytics();
    if (!isValidToken(token)) {
      isTokenMissing = true;
    }
    if (!isTokenMissing) {
      props.getAccountDetails({
        successCbk: accountDetail => {
          this.gaAnalytics.setUserId(accountDetail.user_id);
        }
      });
    }
  }

  UNSAFE_componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    const isMobileView = window.innerWidth <= 1200;
    this.props.setMobileView(isMobileView);
  };

  componentDidMount = () => {
    this.handleWindowSizeChange();
    if (isTokenMissing) {
      document.getElementById('login_screen').click();
    }
  };

  render() {
    const { component: Component, isMobileView, loading, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={matchProps => (
          <Layout isMobileView={isMobileView} loading={loading} matchProps={matchProps}>
            <Component {...matchProps} />
          </Layout>
        )}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setMobileView: isMobileView => dispatch(setMobileView(isMobileView)),
  setEnlightenManagerToken: tokenVal => dispatch(setEnlightenManagerToken(tokenVal)),
  setEnlightenManagerUserType: userType => dispatch(setEnlightenManagerUserType(userType)),
  getAccountDetails: payload => dispatch(getAccountDetails(payload)),
  setSiteIdValue: siteId => dispatch(setSiteIdValue(siteId)),
  setUserOperatingSystem: os => dispatch(setUserOperatingSystem(os))
});

const mapStateToProps = state => ({
  isMobileView: state.appReducer.isMobileView,
  loading: state.appReducer.loading
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout);
