import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import { numberWithCommas } from '../../../utils/helper';
import {
  Box,
  Typography,
  Grid,
  Button,
  TableBody,
  TableRow,
  TableCell,
  Table,
  TableContainer
} from '@material-ui/core';
import { Title } from '../../Texts';

const styles = theme => ({
  dialogTitle: {
    padding: 0,
    textAlign: 'right'
  },
  root: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2.5)
    }
  },
  title: {
    padding: '20px'
  },
  heading: {
    color: '#545456'
  },
  boxWrapper: {
    border: 'solid 1px #c6c8ca',
    borderRadius: '10px',
    padding: '20px'
  },
  label: {
    color: '#545456',
    marginTop: '18px'
  },
  subLabel: {
    fontSize: 12,
    color: '#545456',
    marginTop: '18px'
  },
  tdborder: {
    borderBottom: 'none',
    textTransform: 'none',
    width: '50%',
    padding: '0px 0px',
    color: '#545456',
    fontWeight: 'normal'
  },
  tdnoborder: {
    borderBottom: '0px',
    width: '50%',
    padding: '0px 0px',
    color: '#545456'
  },
  bottomText: {
    borderBottom: '0px',
    width: '50%',
    padding: '10px 0px',
    color: '#545456'
  },
  actions: {
    padding: '0px'
  },
  redText: {
    fontWeight: 'bold',
    color: '#e05750'
  },
  yellowText: {
    fontWeight: 'bold',
    color: '#dfac34'
  },
  orangeText: {
    fontWeight: 'bold',
    color: '#e5762f'
  },
  purpleText: {
    fontWeight: 'bold',
    color: '#6e68b9'
  },
  labelSub: {
    color: '#939598',
    marginTop: '5px',
    lineHeight: 1.25,
    letterSpacing: 'normal'
  },
  equityText: {
    marginTop: '18px'
  }
});

const serviceFeeDialog = props => {
  const scroll = 'paper';
  const { classes, serviceFeePopup } = props;
  return (
    <div>
      <Dialog
        open={true}
        onClose={serviceFeePopup}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        style={{ borderRadius: '10px' }}
        classes={{ root: 'rounded' }}
      >
        <DialogTitle className={classes.title} id="customized-dialog-title" onClose={serviceFeePopup}>
          <Title className={classes.heading}>Application fees and Incentive Benefits</Title>
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'} className={classes.root}>
          <Box>
            <Grid>
              <Typography variant="body1" className={classes.labelSub}>
                There are four categories of Incentives available under SGIP. The incentive structure is mentioned below
                along with the Application Fees
              </Typography>
              <Typography variant="body2" className={classes.label}>
                Small Residential
              </Typography>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.tdborder}>Incentive Benefits</TableCell>
                      <TableCell className={classes.tdnoborder}>: $200/kWh</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tdborder}>Application Fee</TableCell>
                      <TableCell className={classes.tdnoborder}>: $500</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography variant="body2" className={classes.equityText}>
                <span className={classes.label}>Large-scale storage </span>{' '}
                <span className={classes.subLabel}>{`Systems > 10 kW`}</span>
              </Typography>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.tdborder}>Incentive Benefits</TableCell>
                      <TableCell className={classes.tdnoborder}>
                        : $250/kWh<sup className={classes.tdnoborder}>*</sup>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tdborder}>Application Fee</TableCell>
                      <TableCell className={classes.tdnoborder}>: $900</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography variant="body2" className={classes.equityText}>
                <span className={classes.label}>Equity </span>{' '}
                <span className={classes.subLabel}>(Unavailable due to lack of funds)</span>
              </Typography>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.tdborder}>Incentive Benefits</TableCell>
                      <TableCell className={classes.tdnoborder}>: $850/kWh</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tdborder}>Application Fee</TableCell>
                      <TableCell className={classes.tdnoborder}>: $1500</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography variant="body2" className={classes.label}>
                Equity Resiliency
              </Typography>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.tdborder}>Incentive Benefits</TableCell>
                      <TableCell className={classes.tdnoborder}>: $1000/kWh</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tdborder}>Application Fee</TableCell>
                      <TableCell className={classes.tdnoborder}>: $1500</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <Typography variant="body1" className={classes.labelSub} style={{ marginTop: '20px' }}>
                E.g. If you have a system capacity of 10kWh and you belong to the Equity Resiliency Category, you could
                be eligible for incentives upto ${numberWithCommas(10000)}.
              </Typography>
              <Typography variant="body1" className={classes.labelSub} style={{ marginTop: '20px' }}>
                * The rebate rate in Large Scale storage category is ${numberWithCommas(350)}/kWh if the federal tax
                credit is not claimed by System Owner.
              </Typography>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.bottomText} align="center">
                    <Button style={{ color: '#01B4DE' }} onClick={e => serviceFeePopup()}>
                      Ok
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(serviceFeeDialog);
