import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import clsx from 'clsx';
import CardSection from './CardSection';
import { Text, Title } from '../../../Texts';
import { FilledButton } from '../../../Buttons';
import { Typography, Link, FormControlLabel, Checkbox } from '@material-ui/core';
import { postSgipServicePaymentUpdate, postSgipPaymentIntent } from '../../../../containers/SgipApp/actions';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { get } from '../../../../utils/lodash';
import { forwardTo } from '../../../../utils/helper';

const styles = theme => ({
  root: {
    borderRadius: theme.spacing(1.25),
    boxShadow: '1px 2px 5px 0 rgba(152, 152, 152, 0.1)',
    backgroundColor: theme.palette.tertiary.main
  },
  comment: {
    marginTop: theme.spacing(2),
    cursor: 'default'
  },
  inline: {
    fontSize: 12 // needed for installer
  },
  currency: {},
  policyText: {
    padding: '8px 0',
    marginRight: '0px',
    color: '#545456',
    cursor: 'default'
  },
  policyTextHomeOwner: {
    padding: '10px 0px 0px 0px',
    marginRight: '0px',
    color: '#545456'
  },
  note: {
    fontSize: 12,
    color: '#939598',
    marginTop: '10px',
    cursor: 'default'
  }
});

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { termsPolicyCheck: false, permissionCheck: false };
    this.handleTermsPolicyCheckBox = this.handleTermsPolicyCheckBox.bind(this);
    this.handlePermissionCheckBox = this.handlePermissionCheckBox.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  handleSubmit = async event => {
    const { stripe, elements } = this.props;

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }
    const { siteId } = this.props;

    await this.props.postSgipPaymentIntent({
      siteId,
      successCb: async () => {
        const result = await stripe.confirmCardPayment(this.props.client_secret, {
          payment_method: {
            card: elements.getElement(CardElement)
          }
        });

        if (result.error) {
          forwardTo('paymentFailed');
        } else {
          if (result.paymentIntent.status === 'succeeded') {
            let referenceNumber = result.paymentIntent.id;
            let paymentStatus = result.paymentIntent.status;
            this.props.postSgipServicePaymentUpdate({
              siteId,
              referenceNumber,
              paymentStatus,
              application_submitted_by: this.props.isInstaller ? 'installer' : 'homeowner',
              successCb: () => {
                forwardTo('paymentSuccess');
              },
              failureCb: () => {
                forwardTo('paymentFailed');
              }
            });
          }
        }
      },
      failureCb: () => {
        forwardTo('paymentFailed');
      }
    });
  };

  handleTermsPolicyCheckBox() {
    this.setState({ termsPolicyCheck: !this.state.termsPolicyCheck });
  }
  handlePermissionCheckBox() {
    this.setState({ permissionCheck: !this.state.permissionCheck });
  }

  render() {
    const { classes, stripe, serviceFee, isInstaller, isIOS } = this.props;
    let service_fee = get(serviceFee, 'serviceFee');
    return (
      <form onSubmit={this.handleSubmit}>
        <CardSection />

        <Text className={isInstaller ? clsx(classes.comment, classes.inline) : classes.comment}>
          Your card will be charged and you will be sent an email receipt when you click Pay button at the bottom of
          this form. We use Stripe for processing your payments. At no point will your card information be stored.
          {isIOS ? (
            <a
              style={{ color: '#01B4DE', fontWeight: 'bold', textDecoration: 'none' }}
              href="enphase://user?url=https://stripe.com/docs/security/stripe"
              target="_self"
            >
              {`Click here`}
            </a>
          ) : (
            <Link
              style={{ color: '#01b4de', fontWeight: 'bold', marginLeft: '2px', marginRight: '2px', cursor: 'pointer' }}
              target="_blank"
              rel="noreferrer"
              underline="none"
              onClick={() => window.open('https://stripe.com/docs/security/stripe', '_system', 'noopener,noreferrer')}
            >
              {`Click here`}
            </Link>
          )}{' '}
          if you want to know more about security during your transaction.
        </Text>
        {isInstaller && (
          <Typography className={classes.note}>
            Note: The system owner will be notified about this application and any future communications regarding it.
          </Typography>
        )}
        {isInstaller ? (
          <FormControlLabel
            className={false ? classes.textGreyed : classes.text}
            control={
              <Checkbox
                name="privacy_terms"
                checked={this.state.permissionCheck}
                onChange={this.handlePermissionCheckBox}
              />
            }
            label={
              <Typography className={clsx(classes.policyText, classes.inline)}>
                I have System Owner’s consent to submit this application
              </Typography>
            }
          />
        ) : (
          ''
        )}

        <FormControlLabel
          className={false ? classes.textGreyed : classes.text}
          control={
            <Checkbox
              name="privacy_terms"
              checked={this.state.termsPolicyCheck}
              onChange={this.handleTermsPolicyCheckBox}
            />
          }
          label={
            <Typography className={isInstaller ? clsx(classes.policyText, classes.inline) : classes.policyText}>
              I have read and understood Enphase’s
              {isIOS ? (
                <a
                  style={{ color: '#01B4DE', fontWeight: 'bold', textDecoration: 'none', cursor: 'pointer' }}
                  href="enphase://user?url=https://enphase.com/en-us/legal/privacy-policy"
                  target="_self"
                >
                  {` Privacy Policy`}
                </a>
              ) : (
                <Link
                  style={{ color: '#01b4de', fontWeight: 'bold', marginLeft: '5px', cursor: 'pointer' }}
                  target="_blank"
                  rel="noreferrer"
                  underline="none"
                  onClick={() => window.open('https://enphase.com/en-us/legal/privacy-policy', '_blank')}
                >
                  Privacy Policy
                </Link>
              )}{' '}
              and
              {isIOS ? (
                <a
                  style={{ color: '#01B4DE', fontWeight: 'bold', textDecoration: 'none', cursor: 'pointer' }}
                  href="enphase://user?url=https://enphase.com/en-us/legal/SGIP-incentive-assistance"
                  target="_self"
                >
                  {` Terms of Service.`}
                </a>
              ) : (
                <Link
                  style={{ color: '#01b4de', fontWeight: 'bold', marginLeft: '5px', cursor: 'pointer' }}
                  target="_blank"
                  rel="noreferrer"
                  underline="none"
                  onClick={() => window.open('https://enphase.com/en-us/legal/SGIP-incentive-assistance', '_blank')}
                >
                  Terms of Service.
                </Link>
              )}
            </Typography>
          }
        />

        {!isInstaller ? (
          <FilledButton
            disabled={!stripe || !this.state.termsPolicyCheck}
            style={{ marginTop: '15px', marginBottom: '10px', height: '40px' }}
            onClick={e => this.handleSubmit()}
          >
            <Typography variant="subtitle2" className={classes.currency}>
              <b>Pay ${service_fee === '' ? '' : Number(service_fee).toFixed(2)}</b>
            </Typography>
          </FilledButton>
        ) : (
          <FilledButton
            disabled={!stripe || !this.state.permissionCheck || !this.state.termsPolicyCheck}
            style={{ marginTop: '15px', marginBottom: '10px', height: '40px' }}
            onClick={e => this.handleSubmit()}
          >
            <Typography variant="subtitle2" className={classes.currency}>
              <b>Pay ${service_fee === '' ? '' : Number(service_fee).toFixed(2)}</b>
            </Typography>
          </FilledButton>
        )}
      </form>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  postSgipPaymentIntent: payload => dispatch(postSgipPaymentIntent(payload)),
  postSgipServicePaymentUpdate: payload => dispatch(postSgipServicePaymentUpdate(payload))
});

const mapStateToProps = state => ({
  currentlySending: state.sgipAppReducer.currentlySending,
  client_secret: state.sgipAppReducer.client_secret,
  siteId: state.appReducer.siteId,
  sgip_application: state.sgipAppReducer.sgip_application,
  isInstaller: state.appReducer.isInstaller,
  isIOS: state.appReducer.isIOS
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CheckoutForm));
