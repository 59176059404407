import React from 'react';

export default function BellIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24">
      <g id="bell" transform="translate(728 -312.09)">
        <rect
          id="Rectangle_2184"
          data-name="Rectangle 2184"
          width="24"
          height="24"
          transform="translate(-728 312.09)"
          fill="none"
        />
        <path
          id="Path_2747"
          data-name="Path 2747"
          d="M-705.648,326.771l-2.038-2.038v-2.7a7.538,7.538,0,0,0-6.775-7.49v-.791a.753.753,0,0,0-.753-.753h0a.753.753,0,0,0-.753.753v.791a7.538,7.538,0,0,0-6.775,7.49v2.7l-2.038,2.038a.752.752,0,0,0-.221.532v2.258a.753.753,0,0,0,.753.753h5.27v.585a3.878,3.878,0,0,0,3.388,3.913,3.768,3.768,0,0,0,2.9-.955,3.768,3.768,0,0,0,1.24-2.79v-.753h5.27a.753.753,0,0,0,.753-.753V327.3A.752.752,0,0,0-705.648,326.771Zm-7.307,4.3a2.261,2.261,0,0,1-2.258,2.258,2.261,2.261,0,0,1-2.258-2.258v-.753h4.517Zm6.022-2.258H-723.5v-1.194l2.038-2.038a.751.751,0,0,0,.221-.532v-3.011a6.029,6.029,0,0,1,6.022-6.022,6.029,6.029,0,0,1,6.022,6.022v3.011a.751.751,0,0,0,.221.532l2.038,2.038Z"
          transform="translate(-0.742 -0.247)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
