import React from 'react';
import { Link, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { PRIVACY_POLICY_URL } from '../../../config/constants';
import { useStyles } from './styles';
import { connect } from 'react-redux';

const PrivacyPolicy = props => {
  const { isIOS, isInstaller = true } = props;
  const classes = useStyles();
  return (
    <Typography
      component="div"
      variant="caption"
      className={isInstaller ? clsx(classes.privacyText, classes.fontBold) : classes.privacyText}
    >
      You can review Enphase's
      {isIOS ? (
        <a className={classes.iosPrivacyURL} href={'enphase://user?url=' + PRIVACY_POLICY_URL} target="_self">
          {` Privacy Policy here`}
        </a>
      ) : (
        <Link
          className={classes.privacyURL}
          target="_blank"
          rel="noreferrer"
          underline="none"
          onClick={() => window.open(PRIVACY_POLICY_URL, '_blank')}
        >
          Privacy Policy here
        </Link>
      )}
    </Typography>
  );
};

const mapStateToProps = state => ({
  isIOS: state.appReducer.isIOS,
  isInstaller: state.appReducer.isInstaller
});

export default connect(mapStateToProps)(PrivacyPolicy);
