import * as React from 'react';
import { SvgIcon } from '@material-ui/core';

function InprogressIcon(props) {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.673 10.673">
      <path
        style={{ fill: '#ea6318', fillRule: 'evenodd' }}
        d="M5.336,10.673A5.336,5.336,0,1,0,0,5.336,5.336,5.336,0,0,0,5.336,10.673Zm0-.427A4.909,4.909,0,1,0,.427,5.336,4.909,4.909,0,0,0,5.336,10.246ZM5.123,2.561H5.55V6.4H5.123ZM5.55,5.977H8.965V6.4H5.55Z"
      />
    </SvgIcon>
  );
}

export default InprogressIcon;
