import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import MaskedInput from 'react-text-mask';
import { Grid, TextField, Button } from '@material-ui/core';
import { validateApplicationEditForm } from '../../../containers/Admin/helper';
import { sendForApproval } from '../../../containers/Admin/actions';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  dataHeader: {
    color: '#246AB0'
  },
  dataSubHeader: {
    fontSize: '16px',
    color: 'grey'
  },
  fullWidth: {
    width: '100%'
  },
  input: {
    textTransform: 'uppercase'
  },
  phoneNumber: {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  }
});

const hexRegEx = /[a-zA-Z0-9]/;
function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      guide={false}
      {...other}
      style={{ textTransform: 'uppercase' }}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[ hexRegEx, hexRegEx, hexRegEx, hexRegEx, hexRegEx, hexRegEx, hexRegEx, hexRegEx ]}
    />
  );
}
export class ApplicantDetailsEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aDetails: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        installer_name: '',
        installer_code: '',
        battery_installation_site_address: '',
        communication_address: '',
        utility_company_name: '',
        unit_of_encharge10: '',
        unit_of_encharge3: '',
        reason_for_edit: '',
        city: '',
        sgip_budget_information: { sgip_budget_elligibility: '' }
      },
      errorInfo: {
        first_name: { error: false, errMsg: '' },
        last_name: { error: false, errMsg: '' },
        email: { error: false, errMsg: '' },
        phone: { error: false, errMsg: '' },
        installer_name: { error: false, errMsg: '' },
        installer_code: { error: false, errMsg: '' },
        battery_installation_site_address: { error: false, errMsg: '' },
        communication_address: { error: false, errMsg: '' },
        utility_company_name: { error: false, errMsg: '' },
        unit_of_encharge10: { error: false, errMsg: '' },
        unit_of_encharge3: { error: false, errMsg: '' },
        reason_for_edit: { error: false, errMsg: '' },
        city: { error: false, errMsg: '' },
        sgip_budget_information: { sgip_budget_elligibility: { error: false, errMsg: '' } }
      }
    };
  }

  componentDidMount() {
    const { appDetails } = this.props;
    let aDetails = appDetails;
    aDetails.reason_for_edit = '';
    this.setState({ aDetails: aDetails });
  }

  handleChange(event, field) {
    let aDetails = this.state.aDetails;
    if (field === 'sgip_budget_information.sgip_budget_elligibility') {
      aDetails.sgip_budget_information.sgip_budget_elligibility = event.target.value;
      this.setState({ aDetails: aDetails });
      if (this.state.errorInfo.sgip_budget_information.sgip_budget_elligibility.error) {
        let validation = validateApplicationEditForm(this.state.errorInfo, this.state.aDetails);
        this.setState({ errorInfo: validation['errInfo'] });
      }
    } else {
      aDetails[field] = event.target.value;
      this.setState({ aDetails: aDetails });
      if (this.state.errorInfo[field].error) {
        let validation = validateApplicationEditForm(this.state.errorInfo, this.state.aDetails);
        this.setState({ errorInfo: validation['errInfo'] });
      }
    }
  }

  sendForHOApproval() {
    let validation = validateApplicationEditForm(this.state.errorInfo, this.state.aDetails);
    if (validation.isError) {
      this.setState({ errorInfo: validation.errInfo });
    } else {
      let aDetails = this.state.aDetails;
      let fData = {
        first_name: aDetails.first_name,
        last_name: aDetails.last_name,
        email: aDetails.email,
        phone: aDetails.phone,
        city: aDetails.city,
        battery_installation_site_address: aDetails.battery_installation_site_address,
        communication_address: aDetails.communication_address,
        utility_company_name: aDetails.utility_company_name,
        installer_name: aDetails.installer_name,
        installer_code: aDetails.installer_code,
        unit_of_encharge3: parseInt(aDetails.unit_of_encharge3),
        unit_of_encharge10: parseInt(aDetails.unit_of_encharge10),
        application_id: aDetails.application_id,
        sgip_budget_elligibility: aDetails.sgip_budget_information.sgip_budget_elligibility,
        reason_for_edit: aDetails.reason_for_edit
      };
      this.props.sendForApproval({ fData: fData });
    }
  }

  render() {
    const { classes } = this.props;
    const { aDetails, errorInfo } = this.state;
    return (
      <Fragment>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} md={2}>
            <TextField
              label="First Name"
              value={aDetails.first_name}
              className={classes.fullWidth}
              onChange={e => this.handleChange(e, 'first_name')}
              error={errorInfo.first_name.error}
              helperText={errorInfo.first_name.errMsg}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              label="Last Name"
              value={aDetails.last_name}
              className={classes.fullWidth}
              error={errorInfo.last_name.error}
              helperText={errorInfo.last_name.errMsg}
              onChange={e => this.handleChange(e, 'last_name')}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="City in California"
              value={aDetails.city}
              className={classes.fullWidth}
              error={errorInfo.city.error}
              helperText={errorInfo.city.errMsg}
              onChange={e => this.handleChange(e, 'city')}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Email"
              value={aDetails.email}
              className={classes.fullWidth}
              error={errorInfo.email.error}
              helperText={errorInfo.email.errMsg}
              onChange={e => this.handleChange(e, 'email')}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              label="Mobile No"
              type="number"
              inputProps={{
                min: 0,
                className: classes.phoneNumber
              }}
              value={aDetails.phone}
              className={classes.fullWidth}
              error={errorInfo.phone.error}
              helperText={errorInfo.phone.errMsg}
              onChange={e => this.handleChange(e, 'phone')}
            />
          </Grid>
        </Grid>
        <br />
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} md={2}>
            <TextField
              label="Installer Name"
              value={aDetails.installer_name}
              className={classes.fullWidth}
              error={errorInfo.installer_name.error}
              helperText={errorInfo.installer_name.errMsg}
              onChange={e => this.handleChange(e, 'installer_name')}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              label="Installer Code"
              value={aDetails.installer_code}
              className={classes.fullWidth}
              error={errorInfo.installer_code.error}
              helperText={errorInfo.installer_code.errMsg}
              onChange={e => this.handleChange(e, 'installer_code')}
              InputProps={{
                inputComponent: TextMaskCustom
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Battery Installation Site Address"
              value={aDetails.battery_installation_site_address}
              className={classes.fullWidth}
              error={errorInfo.battery_installation_site_address.error}
              helperText={errorInfo.battery_installation_site_address.errMsg}
              onChange={e => this.handleChange(e, 'battery_installation_site_address')}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Communication Address"
              value={aDetails.communication_address}
              className={classes.fullWidth}
              error={errorInfo.communication_address.error}
              helperText={errorInfo.communication_address.errMsg}
              onChange={e => this.handleChange(e, 'communication_address')}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              label="Utility Name"
              value={aDetails.utility_company_name}
              className={classes.fullWidth}
              error={errorInfo.utility_company_name.error}
              helperText={errorInfo.utility_company_name.errMsg}
              onChange={e => this.handleChange(e, 'utility_company_name')}
            />
          </Grid>
        </Grid>
        <br />
        <Grid container direction="row" spacing={3}>
          {/* <Grid item xs={12} md={2}>
            <TextField
              label="SGIP Budget"
              value={aDetails.sgip_budget_information.sgip_budget_elligibility}
              className={classes.fullWidth}
              error={errorInfo.sgip_budget_information.sgip_budget_elligibility.error}
              helperText={errorInfo.sgip_budget_information.sgip_budget_elligibility.errMsg}
              onChange={e => this.handleChange(e, 'sgip_budget_information.sgip_budget_elligibility')}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              label="Encharge 10"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              value={aDetails.unit_of_encharge10}
              className={classes.fullWidth}
              error={errorInfo.unit_of_encharge10.error}
              helperText={errorInfo.unit_of_encharge10.errMsg}
              onChange={e => this.handleChange(e, 'unit_of_encharge10')}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              label="Encharge 3"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              value={aDetails.unit_of_encharge3}
              className={classes.fullWidth}
              error={errorInfo.unit_of_encharge3.error}
              helperText={errorInfo.unit_of_encharge3.errMsg}
              onChange={e => this.handleChange(e, 'unit_of_encharge3')}
            />
          </Grid> */}
          <Grid item xs={12} md={6}>
            <TextField
              label="Reason for edit"
              value={aDetails.reason_for_edit}
              className={classes.fullWidth}
              error={errorInfo.reason_for_edit.error}
              helperText={errorInfo.reason_for_edit.errMsg}
              onChange={e => this.handleChange(e, 'reason_for_edit')}
            />
          </Grid>
        </Grid>
        <br />
        <Grid container direction="row">
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              component="span"
              size="small"
              style={{ color: 'white', float: 'right', textTransform: 'none' }}
              onClick={() => this.sendForHOApproval()}
            >
              Send for HO approval
            </Button>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  sendForApproval: payload => dispatch(sendForApproval(payload))
});

const mapStateToProps = state => ({
  currentlySending: state.adminReducer.currentlySending
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ApplicantDetailsEdit));
