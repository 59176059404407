import {
  REQUEST_FAILURE,
  SET_EMAIL_ID,
  SET_SITE_ID,
  SET_ENLIGHTEN_MANAGER_TOKEN,
  SET_ENLIGHTEN_MANAGER_USER_TYPE,
  SET_USER_OPERATING_SYSTEM,
  SET_MOBILE_VIEW,
  SET_APPLICATION_ID,
  SET_APPLICATION_STAGE,
  SET_EMAIL,
  SET_NPS_RATINGS,
  SET_STORE_PAYMENT_STATUS
} from './constants';

let initialState = {
  isMobileView: false,
  loading: false,
  emailId: '',
  siteId: '',
  enlightenManagerToken: '',
  isInstaller: true,
  isIOS: false,
  isAndroid: false,
  email: '',
  npsRatings: 0,
  paymentStatus: ''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_FAILURE:
      return { ...state, message: action.error, messageType: 'error', loading: false };
    case SET_EMAIL_ID:
      return { ...state, emailId: action.emailId };
    case SET_SITE_ID:
      return { ...state, siteId: action.siteId };
    case SET_ENLIGHTEN_MANAGER_TOKEN:
      return { ...state, enlightenManagerToken: action.enlightenManagerToken };
    case SET_APPLICATION_ID:
      return { ...state, applicationId: action.applicationId };
    case SET_NPS_RATINGS:
      return { ...state, npsRatings: action.ratings };
    case SET_APPLICATION_STAGE:
      return { ...state, applicationStage: action.applicationStage };
    case SET_ENLIGHTEN_MANAGER_USER_TYPE:
      return { ...state, isInstaller: action.isInstaller };
    case SET_USER_OPERATING_SYSTEM:
      return { ...state, isIOS: action.isIOS, isAndroid: action.isAndroid };
    case SET_MOBILE_VIEW:
      return { ...state, isMobileView: action.isMobileView };
    case SET_EMAIL:
      return { ...state, email: action.email };
    case SET_STORE_PAYMENT_STATUS:
      return { ...state, paymentStatus: action.status };
    default:
      return state;
  }
};

export default reducer;
