import { makeStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: theme.spacing(1.25),
    boxShadow: '1px 2px 5px 0 rgba(152, 152, 152, 0.1)',
    backgroundColor: theme.palette.tertiary.main
  },
  top: {
    marginTop: theme.spacing(2.25)
  },
  header: {
    fontSize: 18
  },
  headerSection: {
    marginBottom: theme.spacing(3)
  },
  text: {
    fontSize: 14,
    color: theme.palette.primary.lightBlack,
    fontWeight: 500
  },
  label: {
    marginTop: theme.spacing(1),
    fontSize: 12
  },

  privacyText: {
    color: '#939598',
    fontSize: '12px',
    fontWeight: 500,
    textAlign: 'center',
    margin: '20px'
  },

  btnblue: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: '#01b4de',
    marginTop: '25px',
    height: '40px',
    width: 'auto'
  },

  adornment: {
    fontSize: 12,
    color: '#939598'
  },

  pHide: {
    display: 'block',
    textAlign: 'center',
    height: '50%'
  },
  formControl: {
    marginTop: theme.spacing(3)
  },
  optionControl: {
    fontSize: 12,
    color: theme.palette.primary.lightBlack,
    fontWeight: 500
  },
  error: {
    color: 'red'
  },
  loadHelpIcon: {
    marginLeft: theme.spacing(0.75),
    position: 'relative',
    top: theme.spacing(0.25)
  },

  [theme.breakpoints.up('md')]: {
    label: {
      marginTop: '15px !important'
    },
    wrapper: {
      flexBasis: '45%'
    },
    text: {
      fontSize: 16
    }
  }
}));

export { useStyles };
