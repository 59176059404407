import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import DefaultButton from './DefaultButton';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.secondary.main,
    outlinedPrimary: theme.palette.secondary.main,
    '&:hover': {
      outlinedPrimary: theme.palette.secondary.main
    }
    
  }
}));

const OutlinedButton = props => {
  const { children, className, ...remainingProps } = props;
  const classes = useStyles();
  const classname = className ? clsx(classes.root, className) : classes.root;
  return (
    <DefaultButton className={classname} variant="outlined" {...remainingProps}>
      {children}
    </DefaultButton>
  );
};

export default OutlinedButton;
