import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button as MUIButton } from '@material-ui/core';

const Button = withStyles(theme => ({
  root: {
    textTransform: 'capitalize',
    borderRadius: theme.spacing(2.5),
    minWidth: theme.spacing(17.5),
    padding: '3px 16px'
  }
}))(MUIButton);

const DefaultButton = props => {
  const { children, ...remainingProps } = props;
  return (
    <Button color="secondary" disableElevation disableRipple disableFocusRipple {...remainingProps}>
      {children}
    </Button>
  );
};

export default DefaultButton;
