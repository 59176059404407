import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import { Box, Typography, Grid, Button, TableBody, TableRow, TableCell, Table } from '@material-ui/core';
import clsx from 'clsx';
import { BoxLayout } from '../../../components/Common';
import { Title } from '../../../components/Texts';
import Loader from '../../../components/Loader';
import uploadFileDetails from '../../../utils/uploadFile';
import { BILLS_TYPE } from '../../../utils/constants';
import {
  getSgipDocumentPresignedURL,
  putSgipDocumentToS3,
  deleteSgipDocumentFromS3,
  postSgipApplicationTrackUploadDocuments,
  postApplicantNotification,
  getSgipApplicationDetailsBySiteId
} from '../actions';
import { FILE_UPLOAD_SIZE } from '../constants';
import { FileAttachementBar } from '../../../components/FileAttachmentBar';
import { sendMessage } from '../../../containers/Admin/actions';

const styles = theme => ({
  dialogTitle: {
    padding: 0,
    textAlign: 'right'
  },

  button: {
    color: theme.palette.secondary.main,
    textTransform: 'none'
  },
  cancelbutton: {
    textTransform: 'none'
  },
  boxWrapper: {
    border: 'solid 1px #c6c8ca',
    borderRadius: '10px',
    padding: '20px'
  },
  label: {
    color: '#939598',
    marginTop: '5px'
  },
  tdborder: {
    borderBottom: 'none',
    borderRight: '1px solid #d8dcde',
    textTransform: 'none',
    padding: '0px'
  },
  tdnoborder: {
    borderBottom: '0px',
    color: theme.palette.primary.mainText,
    padding: '0px'
  },
  actions: {
    padding: '0px 20px'
  },

  header: {
    marginBottom: theme.spacing(1.25)
  },
  chooseFileButton: {
    padding: theme.spacing(0, 0.5, 0, 0.5),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1)
  },
  chooseFile: {
    border: '1px solid #bbb',
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.secondary.main,
    marginTop: theme.spacing(1),
    display: 'flex'
  },
  uploadButton: {
    textTransform: 'capitalize',
    borderRadius: theme.spacing(2.5),
    minWidth: theme.spacing(17.5),
    padding: '3px 16px',
    color: '#01b4de',
    marginTop: theme.spacing(1.5)
  },
  errorText: {
    color: '#f44336',
    marginLeft: theme.spacing(0.5),
    marginTop: '3px'
  },
  fileCard: {
    backgroundColor: '#5a5a5c',
    paddingTop: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  cardFileNameText: {
    color: '#ffffff',
    flexDirection: 'row',
    textAlign: 'left',
    marginTop: '0px',
    display: 'flex'
  },
  cardFileSizeText: {
    color: '#ffffff'
  },
  cardFileAttachmentIcon: {
    marginRight: theme.spacing(0.5),
    width: 25,
    height: 30,
    color: '#ffffff',
    flexDirection: 'row',
    marginLeft: '5px'
  },
  cardFileCloseIcon: {
    marginRight: theme.spacing(1),
    //width: 20,
    height: 20,
    color: '#ffffff',
    float: 'right',
    flexDirection: 'row',
    width: '20px',
    marginLeft: 'auto'
  },
  btncenter: {
    textAlign: 'center'
  }
});

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sgipApplication: {},
      application_id: '',
      docUploadStatus: 'idle',
      docDeleteStatus: 'idle',
      uploaded_docs: [],
      uploadDocumentError: {
        file_name: {
          error: false,
          msg: ''
        }
      }
    };
    this.deleteUploadedFile = this.deleteUploadedFile.bind(this);
    this.cancelDeleteUploadedFile = this.cancelDeleteUploadedFile.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.cancelHandleClose = this.cancelHandleClose.bind(this);
    this.handleOkGotIt = this.handleOkGotIt.bind(this);
  }

  handleSubmit() {
    const { sgip_application } = this.props;
    let documents = this.state.uploaded_docs;
    this.props.sendMessage({
      app_id: sgip_application.application_id,
      mObj: { mBody: '', mSubject: 'Others', requested_doc: false },
      fileName: documents.map(list => list.file_name),
      returnMsgHistory: true,
      successCb: () => {
        this.handleOkGotIt();
      },
      failureCb: () => {}
    });
  }

  handleOkGotIt() {
    const { siteId } = this.props;
    let applicationId = this.props.appId;
    let messageId = this.props.messageId;
    this.props.postApplicantNotification({
      applicationId: applicationId,
      messageId: messageId,
      notificationContent: 'The requested documents have been uploaded.',
      actionStatus: 'Completed',
      successCb: sgipApplication => {
        this.props.loadApplication(siteId);
        this.props.handleClose();
        this.props.documentRequestedPopupFlag();
      },
      failureCb: () => {
        this.props.loadApplication(siteId);
      }
    });
  }

  cancelHandleClose() {
    this.cancelDeleteUploadedFile();
    this.props.handleClose();
  }

  setDocumentError(documentError, obj, cb) {
    documentError.file_name.error = obj.error;
    documentError.file_name.msg = obj.msg;
    cb(documentError);
  }

  handleFileUpload(event) {
    //Clear error message
    let documentError = this.state.uploadDocumentError;
    this.setDocumentError(documentError, { error: false, msg: '' }, errorObj => {
      this.setState({ uploadDocumentError: errorObj });
    });

    let uploaded_docs = this.state.uploaded_docs;
    if (!document.getElementById('uploadDocuments')) {
      return;
    }

    const setStatus = status => {
      this.setState({ docUploadStatus: status });
    };

    const details = {
      event,
      setStatus,
      documentType: BILLS_TYPE.APPLICATION_UPLOAD,
      isIOS: this.props.isIOS,
      app_id: this.props.sgip_application.application_id,
      bills: uploaded_docs,
      fileUploadSize: FILE_UPLOAD_SIZE,
      getSgipDocumentPresignedURL: this.props.getSgipDocumentPresignedURL,
      putSgipDocumentToS3: this.props.putSgipDocumentToS3,
      setDocumentError: (errorDetails, status) => {
        let documentError = this.state.uploadDocumentError;
        this.setDocumentError(documentError, errorDetails, errorObj => {
          this.setState({ uploadDocumentError: errorObj });
          status && setStatus(status);
        });
      }
    };

    uploadFileDetails(details, bills => {
      this.setState({ uploaded_docs: bills });
      if (document.getElementById('uploadDocuments')) {
        document.getElementById('uploadDocuments').value = '';
        return;
      }
    });
  }

  deleteUploadedFile(display_file_name, file_name) {
    //Clear error message
    let documentError = this.state.uploadDocumentError;
    documentError.file_name.error = false;
    documentError.file_name.msg = '';
    this.setState({ uploadDocumentError: documentError });
    this.setState({ docDeleteStatus: 'processing' });
    this.props.deleteSgipDocumentFromS3({
      file_name: file_name,
      successCb1: () => {
        this.setState({ docDeleteStatus: 'success' });
        //Remove the selected file from array
        let uploadedDocs = this.state.uploaded_docs.filter(uFile => uFile.display_file_name !== display_file_name);
        this.setState({ uploaded_docs: uploadedDocs });
        Object.assign(this.state, { uploaded_docs: uploadedDocs });
        this.setState({ docDeleteStatus: 'idle' });
        if (document.getElementById('uploadDocuments')) {
          document.getElementById('uploadDocuments').value = '';
          return;
        }
      },
      failureCb1: () => {
        let documentError = this.state.uploadDocumentError;
        documentError.file_name.error = true;
        documentError.file_name.msg = 'We are facing some issue with file delete, you can try after sometime.';
        this.setState({ uploadDocumentError: documentError, docDeleteStatus: 'idle' });
      }
    });
  }

  cancelDeleteUploadedFile(fileSeq = 0) {
    if (this.state.uploaded_docs.length > 0) {
      this.setState({ docUploadStatus: 'processing' });
      this.props.deleteSgipDocumentFromS3({
        file_name: this.state.uploaded_docs[fileSeq].file_name,
        successCb1: () => {
          let docsNumber = this.state.uploaded_docs.length;
          fileSeq = fileSeq + 1;
          if (docsNumber === fileSeq) {
            this.setState({ docUploadStatus: 'idle' });
          } else {
            this.cancelDeleteUploadedFile(fileSeq);
          }
        },
        failureCb1: () => {
          this.setState({ docDeleteStatus: 'idle' });
        }
      });
    }
  }

  render() {
    const scroll = 'paper';
    const { classes, handleClose, messageList, docsUploadType, messageId } = this.props;
    const { uploaded_docs } = this.state;
    return (
      <div>
        {this.state.docUploadStatus === 'processing' && <Loader loading={true} />}
        {this.state.docDeleteStatus === 'processing' && <Loader loading={true} />}
        <Dialog
          open={true}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogContent dividers={scroll === 'paper'} style={{ padding: '0px' }}>
            {messageList.map((message, key1) => {
              return (
                <Grid className={classes.top} key={key1}>
                  {message.subject === docsUploadType &&
                  message.document_requested &&
                  message.id === messageId && (
                    <BoxLayout>
                      <Title className={classes.header}>{message.subject}</Title>
                      <Box>
                        <Typography variant="body1" className={classes.label}>
                          {message.body_content}
                        </Typography>
                        <div>
                          {
                            (this.uDoc = this.state.uploaded_docs.map((uDoc, key2) => (
                              <FileAttachementBar
                                classes={classes}
                                key={uDoc.display_file_name}
                                id={uDoc.display_file_name}
                                fileType="upload"
                                display_file_name={uDoc.display_file_name}
                                fileName={uDoc.file_name}
                                fileSize={uDoc.file_size}
                                onClose={this.deleteUploadedFile}
                              />
                            )))
                          }
                          {this.state.uploadDocumentError.file_name.msg !== '' ? (
                            <div>
                              <Typography className={classes.errorText}>
                                {this.state.uploadDocumentError.file_name.msg}
                              </Typography>
                            </div>
                          ) : (
                            <div />
                          )}
                          {this.state.uploaded_docs.length < 5 && (
                            <div className={classes.btncenter}>
                              <Button
                                className={clsx(classes.chooseFileButton, classes.button, classes.uploadButton)}
                                variant="outlined"
                                color="secondary"
                                disableElevation
                                component="label"
                                onChange={e => this.handleFileUpload(e)}
                              >
                                Choose File
                                <input
                                  type="file"
                                  style={{ display: 'none' }}
                                  id="uploadDocuments"
                                  name="uploadDocuments"
                                  onChange={() => {}}
                                  multiple
                                />
                              </Button>
                            </div>
                          )}
                        </div>
                      </Box>
                    </BoxLayout>
                  )}
                </Grid>
              );
            })}
          </DialogContent>
          <DialogActions className={classes.actions}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell align="center" className={classes.tdborder}>
                    <Button onClick={this.cancelHandleClose} className={classes.cancelbutton}>
                      Cancel
                    </Button>
                  </TableCell>
                  <TableCell className={classes.tdnoborder} align="center">
                    <Button disabled={!uploaded_docs.length > 0} className={classes.button} onClick={this.handleSubmit}>
                      Submit
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getSgipDocumentPresignedURL: payload => dispatch(getSgipDocumentPresignedURL(payload)),
  putSgipDocumentToS3: payload => dispatch(putSgipDocumentToS3(payload)),
  deleteSgipDocumentFromS3: payload => dispatch(deleteSgipDocumentFromS3(payload)),
  postSgipApplicationTrackUploadDocuments: payload => dispatch(postSgipApplicationTrackUploadDocuments(payload)),
  getSgipApplicationDetailsBySiteId: payload => dispatch(getSgipApplicationDetailsBySiteId(payload)),
  postApplicantNotification: payload => dispatch(postApplicantNotification(payload)),
  sendMessage: payload => dispatch(sendMessage(payload))
});

const mapStateToProps = state => ({
  preSignedS3Url: state.sgipAppReducer.getSgipDocumentPresignedURL,
  siteId: state.appReducer.siteId,
  sgip_application: state.sgipAppReducer.sgip_application,
  currentlySending: state.sgipAppReducer.currentlySending
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(index));
