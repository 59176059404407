import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Grid,
  Link,
  Button,
  TableBody,
  TableRow,
  TableCell,
  Table,
  TableContainer
} from '@material-ui/core';
import { get } from 'lodash';
import {
  SOUTHERN_CALIFORNIA_EDISON,
  SOUTHERN_CALIFORNIA_GAS_COMPANY,
  SAN_DIEGO_GAS_AND_ELECTRIC_COMPANY
} from '../../../containers/SgipApp/constants';
import { Title } from '../../Texts';

const styles = theme => ({
  dialogTitle: {
    padding: 0,
    textAlign: 'right'
  },
  root: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2.5)
    },
    borderRadius: '10px'
  },
  button: {
    color: theme.palette.secondary.main,
    textTransform: 'none'
  },
  cancelbutton: {
    textTransform: 'none',
    fontWeight: 'normal'
  },

  title: {
    padding: '20px'
  },
  heading: {
    color: '#545456'
  },
  subheading: {},
  boxWrapper: {
    border: 'solid 1px #c6c8ca',
    borderRadius: '10px',
    padding: '20px'
  },
  label: {
    color: '#545456',
    marginTop: '5px'
  },
  value: {
    color: '#545456',
    marginTop: '15px'
  },
  tdborder: {
    borderBottom: 'none',
    borderRight: '1px solid #d8dcde',
    textTransform: 'none',
    width: '50%',
    padding: '18px 0px'
  },
  tdnoborder: {
    borderBottom: '0px',
    color: theme.palette.primary.mainText,
    width: '50%',
    padding: '18px 0px'
  },
  actions: {
    padding: '0px'
  },
  redText: {
    fontWeight: 'bold',
    color: '#e05750'
  },
  yellowText: {
    fontWeight: 'bold',
    color: '#dfac34'
  },
  orangeText: {
    fontWeight: 'bold',
    color: '#e5762f'
  },
  purpleText: {
    fontWeight: 'bold',
    color: '#6e68b9'
  },
  labelSub: {
    color: '#545456',
    marginTop: '5px'
  },
  closebutton: {
    marginRight: 'auto',
    marginLeft: 'auto',
    color: '#01b4de',
    padding: theme.spacing(2)
  }
});

const hftdDialog = props => {
  const scroll = 'paper';
  const { classes, handleClose, findOutType, isIOS, isInstaller, sgipApplication } = props;
  const selectedUtility = get(sgipApplication, 'utility_company_name', '');
  const utitlitiesWithPspsProgramEmail =
    selectedUtility === SOUTHERN_CALIFORNIA_EDISON ||
    selectedUtility === SOUTHERN_CALIFORNIA_GAS_COMPANY ||
    selectedUtility === SAN_DIEGO_GAS_AND_ELECTRIC_COMPANY;
  const isPSPS = findOutType === 'psps';

  const utilityEmail = () => {
    if (selectedUtility === SOUTHERN_CALIFORNIA_EDISON) {
      return 'sgipgroup@sce.com';
    } else if (selectedUtility === SOUTHERN_CALIFORNIA_GAS_COMPANY || SAN_DIEGO_GAS_AND_ELECTRIC_COMPANY) {
      return 'sgip@energycenter.org';
    }
  };

  return (
    <div>
      <Dialog
        open={true}
        onClose={props.handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        style={{ borderRadius: '10px' }}
        classes={{ root: 'rounded' }}
      >
        <DialogTitle className={classes.title} id="customized-dialog-title" onClose={handleClose}>
          <Title className={classes.heading}>
            {isPSPS && utitlitiesWithPspsProgramEmail ? (
              'Email to Program Administrator'
            ) : (
              'Redirecting to CPUC FireMap'
            )}
          </Title>
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'} className={classes.root}>
          <Box>
            <Grid>
              {!(isPSPS && utitlitiesWithPspsProgramEmail) && (
                <Typography variant="body2" className={classes.label}>
                  Here is how to read the map:
                </Typography>
              )}
              {findOutType === 'hftd' && (
                <Typography variant="body1" className={classes.labelSub}>
                  If your address is in <span className={classes.redText}>Red</span> or{' '}
                  <span className={classes.yellowText}>Yellow</span> zone you are in Tier 2 or Tier 3 HFTD
                </Typography>
              )}
              {findOutType === 'psps' &&
              !utitlitiesWithPspsProgramEmail && (
                <Typography variant="body1" className={classes.labelSub}>
                  Check if {!isInstaller ? `your` : `system owner's`} address is in{' '}
                  <span className={classes.orangeText}>Orange</span> Zone - If yes,{' '}
                  {!isInstaller ? `you have ` : `system owner has `}
                  been subjected to two or more PSPS events.
                </Typography>
              )}
              {isPSPS &&
              utitlitiesWithPspsProgramEmail && (
                <Typography variant="body2" className={classes.subheading}>
                  Please email the program administrator to find out if your home experiences two or more PSPS events
                  per SGIP guidelines.
                  <br />
                  Email to {utilityEmail()}
                </Typography>
              )}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions className={classes.actions}>
          {isPSPS && utitlitiesWithPspsProgramEmail ? (
            <Button onClick={props.handleClose} className={classes.closebutton}>
              Close
            </Button>
          ) : (
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell align="center" className={classes.tdborder}>
                      <Button onClick={props.handleClose} className={classes.cancelbutton}>
                        Cancel
                      </Button>
                    </TableCell>
                    <TableCell className={classes.tdnoborder} align="center">
                      {isIOS ? (
                        <a
                          style={{ color: '#01B4DE', fontWeight: 'bold', textDecoration: 'none' }}
                          href="enphase://user?url=https://ia.cpuc.ca.gov/firemap/"
                          target="_self"
                        >
                          {`View FireMap`}
                        </a>
                      ) : (
                        <Link
                          style={{ color: '#01B4DE', fontWeight: 'bold', cursor: 'pointer' }}
                          target="_blank"
                          rel="noreferrer"
                          underline="none"
                          onClick={() => window.open('https://ia.cpuc.ca.gov/firemap/', '_blank', 'noopener,noreferrer')}
                        >
                          {`View FireMap`}
                        </Link>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(hftdDialog);
