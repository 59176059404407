import React, { Component } from 'react';
import { Router, Switch } from 'react-router-dom';
import history from './history';
import { DefaultLayout, AdminLayout, InstallerLayout } from '../containers/Layouts';
import StatusDashboard from '../containers/Admin/StatusDashboard';
import ApplyIncentive from '../containers/SgipApp/ApplyIncentive';
import NoMatch from '../components/NoMatch';
import RequestDetails from '../containers/Admin/RequestDetails';
import MessageHistory from '../containers/Admin/MessageHistory';
import SgipServiceCharge from '../containers/SgipApp/SgipServiceCharge';
import PrimaryDetails from '../containers/SgipApp/PrimaryDetails';
import PersonalDetails from '../containers/SgipApp/PersonalDetails';
import PersonalDetailQuestions from '../containers/SgipApp/PersonalDetailQuestions';
import UploadDocuments from '../containers/SgipApp/UploadDocuments';
import ApplicationDetails from '../containers/SgipApp/ApplicationDetails';
import ApplicationStatusTrack from '../containers/SgipApp/ApplicationStatusTrack';
import SgipLiveStatus from '../containers/SgipApp/SgipLiveStatus';
import PaymentSuccess from '../components/SgipApp/PaymentSuccess';
import PaymentFailure from '../components/SgipApp/PaymentFailure';
import ScrollToTop from '../components/ScrollToTop';
import Chats from '../containers/Admin/Chats';
import AttestationForm from '../containers/SgipApp/AttestationForm';
import NpsFeedbackDialog from '../components/SgipApp/NpsFeedbackDialog';

export default class Routes extends Component {
  render() {
    return (
      <Router history={history}>
        <ScrollToTop />
        <Switch>
          <DefaultLayout exact path="/" component={ApplyIncentive} />
          <AdminLayout exact path="/status_dashboard" component={StatusDashboard} />
          <AdminLayout exact path="/request/:request_id" component={RequestDetails} />
          <AdminLayout exact path="/request/:request_id/msg_history" component={MessageHistory} />

          <InstallerLayout exact path="/sgip-applications" component={StatusDashboard} />
          <InstallerLayout exact path="/sgip-request/:request_id" component={RequestDetails} />

          <DefaultLayout exact path="/serviceCharge" component={SgipServiceCharge} />
          <DefaultLayout exact path="/primaryDetails" component={PrimaryDetails} />
          <DefaultLayout exact path="/personalDetails" component={PersonalDetails} />
          <DefaultLayout exact path="/uploadDocuments" component={UploadDocuments} />
          <DefaultLayout exact path="/personalDetailsQuestions" component={PersonalDetailQuestions} />
          <DefaultLayout exact path="/applicationDetails" component={ApplicationDetails} />
          <DefaultLayout exact path="/applicationTrack" component={ApplicationStatusTrack} />
          <DefaultLayout exact path="/paymentSuccess" component={PaymentSuccess} />
          <DefaultLayout exact path="/paymentFailed" component={PaymentFailure} />
          <DefaultLayout exact path="/liveStatus" component={SgipLiveStatus} />
          <DefaultLayout exact path="/request/:request_id/chats" component={Chats} />
          <DefaultLayout exact path="/attestationForm" component={AttestationForm} />
          <DefaultLayout exact path="/feedback" component={NpsFeedbackDialog} />
          <DefaultLayout component={NoMatch} />
        </Switch>
      </Router>
    );
  }
}
