import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import DefaultText from './DefaultText';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: 11,
    color: theme.palette.primary.subText
  }
}));

const Label = props => {
  const { children, className, ...remainingProps } = props;
  const classes = useStyles();
  const classname = className ? clsx(classes.root, className) : classes.root;
  return (
    <DefaultText component="div" className={classname} {...remainingProps}>
      {children}
    </DefaultText>
  );
};

export default Label;
