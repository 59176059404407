import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import {
  forwardTo,
  setToken,
  getToken,
  constructQueryString,
  parseParams,
  isValidToken,
} from '../../utils/helper';
import {
  setMobileView,
  setSiteIdValue,
  setEnlightenManagerToken,
  setEnlightenManagerUserType,
  setUserOperatingSystem,
  setApplicationId,
  setNpsRatings,
  setApplicationStage,
  setEmail,
  setStorePaymentStatus
} from './actions';
import { getAccountDetails } from '../Admin/actions';
import analytics from '../../utils/analytics';
import settings from '../../utils/settings';
import { getValue, setValue } from '../../utils/localStorage';

const Layout = ({ children, isMobileView, loading, matchProps, ...rest }) => {
  return <Fragment>{children}</Fragment>;
};

let isTokenMissing = false;

class DefaultLayout extends React.Component {
  constructor(props) {
    super(props);
    const { search, pathname } = props.location;
    let {
      token,
      os,
      siteId,
      utype,
      application_id,
      application_stage,
      email,
      rating,
      status
    } = parseParams(search);
    // this.setSite(siteId);

    let cookieToken = getToken(settings.getTokenCookieName());

    //if (utype) setUserType(utype);
    if (siteId) this.props.setSiteIdValue(siteId);
    if (os) this.props.setUserOperatingSystem(os);
    if (application_id) this.props.setApplicationId(application_id);
    if (application_stage) this.props.setApplicationStage(application_stage);
    if (email) this.props.setEmail(email);
    if (rating) this.props.setNpsRatings(rating);
    if (status) this.props.setStorePaymentStatus(status);
    this.props.setEnlightenManagerUserType(utype);

    const params = constructQueryString({ status }, true);
    if (isValidToken(cookieToken)) {
      token = cookieToken;
    }
    console.log(getValue('referrer'),"getValue('referrer')");
    if (token) {
      if (!getValue('referrer')) {
        setValue('referrer', document.referrer);
      }
     this.props.setEnlightenManagerToken(token);
    }
    if (token && pathname) {
      setToken(token);
      forwardTo(pathname + params);
    }
    if (application_id || application_stage) {
      forwardTo(pathname);
    }
    if (!isValidToken(token)) {
      isTokenMissing = true;
    }

    this.gaAnalytics = new analytics();
    if (!isTokenMissing) {
      this.props.getAccountDetails({
        successCbk: accountDetail => {
          this.gaAnalytics.setUserId(accountDetail.user_id);
        }
      });
    }
  }

  // setSite = (siteid = '') => {
  //   const siteIdValue = siteid || getSiteId();
  //   this.props.setSiteIdValue(siteIdValue);
  // };

  UNSAFE_componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    const isMobileView = window.innerWidth <= 1200;
    this.props.setMobileView(isMobileView);
  };

  componentDidMount = () => {
    this.handleWindowSizeChange();
  };

  render() {
    const { component: Component, isMobileView, loading, emailId, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={matchProps => (
          <Layout isMobileView={isMobileView} loading={loading} matchProps={matchProps}>
            <Component {...matchProps} emailId={emailId} />
          </Layout>
        )}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setMobileView: isMobileView => dispatch(setMobileView(isMobileView)),
  setEnlightenManagerToken: tokenVal => dispatch(setEnlightenManagerToken(tokenVal)),
  setSiteIdValue: siteId => dispatch(setSiteIdValue(siteId)),
  getAccountDetails: payload => dispatch(getAccountDetails(payload)),
  setEnlightenManagerUserType: userType => dispatch(setEnlightenManagerUserType(userType)),
  setUserOperatingSystem: os => dispatch(setUserOperatingSystem(os)),
  setApplicationId: applicationId => dispatch(setApplicationId(applicationId)),
  setApplicationStage: applicationStage => dispatch(setApplicationStage(applicationStage)),
  setNpsRatings: rating => dispatch(setNpsRatings(rating)),
  setEmail: email => dispatch(setEmail(email)),
  setStorePaymentStatus: status => dispatch(setStorePaymentStatus(status))
});

const mapStateToProps = state => ({
  isMobileView: state.appReducer.isMobileView,
  loading: state.appReducer.loading
});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
