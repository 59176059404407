import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Grid,
  Button,
  TableBody,
  TableRow,
  TableCell,
  Table,
  TableContainer
} from '@material-ui/core';
import { Title } from '../../Texts';

const styles = theme => ({
  dialogTitle: {
    padding: 0,
    textAlign: 'right'
  },
  root: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2.5)
    },
    borderRadius: '10px'
  },
  button: {
    color: theme.palette.secondary.main,
    textTransform: 'none'
  },
  cancelbutton: {
    textTransform: 'none'
  },
  title: {
    padding: '20px'
  },
  heading: {
    color: '#545456'
  },
  boxWrapper: {
    border: 'solid 1px #c6c8ca',
    borderRadius: '10px',
    padding: '20px'
  },
  tdborder: {
    borderBottom: 'none',
    borderRight: '1px solid #d8dcde',
    textTransform: 'none',
    width: '50%',
    padding: '18px 0px'
  },
  tdnoborder: {
    borderBottom: '0px',
    width: '50%',
    padding: '18px 0px'
  },
  proceed: {
    color: '#01B4DE',
    textTransform: 'none'
  },
  actions: {
    padding: '0px'
  }
});

const EquityResiliencyDialog = props => {
  const scroll = 'paper';
  const { classes, handleClose, handleProceed, isIOS } = props;
  const dialogClasses = isIOS ? { root: 'rounded', container: classes.container } : { root: 'rounded' };
  return (
    <div>
      <Dialog open={true} onClose={props.handleClose} scroll={scroll} classes={dialogClasses}>
        <DialogTitle className={classes.title} id="customized-dialog-title" onClose={handleClose}>
          <Title className={classes.heading}>Important Notification</Title>
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'} className={classes.root}>
          <Box>
            <Grid>
              <Typography>
                There is a waitlist in place for this budget category. There is no guarantee to be selected from the
                waitlist since the waitlist is based on previous applications getting cancelled and the rebate money
                from cancelled projects being reallocated to the waitlist.
              </Typography>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell align="center" className={classes.tdborder}>
                    <Button onClick={props.handleClose} className={classes.cancelbutton}>
                      Cancel
                    </Button>
                  </TableCell>
                  <TableCell className={classes.tdnoborder} align="center">
                    <Button onClick={handleProceed} className={classes.proceed}>
                      Proceed
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(EquityResiliencyDialog);
