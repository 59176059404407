import React from 'react';
import { Box } from '@material-ui/core';
import { useMobileStyles, useDesktopStyles } from './style';

const BoxLayout = props => {
  const { children, isMobileView = true, noPadding, disableBackground, style } = props;
  const mobileClasses = useMobileStyles();
  const desktopClasses = useDesktopStyles();
  const classes = isMobileView ? mobileClasses : desktopClasses;
  let applyClass = classes.root;
  if (noPadding) {
    applyClass = classes.rootnopadding;
  } else if (disableBackground) {
    applyClass = classes.disableBackground;
  } else {
    applyClass = classes.root;
  }

  return (
    <Box style={style} className={applyClass}>
      {children}
    </Box>
  );
};

export default BoxLayout;
