import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import StatusTracking from '../../../components/SgipApp/StatusTracking';
import {
  Box,
  Grid,
  Typography,
  Divider,
  TableBody,
  TableRow,
  TableCell,
  Table,
  RadioGroup,
  Radio,
  FormHelperText,
  FormControl,
  FormLabel,
  FormControlLabel,
  IconButton,
  Tooltip
} from '@material-ui/core';
import { Link, Button } from '@material-ui/core';
import clsx from 'clsx';
//import { useMobileStyles, useDesktopStyles } from './style';
import { BoxLayout } from '../../../components/Common';
import { Title } from '../../../components/Texts';
import FilledButton from '../../../components/Buttons/FilledButton';
import { baselineLink, forwardTo } from '../../../utils/helper';
import {
  EQUITY,
  SMALL_RESIDENTIAL,
  EQUITY_RESILIENCY,
  PACIFIC_GAS_AND_ELECTRIC_COMPANY,
  LARGE_SCALE_STORAGE,
  SAN_DIEGO_GAS_AND_ELECTRIC_COMPANY,
  SOUTHERN_CALIFORNIA_EDISON
} from '../constants';
import {
  postSgipBudgetQuestionnaires,
  getSgipApplicationDetailsBySiteId,
  postNotifyMeDetails,
  getSgipUserSelectionData,
  getSgipIncentiveRates,
  getSgipConfigs,
  getCpucURL
} from '../actions';
import Loader from '../../../components/Loader';
import { get } from '../../../utils/lodash';
import { applicationState, numberWithCommas, trackEvents } from '../../../utils/helper';
import {
  EQUITY_AND_ELIGIBILITY_LINK,
  QA_BASELINE_CUST_HO,
  QA_BASELINE_CUST_INST,
  QA_MED_BASELINE_HO,
  QA_MED_BASELINE_INST,
  QA_QUALIFY_EQUITY_HO,
  QA_QUALIFY_EQUITY_INST,
  SGIP_UTILITY_COMPANY_MAP,
  COMMON_MEDICAL_BASELINE_URL,
  RESILIENCY_NOT_APPLICABLE_CATEGORIES
} from '../../../utils/constants';
import HftdDialog from '../../../components/SgipApp/HftdDialog';
import EquityAndSmallResidentailDialog from '../../../components/SgipApp/EquityAndSmallResidentailDialog';
import OkNotifyMe from '../../../components/SgipApp/okNotifyMe';
import { isEqual, isEmpty } from '../../../utils/lodash';
import IncomeQualifierDialog from '../../../components/SgipApp/IncomeQualifierDialog';
import PrivacyPolicy from '../../../components/SgipApp/PrivacyPolicy';
import EquityResiliencyDialog from '../../../components/SgipApp/EquityResiliencyDialog';
import QuestionPersonalDetails from '../../../components/SgipApp/PersonalDetails/QuestionPersonalDetails';
import BellIcon from './Icons/Bell';

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5)
  },
  boxWrapper: {
    borderRadius: theme.spacing(1.25)
  },
  quesHighlight: {
    borderColor: `${theme.palette.warning.main}!important`,
    [theme.breakpoints.down('md')]: {
      border: `1px solid ${theme.palette.warning.main}`,
      borderRadius: theme.spacing(1.25)
    }
  },
  top: {
    marginTop: theme.spacing(2.25)
  },
  description: {
    marginBottom: theme.spacing(2),
    lineHeight: 1.36
  },

  qa: {
    color: theme.palette.primary.mainText,
    padding: '20px'
  },
  information: {
    color: '#939598'
  },
  informationans: {
    fontSize: 14,
    color: '#939598',
    fontStyle: 'italic'
  },

  radio: {
    marginLeft: 2
  },
  tdborder: {
    borderBottom: '0px',
    padding: '0px',
    borderRight: '1px solid #d8dcde',
    color: theme.palette.primary.mainText
  },
  tdnoborder: {
    borderBottom: '0px',
    padding: '0px',
    color: theme.palette.primary.mainText
  },
  boxpadding: {
    padding: '0px'
  },
  nextbtn: {
    paddingTop: '16px',
    textAlign: 'center'
  },
  selectOption: {
    backgroundColor: '#01b5de',
    color: '#ffffff'
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))'
  },
  error: {
    color: 'red'
  },
  warning: {
    color: theme.palette.warning.main
  },
  links: {
    color: '#01b4de',
    marginTop: '10px',
    fontWeight: 500
  },
  header: {
    marginBottom: theme.spacing(1.87)
  },
  link: {
    color: '#01b4de'
  },
  btn: {
    height: '40px',
    margin: '10px 0'
  },
  privacyText: {
    color: '#939598',
    fontWeight: 500,
    textAlign: 'center',
    margin: '20px'
  },
  dHeader: {
    display: 'block',
    margin: '20px 0 15px 20px'
  },
  mHeader: {
    display: 'block'
  },
  bellIconButton: {
    background: theme.palette.secondary.main,
    padding: theme.spacing(0.5),
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  disableIcon: {
    opacity: 0.5,
    cursor: 'default',
    pointerEvents: 'none'
  },
  categoryLabel: {
    margin: theme.spacing(0.25, 0),
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(0.5, 0),
      display: 'flex',
      alignItems: 'flex-start',
      '& .MuiFormControlLabel-label': {
        padding: theme.spacing(1, 0, 0)
      }
    }
  },
  [theme.breakpoints.down('md')]: {
    qaItem: {
      marginBottom: '20px'
    }
  },
  [theme.breakpoints.up('md')]: {
    root: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between'
    },
    dWrapper: {
      margin: '30px 194px',
      boxShadow: '0 2px 10px 0 rgba(63, 67, 71, 0.08)',
      background: '#fff',
      borderRadius: '3px'
    },
    qaItem: {
      flexBasis: '45%',
      border: '1px solid rgba(136, 136, 136, 0.2)',
      borderRadius: '3px',
      height: '100%',
      '&:nth-child(odd)': {
        margin: '10px 10px 10px 0'
      },
      '&:nth-child(even)': {
        margin: '10px 0 10px 10px'
      }
    },
    mHeader: {
      display: 'block'
    },
    dHeader: {
      display: 'block',
      margin: '20px 0 0 20px'
    },
    privacyText: {
      margin: '20px auto'
    },
    btn: {
      width: 'auto',
      height: '40px',
      margin: '10px'
    },
    qa: {
      color: theme.palette.primary.mainText,
      padding: '20px',
      display: 'flex',
      alignItems: 'center',
      height: '32px'
    },
    fullWidthGrid: {
      width: '100%'
    }
  }
});

const ENCHARGE3_POWER = 1.28;
const ENCHARGE10_POWER = 3.84;
const IQ_BATTERY_5P_POWER = 3.84;

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeState: 1,
      sgipBudget: {
        q1: '',
        q2: '',
        q3: '',
        q4: '',
        q5: '',
        q6: '',
        q7: '',
        q8: '',
        sgip_budget_eligibility: '',
        incentive: {
          'Equity Budget': 0,
          'Small Residential Budget': 0,
          'Equity Resiliency Budget': 0,
          'Large Scale Storage': 0
        },
        site_id: ''
      },
      optedBudgetCategory: '',
      sgipBudgetError: {
        q1: { error: false, msg: '' },
        q2: { error: false, msg: '' },
        q3: { error: false, msg: '' },
        q4: { error: false, msg: '' },
        q5: { error: false, msg: '' },
        q6: { error: false, msg: '' },
        q7: { error: false, msg: '' },
        categoryErr: { error: false, msg: '' }
      },
      hftdFlag: false,
      incomeQualifierFlag: false,
      findOutType: '',
      equitySmallResidentialFlag: false,
      equityResiliencyFlag: false,
      notifyMeButtonsDisable: false,
      equityResiliencyButtonsDisable: false,
      shouldBlockJourney: {
        'Equity Budget': false,
        'Small Residential Budget': false,
        'Equity Resiliency Budget': false,
        'Large Scale Storage': false
      },
      smallResidentialButtonsDisable: false,
      shouldDisplayQ7: false,
      shouldDisplayQ8: false,
      highlightQ8: false,
      isPaymentCompleted: false,
      baselineLink: '',
      /* For any parameter regarding all categories use this by adding variables */
      budgetCategories: {
        'Equity Budget': { notifyMe: false },
        'Small Residential Budget': { notifyMe: false },
        'Equity Resiliency Budget': { notifyMe: false },
        'Large Scale Storage': { notifyMe: false }
      },
      isNotifyMe: false,
      optedNotifyMeCategory: ''
    };

    this.largeScaleStorageCapasity = 10; // kW
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateSgipBudgetDetails = this.validateSgipBudgetDetails.bind(this);
    this.validateValues = this.validateValues.bind(this);
    this.calculateIncentive = this.calculateIncentive.bind(this);
    this.hftdPopup = this.hftdPopup.bind(this);
    this.incomeQualifierDialog = this.incomeQualifierDialog.bind(this);
    this.equitySmallResidentialPopup = this.equitySmallResidentialPopup.bind(this);
    this.okNotifyMe = this.okNotifyMe.bind(this);
    this.cancelApplication = this.cancelApplication.bind(this);
    this.getStorageCapacity = this.getStorageCapacity.bind(this);
    this.sgipBugetCategories = [EQUITY, SMALL_RESIDENTIAL, EQUITY_RESILIENCY];
    this.encharge3Rates = {
      'Equity Budget': 0,
      'Small Residential Budget': 0,
      'Equity Resiliency Budget': 0,
      'Large Scale Storage': 0
    };
    this.encharge10Rates = {
      'Equity Budget': 0,
      'Small Residential Budget': 0,
      'Equity Resiliency Budget': 0,
      'Large Scale Storage': 0
    };
    this.iqBattery5pRates = {
      'Equity Budget': 0,
      'Small Residential Budget': 0,
      'Equity Resiliency Budget': 0,
      'Large Scale Storage': 0
    };
    this.multipleCategoryGtLargeScaleCapacity = {
      'Equity Resiliency Budget Equity Qualify': [EQUITY_RESILIENCY, EQUITY, LARGE_SCALE_STORAGE],
      'Equity Resiliency Budget': [EQUITY_RESILIENCY, LARGE_SCALE_STORAGE],
      'Equity Budget': [EQUITY, LARGE_SCALE_STORAGE]
    };
    this.multipleCategoryLtLargeScaleCapacity = {
      'Equity Resiliency Budget Equity Qualify': [EQUITY_RESILIENCY, EQUITY, SMALL_RESIDENTIAL],
      'Equity Resiliency Budget': [EQUITY_RESILIENCY, SMALL_RESIDENTIAL],
      'Equity Budget': [EQUITY, SMALL_RESIDENTIAL]
    };
    this.multipleCategories = [EQUITY, SMALL_RESIDENTIAL, EQUITY_RESILIENCY, LARGE_SCALE_STORAGE];
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { siteId } = this.props;
    this.props.getSgipIncentiveRates({
      successCb: incentiveRates => {
        this.calculateIncentive(incentiveRates);
      }
    });
    this.loadApplication(siteId);
    this.loadCountryDetails();
  }

  loadApplication(siteId) {
    this.props.getSgipApplicationDetailsBySiteId({
      siteId: siteId,
      isInstaller: this.props.isInstaller,
      successCb: sgip_application => {
        const { budgetCategories } = this.state;
        let modifiedBudgetCategories = { ...budgetCategories };
        let sgipBudgetData = this.state.sgipBudget;
        let shouldDisplayQ7 = false;
        let shouldDisplayQ8 = false;

        if (get(sgip_application.payment_details, 'payment_status', '') === 'succeeded') {
          this.setState({ isPaymentCompleted: true });
        }

        if (!isEmpty(sgip_application.sgip_budget_information)) {
          sgipBudgetData.q2 = sgip_application.sgip_budget_information.q2_house_in_tier2_or_tier3_hftd ? 'yes' : 'no';
          sgipBudgetData.q3 = sgip_application.sgip_budget_information.q3_subject_to_two_or_more_psps_events
            ? 'yes'
            : 'no';
          if (sgipBudgetData.q2 === 'yes' || sgipBudgetData.q3 === 'yes') {
            sgipBudgetData.q1 = sgip_application.sgip_budget_information.q1_live_in_low_income_housing_community
              ? 'yes'
              : 'no';
            sgipBudgetData.q4 = sgip_application.sgip_budget_information.q4_medical_baseline_customer_defined_by_cpuc
              ? 'yes'
              : 'no';
            sgipBudgetData.q5 = sgip_application.sgip_budget_information
              .q5_have_you_notified_your_utility_about_your_illness
              ? 'yes'
              : 'no';
            sgipBudgetData.q6 = sgip_application.sgip_budget_information.q6_rely_on_electric_pump_wells_for_water_supply
              ? 'yes'
              : 'no';
            if (sgipBudgetData.q6 === 'yes') {
              sgipBudgetData.q7 = sgip_application.sgip_budget_information.q7_income_less_than_80_of_area_median_income
                ? 'yes'
                : 'no';
              shouldDisplayQ7 = true;
            }
          }
          sgipBudgetData.q8 = sgip_application.sgip_budget_information.q8_federal_tax_credit ? 'yes' : 'no';
          if (sgipBudgetData.q2 === 'no' && sgipBudgetData.q3 === 'no') {
            sgipBudgetData.q1 = sgip_application.sgip_budget_information.q1_live_in_low_income_housing_community
              ? 'yes'
              : 'no';
          }
          sgipBudgetData.sgip_budget_eligibility = sgip_application.sgip_budget_information.sgip_budget_elligibility;
          sgipBudgetData.incentive[sgipBudgetData.sgip_budget_eligibility] =
            sgip_application.sgip_budget_information.incentive;
          const siteId = this.props.siteId;
          sgipBudgetData.site_id = siteId;
          if (get(sgipBudgetData, 'sgip_budget_eligibility', '') === LARGE_SCALE_STORAGE) {
            shouldDisplayQ8 = true;
          } else {
            shouldDisplayQ8 = false;
            sgipBudgetData.q8 = '';
          }

          const stepCompleted = applicationState(sgip_application.stepCompleted);
          this.setState(
            {
              sgipApplicationFound: 'success',
              sgipBudget: sgipBudgetData,
              activeState: stepCompleted,
              shouldDisplayQ7,
              shouldDisplayQ8
            },
            () => {
              if (!this.state.isPaymentCompleted) {
                const { utility_company_name } = sgip_application;
                const utilityId = SGIP_UTILITY_COMPANY_MAP[utility_company_name];
                this.props.getSgipConfigs({
                  utilityId: utilityId,
                  successCb: async () => {
                    this.shouldBlockJourneyFunc(sgipBudgetData);
                    this.validateAndCalculateSgipBudgetEligibility();
                  }
                });
              }
            }
          );

          /* while loading based on answers category may be different
            from the actual selected category to keep both the information 
            state set after calculation */
          this.setState({
            optedBudgetCategory: !isEmpty(sgip_application.sgip_budget_information.sgip_budget_elligibility)
              ? sgip_application.sgip_budget_information.sgip_budget_elligibility
              : sgipBudgetData.sgip_budget_eligibility
          });
          sgip_application.notify_me.forEach(category => {
            modifiedBudgetCategories[category].notifyMe = true;
          });
          this.setState({ budgetCategories: modifiedBudgetCategories });
        }
        if (sgip_application.stepCompleted >= 1) {
          const appId = get(sgip_application, 'application_id', '');
          this.props.getCpucURL({
            appId: appId,
            successCb: url => {
              this.setState({ baselineLink: url });
            },
            failureCb: () => {
              this.setState({ baselineLink: COMMON_MEDICAL_BASELINE_URL });
            }
          });
        }
      },
      failureCb: () => {
        this.setState({ sgipApplicationFound: 'failed' });
      }
    });
  }

  loadCountryDetails() {
    this.props.getSgipUserSelectionData({
      successCb: () => {},
      failureCb: () => {}
    });
  }

  componentDidUpdate() {}
  hftdPopup() {
    this.setState({ hftdFlag: !this.state.hftdFlag, findOutType: '' });
  }
  incomeQualifierDialog() {
    this.setState({ incomeQualifierFlag: !this.state.incomeQualifierFlag });
  }
  fireMapPopup(type) {
    this.setState({ hftdFlag: !this.state.hftdFlag, findOutType: type });
  }
  equitySmallResidentialPopup() {
    this.setState({
      equitySmallResidentialFlag: !this.state.equitySmallResidentialFlag
    });
  }
  equityResiliencyPopup = () => {
    this.setState({ equityResiliencyFlag: !this.state.equityResiliencyFlag });
  };
  cancelApplication() {
    this.setState({
      notifyMeButtonsDisable: !this.state.notifyMeButtonsDisable
    });

    if (this.props.isInstaller) {
      forwardTo('sgip-applications');
    }
  }

  /**
   * To handle user click on the notify me bell icon
   * @param {string} category selected category for notifying
   */
  handleNotifyMeIconClick = category => {
    if (this.validateValues({ validateNotifyMe: true })) {
      this.setState({ isNotifyMe: true, optedNotifyMeCategory: category });
    }
  };

  /**
   * To add the category for notifying the user when budget is available
   * @param {String} notifyCategory selected category for notifying
   */
  okNotifyMe(notifyCategory) {
    const { siteId } = this.props;
    const { sgipBudget, budgetCategories } = this.state;

    this.setState({
      notifyMeButtonsDisable: !this.state.notifyMeButtonsDisable
    });
    let sgipBudgetInfo = {
      q1_live_in_low_income_housing_community: isEqual(this.state.sgipBudget.q1, 'yes') ? true : false,
      q2_house_in_tier2_or_tier3_hftd: isEqual(this.state.sgipBudget.q2, 'yes') ? true : false,
      q3_subject_to_two_or_more_psps_events: isEqual(this.state.sgipBudget.q3, 'yes') ? true : false,
      q4_medical_baseline_customer_defined_by_cpuc: isEqual(this.state.sgipBudget.q4, 'yes') ? true : false,
      q5_have_you_notified_your_utility_about_your_illness: isEqual(this.state.sgipBudget.q5, 'yes') ? true : false,
      q6_rely_on_electric_pump_wells_for_water_supply: isEqual(this.state.sgipBudget.q6, 'yes') ? true : false,
      q7_income_less_than_80_of_area_median_income: isEqual(this.state.sgipBudget.q7, 'yes') ? true : false,
      q8_federal_tax_credit: isEqual(this.state.sgipBudget.q8, 'yes') ? true : false,
      sgip_budget_elligibility: this.state.sgipBudget.sgip_budget_eligibility,
      incentive: this.state.sgipBudget.incentive[sgipBudget.sgip_budget_eligibility],
      site_id: siteId,
      notify_me: [
        ...Object.keys(budgetCategories).filter(category => budgetCategories[category].notifyMe === true),
        notifyCategory
      ]
    };

    let modifiedBudgetCategories = { ...budgetCategories };
    modifiedBudgetCategories[notifyCategory].notifyMe = true;
    this.setState({
      isNotifyMe: false,
      budgetCategories: modifiedBudgetCategories
    });

    this.props.postSgipBudgetQuestionnaires({
      sgipBudget: sgipBudgetInfo
    });
  }

  validateSgipBudgetDetails(validateNotifyMe = false) {
    let formValidation = {
      eFound: false,
      pDE: {
        q1: { error: false, msg: '' },
        q2: { error: false, msg: '' },
        q3: { error: false, msg: '' },
        q4: { error: false, msg: '' },
        q5: { error: false, msg: '' },
        q6: { error: false, msg: '' },
        q7: { error: false, msg: '' },
        categoryErr: { error: false, msg: '' }
      }
    };

    if (this.state.sgipBudget.q2 === '') {
      formValidation.eFound = true;
      formValidation.pDE.q2.error = true;
      formValidation.pDE.q2.msg = 'Selection Required';
    } else {
      formValidation.pDE.q2.error = false;
      formValidation.pDE.q2.msg = '';
    }

    if (this.state.sgipBudget.q3 === '') {
      formValidation.eFound = true;
      formValidation.pDE.q3.error = true;
      formValidation.pDE.q3.msg = 'Selection Required';
    } else {
      formValidation.pDE.q3.error = false;
      formValidation.pDE.q3.msg = '';
    }

    if (this.state.sgipBudget.q2 === 'yes' || this.state.sgipBudget.q3 === 'yes') {
      if (this.state.sgipBudget.q1 === '') {
        formValidation.eFound = true;
        formValidation.pDE.q1.error = true;
        formValidation.pDE.q1.msg = 'Selection Required';
      } else {
        formValidation.pDE.q1.error = false;
        formValidation.pDE.q1.msg = '';
      }

      if (this.state.sgipBudget.q4 === '') {
        formValidation.eFound = true;
        formValidation.pDE.q4.error = true;
        formValidation.pDE.q4.msg = 'Selection Required';
      } else {
        formValidation.pDE.q5.error = false;
        formValidation.pDE.q5.msg = '';
      }

      if (this.state.sgipBudget.q6 === '') {
        formValidation.eFound = true;
        formValidation.pDE.q6.error = true;
        formValidation.pDE.q6.msg = 'Selection Required';
      } else {
        formValidation.pDE.q6.error = false;
        formValidation.pDE.q6.msg = '';
      }

      if (this.state.sgipBudget.q7 === '' && this.state.sgipBudget.q6 === 'yes') {
        formValidation.eFound = true;
        formValidation.pDE.q7.error = true;
        formValidation.pDE.q7.msg = 'Selection Required';
      } else {
        formValidation.pDE.q7.error = false;
        formValidation.pDE.q7.msg = '';
      }
    }
    if (!validateNotifyMe && this.state.shouldBlockJourney[this.state.optedBudgetCategory]) {
      formValidation.eFound = true;
      formValidation.pDE.categoryErr.error = true;
      formValidation.pDE.categoryErr.msg = 'Please select a catgory with budget';
    }
    return formValidation;
  }

  /**
   * To validate the questionnaire form response
   * @returns Returns false if no error found else returns true
   */
  validateValues(args = { validateNotifyMe: false }) {
    let pDE = this.state.sgipBudgetError;
    let eFound = false;
    let formValidation = this.validateSgipBudgetDetails(args.validateNotifyMe);
    eFound = formValidation.eFound;
    pDE = formValidation.pDE;
    this.setState({ sgipBudgetError: pDE });
    return !eFound;
  }

  handleSubmit() {
    const { siteId, isInstaller, sgip_application } = this.props;
    const { budgetCategories } = this.state;
    if (this.validateValues()) {
      const utility_company_name =
        sgip_application.utility_company_name || sgip_application.sgip_application.utility_company_name;
      let sgipBudget = { ...this.state.sgipBudget };
      sgipBudget.sgip_budget_eligibility = this.state.optedBudgetCategory || sgipBudget.sgip_budget_eligibility;
      let sgipBudgetInfo = {
        q1_live_in_low_income_housing_community: isEqual(this.state.sgipBudget.q1, 'yes') ? true : false,
        q2_house_in_tier2_or_tier3_hftd: isEqual(this.state.sgipBudget.q2, 'yes') ? true : false,
        q3_subject_to_two_or_more_psps_events: isEqual(this.state.sgipBudget.q3, 'yes') ? true : false,
        q4_medical_baseline_customer_defined_by_cpuc: isEqual(this.state.sgipBudget.q4, 'yes') ? true : false,
        q5_have_you_notified_your_utility_about_your_illness: isEqual(this.state.sgipBudget.q5, 'yes') ? true : false,
        q6_rely_on_electric_pump_wells_for_water_supply: isEqual(this.state.sgipBudget.q6, 'yes') ? true : false,
        q7_income_less_than_80_of_area_median_income: isEqual(this.state.sgipBudget.q7, 'yes') ? true : false,
        q8_federal_tax_credit: isEqual(this.state.sgipBudget.q8, 'yes') ? true : false,
        sgip_budget_elligibility: sgipBudget.sgip_budget_eligibility,
        incentive: this.state.sgipBudget.incentive[sgipBudget.sgip_budget_eligibility],
        site_id: siteId,
        notify_me: [...Object.keys(budgetCategories).filter(category => budgetCategories[category].notifyMe === true)]
      };

      this.props.postSgipBudgetQuestionnaires({
        sgipBudget: sgipBudgetInfo,
        successCb: () => {
          // if (
          //   this.state.sgipBudget.sgip_budget_eligibility === 'Small Residential Budget' ||
          //   (this.state.sgipBudget.sgip_budget_eligibility === EQUITY_RESILIENCY &&
          //     utility_company_name === PACIFIC_GAS_AND_ELECTRIC_COMPANY)
          // ) {
          //   this.equitySmallResidentialPopup();
          // }
          this.setState({ sgipBudget: sgipBudget });
          if (
            this.state.sgipBudget.sgip_budget_eligibility === EQUITY_RESILIENCY &&
            [SOUTHERN_CALIFORNIA_EDISON, SAN_DIEGO_GAS_AND_ELECTRIC_COMPANY, PACIFIC_GAS_AND_ELECTRIC_COMPANY].includes(
              utility_company_name
            )
          ) {
            this.equityResiliencyPopup();
          } else {
            this.navigateToNextScreen();
          }
        }
      });
    }
  }

  updateAnwser(e) {
    e.persist();
    let sgipBudgetData = this.state.sgipBudget;
    let shouldDisplayQ7 = this.state.shouldDisplayQ7;
    switch (e.target.name) {
      case 'q1':
        sgipBudgetData.q1 = e.target.value;
        break;
      case 'q2':
        sgipBudgetData.q2 = e.target.value;
        break;
      case 'q3':
        sgipBudgetData.q3 = e.target.value;
        break;
      case 'q4':
        sgipBudgetData.q4 = e.target.value;
        break;
      case 'q5':
        sgipBudgetData.q5 = e.target.value;
        break;
      case 'q6':
        sgipBudgetData.q6 = e.target.value;
        if (e.target.value === 'yes') {
          shouldDisplayQ7 = true;
        } else {
          sgipBudgetData.q7 = '';
          shouldDisplayQ7 = false;
        }
        break;
      case 'q7':
        sgipBudgetData.q7 = e.target.value;
        break;
      case 'q8':
        sgipBudgetData.q8 = e.target.value;
        break;
      default:
        break;
    }
    if (sgipBudgetData.q2 === 'no' && sgipBudgetData.q3 === 'no') {
      // sgipBudgetData.q1 = '';
      sgipBudgetData.q4 = '';
      sgipBudgetData.q5 = '';
      sgipBudgetData.q6 = '';
      sgipBudgetData.q7 = '';
      shouldDisplayQ7 = false;
    }
    this.setState({ sgipBudget: sgipBudgetData, shouldDisplayQ7 }, () => {
      this.validateAndCalculateSgipBudgetEligibility(e.target.name, true);
    });
  }

  shouldBlockJourneyFunc = sgipBudgetData => {
    const { shouldBlockJourney } = this.state;
    /**
     * flag for each category. Flag would be enabled
     * if the selected (category AND resiliency) matches
     * with the utility block info item's (category AND resiliency)
     */
    let blockJourneyUpdated = {
      'Equity Budget': false,
      'Small Residential Budget': false,
      'Equity Resiliency Budget': false,
      'Large Scale Storage': false
    };
    this.props.utilityBlockInfo.map(utilityBlockObject => {
      if (
        Object.keys(shouldBlockJourney).includes(utilityBlockObject.budget_category_name) &&
        utilityBlockObject.block === true
      ) {
        if (
          RESILIENCY_NOT_APPLICABLE_CATEGORIES.includes(utilityBlockObject.budget_category_name) &&
          utilityBlockObject.resiliency === 'NA'
        ) {
          shouldBlockJourney[utilityBlockObject.budget_category_name] = true;
          this.setState({ shouldBlockJourney });
        } else if (!RESILIENCY_NOT_APPLICABLE_CATEGORIES.includes(utilityBlockObject.budget_category_name)) {
          const currentResiliency = sgipBudgetData.q2 === 'yes' || sgipBudgetData.q3 === 'yes';
          if (!blockJourneyUpdated[utilityBlockObject.budget_category_name]) {
            shouldBlockJourney[utilityBlockObject.budget_category_name] = false;
            if (
              (currentResiliency && utilityBlockObject.resiliency === 'YES') ||
              (!currentResiliency && utilityBlockObject.resiliency === 'NO')
            ) {
              shouldBlockJourney[utilityBlockObject.budget_category_name] = utilityBlockObject.block;
              blockJourneyUpdated[utilityBlockObject.budget_category_name] = true;
            }
          }
          this.setState({ shouldBlockJourney });
        }
      }
      return null;
    });
  };

  validateAndCalculateSgipBudgetEligibility(quesNo, changeOptedCat = false) {
    const { sgip_application } = this.props;
    const { totalStorageCapacity } = this.getStorageCapacity();
    let shouldDisplayQ8 = this.state.shouldDisplayQ8;
    const { optedBudgetCategory, highlightQ8 } = this.state;
    let optedCategory = optedBudgetCategory;
    let isQ8Highlight = highlightQ8;
    if (changeOptedCat && !(optedBudgetCategory === LARGE_SCALE_STORAGE && quesNo === 'q8')) {
      // Q8 response should not empty the opted category
      optedCategory = '';
    }
    isQ8Highlight = false;
    let sgipBudgetData = this.state.sgipBudget;

    let q1 = this.state.sgipBudget.q1 === 'yes' ? true : false;
    let q2 = this.state.sgipBudget.q2 === 'yes' ? true : false;
    let q3 = this.state.sgipBudget.q3 === 'yes' ? true : false;
    let q4 = this.state.sgipBudget.q4 === 'yes' ? true : false;
    let q5 = this.state.sgipBudget.q5 === 'yes' ? true : false;
    let q7 = this.state.sgipBudget.q7 === 'yes' ? true : false;

    let budget_eligibility = '';

    if ((q2 || q3) && (q4 || q5 || q1)) {
      budget_eligibility = EQUITY_RESILIENCY;
    } else if (q1 && !q2 && !q3 && !q4 && !q5) {
      budget_eligibility = EQUITY;
    } else if (this.state.shouldDisplayQ7) {
      budget_eligibility = q7 ? EQUITY_RESILIENCY : SMALL_RESIDENTIAL;
    } else {
      budget_eligibility = SMALL_RESIDENTIAL;
    }

    if (budget_eligibility === SMALL_RESIDENTIAL && totalStorageCapacity > this.largeScaleStorageCapasity) {
      budget_eligibility = LARGE_SCALE_STORAGE;
    }

    sgipBudgetData.sgip_budget_eligibility = budget_eligibility;
    optedCategory = optedCategory ? optedCategory : this.getHighestIncentiveCategoryValue(budget_eligibility);
    if (optedCategory === LARGE_SCALE_STORAGE) {
      shouldDisplayQ8 = true;
      sgipBudgetData.q8 = this.state.shouldDisplayQ8 ? this.state.sgipBudget.q8 : 'yes';
    } else {
      shouldDisplayQ8 = false;
      sgipBudgetData.q8 = '';
    }
    if (!this.state.isPaymentCompleted) {
      const { utility_company_name } = sgip_application;
      const utilityId = SGIP_UTILITY_COMPANY_MAP[utility_company_name];
      if (isEmpty(this.props.utilityBlockInfo)) {
        this.props.getSgipConfigs({
          utilityId: utilityId,
          successCb: () => {
            this.shouldBlockJourneyFunc(sgipBudgetData);
          }
        });
      } else {
        this.shouldBlockJourneyFunc(sgipBudgetData);
      }
    }
    this.setState({
      shouldDisplayQ8,
      optedBudgetCategory: optedCategory,
      highlightQ8: isQ8Highlight,
      sgipBudget: sgipBudgetData
    });
    this.calculateIncentive();
  }

  /**
   * @typedef {Object} UnitOfEncharge
   * @property {number} unit_of_encharge3 - encharge 3 units
   * @property {number} unit_of_encharge10 - encharge 1 units
   * @property {number} unit_of_encharge - encharge 3 + 3 times encharge 10 units
   */

  /**
   * Returns number of encharge units in the application
   * @returns {UnitOfEncharge} Unit of encharges from Application
   */
  getStorageCapacity() {
    const { sgip_application } = this.props;
    let unitOfEncharge3 = 0;
    let unitOfEncharge10 = 0;
    let unitOfIqBattery5p = 0;
    let totalEncharge3Capacity = 0;
    let totalEncharge10Capacity = 0;
    let totalIQBattery5PCapacity = 0;

    if (sgip_application.hasOwnProperty('sgip_application')) {
      unitOfEncharge3 = get(sgip_application.sgip_application, 'unit_of_encharge3', 0);
      totalEncharge3Capacity = unitOfEncharge3 * ENCHARGE3_POWER; //kW
      unitOfEncharge10 = get(sgip_application.sgip_application, 'unit_of_encharge10', 0);
      totalEncharge10Capacity = unitOfEncharge10 * ENCHARGE10_POWER; //kW
      unitOfIqBattery5p = get(sgip_application.sgip_application, 'unit_of_iq_battery5p', 0);
      totalIQBattery5PCapacity = unitOfIqBattery5p * IQ_BATTERY_5P_POWER; //kW
    } else {
      unitOfEncharge3 = get(sgip_application, 'unit_of_encharge3', 0);
      totalEncharge3Capacity = unitOfEncharge3 * ENCHARGE3_POWER; //kW
      unitOfEncharge10 = get(sgip_application, 'unit_of_encharge10', 0);
      totalEncharge10Capacity = unitOfEncharge10 * ENCHARGE10_POWER; //kW
      unitOfIqBattery5p = get(sgip_application, 'unit_of_iq_battery5p', 0);
      totalIQBattery5PCapacity = unitOfIqBattery5p * IQ_BATTERY_5P_POWER; //kW
    }
    const totalStorageCapacity = totalEncharge3Capacity + totalEncharge10Capacity + totalIQBattery5PCapacity;

    return {
      unitOfEncharge3,
      unitOfEncharge10,
      unitOfIqBattery5p,
      totalEncharge3Capacity,
      totalEncharge10Capacity,
      totalIQBattery5PCapacity,
      totalStorageCapacity
    };
  }

  calculateIncentive(rates) {
    if (!this.state.isPaymentCompleted) {
      let cIncentive = {
        'Equity Budget': 0,
        'Small Residential Budget': 0,
        'Equity Resiliency Budget': 0,
        'Large Scale Storage': 0
      };
      const { unitOfEncharge3, unitOfEncharge10, unitOfIqBattery5p, totalStorageCapacity } = this.getStorageCapacity();
      const { sgip_application } = this.props;
      const incentiveRates = !isEmpty(rates) ? rates : this.props.incentiveRates;
      const { utility_company_name } = sgip_application;
      incentiveRates.map(incentiveInfo => {
        if (
          incentiveInfo.utility_name === utility_company_name &&
          (this.sgipBugetCategories.includes(incentiveInfo.budget_category) ||
            (incentiveInfo.budget_category === LARGE_SCALE_STORAGE &&
              incentiveInfo.itc_claimed === 'YES' &&
              (this.state.sgipBudget.q8 === 'yes' || this.state.sgipBudget.q8 === '')) || // Calculate incentive for large scale storage assuming q8 as true by default
            (incentiveInfo.budget_category === LARGE_SCALE_STORAGE &&
              incentiveInfo.itc_claimed === 'NO' &&
              this.state.sgipBudget.q8 === 'no'))
        ) {
          this.encharge3Rates[incentiveInfo.budget_category] = parseInt(get(incentiveInfo, 'encharge3_rate', ''));
          this.encharge10Rates[incentiveInfo.budget_category] = parseInt(get(incentiveInfo, 'encharge10_rate', ''));
          this.iqBattery5pRates[incentiveInfo.budget_category] = parseInt(
            get(incentiveInfo, 'iq_battery5p_rate') ? get(incentiveInfo, 'iq_battery5p_rate') : '0'
          );
        }
        return null;
      });
      cIncentive[EQUITY] =
        this.encharge3Rates[EQUITY] * unitOfEncharge3 +
        this.encharge10Rates[EQUITY] * unitOfEncharge10 +
        this.iqBattery5pRates[EQUITY] * unitOfIqBattery5p;
      // for small residential in multiple category user can at max claim for 7 encharges
      cIncentive[SMALL_RESIDENTIAL] =
        totalStorageCapacity > this.largeScaleStorageCapasity
          ? this.encharge3Rates[SMALL_RESIDENTIAL] * this.largeScaleStorageCapasity
          : this.encharge3Rates[SMALL_RESIDENTIAL] * unitOfEncharge3 +
            this.encharge10Rates[SMALL_RESIDENTIAL] * unitOfEncharge10 +
            this.iqBattery5pRates[SMALL_RESIDENTIAL] * unitOfIqBattery5p;
      cIncentive[EQUITY_RESILIENCY] =
        this.encharge3Rates[EQUITY_RESILIENCY] * unitOfEncharge3 +
        this.encharge10Rates[EQUITY_RESILIENCY] * unitOfEncharge10 +
        this.iqBattery5pRates[EQUITY_RESILIENCY] * unitOfIqBattery5p;
      cIncentive[LARGE_SCALE_STORAGE] =
        this.encharge3Rates[LARGE_SCALE_STORAGE] * unitOfEncharge3 +
        this.encharge10Rates[LARGE_SCALE_STORAGE] * unitOfEncharge10 +
        this.iqBattery5pRates[LARGE_SCALE_STORAGE] * unitOfIqBattery5p;
      let sgipBudgetData = this.state.sgipBudget;

      sgipBudgetData.incentive = cIncentive;
      this.setState({ sgipBudget: sgipBudgetData });
    }
  }

  navigateToNextScreen = () => {
    if (this.props.isInstaller) {
      trackEvents('triggerSgipPageView', { pageTitle: 'Attestation Form' });
      forwardTo('attestationForm');
    } else {
      trackEvents('triggerSgipPageView', { pageTitle: 'Upload Documents' });
      forwardTo('uploadDocuments');
    }
  };

  handleCategoryChange = event => {
    let sgipBudgetData = this.state.sgipBudget;
    let shouldDisplayQ8 = false;
    let highlightQ8 = false;
    if (event.target.value === LARGE_SCALE_STORAGE) {
      shouldDisplayQ8 = true;
      highlightQ8 = true;
      sgipBudgetData.q8 = this.state.shouldDisplayQ8 ? this.state.sgipBudget.q8 : 'yes';
    } else {
      sgipBudgetData.q8 = '';
      this.calculateIncentive();
    }
    this.setState({
      shouldDisplayQ8,
      highlightQ8,
      optedBudgetCategory: event.target.value,
      sgipBudget: sgipBudgetData
    });
  };

  getPossibleMultipleCategories = budgetCategory => {
    const isQ1 = this.state.sgipBudget.q1 === 'yes';
    let category = !isEmpty(budgetCategory) ? budgetCategory : this.state.sgipBudget.sgip_budget_eligibility;
    if (isQ1 && category === EQUITY_RESILIENCY) {
      category = `${category} Equity Qualify`;
    }
    return this.getStorageCapacity().totalStorageCapacity > this.largeScaleStorageCapasity
      ? this.multipleCategoryGtLargeScaleCapacity[category]
      : this.multipleCategoryLtLargeScaleCapacity[category];
  };

  getHighestIncentiveCategoryValue = budgetCategory => {
    const { sgipBudget, shouldBlockJourney } = this.state;
    const { incentive } = sgipBudget;
    const category = !isEmpty(budgetCategory) ? budgetCategory : sgipBudget.sgip_budget_eligibility;
    const possibleCategories = this.getPossibleMultipleCategories(category);
    const result =
      possibleCategories &&
      possibleCategories
        .sort((catA, catB) => (incentive[catA] > incentive[catB] ? 1 : -1))
        .sort((catA, catB) => (shouldBlockJourney[catA] > shouldBlockJourney[catB] ? 1 : -1));
    return !isEmpty(result) ? result[0] : sgipBudget.sgip_budget_eligibility;
  };

  getCategorySpecificText = category => {
    let text = '';
    const { unitOfIqBattery5p, totalStorageCapacity } = this.getStorageCapacity();
    if (category === SMALL_RESIDENTIAL && totalStorageCapacity > this.largeScaleStorageCapasity) {
      if (unitOfIqBattery5p > 0) {
        text = ', (9.518 kWh SGIP Verified Capacity).';
      } else {
        text = ', (22.647 kWh SGIP Verified Capacity).';
      }
    }
    return text;
  };

  /**
   * To return if the journey is to be blocked for selected category or its alternate categories
   * @returns {boolean} Returns true if the journey is to be blocked else {false}
   */
  blockJourney = () => {
    const { shouldBlockJourney, sgipBudget } = this.state;
    const possibleCategories = this.getPossibleMultipleCategories();
    return !isEmpty(possibleCategories) ? false : shouldBlockJourney[sgipBudget.sgip_budget_eligibility];
  };

  render() {
    const { classes, currentlySending, isInstaller, sgip_application, isIOS, user_selection_data } = this.props;
    const {
      sgipBudgetError,
      sgipBudget,
      hftdFlag,
      incomeQualifierFlag,
      findOutType,
      equitySmallResidentialFlag,
      equityResiliencyFlag,
      isNotifyMe,
      budgetCategories,
      optedNotifyMeCategory,
      shouldBlockJourney,
      shouldDisplayQ7,
      shouldDisplayQ8
    } = this.state;

    let error = false;
    let errorMessage = '';
    if (sgipBudgetError.categoryErr.error) {
      error = true;
      errorMessage = sgipBudgetError.categoryErr.msg;
    } else if (sgipBudget.q2 === 'yes' || sgipBudget.q3 === 'yes') {
      error =
        sgipBudgetError.q2.error ||
        sgipBudgetError.q3.error ||
        sgipBudgetError.q1.error ||
        sgipBudgetError.q6.error ||
        sgipBudgetError.q4.error ||
        sgipBudgetError.q7.error;
    } else {
      error = sgipBudgetError.q2.error || sgipBudgetError.q3.error;
    }
    const utility_company_name = sgip_application.utility_company_name || '';

    const IncentiveRateHeaderText = () => {
      const textStart = !isInstaller
        ? 'Based on your responses, you fall under '
        : 'Based on the responses, this application falls under ';
      const possibleCategories = this.getPossibleMultipleCategories();
      return (
        <Typography className={classes.information} style={{ color: '#545456' }}>
          {possibleCategories ? (
            textStart + 'multiple categories. Please choose one to proceed further.'
          ) : (
            <Fragment>
              {textStart} <b>{this.state.sgipBudget.sgip_budget_eligibility}</b> and{' '}
              {!isInstaller ? ' you are eligible for an' : ' is eligible for an'} incentive up to{' '}
              <b>${numberWithCommas(this.state.sgipBudget.incentive[this.state.sgipBudget.sgip_budget_eligibility])}</b>
            </Fragment>
          )}{' '}
          <br />
        </Typography>
      );
    };

    const NotifyMeBellIcon = props => {
      const { category = this.state.sgipBudget.sgip_budget_eligibility } = props;
      return (
        <Tooltip placement="top" title="Notify me when budget is available">
          <IconButton
            className={clsx(classes.bellIconButton, budgetCategories[category].notifyMe && classes.disableIcon)}
            onClick={() => this.handleNotifyMeIconClick(category)}
          >
            <BellIcon />
          </IconButton>
        </Tooltip>
      );
    };

    return (
      <div className={classes.dWrapper}>
        <StatusTracking activeState={this.state.activeState} pageNumber="2" isInstaller={isInstaller} />
        {currentlySending && <Loader loading={currentlySending} />}
        {hftdFlag && (
          <HftdDialog
            handleClose={this.hftdPopup.bind(this)}
            findOutType={findOutType}
            isIOS={isIOS}
            isInstaller={isInstaller}
            sgipApplication={this.props.sgip_application}
          />
        )}
        {incomeQualifierFlag && (
          <IncomeQualifierDialog
            handleClose={this.incomeQualifierDialog.bind(this)}
            isInstaller={isInstaller}
            user_selection_data={user_selection_data}
          />
        )}
        {equitySmallResidentialFlag && (
          <EquityAndSmallResidentailDialog
            isIOS={isIOS}
            handleClose={this.equitySmallResidentialPopup}
            handleProceed={this.navigateToNextScreen}
            isInstaller={isInstaller}
            sgipBudgetEligibility={this.state.sgipBudget.sgip_budget_eligibility}
            handleNotifyMe={this.notifyMe}
          />
        )}
        {equityResiliencyFlag && (
          <EquityResiliencyDialog
            isIOS={isIOS}
            handleClose={this.equityResiliencyPopup}
            handleProceed={this.navigateToNextScreen}
          />
        )}
        <OkNotifyMe
          openNotifyMeDialog={isNotifyMe}
          handleOkNotifyMe={this.okNotifyMe}
          handleDialogClose={() => {
            this.setState({ isNotifyMe: false });
          }}
          sgipBudget={optedNotifyMeCategory}
          isInstaller={isInstaller}
        />
        {!isInstaller ? (
          <Title className={classes.dHeader}>Find out your Incentive Amount</Title>
        ) : (
          <Title className={classes.dHeader}>Find out the Incentive Amount</Title>
        )}
        <Box className={classes.root}>
          <Grid className={classes.qaItem}>
            <BoxLayout noPadding>
              <Box>
                <Box className={classes.qa}>
                  <Box>
                    <Typography display="inline">
                      Is {!isInstaller ? 'your' : 'the'} house in Tier 2 or Tier 3 High Fire Threat District (HFTD)?
                      {` `}
                    </Typography>
                    <Typography
                      display="inline"
                      variant="body2"
                      className={classes.link}
                      style={{ textTransform: 'none', padding: '0px' }}
                      onClick={e => this.fireMapPopup('hftd')}
                    >
                      {`Click here to find out`}
                    </Typography>
                  </Box>
                </Box>

                <Divider />
                <RadioGroup
                  aria-label="q2_answer"
                  name="q2"
                  value={sgipBudget.q2}
                  id="q1"
                  onChange={e => this.updateAnwser(e)}
                >
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" className={classes.tdborder}>
                          <Radio value="yes" label="Yes">
                            Yes
                          </Radio>
                          Yes
                        </TableCell>
                        <TableCell align="center" className={classes.tdnoborder}>
                          <Radio value="no" label="No">
                            No
                          </Radio>
                          No
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </RadioGroup>
              </Box>
            </BoxLayout>
          </Grid>

          <Grid className={classes.qaItem}>
            <BoxLayout noPadding>
              <Box className={classes.qa}>
                <Box>
                  <Typography display="inline">
                    {' '}
                    {!isInstaller ? 'Have you been subjected' : 'Has the System Owner been subjected'} to two or more
                    PSPS (public safety power shutoff) events?{` `}
                  </Typography>
                  {utility_company_name === PACIFIC_GAS_AND_ELECTRIC_COMPANY ? (
                    isIOS ? (
                      <a
                        style={{ color: '#01B4DE', textDecoration: 'none' }}
                        href="enphase://user?url=https://vizmap.ss.pge.com/"
                        target="_self"
                      >
                        <Typography component="span" variant="body2">{`Click here to find out`}</Typography>
                      </a>
                    ) : (
                      <Link
                        variant="body2"
                        style={{ color: '#01B4DE', cursor: 'pointer' }}
                        target="_blank"
                        rel="noreferrer"
                        underline="none"
                        onClick={() =>
                          window.open(
                            'https://vizmap.ss.pge.com/',
                            '_blank',
                            'noopener,noreferrer'
                          )
                        }
                      >
                        {`Click here to find out`}
                      </Link>
                    )
                  ) : (
                    <Typography
                      display="inline"
                      variant="body2"
                      className={classes.link}
                      style={{ textTransform: 'none', padding: '0px' }}
                      onClick={e => this.fireMapPopup('psps')}
                    >
                      {`Click here to find out`}
                    </Typography>
                  )}
                </Box>
              </Box>

              <Divider />
              <RadioGroup
                aria-label="q3_answer"
                name="q3"
                value={sgipBudget.q3}
                id="q1"
                onChange={e => this.updateAnwser(e)}
              >
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" className={classes.tdborder}>
                        <Radio value="yes" label="Yes">
                          Yes
                        </Radio>
                        Yes
                      </TableCell>
                      <TableCell align="center" className={classes.tdnoborder}>
                        <Radio value="no" label="No">
                          No
                        </Radio>
                        No
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </RadioGroup>
            </BoxLayout>
          </Grid>

          {(sgipBudget.q2 === 'yes' || sgipBudget.q3 === 'yes') && (
            <Fragment>
              <Grid className={classes.qaItem}>
                <BoxLayout noPadding>
                  <Box>
                    <QuestionPersonalDetails
                      questionForHO={QA_BASELINE_CUST_HO}
                      questionForInstaller={QA_BASELINE_CUST_INST}
                      quesRelatedLink={this.state.baselineLink}
                      linkText={`here?`}
                      isInstaller={isInstaller}
                      isIOS={isIOS}
                    />
                    <Divider />
                    <RadioGroup
                      aria-label="q4_answer"
                      name="q4"
                      value={sgipBudget.q4}
                      id="q1"
                      onChange={e => this.updateAnwser(e)}
                    >
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" className={classes.tdborder}>
                              <Radio value="yes" label="Yes">
                                Yes
                              </Radio>
                              Yes
                            </TableCell>
                            <TableCell align="center" className={classes.tdnoborder}>
                              <Radio value="no" label="No">
                                No
                              </Radio>
                              No
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </RadioGroup>
                  </Box>
                </BoxLayout>
              </Grid>

              <Grid className={classes.qaItem}>
                <BoxLayout noPadding>
                  <Box>
                    <Box className={classes.qa}>
                      <Box>
                        <Typography>
                          {' '}
                          {!isInstaller ? 'Do you rely on' : 'Does the System Owner rely on'} electric pump wells for
                          water supply?
                        </Typography>
                      </Box>
                    </Box>

                    <Divider />
                    <RadioGroup
                      aria-label="q6_answer"
                      name="q6"
                      value={sgipBudget.q6}
                      id="q1"
                      onChange={e => this.updateAnwser(e)}
                    >
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" className={classes.tdborder}>
                              <Radio value="yes"> Yes </Radio>Yes
                            </TableCell>
                            <TableCell align="center" className={classes.tdnoborder}>
                              <Radio value="no"> No</Radio>No
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </RadioGroup>
                  </Box>
                </BoxLayout>
              </Grid>
              <Grid className={classes.qaItem}>
                <BoxLayout noPadding>
                  <Box>
                    <QuestionPersonalDetails
                      questionForHO={QA_MED_BASELINE_HO}
                      questionForInstaller={QA_MED_BASELINE_INST}
                      quesRelatedLink={this.state.baselineLink}
                      linkText={`CPUC`}
                      isInstaller={isInstaller}
                      isIOS={isIOS}
                    />
                    <Divider />
                    <RadioGroup
                      aria-label="q5_answer"
                      name="q5"
                      value={sgipBudget.q5}
                      id="q1"
                      onChange={e => this.updateAnwser(e)}
                    >
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" className={classes.tdborder}>
                              <Radio value="yes" label="Yes">
                                Yes
                              </Radio>
                              Yes
                            </TableCell>
                            <TableCell align="center" className={classes.tdnoborder}>
                              <Radio value="no" label="No">
                                No
                              </Radio>
                              No
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </RadioGroup>
                  </Box>
                </BoxLayout>
              </Grid>
            </Fragment>
          )}

          {(sgipBudget.q2 === 'yes' || sgipBudget.q3 === 'yes') && (
            <Fragment>
              <Grid className={classes.qaItem}>
                <BoxLayout noPadding>
                  <Box className={classes.boxWrapper}>
                    <QuestionPersonalDetails
                      questionForHO={QA_QUALIFY_EQUITY_HO}
                      questionForInstaller={QA_QUALIFY_EQUITY_INST}
                      quesRelatedLink={EQUITY_AND_ELIGIBILITY_LINK}
                      linkText={``}
                      isInstaller={isInstaller}
                      isIOS={isIOS}
                    />
                    <Divider />
                    <RadioGroup
                      row
                      aria-label="q1_answer"
                      value={sgipBudget.q1}
                      id="q1"
                      name="q1"
                      onChange={e => this.updateAnwser(e)}
                    >
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" className={classes.tdborder}>
                              <Radio value="yes" label="Yes">
                                Yes
                              </Radio>
                              Yes
                            </TableCell>
                            <TableCell align="center" className={classes.tdnoborder}>
                              <Radio value="no" label="No">
                                No
                              </Radio>
                              No
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </RadioGroup>
                  </Box>
                </BoxLayout>
              </Grid>
            </Fragment>
          )}

          {sgipBudget.q2 === 'no' && sgipBudget.q3 === 'no' && (
            <Fragment>
              <Grid className={classes.qaItem}>
                <BoxLayout noPadding>
                  <Box className={classes.boxWrapper}>
                    <QuestionPersonalDetails
                      questionForHO={QA_QUALIFY_EQUITY_HO}
                      questionForInstaller={QA_QUALIFY_EQUITY_INST}
                      quesRelatedLink={EQUITY_AND_ELIGIBILITY_LINK}
                      linkText={``}
                      isInstaller={isInstaller}
                      isIOS={isIOS}
                    />

                    <Divider />
                    <RadioGroup
                      row
                      aria-label="q1_answer"
                      value={sgipBudget.q1}
                      id="q1"
                      name="q1"
                      onChange={e => this.updateAnwser(e)}
                    >
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" className={classes.tdborder}>
                              <Radio value="yes" label="Yes">
                                Yes
                              </Radio>
                              Yes
                            </TableCell>
                            <TableCell align="center" className={classes.tdnoborder}>
                              <Radio value="no" label="No">
                                No
                              </Radio>
                              No
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </RadioGroup>
                  </Box>
                </BoxLayout>
              </Grid>
            </Fragment>
          )}

          {shouldDisplayQ7 && (
            <Fragment>
              <Grid className={classes.qaItem}>
                <BoxLayout noPadding>
                  <Box className={classes.boxWrapper}>
                    <Box className={classes.qa}>
                      <Box>
                        {' '}
                        <Typography display="inline">
                          {!isInstaller
                            ? 'Are you in a single-family household with an income less than or equal to 80% of your area’s median income? '
                            : 'Is the System Owner in a single-family household with an income less than or equal to 80% of their area’s median income? '}
                        </Typography>
                        <Typography
                          display="inline"
                          variant="body2"
                          className={classes.link}
                          style={{ textTransform: 'none', padding: '0px', fontWeight: 'bold' }}
                          onClick={this.incomeQualifierDialog}
                        >
                          {` Click here to find out`}
                        </Typography>
                      </Box>
                    </Box>

                    <Divider />
                    <RadioGroup
                      row
                      aria-label="q7_answer"
                      value={sgipBudget.q7}
                      id="q7"
                      name="q7"
                      onChange={e => this.updateAnwser(e)}
                    >
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" className={classes.tdborder}>
                              <Radio value="yes" label="Yes">
                                Yes
                              </Radio>
                              Yes
                            </TableCell>
                            <TableCell align="center" className={classes.tdnoborder}>
                              <Radio value="no" label="No">
                                No
                              </Radio>
                              No
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </RadioGroup>
                  </Box>
                </BoxLayout>
              </Grid>
            </Fragment>
          )}
          {shouldDisplayQ8 && (
            <Fragment>
              <Grid className={this.state.highlightQ8 ? clsx(classes.quesHighlight, classes.qaItem) : classes.qaItem}>
                <BoxLayout noPadding>
                  <Box className={classes.boxWrapper}>
                    <Box className={classes.qa}>
                      <Box>
                        <Typography display="inline">
                          {isInstaller
                            ? 'Is the System Owner going to claim the federal tax credit for the battery system?'
                            : 'Are you going to claim the federal tax credit for your battery system?'}
                        </Typography>
                      </Box>
                    </Box>

                    <Divider />
                    <RadioGroup
                      row
                      aria-label="q8_answer"
                      value={sgipBudget.q8}
                      id="q8"
                      name="q8"
                      onChange={e => this.updateAnwser(e)}
                    >
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" className={classes.tdborder}>
                              <Radio value="yes" label="Yes">
                                Yes
                              </Radio>
                              Yes
                            </TableCell>
                            <TableCell align="center" className={classes.tdnoborder}>
                              <Radio value="no" label="No">
                                No
                              </Radio>
                              No
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </RadioGroup>
                  </Box>
                </BoxLayout>
              </Grid>
            </Fragment>
          )}
          <Grid className={classes.fullWidthGrid}>
            <br />
            <BoxLayout>
              <Title className={classes.header}>SGIP Budget information</Title>
              {!isEmpty(sgipBudget.sgip_budget_eligibility) && this.blockJourney() && (
                <div>
                  <Typography className={classes.information} style={{ color: '#545456' }}>
                    As per the options selected by you, your application will be in the{' '}
                    <b>{`${this.state.sgipBudget.sgip_budget_eligibility}`}</b> category. However, due to
                    oversubscription, new applications are not being accepted by your utility for this budget.{' '}
                    <NotifyMeBellIcon />
                  </Typography>
                </div>
              )}
              {!isEmpty(sgipBudget.sgip_budget_eligibility) && !this.blockJourney() && (
                <div>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">{<IncentiveRateHeaderText />}</FormLabel>
                    <RadioGroup
                      value={!shouldBlockJourney[this.state.optedBudgetCategory] ? this.state.optedBudgetCategory : ''}
                      onChange={this.handleCategoryChange}
                    >
                      {this.multipleCategories
                        .sort(
                          // Sort desc based on incentive rates
                          (catA, catB) =>
                            this.state.sgipBudget.incentive[catA] > this.state.sgipBudget.incentive[catB] ? 1 : -1
                        )
                        .sort(
                          // blocked category should be at bottom
                          (catA, catB) => (shouldBlockJourney[catA] > shouldBlockJourney[catB] ? 1 : -1)
                        )
                        .map((category, idx) => {
                          if (
                            this.getPossibleMultipleCategories() &&
                            this.getPossibleMultipleCategories().includes(category)
                          ) {
                            return (
                              <FormControlLabel
                                key={idx + '-category'}
                                className={classes.categoryLabel}
                                value={category}
                                control={<Radio />}
                                label={
                                  shouldBlockJourney[category] ? (
                                    <Box>
                                      <b>{category}</b>, new applications are not being accepted by the utility due to
                                      oversubscription. <NotifyMeBellIcon category={category} />
                                    </Box>
                                  ) : (
                                    <Fragment>
                                      <b>{category}</b>, eligible for an incentive of upto $
                                      {this.state.sgipBudget.incentive[category]}
                                      {this.getCategorySpecificText(category)}
                                    </Fragment>
                                  )
                                }
                                disabled={shouldBlockJourney[category]}
                              />
                            );
                          } else {
                            return null;
                          }
                        })}
                    </RadioGroup>
                  </FormControl>
                </div>
              )}

              {isEmpty(this.state.sgipBudget.sgip_budget_eligibility) && (
                <div>
                  <Typography className={classes.informationans}>
                    Answer the above questions to know the SGIP Budget information.
                  </Typography>
                </div>
              )}
              {!isEmpty(sgipBudget.sgip_budget_eligibility) && !this.blockJourney() && (
                <Typography className={classes.information} style={{ marginTop: '5px', color: '#545456' }}>
                  {`Note: This is an indicative incentive amount. Enphase is providing only an application processing
                service; the actual payout will be determined by your utility.`}
                </Typography>
              )}

              {!isEmpty(sgipBudget.sgip_budget_eligibility) && !this.blockJourney() && (
                <Typography className={classes.information} style={{ marginTop: '5px', color: '#545456' }}>
                  For detailed information on eligibility{' '}
                  {isIOS ? (
                    <a
                      style={{ color: '#01B4DE', fontWeight: 'bold', textDecoration: 'none' }}
                      href={`enphase://user?url=${EQUITY_AND_ELIGIBILITY_LINK}`}
                      target="_self"
                    >
                      {` Click to download details`}
                    </a>
                  ) : (
                    <Link
                      style={{ color: '#01b4de', fontWeight: 'bold', cursor: 'pointer' }}
                      target="_blank"
                      rel="noreferrer"
                      underline="none"
                      onClick={() => window.open(EQUITY_AND_ELIGIBILITY_LINK, '_blank')}
                    >
                      Click to download details
                    </Link>
                  )}
                </Typography>
              )}
              <div>
                <Box className={classes.nextbtn}>
                  {this.state.highlightQ8 && (
                    <FormHelperText className={classes.warning}>
                      {'Please confirm federal tax credit claim'}
                    </FormHelperText>
                  )}
                  {error && (
                    <FormHelperText className={classes.error}>
                      {errorMessage || 'Please answer all the questions to proceed'}
                    </FormHelperText>
                  )}
                  {!isEmpty(sgipBudget.sgip_budget_eligibility) && !this.blockJourney() && (
                    <FilledButton
                      className={classes.btn}
                      onClick={e => {
                        return this.handleSubmit();
                      }}
                      disabled={shouldBlockJourney[this.state.optedBudgetCategory]}
                    >
                      Next
                    </FilledButton>
                  )}
                  {!isEmpty(sgipBudget.sgip_budget_eligibility) && this.blockJourney() && (
                    <Fragment>
                      <FilledButton
                        disabled={this.state.notifyMeButtonsDisable}
                        onClick={() => this.cancelApplication()}
                        className={classes.btn}
                      >
                        Cancel Application
                      </FilledButton>
                    </Fragment>
                  )}
                </Box>
              </div>
            </BoxLayout>
          </Grid>
        </Box>
        <PrivacyPolicy />
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => ({
  postSgipBudgetQuestionnaires: payload => dispatch(postSgipBudgetQuestionnaires(payload)),
  getSgipApplicationDetailsBySiteId: payload => dispatch(getSgipApplicationDetailsBySiteId(payload)),
  postNotifyMeDetails: payload => dispatch(postNotifyMeDetails(payload)),
  getSgipUserSelectionData: payload => dispatch(getSgipUserSelectionData(payload)),
  getSgipIncentiveRates: payload => dispatch(getSgipIncentiveRates(payload)),
  getSgipConfigs: payload => dispatch(getSgipConfigs(payload)),
  getCpucURL: payload => dispatch(getCpucURL(payload))
});

const mapStateToProps = state => ({
  siteId: state.appReducer.siteId,
  os: state.appReducer.os,
  sgip_application: state.sgipAppReducer.sgip_application,
  currentlySending: state.sgipAppReducer.currentlySending,
  user_selection_data: state.sgipAppReducer.user_selection_data,
  isInstaller: state.appReducer.isInstaller,
  isIOS: state.appReducer.isIOS,
  incentiveRates: state.sgipAppReducer.incentiveRates,
  utilityBlockInfo: state.sgipAppReducer.utilityBlockInfo
});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(index));
