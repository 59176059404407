import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Grid, Typography, Box, Avatar } from '@material-ui/core';
import { BoxLayout } from '../../../components/Common';
import { FilledButton } from '../../../components/Buttons';
import { useMobileStyles, useDesktopStyles } from './style';
import TickImage from '../../../components/Payment/tickmark-green.png';
import { forwardTo, trackEvents } from '../../../utils/helper';
import NpsFeedbackDialog from '../NpsFeedbackDialog';
import { getSgipApplicationDetailsBySiteId } from '../../../containers/SgipApp/actions';
import { get } from '../../../utils/lodash';
import { Title } from '../../Texts';

const PaymentSuccess = props => {
  const { isMobileView = true } = props;
  const mobileClasses = useMobileStyles();
  const desktopClasses = useDesktopStyles();
  const classes = isMobileView ? mobileClasses : desktopClasses;

  const [ applicationId, setApplicationId ] = useState('');
  const [ email, setEmailId ] = useState('');
  const [ showNPSDialog, setShowNPSDialog ] = useState(false);
  const [ NPSDialogTimeout, setNPSDialogTimeout ] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    setNPSDialogTimeout(
      setTimeout(() => {
        setShowNPSDialog(true);
      }, 2000)
    );
    props.getSgipApplicationDetailsBySiteId({
      siteId: props.siteId,
      isInstaller: props.isInstaller,
      successCb: () => {
        const { sgip_application } = props;
        const applicationId = get(sgip_application, 'application_id');
        const email = get(sgip_application, 'email');
        setApplicationId(applicationId);
        setEmailId(email);
      },
      failureCb: () => {}
    });
    return () => clearTimeout(NPSDialogTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Fragment>
        <Grid className={classes.top}>
          <BoxLayout noPadding>
            <Grid
              container
              direction="column"
              alignItems="center"
              justify="center"
              style={{ minHeight: '100vh', textAlign: 'center' }}
            >
              <div>
                <Typography component="span" style={{ justifyContent: 'center', display: 'flex' }}>
                  <Avatar variant="square" alt="enphase tick image" src={TickImage} className={classes.large} />
                </Typography>

                <Title className={classes.value} id="uploadDoc">
                  Payment Successful
                </Title>
                <Typography className={classes.label} id="status_label">
                  Your application has been submitted successfully.
                </Typography>
                <Box className={classes.nextbtn}>
                  <FilledButton
                    style={{ height: '40px' }}
                    onClick={() => {
                      trackEvents('triggerSgipPageView', { pageTitle: 'Application Status' });
                      forwardTo('applicationTrack');
                    }}
                  >
                    Check Application Status
                  </FilledButton>
                </Box>
              </div>
            </Grid>
          </BoxLayout>
        </Grid>
        {showNPSDialog && <NpsFeedbackDialog applicationId={applicationId} applicationStage={1} email={email} />}
      </Fragment>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  getSgipApplicationDetailsBySiteId: payload => dispatch(getSgipApplicationDetailsBySiteId(payload))
});

const mapStateToProps = state => ({
  siteId: state.appReducer.siteId,
  sgip_application: state.sgipAppReducer.sgip_application,
  isInstaller: state.appReducer.isInstaller
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess);
