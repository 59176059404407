import { take, put, call } from 'redux-saga/effects';
import { get, post, deleteMethod } from '../../utils/api';
import { forwardTo } from '../../utils/helper';
import settings from '../../utils/settings';

import {
  GET_SGIP_INSTALLERS_REQUEST,
  GET_SGIP_PRIMARY_INSTALLERS_REQUEST,
  GET_SGIP_INSTALLERS_REQUEST_SUCCESS,
  GET_SGIP_PRIMARY_INSTALLERS_SUCCESS,
  GET_APPLICATION_NUMBER_REQUEST,
  GET_APPLICATION_NUMBER_REQUEST_SUCCESS,
  GET_SGIP_DOC_S3_PRE_SIGNED_URL_REQUEST,
  GET_SGIP_DOC_S3_PRE_SIGNED_URL_SUCCESS,
  PUT_SGIP_DOC_UPLOAD_TO_S3_REQUEST,
  GET_SGIP_DOC_FILE_FROM_S3_REQUEST,
  POST_SGIP_PRIMARY_DETAILS_REQUEST,
  POST_SGIP_PRIMARY_DETAILS_SUCCESS,
  POST_DEVELOPER_KEY_UPDATE_MAIL_REQUEST,
  POST_DEVELOPER_KEY_UPDATE_MAIL_SUCCESS,
  POST_SGIP_NOTIFY_ME_DETAILS_REQUEST,
  POST_SGIP_NOTIFY_ME_DETAILS_SUCCESS,
  POST_SGIP_PERSONAL_DETAILS_REQUEST,
  POST_SGIP_PERSONAL_DETAILS_SUCCESS,
  POST_SGIP_QUESTIONNAIRE_REQUEST,
  POST_SGIP_QUESTIONNAIRE_SUCCESS,
  POST_SGIP_APPLICATION_DOCUMENTS_REQUEST,
  POST_SGIP_APPLICATION_DOCUMENTS_SUCCESS,
  POST_SGIP_APPLICATION_TRACKER_UPLOAD_DOCUMENTS_REQUEST,
  POST_SGIP_APPLICATION_TRACKER_UPLOAD_DOCUMENTS_SUCCESS,
  GET_DOCUMENTS_TYPE_PRE_SIGNED_URL_REQUEST,
  GET_DOCUMENTS_TYPE_PRE_SIGNED_URL_SUCCESS,
  DELETE_SGIP_DOC_FILE_FROM_S3_REQUEST,
  DELETE_SGIP_DOC_FILE_FROM_S3_SUCCESS,
  GET_SGIP_APPLICATION_DETAILS_BY_SITEID_REQUEST,
  GET_SGIP_APPLICATION_DETAILS_BY_SITEID_SUCCESS,
  GET_FILE_FROM_S3_REQUEST,
  POST_SGIP_PAYMENT_UPDATE_REQUEST,
  POST_SGIP_PAYMENT_UPDATE_SUCCESS,
  POST_SGIP_PAYMENT_INTENT_REQUEST,
  POST_SGIP_PAYMENT_INTENT_SUCCESS,
  GET_SGIP_STRIPE_PUBLISHABLE_KEY_REQUEST,
  GET_SGIP_STRIPE_PUBLISHABLE_KEY_SUCCESS,
  GET_UTILITY_BILLS_PRE_SIGNED_URL_REQUEST,
  GET_UTILITY_BILLS_PRE_SIGNED_URL_SUCCESS,
  GET_UPLOADED_DOCUMENTS_PRE_SIGNED_URL_REQUEST,
  GET_UPLOADED_DOCUMENTS_PRE_SIGNED_URL_SUCCESS,
  GET_REQUEST_FAILURE,
  POST_REQUEST_FAILURE,
  POST_APPLICANT_NOTIFICATION_REQUEST,
  POST_APPLICANT_NOTIFICATION_SUCCESS,
  POST_SIGNED_DOCUMENTS_REQUEST,
  POST_SIGNED_DOCUMENTS_SUCCESS,
  POST_FIELD_CHANGED_ACCEPT_NOTIFICATION_REQUEST,
  POST_FIELD_CHANGED_ACCEPT_NOTIFICATION_SUCCESS,
  POST_FIELD_CHANGED_REJECT_NOTIFICATION_REQUEST,
  POST_FIELD_CHANGED_REJECT_NOTIFICATION_SUCCESS,
  POST_PROMOCODE_SUCCESS,
  POST_PROMOCODE_REQUEST,
  POST_SGIP_PROMOCODE_PAYMENT_REQUEST,
  POST_SGIP_PROMOCODE_PAYMENT_SUCCESS,
  POST_SGIP_ATTESTATION_FORM_REQUEST,
  POST_SGIP_ATTESTATION_FORM_SUCCESS,
  GET_SGIP_USER_SELECTION_REQUEST,
  GET_SGIP_USER_SELECTION_SUCCESS,
  POST_SGIP_NPS_FEEDBACK_REQUEST,
  POST_SGIP_NPS_FEEDBACK_SUCCESS,
  GET_SGIP_NPS_FEEDBACK_REQUEST,
  GET_SITE_INFO_REQUEST,
  GET_INCENTIVE_RATES_SGIP_REQUEST,
  GET_INCENTIVE_RATES_SGIP_SUCCESS,
  GET_INCENTIVE_RATES_SGIP_FAILURE,
  GET_SGIP_CONFIGS_REQUEST,
  GET_SGIP_CONFIGS_SUCCESS,
  GET_SGIP_CONFIGS_FAILURE,
  GET_CPUC_URL_REQUEST,
  CREATE_SGIP_APPLICATION_REQUEST,
  CREATE_SGIP_APPLICATION_SUCCESS,
  CREATE_SGIP_APPLICATION_FAILURE,
  GET_SGIP_APPLICATION_DETAILS_BY_SITEID_FAILURE,
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_SUCCESS,
  ADD_TO_CART_FAILURE
} from './constants';
import { isEmpty, isNull } from '../../utils/lodash';

export function* getSGIPInstallers() {
  while (true) {
    const request = yield take(GET_SGIP_INSTALLERS_REQUEST);
    try {
      let response;
      const { source, installerName } = request.payload;
      response = yield call(get, {
        url: settings.sgipCoreUrl(
          `sgipkey/installers?source=${source}&installerName=${installerName}`
        ),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_SGIP_INSTALLERS_REQUEST_SUCCESS,
          sgip_installers: result.installers,
          totalElements: result.installers.length,
          message: '',
          currentlySending: false,
        });
      }
    } catch (error) {
      yield put({ type: GET_REQUEST_FAILURE, message: error });
    }
  }
}

export function* getSGIPPrimaryInstallers() {
  while (true) {
    const request = yield take(GET_SGIP_PRIMARY_INSTALLERS_REQUEST);
    const { siteId, successCb, failureCb } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.sgipCoreUrl(`sgipkey/installerswithaccess?siteId=${siteId}`),
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_SGIP_PRIMARY_INSTALLERS_SUCCESS,
          sgip_primary_installers: result.installers,
          totalElements: result.installers.length,
          message: '',
          currentlySending: false
        });
        successCb();
      } else {
        failureCb();
      }
    } catch (error) {
      yield put({ type: GET_REQUEST_FAILURE, message: error });
      failureCb();
    }
  }
}

export function* getApplicationNumber() {
  while (true) {
    const request = yield take(GET_APPLICATION_NUMBER_REQUEST);
    const { siteId } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.sgipCoreUrl(`sgip/${siteId}/application/number`),
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_APPLICATION_NUMBER_REQUEST_SUCCESS,
          application_number: result.application_number,
          message: '',
          currentlySending: false
        });
      }
    } catch (error) {
      yield put({ type: GET_REQUEST_FAILURE, message: error });
    }
  }
}

export function* putSgipDocumentToS3() {
  while (true) {
    const request = yield take(PUT_SGIP_DOC_UPLOAD_TO_S3_REQUEST);
    //const {fileObj, preSignedS3Url, fName, successCbS3, failureCbS3 } = request.payload;
    const { fileObj, preSignedS3Url, fName, successCbS3, failureCbS3 } = request.payload;
    let contentDisposition = 'attachment; filename=' + fName;
    try {
      var requestOptions = {
        method: 'PUT',
        body: fileObj,
        headers: {
          'Content-Type': fileObj.type,
          'Content-Disposition': contentDisposition
        }
      };
      fetch(preSignedS3Url, requestOptions).then(function(data) {
        const { status } = data;
        if (status === 200) {
          successCbS3();
        } else {
          failureCbS3();
        }
      });
    } catch (error) {
      yield put({ type: POST_REQUEST_FAILURE, message: error });
      failureCbS3();
    }
  }
}

export function* sgipDocumentDownloadFromS3() {
  while (true) {
    const request = yield take(GET_SGIP_DOC_FILE_FROM_S3_REQUEST);
    const { preSignedS3Url, successCbS3, failureCbS3 } = request.payload;
    try {
      var requestOptions = {
        method: 'GET'
      };
      fetch(preSignedS3Url, requestOptions).then(function(data) {
        const { status } = data;
        if (status === 200) {
          successCbS3(data);
        } else {
          failureCbS3();
        }
      });
    } catch (error) {
      yield put({ type: GET_REQUEST_FAILURE, message: error });
      failureCbS3();
    }
  }
}

export function* getSgipDocumentPresignedURL() {
  while (true) {
    const request = yield take(GET_SGIP_DOC_S3_PRE_SIGNED_URL_REQUEST);
    const { file_name, http_method, fileType, successCb, failureCb } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.sgipCoreUrl(
          `sgip/presignedurl?file_name=${file_name}&http_method=${http_method}&downloadFormat=${fileType}`
        ),
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_SGIP_DOC_S3_PRE_SIGNED_URL_SUCCESS,
          preSignedS3Url: result
        });
        successCb(result.url);
      } else {
        failureCb();
        yield put({ type: GET_REQUEST_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      failureCb();
      yield put({ type: GET_REQUEST_FAILURE, message: error });
    }
  }
}

export function* postSgipPrimaryDetails() {
  while (true) {
    const request = yield take(POST_SGIP_PRIMARY_DETAILS_REQUEST);
    const { primaryDetails, successCb, failureCb } = request.payload;
    try {
      let response;
      response = yield call(post, {
        url: settings.sgipCoreUrl(`sgip/application/primaryDetails`),
        body: primaryDetails,
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: POST_SGIP_PRIMARY_DETAILS_SUCCESS,
          message: result.message
        });
        successCb();
      } else {
        yield put({ type: POST_REQUEST_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: POST_REQUEST_FAILURE, message: error });
      failureCb();
    }
  }
}

export function* postDeveloperKeyUpdateMail() {
  while (true) {
    const request = yield take(POST_DEVELOPER_KEY_UPDATE_MAIL_REQUEST);
    const { primaryDetails, successCb, failureCb } = request.payload;
    try {
      let response;
      response = yield call(post, {
        url: settings.sgipCoreUrl(`/sgip/application/primaryDetails`),
        body: primaryDetails,
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: POST_DEVELOPER_KEY_UPDATE_MAIL_SUCCESS,
          message: result.message,
          currentlySending: false
        });
        successCb();
      } else {
        yield put({ type: POST_REQUEST_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: POST_REQUEST_FAILURE, message: error });
      failureCb();
    }
  }
}

export function* postNotifyMeDetails() {
  while (true) {
    const request = yield take(POST_SGIP_NOTIFY_ME_DETAILS_REQUEST);
    const { notifyMeDetails, successCb, failureCb } = request.payload;
    try {
      let response;
      response = yield call(post, {
        url: settings.sgipCoreUrl(`save_notify_me`),
        body: notifyMeDetails,
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: POST_SGIP_NOTIFY_ME_DETAILS_SUCCESS,
          message: result.message
        });
        successCb();
      } else {
        yield put({ type: POST_REQUEST_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: POST_REQUEST_FAILURE, message: error });
      failureCb();
    }
  }
}

export function* postSgipPersonalDetails() {
  while (true) {
    const request = yield take(POST_SGIP_PERSONAL_DETAILS_REQUEST);
    const { personalDetails, successCb, failureCb } = request.payload;
    try {
      let response;
      response = yield call(post, {
        url: settings.sgipCoreUrl(`sgip/application/personalDetails`),
        body: personalDetails,
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: POST_SGIP_PERSONAL_DETAILS_SUCCESS,
          sgipApplication: result,
          message: result.message
        });
        successCb();
      } else {
        yield put({ type: POST_REQUEST_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: POST_REQUEST_FAILURE, message: error });
      failureCb();
    }
  }
}

export function* postSgipBudgetQuestionnaires() {
  while (true) {
    const request = yield take(POST_SGIP_QUESTIONNAIRE_REQUEST);
    const { sgipBudget, successCb, failureCb } = request.payload;
    try {
      let response;
      response = yield call(post, {
        url: settings.sgipCoreUrl(`sgip/application/sgipbudget`),
        body: sgipBudget,
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: POST_SGIP_QUESTIONNAIRE_SUCCESS,
          sgip_application: result,
          message: result.message
        });
        successCb && successCb();
      } else {
        yield put({ type: POST_REQUEST_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: POST_REQUEST_FAILURE, message: error });
      failureCb && failureCb();
    }
  }
}

export function* postSgipApplicationDocuments() {
  while (true) {
    const request = yield take(POST_SGIP_APPLICATION_DOCUMENTS_REQUEST);
    const { application_documents, successCb, failureCb } = request.payload;
    try {
      let response;
      response = yield call(post, {
        url: settings.sgipCoreUrl(`sgip/application/documents`),
        body: application_documents,
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: POST_SGIP_APPLICATION_DOCUMENTS_SUCCESS,
          sgipApplication: result,
          message: result.message
        });
        successCb();
      } else {
        yield put({ type: POST_REQUEST_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: POST_REQUEST_FAILURE, message: error });
      failureCb();
    }
  }
}

export function* postSgipApplicationTrackUploadDocuments() {
  while (true) {
    const request = yield take(POST_SGIP_APPLICATION_TRACKER_UPLOAD_DOCUMENTS_REQUEST);
    const { siteId, documents, successCb, failureCb } = request.payload;
    try {
      let response;
      response = yield call(post, {
        url: settings.sgipCoreUrl(`sgip/applicationTracker/upload_documents`),
        body: {
          documents,
          site_id: siteId
        },
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: POST_SGIP_APPLICATION_TRACKER_UPLOAD_DOCUMENTS_SUCCESS,
          sgipApplication: result,
          message: result.message
        });
        successCb();
      } else {
        yield put({ type: POST_REQUEST_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: POST_REQUEST_FAILURE, message: error });
      failureCb();
    }
  }
}

export function* deleteSgipDocumentFromS3() {
  while (true) {
    const request = yield take(DELETE_SGIP_DOC_FILE_FROM_S3_REQUEST);
    const { file_name, successCb1, failureCb1 } = request.payload;
    try {
      let response;
      response = yield call(deleteMethod, {
        url: settings.sgipCoreUrl(`sgip/application/document?file_name=${file_name}`),
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: DELETE_SGIP_DOC_FILE_FROM_S3_SUCCESS,
          message: result.message,
          currentlySending: false
        });
        successCb1();
      } else {
        failureCb1();
        yield put({ type: GET_REQUEST_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      failureCb1();
      yield put({ type: GET_REQUEST_FAILURE, message: error });
    }
  }
}

export function* getSgipApplicationDetailsBySiteId() {
  while (true) {
    const request = yield take(GET_SGIP_APPLICATION_DETAILS_BY_SITEID_REQUEST);
    const { siteId, isInstaller, successCb, failureCb } = request.payload;

    try {
      let response;
      response = yield call(get, {
        url: settings.sgipCoreUrl(`sgip/${siteId}/application`),
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_SGIP_APPLICATION_DETAILS_BY_SITEID_SUCCESS,
          sgip_application: result.sgip_application,
          message_list: result.message_list,
          message: result.message,
          currentlySending: false,
          unreadMessageCount: result.unread_msg_count
        });
        successCb(result.sgip_application);
      } else {
        if ((status === 401 || isNull(status)) && isInstaller) {
          forwardTo('sgip-applications');
        }
        yield put({
          type: GET_SGIP_APPLICATION_DETAILS_BY_SITEID_FAILURE,
          message: result.message || result.messages,
          currentlySending: false,
          isUnauthorizedUser: (status === 401 || isNull(status)) && isInstaller
        });
        failureCb();
      }
    } catch (error) {
      yield put({
        type: GET_SGIP_APPLICATION_DETAILS_BY_SITEID_FAILURE,
        message: error,
        currentlySending: false,
        isUnauthorizedUser: false
      });
      failureCb();
    }
  }
}

export function* downloadFileFromS3() {
  while (true) {
    const request = yield take(GET_FILE_FROM_S3_REQUEST);
    const { preSignedS3Url, successCbS3, failureCbS3 } = request.payload;
    try {
      var requestOptions = {
        method: 'GET'
      };
      fetch(preSignedS3Url, requestOptions).then(function(data) {
        const { status } = data;
        if (status === 200) {
          successCbS3(data);
        } else {
          failureCbS3();
        }
      });
    } catch (error) {
      yield put({ type: GET_REQUEST_FAILURE, message: error });
      failureCbS3();
    }
  }
}

export function* postSgipServicePaymentUpdate() {
  while (true) {
    const request = yield take(POST_SGIP_PAYMENT_UPDATE_REQUEST);
    const { siteId, referenceNumber, paymentStatus, successCb, failureCb, application_submitted_by } = request.payload;
    let paymentDetails = {
      site_id: siteId,
      reference_number: referenceNumber,
      status: paymentStatus,
      application_submitted_by
    };
    try {
      let response;
      response = yield call(post, {
        url: settings.sgipCoreUrl(`sgip/payment`),
        body: paymentDetails,
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: POST_SGIP_PAYMENT_UPDATE_SUCCESS,
          message: result.message
        });
        successCb();
      } else {
        yield put({ type: POST_REQUEST_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: POST_REQUEST_FAILURE, message: error });
      failureCb();
    }
  }
}

export function* postSgipPaymentIntent() {
  while (true) {
    const request = yield take(POST_SGIP_PAYMENT_INTENT_REQUEST);
    const { siteId, successCb, failureCb } = request.payload;
    let request_body = {
      site_id: siteId
    };
    try {
      let response;
      response = yield call(post, {
        url: settings.sgipCoreUrl(`sgip/application/payment_intent`),
        body: request_body,
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: POST_SGIP_PAYMENT_INTENT_SUCCESS,
          message: result.message,
          client_secret: result.client_secret
        });
        successCb();
      } else {
        yield put({ type: POST_REQUEST_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: POST_REQUEST_FAILURE, message: error });
      failureCb();
    }
  }
}

export function* getStripePublishableKey() {
  while (true) {
    yield take(GET_SGIP_STRIPE_PUBLISHABLE_KEY_REQUEST);
    try {
      let response;
      response = yield call(get, {
        url: settings.sgipCoreUrl(`sgip/application/stripe_key`),
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_SGIP_STRIPE_PUBLISHABLE_KEY_SUCCESS,
          stripe_publishable_key: result.stripe_publishable_key,
          message: result.message
        });
      }
    } catch (error) {
      yield put({ type: GET_REQUEST_FAILURE, message: error });
    }
  }
}

export function* getUtilityBillsPresignedURLs() {
  while (true) {
    const request = yield take(GET_UTILITY_BILLS_PRE_SIGNED_URL_REQUEST);
    const { siteId, fileType, successCb, failureCb } = request.payload;
    let siteIdData = {
      site_id: siteId,
      downloadFormat: fileType
    };
    try {
      let response;
      response = yield call(post, {
        url: settings.sgipCoreUrl(`sgip/application/utilitybills/presignedurl`),
        body: siteIdData,
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_UTILITY_BILLS_PRE_SIGNED_URL_SUCCESS,
          utility_bills_urls: result.utility_bills_urls,
          sgip_application: result.sgip_application
        });
        successCb();
      } else {
        failureCb();
        yield put({ type: GET_REQUEST_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      failureCb();
      yield put({ type: GET_REQUEST_FAILURE, message: error });
    }
  }
}

export function* getDocumentsTypePresignedURLs() {
  while (true) {
    const request = yield take(GET_DOCUMENTS_TYPE_PRE_SIGNED_URL_REQUEST);
    const { siteId, documentType, fileType, successCb, failureCb } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.sgipCoreUrl(
          `application_documents_presignedurls?site_id=${siteId}&document_type=${documentType}&downloadFormat=${fileType}`
        ),
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_DOCUMENTS_TYPE_PRE_SIGNED_URL_SUCCESS,
          uploaded_documents_urls: result.presigned_urls,
          sgip_application: result.sgip_application,
          documentType: documentType
        });
        successCb();
      } else {
        failureCb();
        yield put({ type: GET_REQUEST_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      failureCb();
      yield put({ type: GET_REQUEST_FAILURE, message: error });
    }
  }
}

export function* getUploadedDocumentsPresignedURLs() {
  while (true) {
    const request = yield take(GET_UPLOADED_DOCUMENTS_PRE_SIGNED_URL_REQUEST);
    const { messageID, fileType, successCb, failureCb } = request.payload;
    let messageData = {
      message_id: messageID,
      downloadFormat: fileType
    };
    try {
      let response;
      response = yield call(post, {
        url: settings.sgipCoreUrl(`sgip/sgipadmin/uploadeddocuments/presignedurl`),
        body: messageData,
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_UPLOADED_DOCUMENTS_PRE_SIGNED_URL_SUCCESS,
          documents_urls: result.documents_urls
        });
        successCb();
      } else {
        failureCb();
        yield put({ type: GET_REQUEST_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      failureCb();
      yield put({ type: GET_REQUEST_FAILURE, message: error });
    }
  }
}

export function* postApplicantNotification() {
  while (true) {
    const request = yield take(POST_APPLICANT_NOTIFICATION_REQUEST);
    const { applicationId, notificationContent, messageId, actionStatus, successCb, failureCb } = request.payload;
    let request_body = {
      application_id: applicationId,
      message_id: messageId,
      content: notificationContent,
      action_status: actionStatus
    };
    try {
      let response;
      response = yield call(post, {
        url: settings.sgipCoreUrl(`sgip/applicant/notification`),
        body: request_body,
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: POST_APPLICANT_NOTIFICATION_SUCCESS,
          message: result.message
        });
        successCb();
      } else {
        yield put({ type: POST_REQUEST_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: POST_REQUEST_FAILURE, message: error });
      failureCb();
    }
  }
}

export function* postSignedDocument() {
  while (true) {
    const request = yield take(POST_SIGNED_DOCUMENTS_REQUEST);
    const { signedDocument, successCb4, failureCb4 } = request.payload;
    try {
      let response;
      response = yield call(post, {
        url: settings.sgipCoreUrl(`sgip/application/signeddocument`),
        body: signedDocument,
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: POST_SIGNED_DOCUMENTS_SUCCESS,
          sgipApplication: result,
          message: result.message
        });
        successCb4();
      } else {
        yield put({ type: POST_REQUEST_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: POST_REQUEST_FAILURE, message: error });
      failureCb4();
    }
  }
}

export function* postFieldChangeAcceptNotification() {
  while (true) {
    const request = yield take(POST_FIELD_CHANGED_ACCEPT_NOTIFICATION_REQUEST);
    const { messageId, successCb, failureCb } = request.payload;
    let request_body = {
      message_id: messageId
    };
    try {
      let response;
      response = yield call(post, {
        url: settings.sgipCoreUrl(`sgip/applicant/fieldchanges/accept`),
        body: request_body,
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: POST_FIELD_CHANGED_ACCEPT_NOTIFICATION_SUCCESS,
          message: result.message
        });
        successCb();
      } else {
        yield put({ type: POST_REQUEST_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: POST_REQUEST_FAILURE, message: error });
      failureCb();
    }
  }
}

export function* postFieldChangeRejectNotification() {
  while (true) {
    const request = yield take(POST_FIELD_CHANGED_REJECT_NOTIFICATION_REQUEST);
    const { messageId, rejectReason, successCb, failureCb } = request.payload;
    let request_body = {
      message_id: messageId,
      reject_reason: rejectReason
    };
    try {
      let response;
      response = yield call(post, {
        url: settings.sgipCoreUrl(`sgip/applicant/fieldchanges/reject`),
        body: request_body,
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: POST_FIELD_CHANGED_REJECT_NOTIFICATION_SUCCESS,
          message: result.message
        });
        successCb();
      } else {
        yield put({ type: POST_REQUEST_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: POST_REQUEST_FAILURE, message: error });
      failureCb();
    }
  }
}

export function* verifyPromocode() {
  while (true) {
    const request = yield take(POST_PROMOCODE_REQUEST);
    const { promoCode, successCb, failureCb } = request.payload;
    let request_body = {
      promo_code: promoCode
    };
    try {
      let response;
      response = yield call(post, {
        url: settings.sgipCoreUrl(`sgip/application/verify/promocode`),
        body: request_body,
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: POST_PROMOCODE_SUCCESS,
          message: result.message
        });
        successCb();
      } else {
        failureCb();
        yield put({ type: POST_REQUEST_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: POST_REQUEST_FAILURE, message: error });
      failureCb();
    }
  }
}

export function* postSgipServicePromocodePayment() {
  while (true) {
    const request = yield take(POST_SGIP_PROMOCODE_PAYMENT_REQUEST);
    const { promoCode, successCb, failureCb, siteId, application_submitted_by } = request.payload;
    let paymentDetails = {
      promo_code: promoCode,
      site_id: siteId,
      application_submitted_by
    };
    try {
      let response;
      response = yield call(post, {
        url: settings.sgipCoreUrl(`sgip/payment/promocode`),
        body: paymentDetails,
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: POST_SGIP_PROMOCODE_PAYMENT_SUCCESS,
          message: result.message
        });
        successCb();
      } else {
        failureCb();
        yield put({ type: POST_REQUEST_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: POST_REQUEST_FAILURE, message: error });
      failureCb();
    }
  }
}

export function* postSgipAttestationForm() {
  while (true) {
    const request = yield take(POST_SGIP_ATTESTATION_FORM_REQUEST);
    const { attestationFormDetails, successCb, failureCb } = request.payload;
    const { siteId, ...details } = attestationFormDetails;
    try {
      let response;
      response = yield call(post, {
        url: settings.sgipCoreUrl(`sgip/${siteId}/application/attestationForm`),
        body: details,
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: POST_SGIP_ATTESTATION_FORM_SUCCESS,
          message: result.message
        });
        successCb();
      } else {
        failureCb();
        yield put({ type: POST_REQUEST_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: POST_REQUEST_FAILURE, message: error });
      failureCb();
    }
  }
}

export function* getSgipUserSelectionData() {
  while (true) {
    yield take(GET_SGIP_USER_SELECTION_REQUEST);
    try {
      let response;
      response = yield call(get, {
        url: settings.sgipCoreUrl('sgip/userselectiondata'),
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_SGIP_USER_SELECTION_SUCCESS,
          user_selection_data: isEmpty(result.user_selection_data) ? {} : result.user_selection_data[0],
          currentlySending: false
        });
      }
    } catch (error) {
      yield put({ type: POST_REQUEST_FAILURE, message: error });
    }
  }
}

export function* postSgipNpsFeedbackFlow() {
  while (true) {
    const request = yield take(POST_SGIP_NPS_FEEDBACK_REQUEST);
    const {
      applicationStage,
      applicationId,
      email,
      siteId,
      successCb,
      failureCb,
      ...remainingDetails
    } = request.payload;
    try {
      let response;
      response = yield call(post, {
        url: settings.sgipCoreUrl(`nps/${applicationId}?applicationStage=${applicationStage}&email=${email}`),
        body: remainingDetails,
        withBearer: true
      });
      const { success, status } = response;
      if (success && status === 200) {
        yield put({
          type: POST_SGIP_NPS_FEEDBACK_SUCCESS,
          currentlySending: false
        });
        successCb();
      } else {
        failureCb();
      }
    } catch (error) {
      failureCb();
    }
  }
}

export function* getSgipNpsFeedbackFlow() {
  while (true) {
    const request = yield take(GET_SGIP_NPS_FEEDBACK_REQUEST);
    const { applicationId, successCb } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.sgipCoreUrl(`nps/${applicationId}`),
        withBearer: true
      });
      const { success, status, result } = response;

      if (success && status === 200) {
        successCb(result);
      }
    } catch (error) {}
  }
}

export function* getSiteInfoFlow() {
  while (true) {
    const request = yield take(GET_SITE_INFO_REQUEST);
    const { siteId, successCb } = request.payload;

    try {
      let response = yield call(get, {
        url: settings.sgipCoreUrl(`sgip/siteinfo/${siteId}`),
        withBearer: true
      });

      const { success, status, result } = response;

      if (success && status === 200) {
        successCb(result);
      }
    } catch (error) {}
  }
}

export function* getSgipIncentiveRatesFlow() {
  while (true) {
    const request = yield take(GET_INCENTIVE_RATES_SGIP_REQUEST);
    const { successCb } = request.payload;

    try {
      let response;
      response = yield call(get, {
        url: settings.sgipCoreUrl('sgip/incentiverates'),
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_INCENTIVE_RATES_SGIP_SUCCESS,
          incentiveRates: result
        });
        successCb(result);
      }
    } catch (error) {
      yield put({ type: GET_INCENTIVE_RATES_SGIP_FAILURE, message: error });
    }
  }
}

export function* getSgipConfigsFlow() {
  while (true) {
    const request = yield take(GET_SGIP_CONFIGS_REQUEST);
    const { utilityId = '', successCb = () => {} } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url:
          utilityId === ''
            ? settings.sgipCoreUrl(`sgip/quesconfig`)
            : settings.sgipCoreUrl(`sgip/quesconfig?utilityId=${utilityId}`),
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_SGIP_CONFIGS_SUCCESS,
          utilityBlockInfo: result
        });
        successCb();
      }
    } catch (error) {
      yield put({ type: GET_SGIP_CONFIGS_FAILURE, message: error });
    }
  }
}

export function* getCpucURLFlow() {
  while (true) {
    const request = yield take(GET_CPUC_URL_REQUEST);
    const { appId, successCb, failureCb } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.sgipCoreUrl(`sgip/cpucUrl/${appId}`),
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        successCb(result.url);
      }
    } catch (error) {
      failureCb();
    }
  }
}

export function* createSgipApplicationFlow() {
  while (true) {
    const request = yield take(CREATE_SGIP_APPLICATION_REQUEST);
    const { siteId, successCb = () => {} } = request.payload;
    try {
      let response;
      response = yield call(post, {
        url: settings.sgipCoreUrl(`sgip/${siteId}/application`),
        withBearer: true
      });
      const { success, status } = response;
      if (success && status === 200) {
        yield put({
          type: CREATE_SGIP_APPLICATION_SUCCESS
        });
        successCb();
      }
    } catch (error) {
      yield put({ type: CREATE_SGIP_APPLICATION_FAILURE, message: error });
    }
  }
}

export function* addToCartFlow() {
  while (true) {
    const request = yield take(ADD_TO_CART_REQUEST);
    const { applicationId, body, successCbk = () => {}, failureCbk = () => {} } = request.payload;
    try {
      const response = yield call(post, {
        url: settings.sgipCoreUrl(`payments/${applicationId}/cart`),
        body: body,
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: ADD_TO_CART_SUCCESS
        });
        successCbk(result.cart_url);
      } else {
        failureCbk();
      }
    } catch (error) {
      yield put({ type: ADD_TO_CART_FAILURE, message: error });
      failureCbk();
    }
  }
}
