import { makeStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';

const useMobileStyles = makeStyles(theme => ({
  root: {
    borderRadius: theme.spacing(1.25),
    boxShadow: '1px 2px 5px 0 rgba(152, 152, 152, 0.1)',
    backgroundColor: theme.palette.tertiary.main
  },

  homeownerLink: {
    color: '#01b4de'
  },
  checkboxWrapper: {
    '& .MuiIconButton-colorSecondary': {
      color: 'rgba(0, 0, 0, 0.54)'
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#01b4de'
    }
  },
  top: {
    marginTop: theme.spacing(2.25)
  },
  header: {
    marginBottom: theme.spacing(0)
  },
  label: {
    marginTop: theme.spacing(1.5)
  },
  select: {
    marginTop: theme.spacing(0)
  },
  text: {
    fontWeight: 'normal'
  },
  description: {
    marginBottom: theme.spacing(2),
    lineHeight: 1.36
  },
  requiredDocumentWrapper: {
    borderRadius: theme.spacing(1.25),
    border: 'dashed 1px #afafaf',
    backgroundColor: 'transparent',
    padding: theme.spacing(2.5),
    marginTop: theme.spacing(2.5)
  },
  document: {
    lineHeight: 1.5
  },

  blue: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: '#01b4de',
    //padding: '0px',
    width: theme.spacing(3.75),
    height: theme.spacing(3.75)
  },
  lightblue: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: '#01b4de',
    //padding: '0px',
    opacity: 0.4,
    width: theme.spacing(3.75),
    height: theme.spacing(3.75),
    pointerEvents: 'none'
  },
  disablecolor: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: '#01b4de',
    opacity: 0.4,
    marginTop: '25px',
    height: '40px'
  },
  nextbtn: {
    padding: '10px 0px'
    //textAlign: 'center'
  },
  lightnextbtn: {
    paddingTop: '16px',
    textAlign: 'center',
    opacity: 0.4
  },
  cellpadding: {
    padding: '0px'
  },
  error: {
    color: 'red',
    marginTop: '0px'
  },
  details: {
    padding: '16px 0px 16px 16px'
  },
  adornment: {
    color: '#939598'
  },
  field: {
    marginTop: '24px'
  },
  units: {},
  dropdownStyle: {
    marginTop: '42px'
    //width: '100%'
  },
  errorInstaller: {
    color: 'red',
    marginTop: '10px'
  },
  pHide: {
    display: 'none'
  },
  [theme.breakpoints.up('md')]: {
    label: {
      marginTop: '15px !important'
    },
    wrapper: {
      flexBasis: '45%'
    },
    dHide: {
      display: 'none'
    },
    pHide: {
      display: 'block',
      height: '50%'
    },
    showBorder: {
      border: '1px solid #e2e3e4'
    }
  }
}));

const useDesktopStyles = makeStyles(theme => ({
  root: {}
}));

export { useMobileStyles, useDesktopStyles };
