import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  qa: {
    color: theme.palette.primary.mainText,
    padding: theme.spacing(2.5)
  },
  link: {
    color: '#01B4DE',
    cursor: 'pointer'
  },
  iosLink: {
    color: '#01B4DE',
    textDecoration: 'none'
  },
  [theme.breakpoints.up('md')]: {
    qa: {
      color: theme.palette.primary.mainText,
      padding: theme.spacing(2.5),
      display: 'flex',
      alignItems: 'center',
      height: theme.spacing(4)
    }
  }
}));

export { useStyles };
