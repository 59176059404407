import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import enhoTheme from './theme/enhoTheme';
import enlmTheme from './theme/enlmTheme';
import Routes from './routes';

const AppTheme = props => {
  useEffect(() => {
    let fontEnv = '';
    switch (window.location.origin) {
      case 'https://enlighten.enphaseenergy.com':
        fontEnv = 'enlighten';
        break;
      case 'https://qa2.enphaseenergy.com':
        fontEnv = 'qa2';
        break;
      case 'https://enlighten-qa4.enphaseenergy.com':
        fontEnv = 'enlighten-qa4';
        break;
      default:
        fontEnv = 'enlighten-qa4';
        break;
    }
    var newStyle = document.createElement('style');
    newStyle.appendChild(
      document.createTextNode(`
              @font-face {
                font-family: 'Visuelt';
                font-style: normal;
                font-weight: 500;
                src: url('https://assets-${fontEnv}.enphaseenergy.com/enho/fonts/EnphaseVisuelt-Medium.ttf') format('truetype');
              }
              @font-face {
                font-family: 'Visuelt';
                font-style: normal;
                font-weight: 400;
                src: url('https://assets-${fontEnv}.enphaseenergy.com/enho/fonts/EnphaseVisuelt-Regular.ttf')
                format('truetype');
              }
              @font-face {
                font-family: 't-star';
                font-style: normal;
                src: url('https://assets-${fontEnv}.enphaseenergy.com/enho/fonts/T-StarPro-Bold.otf')
                format('opentype');
              }
          `)
    );
    document.head.appendChild(newStyle);
  });

  // isInstaller here actually means isENLM. To differenciate b/w ENHO and ENLM logins
  const isInstaller = props.isInstaller;
  let theme = enlmTheme;
  if (!isInstaller) {
    theme = enhoTheme;
  }
  return (
    <MuiThemeProvider theme={theme}>
      <Routes />
    </MuiThemeProvider>
  );
};

const mapStateToProps = state => ({
  isInstaller: state.appReducer.isInstaller
});

export default connect(mapStateToProps)(AppTheme);
