import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import appReducer from './containers/Layouts/reducer';
import adminReducer from './containers/Admin/reducer';
import sgipAppReducer from './containers/SgipApp/reducer';

export default combineReducers({
  form: formReducer,
  appReducer,
  adminReducer,
  sgipAppReducer
});
