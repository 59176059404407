import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import clsx from 'clsx';
import { Grid, Typography, Divider, Box, Link } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';

import Loader from '../../../components/Loader';
import { LARGE_SCALE_STORAGE, SOUTHERN_CALIFORNIA_GAS_COMPANY } from '../constants';
import { forwardTo, numberWithCommas, trackEvents } from '../../../utils/helper';
import { isEmpty, get } from '../../../utils/lodash';
import {
  getSgipDocumentPresignedURL,
  downloadFileFromS3,
  getUtilityBillsPresignedURLs,
  getDocumentsTypePresignedURLs,
  getSgipUserSelectionData,
  getSgipApplicationDetailsBySiteId,
  getCpucURL
} from '../actions';
import { BoxLayout } from '../../../components/Common';
import { Title } from '../../../components/Texts';
import { Button } from '../../../components/Buttons';
import HftdDialog from '../../../components/SgipApp/HftdDialog';
import IncomeQualifierDialog from '../../../components/SgipApp/IncomeQualifierDialog';
import { downloadBatteryReport } from '../../Admin/actions';
import { EQUITY_AND_ELIGIBILITY_LINK, COMMON_MEDICAL_BASELINE_URL } from '../../../utils/constants';

const styles = theme => ({
  root: {
    borderRadius: theme.spacing(1.25),
    boxShadow: '1px 2px 5px 0 rgba(152, 152, 152, 0.1)',
    backgroundColor: theme.palette.tertiary.main
  },
  top: {
    margin: theme.spacing(2.25)
  },
  batteryReportDownloadWrapper: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  label: {
    color: '#939598',
    marginTop: '10px'
  },
  label2: {
    color: '#939598',
    marginTop: '5px'
  },
  label3: {
    color: '#939598',
    marginTop: '10px'
  },
  value: {
    color: '#545456'
  },
  question: {
    marginTop: '20px',
    color: '#545456'
  },
  answer: {
    color: '#f37321'
  },
  tableDisplay: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  wrapIcon: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    color: '#01b4de',
    marginLeft: '25px',
    cursor: 'pointer'
  },
  documents: {
    borderRadius: theme.spacing(1.25),
    boxShadow: '1px 2px 5px 0 rgba(152, 152, 152, 0.1)',
    backgroundColor: theme.palette.tertiary.main,
    padding: theme.spacing(2.5)
  },
  documentName: {
    marginTop: 0
  },
  divider: {
    margin: '15px 0'
  },
  listWrapper: {
    display: 'flex',
    padding: '0 15px',
    listStyle: 'none',
    marginBottom: 0,
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  list: {
    marginRight: '20px'
  },
  link: {
    textTransform: 'none',
    padding: '0px',
    color: theme.palette.secondary.main,
    minWidth: theme.spacing(1)
  },
  [theme.breakpoints.up('md')]: {
    container: {
      margin: '30px 194px'
    },
    dWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      backgroundColor: theme.palette.tertiary.main,
      boxShadow: '1px 2px 5px 0 rgba(152, 152, 152, 0.1)',
      borderRadius: '3px'
    },
    detailsItem: {
      flexBasis: '100%'
    },
    detailsFlexFifty: {
      flexBasis: '45%'
    },
    documents: {
      display: 'flex',
      flexWrap: 'wrap',
      border: '1px solid rgba(136, 136, 136, 0.2)',
      borderRadius: '3px'
    },
    dHeader: {
      flexBasis: '100%'
    },
    personalInfoItem: {
      flexBasis: '48%'
    },
    divider: {
      display: 'none'
    },
    wrapIcon: {
      marginLeft: 0
    }
  },
  [theme.breakpoints.up('sm')]: {
    list: {
      width: '150px'
    },
    fileName: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  }
});

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileDownloadError: '',
      downloadInProgress: false,
      sgip_application: {},
      hftdFlag: false,
      incomeQualifierFlag: false,
      findOutType: '',
      baselineLink: ''
    };
    this.questionIndex = 0;
    this.loadApplication = this.loadApplication.bind(this);
    this.handleUtilityFileDownload = this.handleUtilityFileDownload.bind(this);
    this.handleGreenButtonFileDownload = this.handleGreenButtonFileDownload.bind(this);
    this.downloadAllUtilityFiles = this.downloadAllUtilityFiles.bind(this);
    this.incomeQualifierDialog = this.incomeQualifierDialog.bind(this);
    this.handleTaxReturnsFileDownload = this.handleTaxReturnsFileDownload.bind(this);
  }

  componentDidMount() {
    const { sgip_application, siteId } = this.props;
    this.loadApplication(siteId);
    this.loadCountryDetails();
    this.setState({ sgip_application: sgip_application });
  }
  hftdPopup() {
    this.setState({ hftdFlag: !this.state.hftdFlag, findOutType: '' });
  }
  incomeQualifierDialog() {
    this.setState({ incomeQualifierFlag: !this.state.incomeQualifierFlag });
  }
  fireMapPopup(type) {
    this.setState({ hftdFlag: !this.state.hftdFlag, findOutType: type });
  }
  loadApplication(siteId) {
    this.props.getSgipApplicationDetailsBySiteId({
      siteId: siteId,
      isInstaller: this.props.isInstaller,
      successCb: () => {
        const { sgip_application } = this.props;
        if (sgip_application.stepCompleted >= 1) {
          const appId = get(sgip_application, 'application_id', '');
          this.props.getCpucURL({
            appId: appId,
            successCb: url => {
              this.setState({ baselineLink: url });
            },
            failureCb: () => {
              this.setState({ baselineLink: COMMON_MEDICAL_BASELINE_URL });
            }
          });
        }
      },
      failureCb: () => {}
    });
  }

  loadCountryDetails() {
    this.props.getSgipUserSelectionData({
      successCb: () => {},
      failureCb: () => {}
    });
  }

  onStage(stage) {
    return get(this.props.sgip_application, 'current_application_status.id') >= stage;
  }

  downloadAllUtilityFiles(urls, i) {
    if (this.props.isAndroid || this.props.isIOS) {
      var download = document.createElement('a');
      const encodedFileUrl = encodeURIComponent(urls[i]);
      download.href = 'enphase://user?download=' + encodedFileUrl;
      download.download = urls[i];
      download.target = '_self';
      document.body.appendChild(download);
      download.click();
      document.body.removeChild(download);
    } else {
      window.open(urls[i], '_blank');
    }
  }

  async handleUtilityFileDownload(sgip_application, index) {
    let emailId = get(sgip_application, 'email', '');
    const { siteId } = this.props;
    if (!isEmpty(emailId) && isEmpty(this.props.utility_bills_urls)) {
      this.props.getUtilityBillsPresignedURLs({
        siteId: siteId,
        fileType: 's3',
        successCb: () => {
          const { utility_bills_urls } = this.props;
          this.downloadAllUtilityFiles(utility_bills_urls, index);
        },
        failureCb: () => {
          this.loadApplication(siteId);
          console.log('We are facing some issue with file download, you can try after sometime.');
        }
      });
    } else if (!isEmpty(this.props.utility_bills_urls)) {
      this.downloadAllUtilityFiles(this.props.utility_bills_urls, index);
    }
    this.loadApplication(siteId);
  }

  async handleGreenButtonFileDownload(sgip_application, doc_type, index) {
    let emailId = get(sgip_application, 'email', '');
    const { siteId } = this.props;
    if (!isEmpty(emailId) && isEmpty(this.props.green_data_documents)) {
      this.props.getDocumentsTypePresignedURLs({
        siteId: siteId,
        documentType: doc_type,
        fileType: 's3',
        successCb: () => {
          const { green_data_documents } = this.props;
          this.downloadAllUtilityFiles(green_data_documents, index);
        },
        failureCb: () => {
          this.loadApplication(siteId);
          console.log('We are facing some issue with file download, you can try after sometime.');
        }
      });
    } else if (!isEmpty(this.props.green_data_documents)) {
      this.downloadAllUtilityFiles(this.props.green_data_documents, index);
    }
    this.loadApplication(siteId);
  }

  async handleTaxReturnsFileDownload(sgip_application, doc_type, index) {
    let emailId = get(sgip_application, 'email', '');
    const { siteId } = this.props;
    if (!isEmpty(emailId) && isEmpty(this.props.tax_returns_documents)) {
      this.props.getDocumentsTypePresignedURLs({
        siteId: siteId,
        documentType: doc_type,
        fileType: 's3',
        successCb: () => {
          const { tax_returns_documents } = this.props;
          this.downloadAllUtilityFiles(tax_returns_documents, index);
        },
        failureCb: () => {
          this.loadApplication(siteId);
          console.log('We are facing some issue with file download, you can try after sometime.');
        }
      });
    } else if (!isEmpty(this.props.tax_returns_documents)) {
      this.downloadAllUtilityFiles(this.props.tax_returns_documents, index);
    }
    this.loadApplication(siteId);
  }

  async handlePumpWellFileDownload(sgip_application, doc_type, index) {
    let emailId = get(sgip_application, 'email', '');
    const { siteId } = this.props;
    if (!isEmpty(emailId) && isEmpty(this.props.uploaded_documents_urls)) {
      this.props.getDocumentsTypePresignedURLs({
        siteId: siteId,
        documentType: doc_type,
        fileType: 's3',
        successCb: () => {
          const { uploaded_documents_urls } = this.props;
          this.downloadAllUtilityFiles(uploaded_documents_urls, index);
        },
        failureCb: () => {
          this.loadApplication(siteId);
          console.log('We are facing some issue with file download, you can try after sometime.');
        }
      });
    } else if (!isEmpty(this.props.uploaded_documents_urls)) {
      this.downloadAllUtilityFiles(this.props.uploaded_documents_urls, index);
    }
    this.loadApplication(siteId);
  }

  async handleContractFileDownload(sgip_application, doc_type, index) {
    let emailId = get(sgip_application, 'email', '');
    const { siteId } = this.props;
    if (!isEmpty(emailId) && isEmpty(this.props.contract_documents_urls)) {
      this.props.getDocumentsTypePresignedURLs({
        siteId: siteId,
        documentType: doc_type,
        fileType: 's3',
        successCb: () => {
          const { contract_documents_urls } = this.props;
          this.downloadAllUtilityFiles(contract_documents_urls, index);
        },
        failureCb: () => {
          this.loadApplication(siteId);
          console.log('We are facing some issue with file download, you can try after sometime.');
        }
      });
    } else if (!isEmpty(this.props.contract_documents_urls)) {
      this.downloadAllUtilityFiles(this.props.contract_documents_urls, index);
    }
    this.loadApplication(siteId);
  }

  async handlePurchaseInvoiceFileDownload(sgip_application, doc_type, index) {
    let emailId = get(sgip_application, 'email', '');
    const { siteId } = this.props;
    if (!isEmpty(emailId) && isEmpty(this.props.purchase_invoices_documents_urls)) {
      this.props.getDocumentsTypePresignedURLs({
        siteId: siteId,
        documentType: doc_type,
        fileType: 's3',
        successCb: () => {
          const { purchase_invoices_documents_urls } = this.props;
          this.downloadAllUtilityFiles(purchase_invoices_documents_urls, index);
        },
        failureCb: () => {
          this.loadApplication(siteId);
          console.log('We are facing some issue with file download, you can try after sometime.');
        }
      });
    } else if (!isEmpty(this.props.purchase_invoices_documents_urls)) {
      this.downloadAllUtilityFiles(this.props.purchase_invoices_documents_urls, index);
    }
    this.loadApplication(siteId);
  }

  handleDownloadFile(event, doc_type, index) {
    const { sgip_application, siteId, isIOS, isAndroid } = this.props;
    if (!isEmpty(sgip_application)) {
      if (doc_type === 'utility') {
        this.handleUtilityFileDownload(sgip_application, index);
      } else if (doc_type === 'greenbutton') {
        this.handleGreenButtonFileDownload(sgip_application, 'green_data_documents', index);
      } else if (doc_type === 'pumpwell') {
        this.handlePumpWellFileDownload(sgip_application, 'well_pump_documents', index);
      } else if (doc_type === 'taxReturns') {
        this.handleTaxReturnsFileDownload(sgip_application, 'tax_return_documents', index);
      } else if (doc_type === 'contract') {
        this.handleContractFileDownload(sgip_application, 'contract_documents', index);
      } else if (doc_type === 'purchaseInvoice') {
        this.handlePurchaseInvoiceFileDownload(sgip_application, 'purchase_invoices', index);
      } else {
        let file = '';
        if (doc_type === 'income') {
          file = get(sgip_application, 'application_documents.income_document.file_name', '');
        }

        this.setState({
          downloadInProgress: true
        });
        this.props.getSgipDocumentPresignedURL({
          file_name: file,
          http_method: 'GET',
          fileType: 's3',
          successCb: preSignedS3Url => {
            if (isIOS || isAndroid) {
              var download = document.createElement('a');
              const encodedFileUrl = encodeURIComponent(preSignedS3Url);
              download.href = 'enphase://user?download=' + encodedFileUrl;
              download.download = preSignedS3Url;
              download.target = '_self';
              document.body.appendChild(download);
              download.click();
              document.body.removeChild(download);
            } else {
              window.open(preSignedS3Url, '_blank');
            }
          },
          failureCb: () => {
            this.loadApplication(siteId);
            console.log('We are facing some issue with file download, you can try after sometime.');
          }
        });
      }
    }
  }

  getIndex() {
    this.questionIndex = this.questionIndex + 1;
    return this.questionIndex;
  }

  downloadBatteryReport = () => {
    const currMoment = moment();
    const endDate = currMoment.subtract(1, 'days').format('l');
    const past7Days = currMoment.subtract(6, 'days').format('l');
    this.props.downloadBatteryReport({
      siteId: this.props.sgip_application.site_id,
      startDate: past7Days,
      endDate: endDate
    });
  };

  render() {
    /*   Note: Do not remove emailId from the props list in render method, even there is warning for it  */
    const { classes, sgip_application, currentlySending, isInstaller, isIOS, user_selection_data } = this.props;
    const { hftdFlag, incomeQualifierFlag, findOutType } = this.state;
    this.questionIndex = 0;
    let utility_bills = [],
      uploaded_documents = [],
      green_data = [],
      tax_returns = [],
      contract_documents = [],
      purchase_invoices = [],
      income_document = '',
      homeowner_code = '',
      sgip_budget_elligibility = '',
      incentive = '0';
    if (sgip_application && sgip_application.hasOwnProperty('sgip_budget_information')) {
      if (
        sgip_application.sgip_budget_information &&
        sgip_application.sgip_budget_information.sgip_budget_elligibility !== null
      ) {
        sgip_budget_elligibility = sgip_application.sgip_budget_information.sgip_budget_elligibility;
        incentive = sgip_application.sgip_budget_information.incentive;
      }
    }
    if (sgip_application && sgip_application.hasOwnProperty('application_documents')) {
      if (sgip_application.application_documents && sgip_application.application_documents.utility_bills) {
        utility_bills = sgip_application.application_documents.utility_bills;
      }

      if (sgip_application.application_documents && sgip_application.application_documents.well_pump_documents) {
        uploaded_documents = sgip_application.application_documents.well_pump_documents;
      }

      if (sgip_application.application_documents && sgip_application.application_documents.green_data_documents) {
        green_data = sgip_application.application_documents.green_data_documents;
      }

      if (get(sgip_application.application_documents, 'tax_return_documents')) {
        tax_returns = sgip_application.application_documents.tax_return_documents;
      }

      if (get(sgip_application.application_documents, 'contract_documents')) {
        contract_documents = sgip_application.application_documents.contract_documents;
      }
      if (get(sgip_application.application_documents, 'purchase_invoices')) {
        purchase_invoices = sgip_application.application_documents.purchase_invoices;
      }
      if (get(sgip_application.application_documents, 'income_document')) {
        income_document = sgip_application.application_documents.income_document;
      }
    }
    homeowner_code = get(sgip_application, 'homeowner_code', '');

    return (
      <div className={classes.container}>
        {currentlySending && <Loader loading={currentlySending} />}
        {hftdFlag && (
          <HftdDialog
            handleClose={this.hftdPopup.bind(this)}
            isIOS={isIOS}
            isInstaller={isInstaller}
            findOutType={findOutType}
            sgipApplication={this.props.sgip_application}
          />
        )}
        {incomeQualifierFlag && (
          <IncomeQualifierDialog
            handleClose={this.incomeQualifierDialog.bind(this)}
            isInstaller={isInstaller}
            user_selection_data={user_selection_data}
          />
        )}
        <Fragment>
          <Box className={classes.batteryReportDownloadWrapper}>
            <Typography
              onClick={e => {
                trackEvents('triggerSgipPageView', { pageTitle: 'Application Status' });
                forwardTo('applicationTrack');
              }}
              className={classes.wrapIcon}
              variant="subtitle2"
            >
              <ArrowBackIosIcon />
              {'Go Back'}
            </Typography>
            {this.onStage(1) && isInstaller && (
              <Button
                className={classes.batteryReportButton}
                endIcon={<SystemUpdateAltIcon />}
                variant="outlined"
                onClick={() => this.downloadBatteryReport()}
              >
                {' '}
                Download Battery Report
              </Button>
            )}
          </Box>
          <div className={classes.dWrapper}>
            <Grid className={clsx(classes.top, classes.detailsItem)}>
              <BoxLayout>
                <Title className={classes.header}>SGIP Budget information</Title>
                <div>
                  <Typography className={classes.label}>
                    {!isInstaller
                      ? 'Based on your responses, you fall'
                      : 'Based on the responses, this application falls'}{' '}
                    under <b>{sgip_budget_elligibility}</b> and{' '}
                    {!isInstaller ? ' you are eligible for an' : ' is eligible for an'} incentive up to{' '}
                    <b>${numberWithCommas(incentive)}</b>.
                  </Typography>
                  <Typography className={classes.label}>
                    Note: This is an indicative incentive amount. Enphase is providing only an application processing
                    service; the actual payout will be determined by your utility.
                  </Typography>
                </div>

                <Typography className={classes.label} style={{ marginTop: '5px' }}>
                  For detailed information on eligibility{' '}
                  {isIOS ? (
                    <a
                      style={{ color: '#01B4DE', textDecoration: 'none' }}
                      href="enphase://user?url=https://www.cpuc.ca.gov/uploadedFiles/CPUCWebsite/Content/News_Room/NewsUpdates/2020/SGIP_residential_web_043020.pdf"
                      target="_self"
                    >
                      <Typography variant="body2">{` click here`}</Typography>
                    </a>
                  ) : (
                    <Link
                      style={{ color: '#01b4de', cursor: 'pointer' }}
                      variant="body2"
                      target="_blank"
                      rel="noreferrer"
                      underline="none"
                      onClick={() =>
                        window.open(
                          'https://www.cpuc.ca.gov/uploadedFiles/CPUCWebsite/Content/News_Room/NewsUpdates/2020/SGIP_residential_web_043020.pdf',
                          '_blank',
                          'noopener,noreferrer'
                        )
                      }
                    >
                      click here
                    </Link>
                  )}
                </Typography>
                <div />
              </BoxLayout>
            </Grid>
            <Grid className={clsx(classes.top, classes.detailsFlexFifty)}>
              <BoxLayout>
                <Title className={classes.header}>Details</Title>
                <Typography className={classes.label} id="application_id">
                  Application No
                </Typography>
                <Typography className={classes.value} id="application_id_value">
                  {sgip_application.application_number}
                </Typography>
                <Typography className={classes.label} id="utility_company_name">
                  {!isInstaller ? 'Your Electricity Utility' : 'Electricity Utility'}
                </Typography>
                <Typography className={classes.value} id="utility_company_name_value">
                  {sgip_application.utility_company_name}
                </Typography>
                <Typography className={classes.label} id="installer_name">
                  {!isInstaller ? 'Your Local Installer' : 'Installer'}
                </Typography>
                <Typography className={classes.value} id="installer_name_value">
                  {sgip_application.installer_name}
                </Typography>

                <Typography className={classes.label} id="battery_rebate_claimed_label">
                  Got SGIP rebate previously
                </Typography>
                <Typography className={classes.value} id="battery_rebate_claimed">
                  {get(sgip_application, 'battery_rebate_claimed') === null
                    ? '--'
                    : get(sgip_application, 'battery_rebate_claimed') === true
                    ? 'Yes'
                    : 'No'}
                </Typography>
                <Typography className={classes.label} id="battery_pto_label">
                  Battery PTO
                </Typography>
                <Typography className={classes.value} id="battery_pto">
                  {get(sgip_application, 'battery_pto') === null
                    ? '--'
                    : get(sgip_application, 'battery_pto') === true
                    ? 'Yes'
                    : 'No'}
                </Typography>
                <Typography className={classes.label} id="pto_date_label">
                  Date of Battery PTO
                </Typography>
                <Typography className={classes.value} id="pto_date">
                  {get(sgip_application, 'pto_date')
                    ? moment(get(sgip_application, 'pto_date')).format('MM/DD/YYYY')
                    : '--'}
                </Typography>
              </BoxLayout>
            </Grid>
            <Grid className={clsx(classes.top, classes.detailsFlexFifty)}>
              <div className={classes.documents}>
                <Title className={clsx(classes.header, classes.dHeader)}>Personal Information</Title>
                <Grid container direction="row" className={classes.personalInfoItem} style={{ flexDirection: 'row' }}>
                  <Grid item>
                    <Typography className={classes.label} id="first_name">
                      {!isInstaller ? 'First Name' : 'System Owner’s First Name​'}
                    </Typography>
                    <Typography className={classes.value} id="first_name_value">
                      {sgip_application.first_name}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container direction="row" className={classes.personalInfoItem} style={{ flexDirection: 'row' }}>
                  <Grid item>
                    <Typography className={classes.label} id="last_name">
                      {!isInstaller ? 'Last Name' : 'System Owner’s Last Name​'}
                    </Typography>
                    <Typography className={classes.value} id="last_name_value">
                      {sgip_application.last_name}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container direction="row" className={classes.personalInfoItem} style={{ flexDirection: 'row' }}>
                  <Grid item>
                    <Typography className={classes.label2} id="mobile">
                      {!isInstaller ? 'Mobile' : 'System Owner’s Mobile​'}
                    </Typography>
                    <Typography className={classes.value} id="mobile_value">
                      {sgip_application.phone}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container direction="row" className={classes.personalInfoItem} style={{ flexDirection: 'row' }}>
                  <Grid item>
                    <Typography className={classes.label2} id="email">
                      {!isInstaller ? 'Email' : 'System Owner’s Email​'}
                    </Typography>
                    <Typography className={classes.value} id="email_value">
                      {sgip_application.email}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container direction="row" className={classes.personalInfoItem} style={{ flexDirection: 'row' }}>
                  <Grid item>
                    <Typography className={classes.label} id="city">
                      {!isInstaller ? 'City in California' : 'System Owner’s City in California​'}
                    </Typography>
                    <Typography className={classes.value} id="city_value">
                      {sgip_application.city}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container direction="row" className={classes.personalInfoItem} style={{ flexDirection: 'row' }}>
                  <Grid item>
                    <Typography className={classes.label} id="site_ownership">
                      Site Ownership
                    </Typography>
                    <Typography className={classes.value} id="site_ownership_value">
                      {sgip_application.site_ownership}
                    </Typography>
                  </Grid>
                </Grid>

                <Divider className={classes.divider} />
                <Grid container direction="row" className={classes.personalInfoItem} style={{ flexDirection: 'row' }}>
                  <Grid item>
                    <Typography className={classes.label} id="battery_installation_site_address">
                      Battery Installation Site Address
                    </Typography>
                    <Typography className={classes.value} id="battery_installation_site_address_value">
                      {sgip_application.battery_installation_site_address}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container direction="row" className={classes.personalInfoItem} style={{ flexDirection: 'row' }}>
                  <Grid item>
                    <Typography className={classes.label3} id="communication_address">
                      {!isInstaller ? 'Mailing Address' : 'System Owner’s Mailing Address​'}
                    </Typography>
                    <Typography className={classes.value} id="communication_address_value">
                      {sgip_application.communication_address}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid className={clsx(classes.top, classes.detailsItem)}>
              <div className={classes.documents}>
                <Title className={clsx(classes.header, classes.dHeader)}>Documents</Title>
                <Box>
                  <div>
                    <Typography
                      className={classes.value}
                      id="utility_Bill_download_label"
                      style={{ marginTop: '15px', fontWeight: 'bold' }}
                    >
                      {`${this.getIndex()}. Latest Utility Bill`}
                    </Typography>
                    <ul className={classes.listWrapper}>
                      {utility_bills.map((url, index) => (
                        <li className={classes.list}>
                          <Typography className={`${classes.question} ${classes.fileName}`} style={{ marginTop: 0 }}>
                            {url.file_name}
                          </Typography>
                          <Button
                            className={clsx(classes.chooseFileButton, classes.button, classes.uploadButton)}
                            id={`utility_Bill_download_button_${index}`}
                            variant="outlined"
                            color="secondary"
                            disableElevation
                            component="label"
                            onClick={e => this.handleDownloadFile(e, 'utility', index)}
                            style={{ margin: '15px 5px 15px 0' }}
                          >
                            {`Download`}
                          </Button>
                        </li>
                      ))}
                    </ul>
                  </div>
                  {isEmpty(homeowner_code) && (
                    <div className={classes.contractDocument}>
                      <Typography className={classes.value} id="contract_document_label" style={{ fontWeight: 'bold' }}>
                        {`${this.getIndex()}. Contract Document`}
                      </Typography>
                      <ul className={classes.listWrapper}>
                        {contract_documents.map((url, index) => (
                          <li className={classes.list}>
                            <Typography className={`${classes.question} ${classes.fileName}`} style={{ marginTop: 0 }}>
                              {url.file_name}
                            </Typography>
                            <Button
                              className={clsx(classes.chooseFileButton, classes.button, classes.uploadButton)}
                              id="contract_document_button"
                              variant="outlined"
                              color="secondary"
                              disableElevation
                              component="label"
                              onClick={e => this.handleDownloadFile(e, 'contract', index)}
                              style={{ margin: '15px 5px 15px 0px' }}
                            >
                              {`Download`}
                            </Button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {!isEmpty(homeowner_code) && (
                    <div>
                      <Typography
                        className={classes.value}
                        id="purchase_invoices_document_label"
                        style={{ fontWeight: 'bold' }}
                      >
                        {`${this.getIndex()}. Purchase Invoice`}
                      </Typography>
                      <ul className={classes.listWrapper}>
                        {purchase_invoices.map((url, index) => (
                          <li className={classes.list}>
                            <Typography className={`${classes.question} ${classes.fileName}`} style={{ marginTop: 0 }}>
                              {url.file_name}
                            </Typography>
                            <Button
                              className={clsx(classes.chooseFileButton, classes.button, classes.uploadButton)}
                              id="purchase_invoice_button"
                              variant="outlined"
                              color="secondary"
                              disableElevation
                              component="label"
                              onClick={e => this.handleDownloadFile(e, 'purchaseInvoice', index)}
                              style={{ margin: '15px 5px 15px 0px' }}
                            >
                              {`Download`}
                            </Button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {!isEmpty(sgip_application.sgip_budget_information) &&
                  sgip_application.sgip_budget_information.sgip_budget_elligibility !== 'Equity Budget' &&
                  sgip_application.sgip_budget_information.q1_live_in_low_income_housing_community ? (
                    <div>
                      <Typography
                        className={classes.value}
                        id="contract_document_label"
                        style={{ marginTop: '15px', fontWeight: 'bold' }}
                      >
                        {`${this.getIndex()}. Income Document`}
                      </Typography>
                      <ul className={classes.listWrapper}>
                        <li className={classes.list}>
                          <Typography className={clsx(classes.question, classes.fileName, classes.documentName)}>
                            {income_document.file_name}
                          </Typography>
                        </li>
                      </ul>
                      <Button
                        className={clsx(classes.chooseFileButton, classes.button, classes.uploadButton)}
                        id="income_document_button"
                        variant="outlined"
                        color="secondary"
                        disableElevation
                        component="label"
                        onClick={e => this.handleDownloadFile(e, 'income')}
                        style={{ margin: '15px 5px 15px 15px' }}
                      >
                        {`Download`}
                      </Button>
                    </div>
                  ) : (
                    ''
                  )}
                  {!isEmpty(sgip_application) &&
                  sgip_application.storage_capacity > 24 &&
                  sgip_application.utility_company_name !== SOUTHERN_CALIFORNIA_GAS_COMPANY ? (
                    <div className={classes.greenButtonDocument}>
                      <Typography
                        className={classes.value}
                        id="contract_document_label"
                        style={{ marginTop: '15px', fontWeight: 'bold' }}
                      >
                        {`${this.getIndex()}. Green Button Data`}
                      </Typography>
                      <ul className={classes.listWrapper}>
                        {green_data.map((url, index) => (
                          <li className={classes.list}>
                            <Typography className={`${classes.question} ${classes.fileName}`} style={{ marginTop: 0 }}>
                              {url.file_name}
                            </Typography>
                            <Button
                              className={clsx(classes.chooseFileButton, classes.button, classes.uploadButton)}
                              id={`green_document_button_${index}`}
                              variant="outlined"
                              color="secondary"
                              disableElevation
                              component="label"
                              onClick={e => this.handleDownloadFile(e, 'greenbutton', index)}
                              style={{ margin: '15px 5px 15px 0' }}
                            >
                              {`Download`}
                            </Button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    ''
                  )}
                  {!isEmpty(sgip_application.sgip_budget_information) &&
                  sgip_application.sgip_budget_information.q6_rely_on_electric_pump_wells_for_water_supply ? (
                    <div>
                      <Typography
                        className={classes.value}
                        id="contract_document_label"
                        style={{ marginTop: '15px', fontWeight: 'bold' }}
                      >
                        {`${this.getIndex()}. Electric Well Pump Photos`}
                      </Typography>
                      <ul className={classes.listWrapper}>
                        {uploaded_documents.map((url, index) => (
                          <li className={classes.list}>
                            <Typography className={`${classes.question} ${classes.fileName}`} style={{ marginTop: 0 }}>
                              {url.file_name}
                            </Typography>
                            <Button
                              className={clsx(classes.chooseFileButton, classes.button, classes.uploadButton)}
                              id={`well_pump_document_button_${index}`}
                              variant="outlined"
                              color="secondary"
                              disableElevation
                              component="label"
                              onClick={e => this.handleDownloadFile(e, 'pumpwell', index)}
                              style={{ margin: '15px 5px 15px 0' }}
                            >
                              {`Download`}
                            </Button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    ''
                  )}
                  {!isEmpty(sgip_application.sgip_budget_information) &&
                  sgip_application.sgip_budget_information.q7_income_less_than_80_of_area_median_income ? (
                    <div>
                      <Typography
                        className={classes.value}
                        id="tax_returns_document_label"
                        style={{ marginTop: '15px', fontWeight: 'bold' }}
                      >
                        {`${this.getIndex()}. Tax Return`}
                      </Typography>
                      <ul className={classes.listWrapper}>
                        {tax_returns.map((url, index) => (
                          <li className={classes.list}>
                            <Typography className={`${classes.question} ${classes.fileName}`} style={{ marginTop: 0 }}>
                              {url.file_name}
                            </Typography>
                            <Button
                              className={clsx(classes.chooseFileButton, classes.button, classes.uploadButton)}
                              id={`tax_returns_document_button_${index}`}
                              variant="outlined"
                              color="secondary"
                              disableElevation
                              component="label"
                              onClick={e => this.handleDownloadFile(e, 'taxReturns', index)}
                              style={{ margin: '15px 5px 15px 0' }}
                            >
                              {`Download`}
                            </Button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    ''
                  )}
                </Box>
              </div>
            </Grid>
            <Grid className={clsx(classes.top, classes.detailsItem)}>
              <BoxLayout>
                <Title className={classes.header}>Questionnaire</Title>

                <Grid>
                  <Typography className={classes.question} id="q2_label">
                    Is {!isInstaller ? 'your' : 'the'} house in Tier 2 or Tier 3 High Fire Threat District (HFTD)?
                    <Typography
                      display="inline"
                      variant="body2"
                      className={classes.link}
                      onClick={e => this.fireMapPopup('hftd')}
                    >
                      {` Click here to find out`}
                    </Typography>
                  </Typography>
                  <Typography variant="body2" className={classes.answer} id="q2_answer">
                    {isEmpty(sgip_application.sgip_budget_information)
                      ? ''
                      : sgip_application.sgip_budget_information.q2_house_in_tier2_or_tier3_hftd === true
                      ? 'Yes'
                      : 'No'}
                  </Typography>

                  <Typography className={classes.question} id="q3_label">
                    {!isInstaller ? 'Have you been subjected' : 'Has the System Owner been subjected'} to two or more
                    PSPS (public safety power shutoff) events?{` `}
                    <Typography
                      display="inline"
                      variant="body2"
                      className={classes.link}
                      onClick={e => this.fireMapPopup('psps')}
                    >
                      {`Click here to find out`}
                    </Typography>
                  </Typography>
                  <Typography variant="body2" className={classes.answer} id="q3_answer">
                    {isEmpty(sgip_application.sgip_budget_information)
                      ? ''
                      : sgip_application.sgip_budget_information.q3_subject_to_two_or_more_psps_events === true
                      ? 'Yes'
                      : 'No'}
                  </Typography>

                  {isEmpty(sgip_application.sgip_budget_information) ? (
                    ''
                  ) : sgip_application.sgip_budget_information.q2_house_in_tier2_or_tier3_hftd === true ||
                    sgip_application.sgip_budget_information.q3_subject_to_two_or_more_psps_events === true ? (
                    <Fragment>
                      <Typography className={classes.question} id="q4_label">
                        {!isInstaller ? 'Are you a medical baseline' : 'Is the System Owner a baseline'} customer as
                        defined by CPUC
                        {isIOS ? (
                          <a
                            style={{ color: '#01B4DE', textDecoration: 'none' }}
                            href={`enphase://user?url=${this.state.baselineLink}`}
                            target="_self"
                          >
                            <Typography variant="body2">{` here?`}</Typography>
                          </a>
                        ) : (
                          <Link
                            style={{ color: '#01B4DE', cursor: 'pointer' }}
                            target="_blank"
                            variant="body2"
                            rel="noreferrer"
                            underline="none"
                            onClick={() => window.open(this.state.baselineLink, '_blank')}
                          >
                            {` here?`}
                          </Link>
                        )}
                      </Typography>
                      <Typography variant="body2" className={classes.answer} id="q4_answer">
                        {isEmpty(sgip_application.sgip_budget_information)
                          ? ''
                          : sgip_application.sgip_budget_information.q4_medical_baseline_customer_defined_by_cpuc ===
                            true
                          ? 'Yes'
                          : 'No'}
                      </Typography>

                      <Typography className={classes.question} id="q6_label">
                        {!isInstaller ? 'Do you rely on' : 'Does the System Owner rely on'} electric pump wells for
                        water supply?
                      </Typography>
                      <Typography variant="body2" className={classes.answer} id="q6_answer">
                        {isEmpty(sgip_application.sgip_budget_information)
                          ? ''
                          : sgip_application.sgip_budget_information.q6_rely_on_electric_pump_wells_for_water_supply ===
                            true
                          ? 'Yes'
                          : 'No'}
                      </Typography>

                      <Typography className={classes.question} id="q5_label">
                        {!isInstaller
                          ? 'Have you already notified your utility about the reason you qualify for the Medical Baseline allowances under the '
                          : 'Has the System Owner already notified their utility about the reason they qualify for the Medical Baseline allowances under the '}{' '}
                        {isIOS ? (
                          <a
                            style={{ color: '#01B4DE', textDecoration: 'none' }}
                            href={this.state.baselineLink}
                            target="_self"
                          >
                            <Typography variant="body2">{` CPUC`}</Typography>
                          </a>
                        ) : (
                          <Link
                            style={{ color: '#01B4DE', cursor: 'pointer' }}
                            variant="body2"
                            target="_blank"
                            rel="noreferrer"
                            underline="none"
                            onClick={() => window.open(this.state.baselineLink, '_blank')}
                          >
                            {` CPUC`}
                          </Link>
                        )}
                        ?
                      </Typography>
                      <Typography variant="body2" className={classes.answer} id="q5_answer">
                        {isEmpty(sgip_application.sgip_budget_information)
                          ? ''
                          : sgip_application.sgip_budget_information
                              .q5_have_you_notified_your_utility_about_your_illness === true
                          ? 'Yes'
                          : 'No'}
                      </Typography>

                      <Typography className={classes.question} id="q1_label">
                        {!isInstaller
                          ? 'Do you qualify for the Equity budget?'
                          : 'Does the System Owner qualify for the Equity budget?'}
                        {isIOS ? (
                          <a
                            style={{ color: '#01B4DE', textDecoration: 'none' }}
                            href={`enphase://user?url=${EQUITY_AND_ELIGIBILITY_LINK}`}
                            target="_self"
                          >
                            <Typography variant="body2">{` Click to download details`}</Typography>
                          </a>
                        ) : (
                          <Link
                            style={{ color: '#01B4DE', cursor: 'pointer' }}
                            variant="body2"
                            target="_blank"
                            rel="noreferrer"
                            underline="none"
                            onClick={() => window.open(EQUITY_AND_ELIGIBILITY_LINK, '_blank')}
                          >
                            {` Click to download details`}
                          </Link>
                        )}
                      </Typography>
                      <Typography variant="body2" className={classes.answer} id="q1_answer">
                        {isEmpty(sgip_application.sgip_budget_information)
                          ? ''
                          : sgip_application.sgip_budget_information.q1_live_in_low_income_housing_community === true
                          ? 'Yes'
                          : 'No'}
                      </Typography>
                    </Fragment>
                  ) : (
                    ' '
                  )}

                  {isEmpty(sgip_application.sgip_budget_information) ? (
                    ''
                  ) : sgip_application.sgip_budget_information.q2_house_in_tier2_or_tier3_hftd === false &&
                    sgip_application.sgip_budget_information.q3_subject_to_two_or_more_psps_events === false ? (
                    <Fragment>
                      <Typography className={classes.question} id="q1_label">
                        {!isInstaller
                          ? 'Do you qualify for the Equity budget?'
                          : 'Does the System Owner qualify for the Equity budget?'}
                        {isIOS ? (
                          <a
                            style={{ color: '#01B4DE', textDecoration: 'none' }}
                            href={`enphase://user?url=${EQUITY_AND_ELIGIBILITY_LINK}`}
                            target="_self"
                          >
                            <Typography variant="body2">{` Click to download details`}</Typography>
                          </a>
                        ) : (
                          <Link
                            style={{ color: '#01B4DE', cursor: 'pointer' }}
                            variant="body2"
                            target="_blank"
                            rel="noreferrer"
                            underline="none"
                            onClick={() => window.open(EQUITY_AND_ELIGIBILITY_LINK, '_blank')}
                          >
                            {` Click to download details`}
                          </Link>
                        )}
                      </Typography>
                      <Typography variant="body2" className={classes.answer} id="q1_answer">
                        {isEmpty(sgip_application.sgip_budget_information)
                          ? ''
                          : sgip_application.sgip_budget_information.q1_live_in_low_income_housing_community === true
                          ? 'Yes'
                          : 'No'}
                      </Typography>
                    </Fragment>
                  ) : (
                    ' '
                  )}
                  {isEmpty(sgip_application.sgip_budget_information)
                    ? ''
                    : sgip_application.sgip_budget_information.q6_rely_on_electric_pump_wells_for_water_supply && (
                        <Box>
                          <Typography className={classes.question} id="q7_label">
                            {!isInstaller
                              ? 'Are you in a single-family household with an income less than or equal to 80% of your area’s median income? '
                              : 'Is the System Owner in a single-family household with an income less than or equal to 80% of their area’s median income? '}
                            <Typography
                              display="inline"
                              variant="body2"
                              className={classes.link}
                              onClick={this.incomeQualifierDialog}
                            >
                              {` Click here to find out`}
                            </Typography>
                          </Typography>
                          <Typography variant="body2" className={classes.answer} id="q7_answer">
                            {isEmpty(sgip_application.sgip_budget_information)
                              ? ''
                              : sgip_application.sgip_budget_information
                                  .q7_income_less_than_80_of_area_median_income === true
                              ? 'Yes'
                              : 'No'}
                          </Typography>
                        </Box>
                      )}
                  {isEmpty(sgip_application.sgip_budget_information)
                    ? ''
                    : sgip_application.sgip_budget_information.sgip_budget_elligibility === LARGE_SCALE_STORAGE && (
                        <Box>
                          <Typography className={classes.question} id="q8_label">
                            {isInstaller
                              ? 'Is the System Owner going to claim the federal tax credit for the battery system?'
                              : 'Are you going to claim the federal tax credit for your battery system?'}
                          </Typography>
                          <Typography variant="body2" className={classes.answer} id="q8_answer">
                            {isEmpty(sgip_application.sgip_budget_information)
                              ? ''
                              : sgip_application.sgip_budget_information.q8_federal_tax_credit === true
                              ? 'Yes'
                              : 'No'}
                          </Typography>
                        </Box>
                      )}
                </Grid>
              </BoxLayout>
            </Grid>
          </div>
        </Fragment>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getSgipDocumentPresignedURL: payload => dispatch(getSgipDocumentPresignedURL(payload)),
  downloadFileFromS3: payload => dispatch(downloadFileFromS3(payload)),
  getUtilityBillsPresignedURLs: payload => dispatch(getUtilityBillsPresignedURLs(payload)),
  getDocumentsTypePresignedURLs: payload => dispatch(getDocumentsTypePresignedURLs(payload)),
  getSgipApplicationDetailsBySiteId: payload => dispatch(getSgipApplicationDetailsBySiteId(payload)),
  getSgipUserSelectionData: payload => dispatch(getSgipUserSelectionData(payload)),
  downloadBatteryReport: payload => dispatch(downloadBatteryReport(payload)),
  getCpucURL: payload => dispatch(getCpucURL(payload))
});

const mapStateToProps = state => ({
  currentlySending: state.sgipAppReducer.currentlySending,
  sgip_application: state.sgipAppReducer.sgip_application,
  siteId: state.appReducer.siteId,
  utility_bills_urls: state.sgipAppReducer.utility_bills_urls,
  uploaded_documents_urls: state.sgipAppReducer.uploaded_documents_urls,
  green_data_documents: state.sgipAppReducer.green_data_documents,
  tax_returns_documents: state.sgipAppReducer.tax_returns_documents,
  contract_documents_urls: state.sgipAppReducer.contract_documents_urls,
  purchase_invoices_documents_urls: state.sgipAppReducer.purchase_invoices_documents_urls,
  isInstaller: state.appReducer.isInstaller,
  isIOS: state.appReducer.isIOS,
  isAndroid: state.appReducer.isAndroid,
  user_selection_data: state.sgipAppReducer.user_selection_data
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(index));
