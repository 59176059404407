import React from 'react';
import {
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  FormHelperText
} from '@material-ui/core';
import { useStyles } from './style';
import { Title } from '../../Texts';
import { FilledButton } from '../../Buttons';
import Info from './Info';
import { BoxLayout } from '../../Common';
import PrivacyPolicy from '../PrivacyPolicy';
const Details = props => {
  const {
    handleSubmit,
    averageLoad,
    average_load_value,
    handleBackTypeChange,
    back_type_value,
    isInstaller,
    isIOS,
    attestationFormDetailsError
  } = props;

  const classes = useStyles();

  const handleRadioChange = event => {
    handleBackTypeChange(event.target.value);
  };

  return (
    <div>
      <div className={classes.headerSection}>
        <Title className={classes.header}>Attestation Form </Title>
        <br />
        <Typography className={classes.text}>
          Your SGIP application will require an Attestation Form
          {` `}
          <Info name="paragraph" />
          {` `}
          containing information related to the storage installation. To help us generate the form please answer the
          following questions:
        </Typography>
      </div>
      <BoxLayout style={{ paddingTop: 2 }}>
        <form>
          <FormControl component="fieldset" className={classes.formControl}>
            <Typography className={classes.text}> Backup Configuration</Typography>
            <RadioGroup aria-label="quiz" name="quiz" value={back_type_value} onChange={handleRadioChange}>
              <FormControlLabel
                value="Complete"
                control={<Radio size="small" />}
                classes={{ label: classes.optionControl }}
                label="Whole Home Backup"
              />
              <FormControlLabel
                value="Partial"
                control={<Radio size="small" />}
                classes={{ label: classes.optionControl }}
                label="Partial Home Backup"
              />
              {attestationFormDetailsError.backType &&
              attestationFormDetailsError.backType.error && (
                <FormHelperText className={classes.error}>{attestationFormDetailsError.backType.msg}</FormHelperText>
              )}
            </RadioGroup>
          </FormControl>
          <br />
        </form>
        <InputLabel className={classes.label} id="average_load">
          Average Daily consumption(kWh) in previous 12 months
          <span className={classes.loadHelpIcon}>
            <Info name="average_load" />
          </span>
          :
        </InputLabel>
        <Input
          fullWidth
          name="average_load"
          type="number"
          value={average_load_value}
          onChange={e => averageLoad(e)}
          onInput={e => {
            const value = e.target.value;
            e.target.value =
              value.split('.').length > 1
                ? value.split('.')[1] ? Math.max(0, parseFloat(e.target.value)).toString().slice(0, 7) : value
                : value;
          }}
          inputProps={{
            maxLength: 7
          }}
          endAdornment={
            <InputAdornment position="start" className={classes.adornment}>
              <Typography>kWh</Typography>
            </InputAdornment>
          }
        />
        {attestationFormDetailsError.averageLoad &&
        attestationFormDetailsError.averageLoad.error && (
          <FormHelperText className={classes.error}>{attestationFormDetailsError.averageLoad.msg}</FormHelperText>
        )}
      </BoxLayout>

      <div className={classes.pHide}>
        <FilledButton className={classes.btnblue} onClick={e => handleSubmit()}>
          Next
        </FilledButton>
        <PrivacyPolicy />
      </div>
    </div>
  );
};

export default Details;
