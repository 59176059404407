import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Title } from '../../Texts';
import { Typography, Button, Divider } from '@material-ui/core';
import { FileAttachementBar } from '../../FileAttachmentBar';
import { BILLS_TYPE } from '../../../utils/constants';
import uploadFileDetails from '../../../utils/uploadFile';
import { EQUITY_RESILIENCY } from '../../../containers/SgipApp/constants';

const styles = theme => ({
  title: {
    marginBottom: theme.spacing(1.25)
  },
  hideSection: {
    display: 'none'
  },
  divider: {
    marginTop: '25px',
    marginBottom: '20px'
  },
  privacyPolicyIos: {
    color: '#01B4DE',
    fontWeight: 'bold',
    textDecoration: 'none'
  },
  privacyPolicyOther: {
    color: '#01b4de',
    fontWeight: 'bold',
    marginLeft: '5px',
    cursor: 'pointer'
  },
  text: {
    color: '#939598'
  },
  fileCard: {
    backgroundColor: '#5a5a5c',
    paddingTop: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  cardFileNameText: {
    color: '#ffffff',
    flexDirection: 'row',
    textAlign: 'left',
    marginTop: '0px',
    display: 'flex'
  },
  cardFileSizeText: {
    color: '#ffffff'
  },
  cardFileAttachmentIcon: {
    marginRight: theme.spacing(0.5),
    width: 25,
    height: 30,
    color: '#ffffff',
    flexDirection: 'row',
    marginLeft: '5px'
  },
  cardFileCloseIcon: {
    marginRight: theme.spacing(1),
    height: 20,
    color: '#ffffff',
    float: 'right',
    flexDirection: 'row',
    width: '20px',
    marginLeft: 'auto'
  },
  chooseFileButton: {
    padding: theme.spacing(0, 0.5, 0, 0.5),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1)
  },
  chooseFile: {
    border: '1px solid #bbb',
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.secondary.main,
    marginTop: theme.spacing(1),
    display: 'flex'
  },
  uploadButton: {
    textTransform: 'capitalize',
    borderRadius: theme.spacing(2.5),
    minWidth: theme.spacing(17.5),
    padding: '3px 16px',
    color: '#01b4de',
    marginTop: theme.spacing(1.5)
  },
  errorText: {
    color: '#f44336',
    marginLeft: theme.spacing(0),
    marginTop: '3px'
  },
  pumpWellItems: {
    listStylePosition: 'outside',
    padding: '0px 10px 0px 15px'
  },
  [theme.breakpoints.up('md')]: {
    dBlock: {
      display: 'block'
    },
    doucmentItem: {
      border: '1px solid rgba(136, 136, 136, 0.2)',
      flexBasis: '40%',
      margin: '10px',
      borderRadius: '2px',
      padding: '20px'
    },
    dHide: {
      display: 'none'
    }
  }
});

const PumpWellFileUpload = props => {
  const {
    classes,
    pumpwell_doc,
    isPumpWellAnswer,
    deleteUploadedFile,
    pumpWellDocumentErrorFileNameMessage,
    pumpWellDocumentError,
    putSgipDocumentToS3,
    applicationId,
    getSgipDocumentPresignedURL,
    setState,
    wellPumpAllFilesSize,
    isIOS,
    sgipBudgetElligibility
  } = props;

  const setDocumentError = (documentError, obj, cb) => {
    documentError.file_name.error = obj.error;
    documentError.file_name.msg = obj.msg;
    cb(documentError);
  };

  const handlePumpWellDocumentFileUpload = event => {
    let documentError = pumpWellDocumentError;
    setDocumentError(documentError, { error: false, msg: '' }, errorObj => {
      setState({ pumpWellDocumentError: errorObj });
    });

    let pumpwell_doc = props.pumpwell_doc;
    if (!document.getElementById('pumpWellDocument')) {
      return;
    }

    const setStatus = status => {
      setState({ pumpWellDocUploadStatus: status });
    };

    const setFilesSize = wellPumpTotalFizeSize => {
      setState({ wellPumpAllFilesSize: wellPumpTotalFizeSize });
    };

    const details = {
      event,
      setStatus,
      setFilesSize,
      documentType: BILLS_TYPE.PUMP_WELL,
      isIOS,
      billsFileSize: wellPumpAllFilesSize,
      app_id: applicationId,
      bills: pumpwell_doc,
      fileUploadSize: 20,
      getSgipDocumentPresignedURL,
      putSgipDocumentToS3,
      setDocumentError: (errorDetails, status) => {
        let documentError = pumpWellDocumentError;
        setDocumentError(documentError, errorDetails, errorObj => {
          setState({ pumpWellDocumentError: errorObj });
          status && setStatus(status);
        });
      }
    };

    uploadFileDetails(details, bills => {
      setState({ pumpwell_doc: bills });
      if (document.getElementById('pumpWellDocument')) {
        document.getElementById('pumpWellDocument').value = '';
        return;
      }
    });
  };
  return (
    <React.Fragment>
      {isPumpWellAnswer && sgipBudgetElligibility === EQUITY_RESILIENCY ? (
        <React.Fragment>
          <div className={classes.doucmentItem}>
            <Fragment>
              <Title className={classes.title}>Electric Well Pump Photos</Title>

              <Typography component="div" variant="caption" className={classes.text}>
                Upload the following photos:
                <ul className={classes.pumpWellItems}>
                  <li>Above ground well components</li>
                  <li>Pump</li>
                  <li>Motor and/or control box</li>
                  <li>Circuit breaker that is connected to the well pump</li>
                  <li>Any tanks on-site</li>
                  <li>Electric meter</li>
                </ul>
              </Typography>
              {pumpwell_doc.map((iDoc, k) => (
                <FileAttachementBar
                  classes={classes}
                  key={iDoc.display_file_name}
                  id={iDoc.display_file_name}
                  fileType="pumpwell"
                  display_file_name={iDoc.display_file_name}
                  fileName={iDoc.file_name}
                  fileSize={iDoc.file_size}
                  onClose={deleteUploadedFile}
                />
              ))}
              {pumpWellDocumentErrorFileNameMessage !== '' ? (
                <div>
                  <Typography className={classes.errorText}>{pumpWellDocumentErrorFileNameMessage}</Typography>
                </div>
              ) : (
                <div />
              )}

              <Button
                className={clsx(classes.chooseFileButton, classes.button, classes.uploadButton)}
                variant="outlined"
                color="secondary"
                disableElevation
                disabled={pumpwell_doc.length >= 20}
                component="label"
              >
                Choose file
                <input
                  type="file"
                  className={classes.hideSection}
                  id="pumpWellDocument"
                  name="pumpWellDocument"
                  onChange={e => handlePumpWellDocumentFileUpload(e)}
                  multiple
                />
              </Button>

              <Divider className={clsx(classes.dHide, classes.divider)} />
            </Fragment>
          </div>
        </React.Fragment>
      ) : (
        ''
      )}
    </React.Fragment>
  );
};

export default withStyles(styles)(PumpWellFileUpload);
