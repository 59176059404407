import {
  GET_SGIP_INSTALLERS_REQUEST,
  GET_SGIP_PRIMARY_INSTALLERS_REQUEST,
  GET_SGIP_INSTALLERS_REQUEST_SUCCESS,
  GET_SGIP_PRIMARY_INSTALLERS_SUCCESS,
  GET_APPLICATION_NUMBER_REQUEST,
  GET_APPLICATION_NUMBER_REQUEST_SUCCESS,
  GET_SGIP_REQUEST_FAILURE,
  POST_REQUEST_FAILURE,
  GET_SGIP_DOC_S3_PRE_SIGNED_URL_SUCCESS,
  GET_SGIP_DOC_S3_PRE_SIGNED_URL_REQUEST,
  PUT_SGIP_DOC_UPLOAD_TO_S3_SUCCESS,
  GET_SGIP_DOC_FILE_FROM_S3_SUCCESS,
  POST_SGIP_PRIMARY_DETAILS_SUCCESS,
  POST_SGIP_NOTIFY_ME_DETAILS_SUCCESS,
  POST_SGIP_PERSONAL_DETAILS_SUCCESS,
  POST_SGIP_QUESTIONNAIRE_SUCCESS,
  POST_SGIP_APPLICATION_DOCUMENTS_SUCCESS,
  POST_SGIP_APPLICATION_TRACKER_UPLOAD_DOCUMENTS_SUCCESS,
  POST_SGIP_APPLICATION_TRACKER_UPLOAD_DOCUMENTS_REQUEST,
  DELETE_SGIP_DOC_FILE_FROM_S3_SUCCESS,
  GET_SGIP_APPLICATION_DETAILS_BY_EMAIL_REQUEST,
  GET_SGIP_APPLICATION_DETAILS_BY_EMAIL_SUCCESS,
  GET_SGIP_APPLICATION_DETAILS_BY_SITEID_REQUEST,
  GET_SGIP_APPLICATION_DETAILS_BY_SITEID_SUCCESS,
  POST_SGIP_PAYMENT_UPDATE_SUCCESS,
  POST_SGIP_PAYMENT_INTENT_REQUEST,
  POST_SGIP_PAYMENT_INTENT_SUCCESS,
  GET_SGIP_STRIPE_PUBLISHABLE_KEY_SUCCESS,
  GET_UTILITY_BILLS_PRE_SIGNED_URL_SUCCESS,
  GET_DOCUMENTS_TYPE_PRE_SIGNED_URL_SUCCESS,
  GET_UPLOADED_DOCUMENTS_PRE_SIGNED_URL_SUCCESS,
  POST_APPLICANT_NOTIFICATION_SUCCESS,
  POST_SIGNED_DOCUMENTS_SUCCESS,
  POST_FIELD_CHANGED_ACCEPT_NOTIFICATION_SUCCESS,
  POST_FIELD_CHANGED_REJECT_NOTIFICATION_SUCCESS,
  POST_PROMOCODE_REQUEST,
  POST_PROMOCODE_SUCCESS,
  POST_SGIP_PROMOCODE_PAYMENT_REQUEST,
  POST_SGIP_PROMOCODE_PAYMENT_SUCCESS,
  GET_REQUEST_FAILURE,
  DELETE_SGIP_DOC_FILE_FROM_S3_REQUEST,
  POST_DEVELOPER_KEY_UPDATE_MAIL_REQUEST,
  POST_DEVELOPER_KEY_UPDATE_MAIL_SUCCESS,
  POST_SGIP_PRIMARY_DETAILS_REQUEST,
  POST_SGIP_PAYMENT_UPDATE_REQUEST,
  POST_SGIP_ATTESTATION_FORM_REQUEST,
  GET_SGIP_USER_SELECTION_REQUEST,
  GET_SGIP_USER_SELECTION_SUCCESS,
  POST_SGIP_ATTESTATION_FORM_SUCCESS,
  GET_INCENTIVE_RATES_SGIP_REQUEST,
  GET_INCENTIVE_RATES_SGIP_SUCCESS,
  GET_INCENTIVE_RATES_SGIP_FAILURE,
  GET_SGIP_CONFIGS_REQUEST,
  GET_SGIP_CONFIGS_SUCCESS,
  GET_SGIP_CONFIGS_FAILURE,
  CREATE_SGIP_APPLICATION_REQUEST,
  CREATE_SGIP_APPLICATION_SUCCESS,
  CREATE_SGIP_APPLICATION_FAILURE,
  GET_SGIP_APPLICATION_DETAILS_BY_SITEID_FAILURE
} from './constants';

let initialState = {
  error: '',
  currentlySending: false,
  sgip_application: {},
  sgip_installers: [],
  sgip_primary_installers: [],
  message: '',
  client_secret: '',
  stripe_publishable_key: '',
  utility_bills_urls: [],
  uploaded_documents_urls: [],
  green_data_documents: [],
  tax_returns_documents: [],
  contract_documents_urls: [],
  purchase_invoices_documents_urls: [],
  documents_urls: [],
  message_list: [],
  unreadMessageCount: 0,
  user_selection_data: {},
  incentiveRates: [],
  utilityBlockInfo: [],
  isUnauthorizedUser: false
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_SGIP_INSTALLERS_REQUEST:
      return {
        ...state,
        currentlySending: true
      };
    case GET_SGIP_PRIMARY_INSTALLERS_REQUEST:
      return {
        ...state
      };
    case GET_SGIP_INSTALLERS_REQUEST_SUCCESS:
      //var contentKeys = Object.keys(action.sgip_installers);
      return {
        ...state,
        currentlySending: false,
        sgip_installers: action.sgip_installers
      };
    case GET_SGIP_PRIMARY_INSTALLERS_SUCCESS:
      return {
        ...state,
        sgip_primary_installers: action.sgip_primary_installers
      };
    case GET_APPLICATION_NUMBER_REQUEST:
      return {
        ...state,
        currentlySending: true
      };
    case GET_APPLICATION_NUMBER_REQUEST_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        application_number: action.application_number
      };
    case GET_SGIP_DOC_S3_PRE_SIGNED_URL_REQUEST:
      return {
        ...state,
        currentlySending: true
      };
    case GET_SGIP_DOC_S3_PRE_SIGNED_URL_SUCCESS:
      return {
        ...state,
        preSignedS3Url: action.preSignedS3Url,
        currentlySending: false
      };
    case PUT_SGIP_DOC_UPLOAD_TO_S3_SUCCESS:
      return {
        ...state,
        messageType: 'success',
        message: action.message,
        currentlySending: false
      };
    case GET_SGIP_DOC_FILE_FROM_S3_SUCCESS:
      return {
        ...state,
        preSignedS3Url: action.preSignedS3Url
      };
    case POST_SGIP_PRIMARY_DETAILS_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        messageType: 'success',
        message: action.message
      };
    case POST_SGIP_NOTIFY_ME_DETAILS_SUCCESS:
      return {
        ...state,
        messageType: 'success',
        message: action.message
      };
    case POST_SGIP_PERSONAL_DETAILS_SUCCESS:
      return {
        ...state,
        messageType: 'success',
        message: action.message
      };
    case POST_SGIP_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        messageType: 'success',
        message: action.message,
        sgip_application: action.sgip_application
      };
    case POST_SGIP_APPLICATION_DOCUMENTS_SUCCESS:
      return {
        ...state,
        messageType: 'success',
        message: action.message
      };
    case POST_SGIP_APPLICATION_TRACKER_UPLOAD_DOCUMENTS_REQUEST:
      return {
        ...state,
        currentlySending: true
      };
    case POST_SGIP_APPLICATION_TRACKER_UPLOAD_DOCUMENTS_SUCCESS:
      return {
        ...state,
        messageType: 'success',
        message: action.message,
        currentlySending: false
      };
    case DELETE_SGIP_DOC_FILE_FROM_S3_REQUEST:
      return {
        ...state,
        currentlySending: true
      };
    case DELETE_SGIP_DOC_FILE_FROM_S3_SUCCESS:
      return {
        ...state,
        messageType: 'success',
        message: action.message,
        currentlySending: false
      };
    case GET_SGIP_APPLICATION_DETAILS_BY_EMAIL_REQUEST:
      return {
        ...state,
        currentlySending: true
      };
    case GET_SGIP_APPLICATION_DETAILS_BY_EMAIL_SUCCESS:
      return {
        ...state,
        messageType: 'success',
        message: action.message,
        sgip_application: action.sgip_application,
        message_list: action.message_list,
        currentlySending: false
      };
    case GET_SGIP_APPLICATION_DETAILS_BY_SITEID_REQUEST:
      return {
        ...state,
        currentlySending: true
      };
    case GET_SGIP_APPLICATION_DETAILS_BY_SITEID_SUCCESS:
      return {
        ...state,
        messageType: 'success',
        message: action.message,
        sgip_application: action.sgip_application,
        message_list: action.message_list,
        currentlySending: false,
        unreadMessageCount: action.unreadMessageCount
      };
    case GET_SGIP_APPLICATION_DETAILS_BY_SITEID_FAILURE:
      return {
        ...state,
        currentlySending: false,
        isUnauthorizedUser: action.isUnauthorizedUser
      };
    case POST_SGIP_PAYMENT_UPDATE_SUCCESS:
      return {
        ...state,
        messageType: 'success',
        message: action.message,
        currentlySending: false
      };
    case POST_SGIP_PAYMENT_INTENT_REQUEST:
      return {
        ...state,
        currentlySending: true
      };
    case POST_SGIP_PAYMENT_INTENT_SUCCESS:
      return {
        ...state,
        messageType: 'success',
        message: action.message,
        client_secret: action.client_secret
      };
    case GET_SGIP_STRIPE_PUBLISHABLE_KEY_SUCCESS:
      return {
        ...state,
        messageType: 'success',
        message: action.message,
        stripe_publishable_key: action.stripe_publishable_key,
        currentlySending: false
      };
    case GET_UTILITY_BILLS_PRE_SIGNED_URL_SUCCESS:
      return {
        ...state,
        utility_bills_urls: action.utility_bills_urls,
        sgip_application: action.sgip_application,
        currentlySending: false
      };
    case GET_DOCUMENTS_TYPE_PRE_SIGNED_URL_SUCCESS:
      if (action.documentType === 'green_data_documents') {
        return {
          ...state,
          green_data_documents: action.uploaded_documents_urls,
          sgip_application: action.sgip_application,
          currentlySending: false
        };
      }
      if (action.documentType === 'tax_return_documents') {
        return {
          ...state,
          tax_returns_documents: action.uploaded_documents_urls,
          sgip_application: action.sgip_application,
          currentlySending: false
        };
      }
      if (action.documentType === 'contract_documents') {
        return {
          ...state,
          contract_documents_urls: action.uploaded_documents_urls,
          sgip_application: action.sgip_application,
          currentlySending: false
        };
      }
      if (action.documentType === 'purchase_invoices') {
        return {
          ...state,
          purchase_invoices_documents_urls: action.uploaded_documents_urls,
          sgip_application: action.sgip_application,
          currentlySending: false
        };
      }
      return {
        ...state,
        uploaded_documents_urls: action.uploaded_documents_urls,
        sgip_application: action.sgip_application,
        currentlySending: false
      };
    case GET_UPLOADED_DOCUMENTS_PRE_SIGNED_URL_SUCCESS:
      return {
        ...state,
        documents_urls: action.documents_urls,
        currentlySending: false
      };
    case POST_APPLICANT_NOTIFICATION_SUCCESS:
      return {
        ...state,
        messageType: 'success',
        message: action.message
      };
    case POST_SIGNED_DOCUMENTS_SUCCESS:
      return {
        ...state,
        messageType: 'success',
        message: action.message
      };
    case GET_SGIP_REQUEST_FAILURE:
      return {
        ...state,
        currentlySending: false
      };
    case POST_REQUEST_FAILURE:
      return {
        ...state,
        currentlySending: false
      };
    case POST_FIELD_CHANGED_ACCEPT_NOTIFICATION_SUCCESS:
      return {
        ...state,
        messageType: 'success',
        message: action.message,
        currentlySending: false
      };
    case POST_FIELD_CHANGED_REJECT_NOTIFICATION_SUCCESS:
      return {
        ...state,
        messageType: 'success',
        message: action.message,
        currentlySending: false
      };
    case POST_PROMOCODE_REQUEST:
      return {
        ...state,
        currentlySending: true
      };
    case POST_PROMOCODE_SUCCESS:
      return {
        ...state,
        messageType: 'success',
        message: action.message,
        currentlySending: false
      };
    case POST_SGIP_PROMOCODE_PAYMENT_REQUEST:
      return {
        ...state,
        currentlySending: true
      };
    case POST_SGIP_PROMOCODE_PAYMENT_SUCCESS:
      return {
        ...state,
        messageType: 'success',
        message: action.message,
        currentlySending: false
      };
    case GET_REQUEST_FAILURE:
      return {
        ...state,
        currentlySending: false
      };
    case POST_DEVELOPER_KEY_UPDATE_MAIL_REQUEST:
      return { ...state, currentlySending: true };
    case POST_DEVELOPER_KEY_UPDATE_MAIL_SUCCESS:
      return { ...state, message: action.message, currentlySending: false };
    case POST_SGIP_PRIMARY_DETAILS_REQUEST:
      return { ...state, currentlySending: true };
    case POST_SGIP_PAYMENT_UPDATE_REQUEST:
      return { ...state, currentlySending: true };
    case POST_SGIP_ATTESTATION_FORM_REQUEST:
      return { ...state, currentlySending: true };
    case POST_SGIP_ATTESTATION_FORM_SUCCESS:
      return { ...state, currentlySending: false };
    case GET_SGIP_USER_SELECTION_REQUEST:
      return { ...state, currentlySending: true };
    case GET_SGIP_USER_SELECTION_SUCCESS:
      return { ...state, user_selection_data: action.user_selection_data, currentlySending: false };
    case GET_INCENTIVE_RATES_SGIP_REQUEST:
      return { ...state, currentlySending: true };
    case GET_INCENTIVE_RATES_SGIP_SUCCESS:
      return { ...state, currentlySending: false, incentiveRates: action.incentiveRates };
    case GET_INCENTIVE_RATES_SGIP_FAILURE:
      return { ...state, currentlySending: false, message: action.message };
    case GET_SGIP_CONFIGS_REQUEST:
      return { ...state, currentlySending: true };
    case GET_SGIP_CONFIGS_SUCCESS:
      return { ...state, currentlySending: false, utilityBlockInfo: action.utilityBlockInfo };
    case GET_SGIP_CONFIGS_FAILURE:
      return { ...state, currentlySending: false, message: action.message };
    case CREATE_SGIP_APPLICATION_REQUEST:
      return { ...state, currentlySending: true };
    case CREATE_SGIP_APPLICATION_SUCCESS:
      return { ...state, currentlySending: false };
    case CREATE_SGIP_APPLICATION_FAILURE:
      return { ...state, currentlySending: false, message: action.message };
    default:
      return state;
  }
}

export default reducer;
