import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Avatar, Toolbar, List, ListItem, Divider, Tooltip, IconButton } from '@material-ui/core';
import HeaderImage from './Icons/enphase-sgip.png';
import settings from '../../utils/settings';
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { setToken } from '../../utils/helper';
import { ENSTORE_URL, ENLIGHTEN_SUPPORT_URL } from '../../config/constants';
import Logout from './Icons/Logout';
import StoreIcon from './Icons/StoreIcon';
import HelpIcon from './Icons/HelpIcon';

const styles = theme => ({
  root: {
    display: 'flex',
    background: theme.palette.primary.main,
    flexDirection: 'row',
    boxShadow: 'none'
  },
  large: {
    width: theme.spacing(15),
    height: 'auto'
  },
  navigation: {
    fontSize: 16,
    margin: theme.spacing(1),
    color: theme.palette.tertiary.main,
    fontWeight: '400',
    textTransform: 'none'
  },
  toolbar: {
    padding: 0,
    minHeight: 0
  },
  headerToolbar: {
    margin: theme.spacing(0, 4)
  },
  active: {
    fontWeight: '700'
  },
  mobileToolbar: {
    padding: 0,
    minHeight: 0,
    flexDirection: 'row-reverse',
    flexGrow: 1
  },
  menuIcon: {
    cursor: 'pointer',
    color: theme.palette.tertiary.main,
    marginRight: theme.spacing(4.5)
  },
  menuStore: {
    width: theme.spacing(4),
    height: theme.spacing(3)
  },
  fullList: {
    width: theme.spacing(25)
  },
  menuList: {
    justifyContent: 'center'
  },
  rightSection: {
    marginLeft: 'auto',
    padding: 0,
    minHeight: 0
  },
  mobileMenuIcon: {
    color: theme.palette.tertiary.dark
  },
  iconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
});

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  toggleDrawer() {
    this.setState({ open: !this.state.open });
  }

  redirect(menu, isInternal = false) {
    let url;
    if (isInternal) url = settings.enlightenUrl(menu);
    else {
      if (menu === 'enstore') url = ENSTORE_URL;
      else if (menu === 'help') url = ENLIGHTEN_SUPPORT_URL;
    }
    if (menu === 'logout') {
      setToken('');
      url = settings.enlightenUrl(menu);
    }
    if (url) window.location.replace(url);
  }

  renderSideList() {
    const { classes } = this.props;
    return (
      <div className={classes.fullList} role="presentation">
        <List>
          {/* {keysIn(enlightenLinks()).map((menu, index) => {
            if (!this.props.isAdmin && menu === 'admin') {
              return <React.Fragment />;
            } else {
              return (
                <Fragment key={index}>
                  <ListItem button key={index} onClick={() => this.redirect(this.getEnlightenUrl(menu, 'url'), true)}>
                    <ListItemText primary={this.getEnlightenUrl(menu, 'name')} />
                  </ListItem>
                  <Divider />
                </Fragment>
              );
            }
          })} */}
          <ListItem button className={classes.menuList}>
            <Tooltip title="Enphase Store">
              <IconButton className={classes.iconButton} onClick={() => this.redirect('enstore')}>
                <StoreIcon
                  className={clsx(classes.menuIcon, classes.mobileMenuIcon, classes.menuStore)}
                  stroke="#7E7E7E"
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Enphase Support">
              <IconButton className={classes.iconButton} onClick={() => this.redirect('help')}>
                <HelpIcon className={clsx(classes.menuIcon, classes.mobileMenuIcon)} stroke="#7E7E7E" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Logout">
              <IconButton className={classes.iconButton} onClick={() => this.redirect('logout', true)}>
                <Logout className={clsx(classes.menuIcon, classes.mobileMenuIcon)} stroke="#7E7E7E" />
              </IconButton>
            </Tooltip>
          </ListItem>
          <Divider />
        </List>
      </div>
    );
  }

  render() {
    const { classes, isMobileView } = this.props;
    return (
      <Fragment>
        <AppBar position="static" color="secondary" className={classes.root}>
          <Toolbar className={clsx(classes.headerToolbar, classes.toolbar)}>
            <Avatar variant="square" alt="enphase logo" src={HeaderImage} className={classes.large} />
          </Toolbar>
          {isMobileView ? (
            <Toolbar className={classes.mobileToolbar}>
              <MenuIcon className={classes.menuIcon} onClick={() => this.toggleDrawer()} />
            </Toolbar>
          ) : (
            <Fragment>
              <Toolbar className={classes.rightSection}>
                <Tooltip title="Enphase Store">
                  <IconButton className={classes.iconButton} onClick={() => this.redirect('enstore')}>
                    <StoreIcon className={clsx(classes.menuIcon, classes.menuStore)} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Enphase Support">
                  <IconButton className={classes.iconButton} onClick={() => this.redirect('help')}>
                    <HelpIcon className={classes.menuIcon} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Logout">
                  <IconButton className={classes.iconButton} onClick={() => this.redirect('logout', true)}>
                    <Logout className={classes.menuIcon} stroke="white" />
                  </IconButton>
                </Tooltip>
              </Toolbar>
            </Fragment>
          )}
        </AppBar>
        {isMobileView && (
          <SwipeableDrawer
            anchor="right"
            open={this.state.open}
            onClose={() => this.toggleDrawer()}
            onOpen={() => this.toggleDrawer()}
          >
            {this.renderSideList()}
          </SwipeableDrawer>
        )}
      </Fragment>
    );
  }
}

export default withStyles(styles)(Header);
