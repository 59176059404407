import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import commonThemeObj from './commonThemeObj';

let theme = createMuiTheme({
  ...commonThemeObj,
  overrides: {
    MuiInputBase: {
      input: {
        fontSize: '1rem'
      }
    },
    MuiTableCell: {
      root: {
        fontSize: '1rem'
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: '1rem'
      }
    },
    MuiStepIcon: {
      text: {
        fontSize: '0.75rem',
        fontWeight: 'bold',
        fill: '#FFFFFF'
      }
    },
    MuiAutocomplete: {
      option: {
        fontSize: '1rem'
      }
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'Visuelt',

    subtitle1: {
      // ~title-1 ENHO Design theme
      fontSize: '1rem',
      fontWeight: 400
    },
    subtitle2: {
      // ~title-2 ENHO Design theme
      fontSize: '1rem',
      fontWeight: 500
    },
    body1: {
      // ~body-1 ENHO Design theme
      fontSize: '0.75rem',
      fontWeight: 400
    },
    body2: {
      // ~body-2 ENHO Design theme
      fontSize: '0.75rem',
      fontWeight: 500
    },
    button: {
      // ~button - 12 px, bold
      fontSize: '1rem',
      fontWeight: 500
    }
  }
});

theme = responsiveFontSizes(theme);

export default theme;
