import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import SgipServiceCharge from '../../../components/SgipApp/SgipServiceCharge';
import StatusTracking from '../../../components/SgipApp/StatusTracking';
import { addInputEventListener } from '../../../utils/helper';
import ServiceFeeDialog from '../../../components/SgipApp/serviceFeeCalculation';
import Loader from '../../../components/Loader';

const styles = theme => ({
  root: {
    padding: theme.spacing(2.5),
    height: '100vh'
  },
  dShow: {
    display: 'none'
  },
  [theme.breakpoints.up('md')]: {
    root: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly',
      height: 'auto'
    },
    dWrapper: {
      margin: '30px 194px',
      boxShadow: '0 2px 10px 0 rgba(63, 67, 71, 0.08)',
      background: '#fff',
      borderRadius: '3px'
    },
    dHide: {
      display: 'none'
    },
    dShow: {
      display: 'flex'
    }
  }
});

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeState: props.isInstaller ? 4 : 3,
      serviceFeeFlag: false
    };
    this.serviceFeePopup = this.serviceFeePopup.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    addInputEventListener();
  }
  serviceFeePopup() {
    this.setState({ serviceFeeFlag: !this.state.serviceFeeFlag });
  }
  render() {
    const { classes, currentlySending, isInstaller } = this.props;
    const { serviceFeeFlag } = this.state;
    return (
      <Fragment>
        <div className={classes.dHide}>{currentlySending && <Loader loading={currentlySending}> </Loader>}</div>
        <div className={classes.dShow}>{currentlySending && <Loader loading={currentlySending}> </Loader>}</div>
        <div className={classes.dWrapper}>
          <StatusTracking
            activeState={this.state.activeState}
            pageNumber={isInstaller ? '5' : '4'}
            isInstaller={isInstaller}
          />
          <div className={classes.root}>
            {serviceFeeFlag && <ServiceFeeDialog serviceFeePopup={this.serviceFeePopup.bind(this)} />}
            <SgipServiceCharge serviceFeePopup={this.serviceFeePopup} />
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = state => ({
  currentlySending: state.sgipAppReducer.currentlySending,
  isInstaller: state.appReducer.isInstaller
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(index));
