import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Link, Typography, Avatar } from '@material-ui/core';
import FooterImage from './Icons/footer_logo.png';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(6, 4.5),
    marginTop: 'auto'
  },
  privacyTerms: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12
  },
  link: {
    color: theme.palette.secondary.link,
    cursor: 'pointer',
    padding: theme.spacing(0, 1)
  },
  copyRights: {
    fontSize: 12
  },
  footerLogo: {
    height: theme.spacing(3.5),
    width: theme.spacing(15.5),
    minHeight: theme.spacing(3.5),
    marginRight: theme.spacing(2.5)
  }
}));

const Footer = props => {
  const classes = useStyles();
  const pathName = window.location.pathname;
  const splitPathName = pathName.split('/');
  const pathArray = [];
  splitPathName.forEach(path => path && pathArray.push(path));
  return (
    <footer className={classes.root}>
      <Box>
        <Link title="Visit the Enphase Energy website" href="http://enphase.com/" target="_blank">
          <Avatar variant="square" className={classes.footerLogo} alt="enphase footer logo" src={FooterImage} />
        </Link>
      </Box>
      <Box className={classes.privacyTerms}>
        <Typography className={classes.copyRights}>©2008-2020 Enphase Energy Inc. All rights reserved.</Typography>
        <Link className={classes.link} href="http://enphase.com/privacy" target="_blank">
          Privacy
        </Link>
        <Typography>|</Typography>
        <Link className={classes.link} href="http://enphase.com/terms" target="_blank">
          Terms
        </Link>
      </Box>
    </footer>
  );
};

export default Footer;
