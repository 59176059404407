import { makeStyles } from '@material-ui/core/styles';

const useMobileStyles = makeStyles(theme => ({
  root: {
    borderRadius: '10px',
    boxShadow: '1px 2px 5px 0 rgba(152, 152, 152, 0.1)',
    backgroundColor: '#ffffff',
    padding: theme.spacing(0)
  },
  top: {
    margin: theme.spacing(0)
  },
  nextbtn: {
    paddingTop: '16px',
    textAlign: 'center'
  },
  label: {
    color: '#545456',
    marginTop: '5px'
  },
  value: {
    color: '#39cc67',
    marginTop: '15px'
  },
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6)
  },
  tickImg: {
    textAlign: 'center'
  }
}));

const useDesktopStyles = makeStyles(theme => ({
  root: {}
}));

export { useMobileStyles, useDesktopStyles };
