import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import commonThemeObj from './commonThemeObj';

let theme = createMuiTheme({
  ...commonThemeObj,

  overrides: {
    MuiTableCell: {
      root: {
        fontWeight: 400
      }
    },
    MuiStepIcon: {
      text: {
        fontSize: '13px',
        fontWeight: 'bold',
        fill: '#FFFFFF'
      },
      root: {}
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'Roboto',

    subtitle1: {
      // 18 px, regular
      fontSize: '1.125rem',
      fontWeight: 400
    },
    subtitle2: {
      // 18 px, bold
      fontSize: '1.125rem',
      fontWeight: 700
    },
    body1: {
      // 14 px, regular
      fontSize: '0.875rem',
      fontWeight: 400
    },
    body2: {
      // 14 px, bold
      fontSize: '0.875rem',
      fontWeight: 700
    },
    button: {
      // 14 px, bold
      fontSize: '0.875rem',
      fontWeight: 700
    }
  }
});

theme = responsiveFontSizes(theme);

export default theme;
