import { makeStyles } from '@material-ui/core/styles';

const useMobileStyles = makeStyles(theme => ({
  root: {
    borderRadius: theme.spacing(1.25),
    boxShadow: '1px 2px 5px 0 rgba(152, 152, 152, 0.1)',
    backgroundColor: theme.palette.tertiary.main,
    padding: theme.spacing(2.5)
  },
  rootnopadding: {
    borderRadius: theme.spacing(1.25),
    boxShadow: '1px 2px 5px 0 rgba(152, 152, 152, 0.1)',
    backgroundColor: theme.palette.tertiary.main
  },
  disableBackground: {
    borderRadius: theme.spacing(1.25),
    boxShadow: '1px 2px 5px 0 rgba(152, 152, 152, 0.1)',
    backgroundColor: theme.palette.primary.disableBg,
    padding: theme.spacing(2.5)
  },
  [theme.breakpoints.up('md')]: {
    root: {
      border: '1px solid rgba(136, 136, 136, 0.2)',
      borderRadius: '3px'
    }
  }
}));

const useDesktopStyles = makeStyles(theme => ({
  root: {}
}));

export { useMobileStyles, useDesktopStyles };
