import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles({
  table: {
    maxHeight: 300,
    marginBottom: '20px',
    borderRadius: 0
  },
  heading: {
    color: '#335977;',
    fontSize: '20px',
    marginBottom: '10px',
    marginTop: '10px'
  },
  spacing: {
    paddingLeft: '24px',
    paddingRight: '24px'
  },
  tableHeader: {
    color: '#FFE785;',
    border: 'none;',
    backgroundColor: '#335977;',
    fontSize: '13px',
    padding: '16px 0px',
    paddingLeft: '16px'
  }
});

export default function BasicTable(props) {
  const classes = useStyles();
  const { tableData, tableHeadings } = props;
  return (
    <Grid className={classes.spacing} container>
      <Typography className={classes.heading}>Current Status</Typography>
      <TableContainer className={classes.table} component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {tableHeadings.map((heading, index) => {
                return (
                  <TableCell key={index} className={classes.tableHeader}>
                    {heading}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, index) => (
              <TableRow key={index}>
                {row.map((content, index) => {
                  return <TableCell key={index}>{content}</TableCell>;
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
