import { REQUEST_STATUSES, STATUS } from './constants';
import moment from 'moment';
import { isEmpty, get, isArray } from '../../utils/lodash';

export function getFullName(appDetails) {
  if (isEmpty(appDetails.first_name) && isEmpty(appDetails.last_name)) return 'NA';
  const firstName = get(appDetails, 'first_name') || '';
  const lastName = get(appDetails, 'last_name') || '';
  return `${firstName} ${lastName}`;
}

function prePopSubStageStatusDetails(subStages) {
  let sArray = [];
  for (let i = 0; i < subStages.length; i++) {
    const e = subStages[i];
    sArray.push({
      label: e.label,
      id: e.id,
      infoText: '',
      completedOn: '',
      status: '',
      actions: {
        updateApplicationId: false,
        updateStatus: false
      },
      status_levels: e.status_levels,
      isGreen: false
    })
  }
  return sArray;
}

function prePopStatusDetails() {
  let sArray = [];
  for (let i = 0; i < REQUEST_STATUSES.length; i++) {
    const e = REQUEST_STATUSES[i];
    sArray.push({
      label: e.label,
      id: e.id,
      infoText: '',
      completedOn: '',
      status: '',
      actions: {
        updateApplicationId: false,
        updateStatus: false
      },
      status_levels: e.status_levels,
      sub_stages: e.sub_stages ? prePopSubStageStatusDetails(e.sub_stages) : [],
      isGreen: false,
      isStageActive: false,
    });
  }
  return sArray;
}

export function getStatusDetails(appDetails) {
  let sArray = prePopStatusDetails();
  let sDetails = {
    sArray: [],
    currentStage: 0
  };
  let app_stages = appDetails.application_stages;
  for (let i = 0; i < sArray.length; i++) {
    const stage = sArray[i];
    for (let j = 0; j < app_stages.length; j++) {
      const cStage = app_stages[j];
      if (cStage.id === stage.id) {
        stage.completedOn = moment(cStage.last_updated_date).format('MMM Do YYYY');
        stage.label = cStage.stage_name;
        stage.status = cStage.status_name;
        stage.isStageActive = true
        const sStage = cStage.substages
        if (isArray(sStage)) {
          stage.sub_stages.forEach((sub, index) => {
            sStage.forEach((obj, indexValue) => {
              if(sub.id === obj.substage_id) {
                if (stage.label !== 'Site Inspection') {
                  stage.sub_stages[indexValue].completedOn = moment(sStage[indexValue].last_updated_date).format('MMM Do YYYY');
                  stage.sub_stages[indexValue].label = sStage[indexValue].substage_name || stage.sub_stages[indexValue].label;
                  stage.sub_stages[indexValue].status = sStage[indexValue].status_name;
                } else {
                  if (sStage[indexValue].substage_name === 'Completion' || sStage[indexValue].substage_name === STATUS.COMPLETED ) {
                    stage.sub_stages = stage.sub_stages.filter(list => list.label === 'Completion' || list.label === STATUS.COMPLETED)
                    stage.sub_stages[0].label = sStage[indexValue].substage_name === STATUS.COMPLETED ? sStage[indexValue].status_name : stage.sub_stages[indexValue].label;
                  } else if (sStage[indexValue].status_name === STATUS.NOT_REQUIRED) {
                    stage.sub_stages = stage.sub_stages.filter(list => list.label === STATUS.NOT_REQUIRED)
                    stage.sub_stages[0].label = sStage[indexValue].status_name || stage.sub_stages[indexValue].label;
                  }
                  stage.sub_stages[0].completedOn = moment(sStage[indexValue].last_updated_date).format('MMM Do YYYY');
                  stage.sub_stages[0].status = sStage[indexValue].status_name;
                }
              }
            })
          })
        } else {
          // stage.sub_stages.forEach((sub, index) => {
          //   stage.sub_stages[index].status = STATUS.INPROGRESS
          // })
        }
        //check if status is ok to go ahead
        if (sDetails.currentStage < stage.id) {
          sDetails.currentStage = stage.id;
        }
      }
    }
    switch (stage.id) {
      case 1:
        stage.actions.updateApplicationId = true;
        if (appDetails.application_number === '' || appDetails.application_number === null) {
          stage.infoText = 'Please update application id to proceed further';
        }
        break;
      default:
        break;
    }
  }
  //enable next stage to allow complete status
  if (sArray.length >= sDetails.currentStage) {
    if (appDetails.application_number === '' || appDetails.application_number === null) {
      sArray[sDetails.currentStage].actions.updateStatus = false;
    } else {
      //check for previous stage is in green zone to go further
      if (sDetails.currentStage) {
          let prevStage = sArray[sDetails.currentStage - 1];
          const curSubStageIndex = prevStage.sub_stages.findIndex(obj => obj.status !== STATUS.COMPLETED && obj.status !== STATUS.REQUIRED && obj.status !== STATUS.NOT_REQUIRED && obj.status !== STATUS.APPROVED )
          if (curSubStageIndex > -1) {
            sArray[sDetails.currentStage - 1].sub_stages[curSubStageIndex].actions.updateStatus = true
            sArray[sDetails.currentStage - 1].isStageActive = true
          } else if (sDetails.currentStage < sArray.length) {
            if (sArray[sDetails.currentStage].label !== 'Site Inspection') {
              sArray[sDetails.currentStage].sub_stages[0].actions.updateStatus = true
            } else {
              sArray[sDetails.currentStage].sub_stages = []
            }
            sArray[sDetails.currentStage].isStageActive = true
            sDetails.currentStage = sDetails.currentStage + 1
          } else {
            sDetails.currentStage = sDetails.currentStage + 1
          }
      }
    }
  }
  sDetails.sArray = sArray;
  return sDetails;
}

export function validateApplicationEditForm(errInfo, aDetails) {
  let isError = false;
  let letters = /^([a-zA-Z ]+)$/;
  if (!aDetails.first_name || aDetails.first_name === '') {
    errInfo.first_name.error = true;
    errInfo.first_name.errMsg = 'Required';
    isError = true;
  } else {
    if (aDetails.first_name.match(letters)) {
      errInfo.first_name.error = false;
      errInfo.first_name.errMsg = '';
    } else {
      errInfo.first_name.error = true;
      errInfo.first_name.errMsg = 'Only alphabets are allowed';
    }
  }
  if (!aDetails.last_name || aDetails.last_name === '') {
    errInfo.last_name.error = true;
    errInfo.last_name.errMsg = 'Required';
    isError = true;
  } else {
    if (aDetails.last_name.match(letters)) {
      errInfo.last_name.error = false;
      errInfo.last_name.errMsg = '';
    } else {
      errInfo.last_name.error = true;
      errInfo.last_name.errMsg = 'Only alphabets are allowed';
    }
  }
  if (!aDetails.city || aDetails.city === '') {
    errInfo.city.error = true;
    errInfo.city.errMsg = 'Required';
    isError = true;
  } else {
    errInfo.city.error = false;
    errInfo.city.errMsg = '';
  }
  if (!aDetails.email || aDetails.email === '') {
    errInfo.email.error = true;
    errInfo.email.errMsg = 'Required';
    isError = true;
  } else {
    errInfo.email.error = false;
    errInfo.email.errMsg = '';
  }
  if (!aDetails.phone || aDetails.phone === '' || aDetails.phone < 0) {
    errInfo.phone.error = true;
    errInfo.phone.errMsg = 'Required & Should be a valid number';
    isError = true;
  } else {
    errInfo.phone.error = false;
    errInfo.phone.errMsg = '';
  }
  if (!aDetails.installer_name || aDetails.installer_name === '') {
    errInfo.installer_name.error = true;
    errInfo.installer_name.errMsg = 'Required';
    isError = true;
  } else {
    errInfo.installer_name.error = false;
    errInfo.installer_name.errMsg = '';
  }
  if (!aDetails.installer_code || aDetails.installer_code === '') {
    errInfo.installer_code.error = true;
    errInfo.installer_code.errMsg = 'Required';
    isError = true;
  } else {
    errInfo.installer_code.error = false;
    errInfo.installer_code.errMsg = '';
  }
  if (!aDetails.battery_installation_site_address || aDetails.battery_installation_site_address === '') {
    errInfo.battery_installation_site_address.error = true;
    errInfo.battery_installation_site_address.errMsg = 'Required';
    isError = true;
  } else {
    errInfo.battery_installation_site_address.error = false;
    errInfo.battery_installation_site_address.errMsg = '';
  }
  if (!aDetails.communication_address || aDetails.communication_address === '') {
    errInfo.communication_address.error = true;
    errInfo.communication_address.errMsg = 'Required';
    isError = true;
  } else {
    errInfo.communication_address.error = false;
    errInfo.communication_address.errMsg = '';
  }
  if (!aDetails.utility_company_name || aDetails.utility_company_name === '') {
    errInfo.utility_company_name.error = true;
    errInfo.utility_company_name.errMsg = 'Required';
    isError = true;
  } else {
    errInfo.utility_company_name.error = false;
    errInfo.utility_company_name.errMsg = '';
  }
  // if (!aDetails.unit_of_encharge10 || aDetails.unit_of_encharge10 < 0 || aDetails.unit_of_encharge10 === '') {
  //   errInfo.unit_of_encharge10.error = true;
  //   errInfo.unit_of_encharge10.errMsg = 'Required & Should be greater than or equal to 0';
  //   isError = true;
  // } else {
  //   errInfo.unit_of_encharge10.error = false;
  //   errInfo.unit_of_encharge10.errMsg = '';
  // }
  // if (!aDetails.unit_of_encharge3 || aDetails.unit_of_encharge3 === '' || aDetails.unit_of_encharge3 < 0) {
  //   errInfo.unit_of_encharge3.error = true;
  //   errInfo.unit_of_encharge3.errMsg = 'Required & Should be greater than or equal to 0';
  //   isError = true;
  // } else {
  //   errInfo.unit_of_encharge3.error = false;
  //   errInfo.unit_of_encharge3.errMsg = '';
  // }
  if (!aDetails.reason_for_edit || aDetails.reason_for_edit === '') {
    errInfo.reason_for_edit.error = true;
    errInfo.reason_for_edit.errMsg = 'Required';
    isError = true;
  } else {
    errInfo.reason_for_edit.error = false;
    errInfo.reason_for_edit.errMsg = '';
  }
  // if (
  //   !aDetails.sgip_budget_information.sgip_budget_elligibility ||
  //   aDetails.sgip_budget_information.sgip_budget_elligibility === ''
  // ) {
  //   errInfo.sgip_budget_information.sgip_budget_elligibility.error = true;
  //   errInfo.sgip_budget_information.sgip_budget_elligibility.errMsg = 'Required';
  //   isError = true;
  // } else {
  //   errInfo.sgip_budget_information.sgip_budget_elligibility.error = false;
  //   errInfo.sgip_budget_information.sgip_budget_elligibility.errMsg = '';
  // }
  return { errInfo: errInfo, isError: isError };
}
