import { makeStyles } from '@material-ui/core/styles';
import Background from './image/background.jpg';

const sliderTrack = {
  height: '13px',
  borderRadius: '10px'
};
const useStyles = makeStyles(theme => ({
  root: {
    backgroundImage: `url('${Background}')`,
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed'
  },
  textArea: {
    width: '100%',
    height: '80px !important'
  },
  ratingsButton: {
    border: '1px solid #233643',
    minWidth: '34px',
    width: '34px',
    height: '34px',
    marginRight: '7px',
    display: 'flex'
  },
  ratingsWrapper: {
    display: 'flex',
    marginTop: '15px',
    flexDirection: 'column'
  },
  noBorder: {
    '&:hover': {
      border: 'none',
      color: '#FFFFFF'
    }
  },
  red: {
    borderColor: '#E43E3D',
    '&:hover': {
      backgroundColor: '#E43E3D'
    }
  },
  orange: {
    borderColor: '#EF874C',
    '&:hover': {
      backgroundColor: '#EF874C'
    }
  },
  yellow: {
    borderColor: '#F8C43C',
    '&:hover': {
      backgroundColor: '#F8C43C'
    }
  },
  green: {
    borderColor: '#5BAF2A',
    '&:hover': {
      backgroundColor: '#5BAF2A'
    }
  },
  submit: {
    color: '#FFFFFF',
    borderRadius: '0px',
    '&:hover': {
      backgroundColor: '#F37320'
    }
  },
  sumbitHo: {
    color: '#FFFFFF',
    backgroundColor: '#00b6de',
    borderRadius: '25px',
    width: '215px'
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  heading: {
    marginTop: '10px',
    width: '34px',
    textAlign: 'center'
  },
  cancel: {
    color: '#919191',
    marginLeft: '20px',
    '&:hover': {
      backgroundColor: '#FFFFFF'
    }
  },
  title: {
    color: '#335977'
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  redSelected: {
    backgroundColor: '#E43E3D',
    border: 'none',
    color: '#FFFFFF'
  },
  orangeSelected: {
    backgroundColor: '#EF874C',
    border: 'none',
    color: '#FFFFFF'
  },
  yellowSelected: {
    backgroundColor: '#F8C43C',
    border: 'none',
    color: '#FFFFFF'
  },
  greenSelected: {
    backgroundColor: '#5BAF2A',
    border: 'none',
    color: '#FFFFFF'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.dark
  },
  headerToolbar: {
    margin: theme.spacing(0, 4)
  },
  large: {
    width: theme.spacing(15),
    height: 'auto'
  },
  toolbar: {
    padding: 0,
    minHeight: 0
  },
  rangeTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#919191',
    paddingLeft: '4px'
  },
  installerTitleWidth: {
    width: '400px'
  },
  homeownerTitleWidth: {
    width: '100%'
  },
  slider: {
    display: 'flex',
    flexDirection: 'column',
    margin: '40px 0 30px'
  },
  ratingRange: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#919191'
  },
  installerRatingRange: {
    paddingLeft: '11px',
    width: '389px'
  },
  homeownerRatingRange: {
    paddingLeft: '16px',
    width: '266px'
  },
  homeownerPopRatingRange: {
    width: '100%'
  },
  scaleReadings: {
    height: '14px',
    width: '14px',
    textAlign: 'center'
  },
  rangeSlider: {
    width: '95%',
    margin: '7px 0 9px'
  },
  sliderRail: {
    background: '#ffff',
    height: '12px',
    borderRadius: '10px',
    border: '1px solid #D3D3D3'
  },
  sliderTrack0: {
    ...sliderTrack,
    background: 'linear-gradient(90deg,rgb(225,13,18) 50%,rgb(225,13,18) 100%)'
  },
  sliderTrack1: {
    ...sliderTrack,
    background: 'linear-gradient(90deg,rgb(225,13,18) 50%,rgb(225,13,18) 100%)'
  },
  sliderTrack2: {
    ...sliderTrack,
    background: 'linear-gradient(90deg,rgb(225,13,18) 50%,rgb(225,13,18) 100%)'
  },
  sliderTrack3: {
    ...sliderTrack,
    background: 'linear-gradient(90deg,rgb(225,13,18) 0%,rgb(225,13,18) 60%,rgb(262,118,61) 100%)'
  },
  sliderTrack4: {
    ...sliderTrack,
    background: 'linear-gradient(90deg,rgb(225,13,18) 0%,rgb(225,13,18) 39%,rgb(255,118,61) 71%,rgb(255,118,61) 100%)'
  },
  sliderTrack5: {
    ...sliderTrack,
    background:
      'linear-gradient(90deg,rgb(225,13,18) 0%,rgb(225,13,18) 39%,rgb(255,118,61) 71%,rgb(255,118,61) 83%,rgb(255,165,52) 100%)'
  },
  sliderTrack6: {
    ...sliderTrack,
    background:
      'linear-gradient(90deg,rgb(225,13,18) 0%,rgb(225,13,18) 25%,rgb(255,118,61) 48%,rgb(255,118,61) 63%,rgb(255,165,52) 78%,rgb(255,165,52) 100%)'
  },
  sliderTrack7: {
    ...sliderTrack,
    background:
      'linear-gradient(90deg,rgb(225,13,18) 0%,rgb(225,13,18) 22%,rgb(255,118,61) 42%,rgb(255,118,61) 69%,rgb(255,165,52) 79%,rgb(255,165,52) 82%, rgb(255,211,1) 100%)'
  },
  sliderTrack8: {
    ...sliderTrack,
    background:
      'linear-gradient(90deg,rgb(225,13,18) 0%,rgb(225,13,18) 29%,rgb(255,118,61) 44%,rgb(255,118,61) 11%,rgb(251,148,10) 70%,rgb(255,165,52) 76%,rgb(255,211,1) 87%,rgb(255,226,52) 100%)'
  },
  sliderTrack9: {
    ...sliderTrack,
    background:
      'linear-gradient(90deg,rgb(225,13,18) 0%,rgb(225,13,18) 19%,rgb(255,118,61) 39%,rgb(255,118,61) 49%,rgb(251,148,10) 56%,rgb(255,165,52) 66%,rgb(255,211,1) 78%,rgb(255,226,52) 89%,rgb(184,238,0) 100%)'
  },
  sliderTrack10: {
    ...sliderTrack,
    background:
      'linear-gradient(90deg, rgb(225, 13, 18) 0%, rgb(225, 13, 18) 16%, rgb(255, 118, 61) 30%, rgb(255, 118, 61) 39%, rgb(251, 148, 10) 49%, rgb(255, 165, 52) 60%, rgb(255, 211, 1) 69%, rgb(255, 226, 52) 79%, rgb(184, 238, 0) 88%, rgb(29, 169, 47) 100%)'
  },
  sliderThumb: {
    width: '26px',
    height: '26px',
    background: '#fff',
    border: '1px solid #D3D3D3'
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '7px 0 9px'
  },
  dialogContent: {
    overflowY: 'initial'
  },
  [theme.breakpoints.up('md')]: {
    dialogOverlay: {
      marginLeft: '140px',
      justifyContent: 'unset'
    },
    homeownerWrapper: {
      width: '297px'
    },
    installerWrapper: {
      width: '406px'
    },
    homeownerPopRatingRange: {
      width: '84%',
      paddingLeft: '10px'
    },
    rangeSlider: {
      width: '80%'
    },
    homeownerTitleWidth: {
      width: '80%'
    },
    scaleReadings: {
      height: '22px',
      width: '22px'
    },
    slider: {
      alignItems: 'center'
    }
  }
}));

export { useStyles };
