import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const Text = withStyles(theme => ({
  root: {
    color: theme.palette.primary.mainText
  }
}))(Typography);

const DefaultText = props => {
  const { children, ...remainingProps } = props;
  return (
    <Text component="div" {...remainingProps}>
      {children}
    </Text>
  );
};

export default DefaultText;
