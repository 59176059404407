import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Grid,
  Button,
  TableBody,
  TableRow,
  TableCell,
  Table,
  TableContainer
} from '@material-ui/core';
import { Title } from '../../Texts';
const styles = theme => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2.5)
    }
  },
  title: {
    padding: '20px'
  },
  heading: {
    color: '#545456'
  },
  label: {
    color: '#545456'
  },
  bottomText: {
    borderBottom: '0px',
    width: '50%',
    padding: '10px 0px',
    color: '#545456'
  },
  actions: {
    padding: '0px'
  },
  redText: {
    fontWeight: 'bold',
    color: '#e05750'
  },
  yellowText: {
    fontWeight: 'bold',
    color: '#dfac34'
  },
  orangeText: {
    fontWeight: 'bold',
    color: '#e5762f'
  },
  purpleText: {
    fontWeight: 'bold',
    color: '#6e68b9'
  },
  content: {
    minHeight: '50px',
    overflow: 'break-word',
    padding: '2px',
    color: '#545456',
    marginTop: '15px',
    fontWeight: 'normal',
    width: '100%',
    border: 'solid 1px #c6c8ca',
    borderRadius: '10px',
    fontFamily: 'inherit'
  },
  labelSub: {
    color: '#939598',
    marginTop: '10px',
    lineHeight: 1.25,
    letterSpacing: 'normal'
  },
  dialogBorder: {
    borderRadius: '10px'
  },
  incomeProofOkButton: {
    color: '#01B4DE',
    fontWeight: 'bold'
  },
  incomeProofItems: {
    listStylePosition: 'outside',
    padding: '0px 10px 0px 15px',
    color: '#939598',
    fontFamily: 'inherit',
    '& li': {
      padding: '9px 0px 9px 0px'
    }
  }
});

const incomeProofDialog = props => {
  const scroll = 'paper';
  const { classes, incomeProofPopup } = props;
  return (
    <div>
      <Dialog
        open={true}
        onClose={incomeProofPopup}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className={classes.dialogBorder}
        classes={{ root: 'rounded' }}
      >
        <DialogTitle className={classes.title} id="customized-dialog-title" onClose={incomeProofPopup}>
          <Title className={classes.heading}>Proof of Equity Budget Eligibility</Title>
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'} className={classes.root}>
          <Box>
            <Grid>
              <Typography variant="body2" className={classes.label}>
                Any one of the items as applicable from the following list can be submitted as proof of Equity Budget
                Eligibility:
              </Typography>
              <Typography variant="body2">
                <ul className={classes.incomeProofItems}>
                  <li>Proof of PU Code Section 2852 Compliance (Single-family only)</li>
                  <li>SGIP Multi-Family Low-Income Housing Documentation Cover Sheet (Multi-family only)</li>
                  <li>Copy of Deed-Restriction or Regulatory Agreement (Multi-family only)</li>
                  <li>
                    Proof that at least 50 percent of census tracts served are DACs or low-income communities (Public
                    agencies and tribal government agencies only)
                  </li>
                  <li>Small Business Affidavit (Small business projects only)</li>
                  <li>Copy of SASH or DAC-SASH Reservation Letter</li>
                  <li> Certification of Tax-Exempt Status (Gov't and non-profit only)</li>
                  <li>
                    Proof of Eligibility under Proposition 39 or an Accredited Institution (Educational Institutions
                    Only)
                  </li>
                  <li>Proof of Indian Country Qualification</li>
                </ul>
              </Typography>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.bottomText} align="center">
                    <Button className={classes.incomeProofOkButton} onClick={e => incomeProofPopup()}>
                      Ok
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(incomeProofDialog);
