import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Grid,
  FormControl,
  Button,
  TableBody,
  TableRow,
  TableCell,
  Table,
  TableContainer
} from '@material-ui/core';

const styles = theme => ({
  dialogTitle: {
    padding: 0,
    textAlign: 'right'
  },
  root: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2.5)
    }
  },

  button: {
    color: theme.palette.secondary.main,
    textTransform: 'none'
  },
  cancelbutton: {
    textTransform: 'none'
  },

  title: {
    padding: '20px'
  },
  heading: {
    color: '#545456'
  },
  boxWrapper: {
    border: 'solid 1px #c6c8ca',
    borderRadius: '10px',
    padding: '20px'
  },
  label: {
    color: '#939598',
    marginTop: '5px'
  },
  value: {
    color: '#545456',
    marginTop: '15px'
  },
  tdborder: {
    borderBottom: 'none',
    borderRight: '1px solid #d8dcde',
    textTransform: 'none',
    padding: '0px'
  },
  tdnoborder: {
    borderBottom: '0px',
    color: theme.palette.primary.mainText,
    padding: '0px'
  },
  actions: {
    padding: '0px 20px'
  },
  content: {
    minHeight: '50px',
    overflow: 'break-word',
    padding: '2px',
    color: '#545456',
    marginTop: '15px',
    width: '100%',
    border: 'solid 1px #c6c8ca',
    borderRadius: '10px',
    fontFamily: 'inherit'
  }
});

const AppDetailsReject = props => {
  const handleSubmit = () => {
    const { rejectApplicationDetailsNotifiction, applicationDetailsMessageId } = props;
    let rejectReson = document.getElementById('reason').value;
    rejectApplicationDetailsNotifiction(applicationDetailsMessageId, rejectReson);
  };

  const reasonForReject = e => {
    let rejectReson = document.getElementById('reason').value;
    if (rejectReson.length > 0) {
      setReason(true);
    } else {
      setReason(false);
    }
  };

  const [ reasonFlag, setReason ] = useState(false);

  if (document.getElementById('reason')) {
    document.getElementById('reason').focus();
  }

  const scroll = 'paper';
  const { classes, handleClose } = props;
  return (
    <div>
      <Dialog
        open={true}
        onClose={props.handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle className={classes.title} id="customized-dialog-title" onClose={handleClose}>
          <Typography variant="subtitle2" className={classes.heading}>
            Reason for rejection
          </Typography>
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'} className={classes.root}>
          <Box className={classes.boxWrapper}>
            <Grid>
              <FormControl component="fieldset" error={'true'}>
                <Grid>
                  <Box className={classes.requestAccessWrapper}>
                    <Typography className={classes.label}>Describe the reason for rejection</Typography>
                    <Typography>
                      <textarea
                        type="text"
                        id="reason"
                        className={classes.content}
                        onChange={e => reasonForReject(e)}
                        autoFocus
                      />
                    </Typography>
                  </Box>
                </Grid>
              </FormControl>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell align="center" className={classes.tdborder}>
                    <Button onClick={props.handleClose} className={classes.cancelbutton}>
                      Cancel
                    </Button>
                  </TableCell>
                  <TableCell className={classes.tdnoborder} align="center">
                    <Button disabled={!reasonFlag} className={classes.button} onClick={() => handleSubmit()}>
                      Submit
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(AppDetailsReject);
