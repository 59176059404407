import * as React from 'react';
import { SvgIcon } from '@material-ui/core';

function HelpOutlineIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 27.341 26.341" id="help_outline-24px" xmlns="http://www.w3.org/2000/svg">
      <path id="Path_1213" dataName="Path 1213" d="M0,0H18.38V18.38H0Z" fill="none" />
      <path
        id="Path_1214"
        dataName="Path 1214"
        d="M8.892,14.253h1.532V12.722H8.892ZM9.658,2a7.658,7.658,0,1,0,7.658,7.658A7.661,7.661,0,0,0,9.658,2Zm0,13.785a6.127,6.127,0,1,1,6.127-6.127A6.135,6.135,0,0,1,9.658,15.785Zm0-10.722A3.062,3.062,0,0,0,6.595,8.127H8.127a1.532,1.532,0,1,1,3.063,0c0,1.532-2.3,1.34-2.3,3.829h1.532c0-1.723,2.3-1.915,2.3-3.829A3.062,3.062,0,0,0,9.658,5.063Z"
        transform="translate(-0.468 -0.468)"
        fill="#777"
      />
    </SvgIcon>
  );
}

export default HelpOutlineIcon;
