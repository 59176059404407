export const GET_SGIP_REQUESTS_REQUEST = 'GET_SGIP_REQUESTS_REQUEST';
export const GET_SGIP_SUCCESS = 'GET_SGIP_SUCCESS';
export const GET_REQUEST_FAILURE = 'GET_REQUEST_FAILURE';
export const GET_SGIP_STATISTICS_REQUEST = 'GET_SGIP_STATISTICS_REQUEST';
export const GET_SGIP_STATISTICS_SUCCESS = 'GET_SGIP_STATISTICS_SUCCESS';
export const GET_MSG_HISTORY_REQUEST = 'GET_MSG_HISTORY_REQUEST';
export const GET_MSG_HISTORY_SUCCESS = 'GET_MSG_HISTORY_SUCCESS';
export const GET_SGIP_REQUEST_DETAILS_REQUEST = 'GET_SGIP__REQUEST_DETAILS_REQUEST';
export const GET_SGIP_REQUEST_DETAILS_SUCCESS = 'GET_SGIP_REQUEST_DETAILS_SUCCESS';
export const UPDATE_APP_ID_REQUEST = 'UPDATE_APP_ID_REQUEST';
export const UPDATE_APP_ID_SUCCESS = 'UPDATE_APP_ID_SUCCESS';
export const UPDATE_STAGE_STATUS_REQUEST = 'UPDATE_STAGE_STATUS_REQUEST';
export const UPDATE_STAGE_STATUS_SUCCESS = 'UPDATE_STAGE_STATUS_SUCCESS';
export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_SGIP_STATISTICS_FAILURE = 'GET_SGIP_STATISTICS_FAILURE';
export const GET_SGIP_REQUESTS_FAILURE = 'GET_SGIP_REQUESTS_FAILURE';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';
export const GET_PRESIGNED_URL = 'GET_PRESIGNED_URL';
export const FILE_UPLOAD_TO_S3_REQUEST = 'FILE_UPLOAD_TO_S3_REQUEST';
export const GET_PDF_FILE_FROM_S3_REQUEST = 'GET_PDF_FILE_FROM_S3_REQUEST';
export const SEND_TO_HO_FOR_APPROVAL_REQUEST = 'SEND_TO_HO_FOR_APPROVAL_REQUEST';
export const SEND_TO_HO_FOR_APPROVAL_SUCCESS = 'SEND_TO_HO_FOR_APPROVAL_SUCCESS';

export const GET_ACCOUNT_DETAILS_REQUEST = 'GET_ACCOUNT_DETAILS_REQUEST';
export const GET_ACCOUNT_DETAILS_SUCCESS = 'GET_ACCOUNT_DETAILS_SUCCESS';
export const GET_ACCOUNT_DETAILS_FAILURE = 'GET_ACCOUNT_DETAILS_FAILURE';

export const SITE_SEARCH_REQUEST = 'SITE_SEARCH_REQUEST';
export const SITE_SEARCH_REQUEST_SUCCESS = 'SITE_SEARCH_REQUEST_SUCCESS';
export const SITE_SEARCH_REQUEST_FAILURE = 'SITE_SEARCH_REQUEST_FAILURE';
export const RESET_SITE_SEARCH_RESULT_REQUEST = 'RESET_SITE_SEARCH_RESULT_REQUEST';

export const UPDATE_CHAT_STATUS_REQUEST = 'UPDATE_CHAT_STATUS_REQUEST';
export const UPDATE_CHAT_STATUS_SUCCESS = 'UPDATE_CHAT_STATUS_SUCCESS';
export const UPDATE_CHAT_STATUS_FAILURE = 'UPDATE_CHAT_STATUS_FAILURE';

export const GET_APP_NOTES_REQUEST = 'GET_APP_NOTES_REQUEST';
export const GET_APP_NOTES_SUCCESS = 'GET_APP_NOTES_SUCCESS';
export const GET_APP_NOTES_FAILURE = 'GET_APP_NOTES_FAILURE';
export const UPDATE_APP_NOTE_REQUEST = 'UPDATE_APP_NOTE_REQUEST';
export const UPDATE_APP_NOTE_SUCCESS = 'UPDATE_APP_NOTE_SUCCESS';
export const UPDATE_APP_NOTE_FAILURE = 'UPDATE_APP_NOTE_FAILURE';
export const CREATE_APP_NOTE_REQUEST = 'CREATE_APP_NOTE_REQUEST';
export const CREATE_APP_NOTE_SUCCESS = 'CREATE_APP_NOTE_SUCCESS';
export const CREATE_APP_NOTE_FAILUTE = 'CREATE_APP_NOTE_FAILURE';
export const DELETE_APP_NOTE_REQUEST = 'DELETE_APP_NOTE_REQUEST';
export const DELETE_APP_NOTE_SUCCESS = 'DELETE_APP_NOTE_SUCCESS';
export const DELETE_APP_NOTE_FAILURE = 'DELETE_APP_NOTE_FAILURE';

export const GET_APPLICATIONS_SITE_LIST_REQUEST = 'GET_APPLICATIONS_SITE_LIST_REQUEST';
export const GET_APPLICATIONS_SITE_LIST_SUCCESS = 'GET_APPLICATIONS_SITE_LIST_SUCCESS';
export const GET_APPLICATIONS_SITE_LIST_FAILURE = 'GET_APPLICATIONS_SITE_LIST_FAILURE';

export const GET_USER_TYPE_REQUEST = 'GET_USER_TYPE_REQUEST';
export const GET_USER_TYPE_SUCCESS = 'GET_USER_TYPE_SUCCESS';

export const UPDATE_LAST_MESSAGE_SEEN_REQUEST = 'UPDATE_LAST_MESSAGE_SEEN_REQUEST';

export const GET_COMPANY_INFO_REQUEST = 'GET_COMPANY_INFO_REQUEST';
export const GET_COMPANY_INFO_SUCCESS = 'GET_COMPANY_INFO_SUCCESS';
export const GET_COMPANY_INFO_FAILURE = 'GET_COMPANY_INFO_FAILURE';

export const GET_DIN_INSTALLERS_LIST_REQUEST = 'GET_DIN_INSTALLERS_LIST_REQUEST';
export const GET_DIN_INSTALLERS_LIST_SUCCESS = 'GET_DIN_INSTALLERS_LIST_SUCCESS';

export const UPDATE_DIN_INSTALLERS_LIST_REQUEST = 'UPDATE_DIN_INSTALLERS_LIST_REQUEST';
export const UPDATE_DIN_INSTALLERS_LIST_SUCCESS = 'UPDATE_DIN_INSTALLERS_LIST_SUCCESS';

export const DELETE_DIN_INSTALLERS_LIST_REQUEST = 'DELETE_DIN_INSTALLERS_LIST_REQUEST';
export const DELETE_DIN_INSTALLERS_LIST_SUCCESS = 'DELETE_DIN_INSTALLERS_LIST_SUCCESS';

export const UPLOAD_SGIP_DOCS_REQUEST = 'UPLOAD_SGIP_DOCS_REQUEST';
export const UPLOAD_SGIP_DOCS_SUCCESS = 'UPLOAD_SGIP_DOCS_SUCCESS';

export const DOWNLOAD_ALL_APP_DOCS_REQUEST = 'DOWNLOAD_ALL_APP_DOCS_REQUEST';

export const BLOCK_UNBLOCK_SGIP_CONFIG_PARAMS_REQUEST = 'BLOCK_UNBLOCK_SGIP_CONFIG_PARAMS_REQUEST';
export const BLOCK_UNBLOCK_SGIP_CONFIG_PARAMS_SUCCESS = 'BLOCK_UNBLOCK_SGIP_CONFIG_PARAMS_SUCCESS';
export const BLOCK_UNBLOCK_SGIP_CONFIG_PARAMS_FAILURE = 'BLOCK_UNBLOCK_SGIP_CONFIG_PARAMS_FAILURE';

export const UPDATE_INCENTIVE_RATE_SGIP_CONFIG_PARAMS_REQUEST = 'UPDATE_INCENTIVE_RATE_SGIP_CONFIG_PARAMS_REQUEST';
export const UPDATE_INCENTIVE_RATE_SGIP_CONFIG_PARAMS_SUCCESS = 'UPDATE_INCENTIVE_RATE_SGIP_CONFIG_PARAMS_SUCCESS';
export const UPDATE_INCENTIVE_RATE_SGIP_CONFIG_PARAMS_FAILURE = 'UPDATE_INCENTIVE_RATE_SGIP_CONFIG_PARAMS_FAILURE';

export const GET_BATTERY_REPORT_DOWNLOAD_REQUEST = 'GET_BATTERY_REPORT_DOWNLOAD_REQUEST';
export const GET_BATTERY_REPORT_DOWNLOAD_SUCCESS = 'GET_BATTERY_REPORT_DOWNLOAD_SUCCESS';
export const GET_BATTERY_REPORT_DOWNLOAD_FAILURE = 'GET_BATTERY_REPORT_DOWNLOAD_FAILURE';

// SGIP-IFRAME Messages
export const OPENING_EXISTING_APPLICATION = 'OPENING_EXISTING_APPLICATION';
export const CREATE_NEW_APPLICATION = 'CREATE_NEW_APPLICATION';

export const SAVE_INSTALLER_SECONDARY_EMAIL_REQUEST = 'SAVE_INSTALLER_SECONDARY_EMAIL_REQUEST';
export const SAVE_INSTALLER_SECONDARY_EMAIL_SUCCESS = 'SAVE_INSTALLER_SECONDARY_EMAIL_SUCCESS';
export const SAVE_INSTALLER_SECONDARY_EMAIL_FAILURE = 'SAVE_INSTALLER_SECONDARY_EMAIL_FAILURE';

// export const REQUEST_LISTING_COLUMNS = [
//   { id: 'application_id', label: 'Enphase Id', minWidth: 120, sortable: true },
//   { id: 'application_number', label: 'Application Number', minWidth: 160, sortable: true },
//   { id: 'email', label: 'Email Id', minWidth: 150, sortable: true },
//   { id: 'application_date', label: 'Application Date', minWidth: 132, sortable: true },
//   { id: 'current_application_status.stage_name', label: 'Status', minWidth: 100, sortable: true }
// ];

export const REQUEST_LISTING_COLUMNS = [
  { id: 'application_id', label: 'Enphase Id', minWidth: 120, sortable: true, searchable: true },
  { id: 'application_number', label: 'Application Number', minWidth: 165, sortable: true, searchable: true },
  { id: 'site_id', label: 'System Id', minWidth: 100, sortable: true, searchable: true },
  { id: 'site_name', label: 'System Name', minWidth: 150, sortable: false, searchable: true },
  { id: 'homeowner_name', label: 'System Owner Name', minWidth: 150, sortable: false, searchable: true },
  { id: 'installer_name', label: 'Installer Name', minWidth: 150, sortable: false, searchable: true },
  { id: 'current_application_status.stage_name', label: 'Status', minWidth: 100, sortable: true, searchable: true },
  { id: 'application_submitted_by', label: 'Submitted By', minWidth: 100, sortable: false, searchable: true },
  { id: 'application_date', label: 'Created On', minWidth: 132, sortable: true, searchable: false },
  {
    id: 'last_modified_date',
    label: 'Updated On',
    minWidth: 132,
    sortable: true,
    searchable: false
  }
];

export const DIN_INSTALLER_LIST_COLUMNS = [
  { id: 'installer_name', label: 'Installer Name', minWidth: 200, sortable: true },
  { id: 'date_added', label: 'Date Added', minWidth: 200, sortable: true },
  { id: 'actions', label: 'Actions', minWidth: 100, sortable: true }
];

export const STATUS = {
  NOT_STARTED: 'Not Started',
  INPROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  APPROVED: 'Approved',
  WAITLISTED: 'Waitlisted',
  REJECTED: 'Rejected',
  REQUIRED: 'Required',
  NOT_REQUIRED: 'Not Required'
};

export const REQUEST_STATUSES = [
  {
    id: 1,
    label: 'New Application',
    status_levels: [
      { label: 'Verified', isGreen: true },
      { label: 'Not Verified', isGreen: false }
    ],
    sub_stages: [
      {
        id: 1,
        label: 'Submitted',
        status_levels: [
          { label: STATUS.INPROGRESS, isGreen: true },
          { label: STATUS.COMPLETED, isGreen: false }
        ]
      },
      {
        id: 2,
        label: 'Verification',
        status_levels: [
          { label: STATUS.INPROGRESS, isGreen: true },
          { label: STATUS.COMPLETED, isGreen: false }
        ]
      }
    ]
  },
  {
    id: 2,
    label: 'Rebate Reservation Form',
    status_levels: [
      { label: 'Submitted', isGreen: true },
      { label: 'Not Submitted', isGreen: false }
    ],
    sub_stages: [
      {
        id: 1,
        label: 'Generation',
        status_levels: [
          { label: STATUS.INPROGRESS, isGreen: true },
          { label: STATUS.COMPLETED, isGreen: false }
        ]
      },
      {
        id: 2,
        label: 'Signature By All parties',
        status_levels: [
          { label: STATUS.INPROGRESS, isGreen: true },
          { label: STATUS.COMPLETED, isGreen: false }
        ]
      },
      {
        id: 3,
        label: 'Submission to Utility',
        status_levels: [
          { label: STATUS.INPROGRESS, isGreen: true },
          { label: STATUS.COMPLETED, isGreen: false }
        ]
      },
      {
        id: 4,
        label: 'Approval from Utility',
        status_levels: [
          { label: STATUS.INPROGRESS, isGreen: true },
          { label: STATUS.COMPLETED, isGreen: false },
          { label: STATUS.REJECTED, isGreen: false }
        ]
      }
    ]
  },
  {
    id: 3,
    label: 'Site activation Documents',
    status_levels: [
      { label: 'Confirmed', isGreen: true },
      { label: 'Rejected', isGreen: false }
    ],
    sub_stages: [
      {
        id: 1,
        label: 'Request to Installer',
        status_levels: [
          { label: STATUS.INPROGRESS, isGreen: true },
          { label: STATUS.COMPLETED, isGreen: false }
        ]
      },
      {
        id: 2,
        label: 'Submission from Installer',
        status_levels: [
          { label: STATUS.INPROGRESS, isGreen: true },
          { label: STATUS.COMPLETED, isGreen: false }
        ]
      },
      {
        id: 3,
        label: 'Documents Verification',
        status_levels: [
          { label: STATUS.INPROGRESS, isGreen: true },
          { label: STATUS.COMPLETED, isGreen: false }
        ]
      }
    ]
  },
  {
    id: 4,
    label: 'Incentive Claim Form',
    status_levels: [
      { label: 'Submitted', isGreen: true },
      { label: 'Not Submitted', isGreen: false }
    ],
    sub_stages: [
      {
        id: 1,
        label: 'Generation',
        status_levels: [
          { label: STATUS.INPROGRESS, isGreen: true },
          { label: STATUS.COMPLETED, isGreen: false }
        ]
      },
      {
        id: 2,
        label: 'Signature By All parties',
        status_levels: [
          { label: STATUS.INPROGRESS, isGreen: true },
          { label: STATUS.COMPLETED, isGreen: false }
        ]
      },
      {
        id: 3,
        label: 'Submission to Utility',
        status_levels: [
          { label: STATUS.INPROGRESS, isGreen: true },
          { label: STATUS.COMPLETED, isGreen: false }
        ]
      },
      {
        id: 4,
        label: 'Site Inspection',
        status_levels: [
          { label: STATUS.REQUIRED, isGreen: true },
          { label: STATUS.NOT_REQUIRED, isGreen: true }
        ]
      },
      {
        id: 5,
        label: 'Approval from Utility',
        status_levels: [
          { label: STATUS.APPROVED, isGreen: false },
          { label: STATUS.REJECTED, isGreen: false }
        ]
      }
    ]
  }
];

export const MSG_HISTORY_COLUMNS = [
  { id: 'app_no', label: 'Enphase Id', minWidth: 100 },
  { id: 'msg_date', label: 'Date', minWidth: 100 },
  { id: 'msg_subject', label: 'Subject', minWidth: 150 },
  { id: 'msg_body', label: 'Body Content', minWidth: 100 },
  { id: 'f_attached', label: 'File Attached', minWidth: 100 },
  { id: 'actions', label: '', minWidth: 50 }
];

export const MESSAGE_SUBJECTS = [
  'Document requested',
  'Correction in details',
  'Clarification notice',
  'Document signature notice',
  'Missing document notice',
  'General update',
  'Others'
];

export const STATUS_UPDATE_MSG_SUBJECT = 'Status Update';

export const FILE_UPLOAD_SIZE_LIMIT = 20;
export const RDS_BATTERY_DEVICE_TYPE = '19';
export const BATTERY_REPORT_TIME_INTERVAL = 15; // in minutes
