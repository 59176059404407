import React, { Fragment } from 'react';
import { Box, Grid, Typography, InputLabel, Checkbox, FormControlLabel, FormHelperText } from '@material-ui/core';
import { useMobileStyles, useDesktopStyles } from '../style';
import { BoxLayout } from '../../../Common';
import { Title } from '../../../Texts';
import FilledButton from '../../../Buttons/FilledButton';
import { TextField } from '@material-ui/core';
import { isNull } from '../../../../utils/lodash';
import PrivacyPolicy from '../../PrivacyPolicy';

const PersonalDetailsForm = props => {
  const {
    isMobileView = true,
    personalDetails,
    personalDetailsError,
    handleSameAddressCheckBox,
    handleConfirmationCheckBox,
    handlePersonalDetailsFormChange,
    handleSubmit,
    isInstaller,
    isIOS,
    isConfirmationChecked,
    isEditablePhone,
    isEditableEmail,
  } = props;
  const mobileClasses = useMobileStyles();
  const desktopClasses = useDesktopStyles();
  const classes = isMobileView ? mobileClasses : desktopClasses;
  const getPhoneNumber = value => {
    const phone = value && !isNull(value) ? value.toString() : '';
    const first = phone.substring(0, 3);
    const middle = phone.substring(3, 6);
    const last = phone.substring(6, 10);

    if (phone.length > 6) {
      return `${first}-${middle}-${last}`;
    } else if (phone.length > 3) {
      return `${first}-${middle}`;
    } else if (phone.length > 0) {
      return `${first}`;
    }
  };

  return (
    <Fragment>
      <Grid>
        <br />
        <BoxLayout disableBackground={personalDetails.site_ownership === 'leased' ? true : false}>
          <Title className={personalDetails.site_ownership === 'leased' ? classes.headerLeased : classes.header}>
            Personal Details
          </Title>
          {!isInstaller ? (
            <Typography variant="body2" className={classes.label}>
              Your First Name, Last Name and the Battery Installation Site Address must match the utility bill.
            </Typography>
          ) : (
            <Typography variant="body2" className={classes.label}>
              System Owner’s First Name, Last Name and the Battery Installation Site Address must match the utility
              bill.
            </Typography>
          )}
          <br />
          <form id="persoal_details_form" className={classes.personalForm} noValidate autoComplete="off">
            <div className={classes.formField}>
              {!isInstaller ? (
                <InputLabel className={classes.field} id="first_name_label">
                  First Name
                </InputLabel>
              ) : (
                <InputLabel className={classes.field} id="first_name_label">
                  System Owner’s First Name
                </InputLabel>
              )}
              <TextField
                required
                fullWidth
                name="first_name"
                value={personalDetails.first_name}
                error={personalDetailsError.first_name.error}
                helperText={personalDetailsError.first_name.msg}
                onChange={handlePersonalDetailsFormChange}
                inputProps={{ maxLength: 100 }}
                disabled={personalDetails.site_ownership === 'leased'}
              />
            </div>

            <div className={classes.formField}>
              {!isInstaller ? (
                <InputLabel className={classes.field} id="last_name_label">
                  Last Name
                </InputLabel>
              ) : (
                <InputLabel className={classes.field} id="last_name_label">
                  System Owner’s Last Name
                </InputLabel>
              )}
              <TextField
                fullWidth
                name="last_name"
                value={personalDetails.last_name}
                error={personalDetailsError.last_name.error}
                helperText={personalDetailsError.last_name.msg}
                onChange={handlePersonalDetailsFormChange}
                inputProps={{ maxLength: 100 }}
                disabled={personalDetails.site_ownership === 'leased'}
              />
            </div>

            <div className={classes.formField}>
              {!isInstaller ? (
                <InputLabel className={classes.field} id="email_label">
                  Email
                </InputLabel>
              ) : (
                <InputLabel className={classes.field} id="email_label">
                  System Owner’s Email
                </InputLabel>
              )}
              <TextField
                required
                disabled={!isEditableEmail}
                fullWidth
                name="email_id"
                value={personalDetails.email}
                error={personalDetailsError.email.error}
                helperText={personalDetailsError.email.msg}
                onChange={handlePersonalDetailsFormChange}
              />
            </div>

            <div className={classes.formField}>
              {!isInstaller ? (
                <InputLabel className={classes.field} id="mobile_label">
                  Contact Number
                </InputLabel>
              ) : (
                <InputLabel className={classes.field} id="mobile_label">
                  System Owner’s Contact Number
                </InputLabel>
              )}
              <TextField
                fullWidth
                name="phone"
                type="tel"
                value={
                  isEditablePhone || !isNaN(parseInt(personalDetails.phone))
                    ? getPhoneNumber(personalDetails.phone)
                    : personalDetails.phone
                }
                inputProps={{
                  maxLength: 20,
                  className: classes.input,
                  pattern: '[0-9]*',
                }}
                min={0}
                error={personalDetailsError.phone.error}
                helperText={personalDetailsError.phone.msg}
                onChange={e => {
                  e.target.value = parseInt(e.target.value.split('-').join(''));
                  if (isNaN(parseInt(e.target.value))) {
                    e.target.value = '';
                  }
                  handlePersonalDetailsFormChange(e);
                }}
                disabled={personalDetails.site_ownership === 'leased'}
              />
            </div>

            <div className={classes.formField}>
              {!isInstaller ? (
                <InputLabel className={classes.field} id="city_label">
                  City in California
                </InputLabel>
              ) : (
                <InputLabel className={classes.field} id="city_label">
                  System Owner’s City in California
                </InputLabel>
              )}
              <TextField
                fullWidth
                name="city"
                error={personalDetailsError.city.error}
                value={personalDetails.city}
                helperText={personalDetailsError.city.msg}
                onChange={handlePersonalDetailsFormChange}
                disabled={personalDetails.site_ownership === 'leased'}
              />
            </div>

            <div className={classes.dHide}>
              <InputLabel className={classes.field} id="battery_installation_site_address_label">
                Battery Installation Site Address
              </InputLabel>
              <TextField
                fullWidth
                name="battery_installation_site_address"
                error={personalDetailsError.battery_installation_site_address.error}
                helperText={personalDetailsError.battery_installation_site_address.msg}
                onChange={handlePersonalDetailsFormChange}
                value={personalDetails.battery_installation_site_address}
                disabled={personalDetails.site_ownership === 'leased'}
              />
            </div>

            <div className={classes.dHide}>
              {!isInstaller ? (
                <InputLabel className={classes.field} id="communication_address_label">
                  Mailing Address
                </InputLabel>
              ) : (
                <InputLabel className={classes.field} id="communication_address_label">
                  System Owner’s Mailing Address
                </InputLabel>
              )}
              <TextField
                fullWidth
                id="communication_address"
                name="communication_address"
                error={personalDetailsError.communication_address.error}
                helperText={personalDetailsError.communication_address.msg}
                onChange={handlePersonalDetailsFormChange}
                value={
                  personalDetails.same_address ? (
                    personalDetails.battery_installation_site_address
                    ) : (
                    personalDetails.communication_address
                )
                }
                disabled={personalDetails.site_ownership === 'leased' || personalDetails.same_address}
              />
            </div>
            <br />

            <div className={classes.dshow}>
              <Title className={classes.header}>Address</Title>
              <div className={classes.formFieldAddress}>
                <InputLabel className={classes.field} id="battery_installation_site_address_label">
                  Battery Installation Site Address
                </InputLabel>
                <TextField
                  fullWidth
                  name="battery_installation_site_address"
                  error={personalDetailsError.battery_installation_site_address.error}
                  helperText={personalDetailsError.battery_installation_site_address.msg}
                  onChange={handlePersonalDetailsFormChange}
                  value={personalDetails.battery_installation_site_address}
                  disabled={personalDetails.site_ownership === 'leased'}
                />
              </div>

              <div className={classes.formFieldAddress}>
                {!isInstaller ? (
                  <InputLabel className={classes.field} id="communication_address_label">
                    Mailing Address
                  </InputLabel>
                ) : (
                  <InputLabel className={classes.field} id="communication_address_label">
                    System Owner’s Mailing Address
                  </InputLabel>
                )}
                <TextField
                  fullWidth
                  id="communication_address"
                  name="communication_address"
                  error={personalDetailsError.communication_address.error}
                  helperText={personalDetailsError.communication_address.msg}
                  onChange={handlePersonalDetailsFormChange}
                  value={
                    personalDetails.same_address ? (
                      personalDetails.battery_installation_site_address
                      ) : (
                      personalDetails.communication_address
                  )
                  }
                  disabled={personalDetails.site_ownership === 'leased' || personalDetails.same_address}
                />
              </div>
              <br />
            </div>

            <FormControlLabel
              className={personalDetails.site_ownership === 'leased' ? classes.textGreyed : classes.sameAsBattery}
              control={
                <Checkbox
                  name="same_address"
                  checked={personalDetails.same_address}
                  onChange={handleSameAddressCheckBox}
                />
              }
              label={<Typography className={classes.text}>Same as Battery Installation Site Address</Typography>}
            />
            <br />

            <FormControlLabel
              className={personalDetails.site_ownership === 'leased' ? classes.textGreyed : classes.sameAsBattery}
              control={
                <Checkbox name="confirmation" checked={isConfirmationChecked} onChange={handleConfirmationCheckBox} />
              }
              label={
                <Typography className={classes.text}>
                  {isInstaller ? (
                    'I confirm that system owner’s first name, last name & address are the same as mentioned in the utility bill.'
                    ) : (
                    'I confirm that my first name, last name & address are the same as mentioned in my utility bill.'
                  )}
                </Typography>
              }
            />
            {personalDetailsError.confirmation_details.error && (
              <FormHelperText className={classes.error}>{personalDetailsError.confirmation_details.msg}</FormHelperText>
            )}
            <br />

            <Box alignItems="center" justifyContent="center" style={{ paddingTop: '16px', margin: '0 auto' }}>
              <FilledButton
                disabled={
                  personalDetails.site_ownership === 'leased' ||
                  personalDetailsError.first_name.error ||
                  personalDetailsError.last_name.error ||
                  !isConfirmationChecked
                }
                onClick={e => handleSubmit()}
                className={classes.btn}
              >
                Next
              </FilledButton>
            </Box>
          </form>
        </BoxLayout>
      </Grid>
      <PrivacyPolicy />
    </Fragment>
  );
};

export default PersonalDetailsForm;
