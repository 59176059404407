import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import {
  Typography,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Link
} from '@material-ui/core';
import ApplicantDetailsView from '../applicantDetails/applicantDetailsView';
import ApplicantDetailsEdit from '../applicantDetails/applicantDetailsEdit';
import { getSGIPRequestDetails } from '../../../containers/Admin/actions';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.dark
  },
  fontBold: {
    fontWeight: 'bold'
  },
  dataHeader: {
    color: '#246AB0'
  },
  panelHead: {
    background: '#f1f1f1',
    color: theme.palette.primary.mainText,
    minHeight: '40px !important',
    height: '40px',
    cursor: 'unset !important'
  },
  panelDetails: {
    flexDirection: 'column'
  },
  heading: {
    fontWeight: 'bold',
    flexBasis: '90%',
    flexShrink: 0
  },
  headingInfo: {
    color: 'darkgray !important'
  },
  dataSubHeader: {
    fontSize: '16px',
    color: 'grey'
  }
});

export class ApplicantDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  switchToEditMode(eAction) {
    if (eAction === 'edit') {
      this.setState({ editMode: true });
    } else {
      this.setState({ editMode: false });
      const { request_id } = this.props;
      this.props.getSGIPRequestDetails({ application_id: request_id });
    }
  }

  render() {
    const { classes, appDetails, installerView } = this.props;
    const { editMode } = this.state;
    return (
      <Fragment>
        <Grid container direction="row">
          <Grid item xs={12}>
            <ExpansionPanel expanded={true}>
              <ExpansionPanelSummary aria-controls="sDetails-content" id="sDetails" className={classes.panelHead}>
                <Typography className={classes.heading}>Applicant Details</Typography>
                {!installerView && (
                  <Typography>
                    {editMode === true ? (
                      <Link
                        component="button"
                        variant="body2"
                        onClick={() => this.switchToEditMode('cancel')}
                        style={{ color: 'blue' }}
                      >
                        Cancel Edit
                      </Link>
                    ) : (
                      <Link
                        component="button"
                        variant="body2"
                        onClick={() => this.switchToEditMode('edit')}
                        style={{ color: 'blue' }}
                      >
                        Edit Details
                      </Link>
                    )}
                  </Typography>
                )}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.panelDetails} style={{ flexDirection: 'column' }}>
                {editMode === true ? (
                  <ApplicantDetailsEdit appDetails={appDetails} />
                ) : (
                  <ApplicantDetailsView appDetails={appDetails} />
                )}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getSGIPRequestDetails: payload => dispatch(getSGIPRequestDetails(payload))
});

const mapStateToProps = state => ({
  currentlySending: state.adminReducer.currentlySending
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ApplicantDetails));
