import { fork } from 'redux-saga/effects';
import {
  getSGIPRequestsFlow,
  getSGIPStatisticsFlow,
  getMsgHistoryFlow,
  getSGIPRequestDetailsFlow,
  updateApplicationNumberFlow,
  updateStageStatusUpdateFlow,
  sendMessageFlow,
  getNotificationsFlow,
  getPresignedURLFlow,
  uploadToS3Flow,
  fileDownloadFromS3Flow,
  sendToHOForApprovalFlow,
  getSiteSearchFlow,
  getAccountDetailsFlow,
  chatStatusUpdateFlow,
  getAppNotesFlow,
  createAppNoteFlow,
  updateAppNoteFlow,
  deleteAppNoteFlow,
  updateLastSeenMessageFlow,
  getSgipSiteListFlow,
  getCompanyInfoFlow,
  getSGIPDinInstallersListFlow,
  getSGIPUserTypeFlow,
  updateSGIPDinInstallerListFlow,
  deleteSGIPDinInstallerListFlow,
  uploadSgipDocumentFlow,
  downloadAllFlow,
  blockUnblockSGIPConfigParamsFlow,
  updateIncentiveRateSGIPConfigParamsFlow,
  downloadBatteryReport,
  saveInstallerSecondaryEmailFlow
} from './containers/Admin/saga';
import {
  getSGIPInstallers,
  getSGIPPrimaryInstallers,
  getApplicationNumber,
  putSgipDocumentToS3,
  sgipDocumentDownloadFromS3,
  deleteSgipDocumentFromS3,
  getSgipDocumentPresignedURL,
  postSgipPrimaryDetails,
  postDeveloperKeyUpdateMail,
  postNotifyMeDetails,
  postSgipPersonalDetails,
  postSgipBudgetQuestionnaires,
  postSgipApplicationDocuments,
  postSgipApplicationTrackUploadDocuments,
  getSgipApplicationDetailsBySiteId,
  downloadFileFromS3,
  postSgipServicePaymentUpdate,
  postSgipPaymentIntent,
  getStripePublishableKey,
  getUtilityBillsPresignedURLs,
  getDocumentsTypePresignedURLs,
  getUploadedDocumentsPresignedURLs,
  postApplicantNotification,
  postSignedDocument,
  postFieldChangeAcceptNotification,
  postFieldChangeRejectNotification,
  verifyPromocode,
  postSgipServicePromocodePayment,
  postSgipAttestationForm,
  getSgipUserSelectionData,
  postSgipNpsFeedbackFlow,
  getSgipNpsFeedbackFlow,
  getSiteInfoFlow,
  getSgipIncentiveRatesFlow,
  getSgipConfigsFlow,
  getCpucURLFlow,
  createSgipApplicationFlow,
  addToCartFlow
} from './containers/SgipApp/saga';

export default function* root() {
  yield fork(getSGIPRequestsFlow);
  yield fork(getSGIPStatisticsFlow);
  yield fork(getMsgHistoryFlow);
  yield fork(getSGIPRequestDetailsFlow);
  yield fork(updateApplicationNumberFlow);
  yield fork(updateStageStatusUpdateFlow);
  yield fork(sendMessageFlow);
  yield fork(getNotificationsFlow);
  yield fork(getPresignedURLFlow);
  yield fork(uploadToS3Flow);
  yield fork(fileDownloadFromS3Flow);
  yield fork(sendToHOForApprovalFlow);
  yield fork(getSiteSearchFlow);
  yield fork(getAccountDetailsFlow);
  yield fork(chatStatusUpdateFlow);
  yield fork(getAppNotesFlow);
  yield fork(createAppNoteFlow);
  yield fork(updateAppNoteFlow);
  yield fork(deleteAppNoteFlow);
  yield fork(updateLastSeenMessageFlow);
  yield fork(getSgipSiteListFlow);
  yield fork(getCompanyInfoFlow);
  yield fork(getSGIPDinInstallersListFlow);
  yield fork(updateSGIPDinInstallerListFlow);
  yield fork(deleteSGIPDinInstallerListFlow);
  yield fork(getSGIPUserTypeFlow);
  yield fork(uploadSgipDocumentFlow);
  yield fork(downloadAllFlow);
  yield fork(blockUnblockSGIPConfigParamsFlow);
  yield fork(updateIncentiveRateSGIPConfigParamsFlow);
  yield fork(downloadBatteryReport);
  yield fork(saveInstallerSecondaryEmailFlow);

  yield fork(getSGIPInstallers);
  yield fork(getSGIPPrimaryInstallers);
  yield fork(getApplicationNumber);
  yield fork(putSgipDocumentToS3);
  yield fork(sgipDocumentDownloadFromS3);
  yield fork(deleteSgipDocumentFromS3);
  yield fork(getSgipDocumentPresignedURL);
  yield fork(postSgipPrimaryDetails);
  yield fork(postDeveloperKeyUpdateMail);
  yield fork(postNotifyMeDetails);
  yield fork(postSgipPersonalDetails);
  yield fork(postSgipBudgetQuestionnaires);
  yield fork(postSgipApplicationDocuments);
  yield fork(postSgipApplicationTrackUploadDocuments);
  yield fork(getSgipApplicationDetailsBySiteId);
  yield fork(downloadFileFromS3);
  yield fork(postSgipServicePaymentUpdate);
  yield fork(postSgipPaymentIntent);
  yield fork(getStripePublishableKey);
  yield fork(getUtilityBillsPresignedURLs);
  yield fork(getDocumentsTypePresignedURLs);
  yield fork(getUploadedDocumentsPresignedURLs);
  yield fork(postApplicantNotification);
  yield fork(postSignedDocument);
  yield fork(postFieldChangeAcceptNotification);
  yield fork(postFieldChangeRejectNotification);
  yield fork(verifyPromocode);
  yield fork(postSgipServicePromocodePayment);
  yield fork(postSgipAttestationForm);
  yield fork(getSgipUserSelectionData);
  yield fork(postSgipNpsFeedbackFlow);
  yield fork(getSgipNpsFeedbackFlow);
  yield fork(getSiteInfoFlow);
  yield fork(getSgipIncentiveRatesFlow);
  yield fork(getSgipConfigsFlow);
  yield fork(getCpucURLFlow);
  yield fork(createSgipApplicationFlow);
  yield fork(addToCartFlow);
}
