import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { Typography, Grid } from '@material-ui/core';
import { getFullName } from '../../../containers/Admin/helper';
import { get, isEqual } from 'lodash';
import { LARGE_SCALE_STORAGE } from '../../../containers/SgipApp/constants';
import moment from 'moment';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.dark
  },
  fontBold: {
    fontWeight: 'bold'
  },
  dataHeader: {
    color: '#246AB0'
  },
  panelHead: {
    background: '#f1f1f1',
    color: theme.palette.primary.mainText,
    minHeight: '40px !important',
    height: '40px',
    cursor: 'unset !important'
  },
  panelDetails: {
    flexDirection: 'column'
  },
  heading: {
    fontWeight: 'bold',
    flexBasis: '90%',
    flexShrink: 0
  },
  headingInfo: {
    color: 'darkgray !important'
  },
  dataSubHeader: {
    fontSize: '16px',
    color: 'grey'
  }
});

export class ApplicantDetailsView extends React.Component {
  render() {
    const { classes, appDetails } = this.props;
    return (
      <Fragment>
        <Grid container direction="row">
          <Grid item xs={12} md={4}>
            <Typography className={classes.dataSubHeader}>Applicant Name</Typography>
            <Typography className={classes.fontBold}>{getFullName(appDetails)}</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography className={classes.dataSubHeader}>Email</Typography>
            <Typography className={classes.fontBold}>{get(appDetails, 'email') || '--'}</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography className={classes.dataSubHeader}>Mobile No</Typography>
            <Typography className={classes.fontBold}>{get(appDetails, 'phone') || '--'}</Typography>
          </Grid>
        </Grid>
        <br />
        <Grid container direction="row">
          <Grid item xs={12} md={4}>
            <Typography className={classes.dataSubHeader}>City in California</Typography>
            <Typography className={classes.fontBold}>{get(appDetails, 'city') || '--'}</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography className={classes.dataSubHeader}>Installer Name (code)</Typography>
            <Typography className={classes.fontBold}>
              {(get(appDetails, 'installer_name') || 'NA') +
                (' (' + (get(appDetails, 'installer_code') || '--').toUpperCase() + ')')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography className={classes.dataSubHeader}>Installer Contact</Typography>
            <Typography className={classes.fontBold}>{get(appDetails, 'installation_poc') || 'NA'}</Typography>
          </Grid>
        </Grid>
        <br />
        <Grid container direction="row">
          <Grid item xs={12} md={4}>
            <Typography className={classes.dataSubHeader}>Battery Installation Site Address</Typography>
            <Typography className={classes.fontBold}>
              {get(appDetails, 'battery_installation_site_address') || '--'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography className={classes.dataSubHeader}>Communication Address</Typography>
            <Typography className={classes.fontBold}>{get(appDetails, 'communication_address') || '--'}</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography className={classes.dataSubHeader}>System Storage</Typography>
            <Typography className={classes.fontBold}>
              Storage Capacity:{' '}
              {get(appDetails, 'storage_capacity') ? get(appDetails, 'storage_capacity') + ' kWh' : '--'}
            </Typography>
            <Typography className={classes.fontBold}>
              IQ Battery 10 / IQ Battery 10T Units: {get(appDetails, 'unit_of_encharge10') || '--'}
            </Typography>
            <Typography className={classes.fontBold}>
              IQ Battery 3 / IQ Battery 3T Units: {get(appDetails, 'unit_of_encharge3') || '--'}
            </Typography>
            <Typography className={classes.fontBold}>
              IQ Battery 5P Units: {get(appDetails, 'unit_of_iq_battery5p') || '--'}
            </Typography>
          </Grid>
        </Grid>
        <br />
        <Grid container direction="row">
          <Grid item xs={12} md={4}>
            <Typography className={classes.dataSubHeader}>SGIP Budget</Typography>
            <Typography className={classes.fontBold}>
              {get(appDetails, 'sgip_budget_information.sgip_budget_elligibility') || '--'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography className={classes.dataSubHeader}>Utility Name</Typography>
            <Typography className={classes.fontBold}>{get(appDetails, 'utility_company_name') || '--'}</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography className={classes.dataSubHeader}>Solar Size</Typography>
            <Typography className={classes.fontBold}>
              {get(appDetails, 'solar_size') ? get(appDetails, 'solar_size') + ' kW' : '--'}
            </Typography>
          </Grid>
        </Grid>
        <br />
        <Grid container direction="row">
          <Grid item xs={12} md={4}>
            <Typography className={classes.dataSubHeader}>Existing System Details</Typography>
            {get(appDetails, 'site_info_from_enl.inverters') &&
            !isEqual(get(appDetails, 'site_info_from_enl.inverters'), {})
              ? Object.keys(appDetails.site_info_from_enl.inverters).map(key => {
                  return (
                    <Typography
                      key={key}
                      className={classes.fontBold}
                    >{`Enphase ${key} : ${appDetails.site_info_from_enl.inverters[key]}`}</Typography>
                  );
                })
              : '--'}
          </Grid>
          {get(appDetails.sgip_budget_information, 'sgip_budget_elligibility') === LARGE_SCALE_STORAGE && (
            <Grid item xs={12} md={4}>
              <Typography className={classes.dataSubHeader}>ITC Claimed</Typography>
              <Typography className={classes.fontBold}>
                {get(appDetails.sgip_budget_information, 'q8_federal_tax_credit') ? 'Yes' : 'No'}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} md={4}>
            <Typography className={classes.dataSubHeader}>Application Submitted By</Typography>
            <Typography className={classes.fontBold} style={{ textTransform: 'capitalize' }}>
              {get(appDetails, 'application_submitted_by') ? get(appDetails, 'application_submitted_by') : '--'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography className={classes.dataSubHeader}>SGIP Developer Key</Typography>
            <Typography className={classes.fontBold} style={{ textTransform: 'capitalize' }}>
              {get(appDetails, 'homeowner_code') ? get(appDetails, 'homeowner_code') : '--'}
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} md={4}>
            <Typography className={classes.dataSubHeader}>Got SGIP Rebate previously</Typography>
            <Typography className={classes.fontBold}>
              {get(appDetails, 'battery_rebate_claimed') === null
                ? '--'
                : get(appDetails, 'battery_rebate_claimed') === true
                ? 'Yes'
                : 'No'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography className={classes.dataSubHeader}>Battery PTO</Typography>
            <Typography className={classes.fontBold}>
              {get(appDetails, 'battery_pto') === null ? '--' : get(appDetails, 'battery_pto') === true ? 'Yes' : 'No'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography className={classes.dataSubHeader}>Date of Battery PTO</Typography>
            <Typography className={classes.fontBold}>
              {get(appDetails, 'pto_date') ? moment(get(appDetails, 'pto_date')).format('MM/DD/YYYY') : '--'}
            </Typography>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = state => ({
  currentlySending: state.adminReducer.currentlySending
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ApplicantDetailsView));
