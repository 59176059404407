import {
  SET_MOBILE_VIEW,
  SET_EMAIL_ID,
  SET_SITE_ID,
  SET_ENLIGHTEN_MANAGER_TOKEN,
  SET_ENLIGHTEN_MANAGER_USER_TYPE,
  SET_USER_OPERATING_SYSTEM,
  SET_APPLICATION_ID,
  SET_APPLICATION_STAGE,
  SET_EMAIL,
  SET_NPS_RATINGS,
  SET_STORE_PAYMENT_STATUS
} from './constants';

export function setMobileView(isMobileView) {
  return { type: SET_MOBILE_VIEW, isMobileView };
}

export function setEmailId(emailId) {
  return { type: SET_EMAIL_ID, emailId };
}

export function setSiteIdValue(siteId) {
  return { type: SET_SITE_ID, siteId };
}

export function setStorePaymentStatus(status) {
  return { type: SET_STORE_PAYMENT_STATUS, status };
}

export function setEnlightenManagerToken(enlightenManagerToken) {
  return { type: SET_ENLIGHTEN_MANAGER_TOKEN, enlightenManagerToken };
}

export function setEnlightenManagerUserType(userType) {
  return { type: SET_ENLIGHTEN_MANAGER_USER_TYPE, isInstaller: userType === 'inst' };
}

export function setUserOperatingSystem(operatingSystem) {
  return {
    type: SET_USER_OPERATING_SYSTEM,
    isIOS: operatingSystem.toLowerCase() === 'ios',
    isAndroid: operatingSystem.toLowerCase() === 'android'
  };
}

export function setApplicationId(applicationId) {
  return { type: SET_APPLICATION_ID, applicationId };
}

export function setNpsRatings(ratings) {
  return { type: SET_NPS_RATINGS, ratings };
}

export function setApplicationStage(applicationStage) {
  return { type: SET_APPLICATION_STAGE, applicationStage };
}

export function setEmail(email) {
  return { type: SET_EMAIL, email };
}
