import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import StatusTracking from '../../../components/SgipApp/StatusTracking';
import { Box, Grid, Typography, Button, Divider, IconButton } from '@material-ui/core';
import { Title } from '../../../components/Texts';
import FilledButton from '../../../components/Buttons/FilledButton';
import { forwardTo, applicationState, trackEvents } from '../../../utils/helper';
import uploadFileDetails from '../../../utils/uploadFile';
import { BILLS_TYPE } from '../../../utils/constants';
import { EQUITY_RESILIENCY, SMALL_RESIDENTIAL } from '../constants';
import {
  getSgipDocumentPresignedURL,
  putSgipDocumentToS3,
  deleteSgipDocumentFromS3,
  postSgipApplicationDocuments,
  getSgipApplicationDetailsBySiteId
} from '../actions';
import { FILE_UPLOAD_SIZE, SOUTHERN_CALIFORNIA_GAS_COMPANY } from '../constants';
import { FileAttachementBar } from '../../../components/FileAttachmentBar';
import clsx from 'clsx';
import Loader from '../../../components/Loader';
import ContractDocumentDialog from '../../../components/SgipApp/ContractDocumentDialog';
import IncomeProofDialog from '../../../components/SgipApp/incomeProofDialog';
import GreenButtonFileUpload from '../../../components/SgipApp/GreenButtonFileUpload';
import PumpWellFileUpload from '../../../components/SgipApp/PumpWellFileUpload';
import TaxReturnsFileUpload from '../../../components/SgipApp/TaxReturnsFileUpload';
import HelpOutlineIcon from '../../../components/Header/Icons/HelpOutlineIcon';
import { isEmpty } from 'lodash';
import PrivacyPolicy from '../../../components/SgipApp/PrivacyPolicy';

const styles = theme => ({
  fileCard: {
    backgroundColor: '#5a5a5c',
    paddingTop: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  hideSection: {
    display: 'none'
  },
  divider: {
    marginTop: '25px',
    marginBottom: '20px'
  },
  privacyPolicyIos: {
    color: '#01B4DE',
    fontWeight: 'bold',
    textDecoration: 'none'
  },
  privacyPolicyOther: {
    color: '#01b4de',
    fontWeight: 'bold',
    marginLeft: '5px',
    cursor: 'pointer'
  },
  cardFileNameText: {
    color: '#ffffff',
    flexDirection: 'row',
    textAlign: 'left',
    marginTop: '0px',
    display: 'flex'
  },
  cardFileSizeText: {
    color: '#ffffff'
  },
  cardFileAttachmentIcon: {
    marginRight: theme.spacing(0.5),
    width: 25,
    height: 30,
    color: '#ffffff',
    flexDirection: 'row',
    marginLeft: '5px'
  },
  cardFileCloseIcon: {
    marginRight: theme.spacing(1),
    //width: 20,
    height: 20,
    color: '#ffffff',
    float: 'right',
    flexDirection: 'row',
    width: '20px',
    marginLeft: 'auto',
    cursor: 'pointer'
  },
  chooseFileButton: {
    padding: theme.spacing(0, 0.5, 0, 0.5),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1)
  },
  chooseFile: {
    border: '1px solid #bbb',
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.secondary.main,
    marginTop: theme.spacing(1),
    display: 'flex'
  },
  root: {
    padding: theme.spacing(2.5),
    borderRadius: theme.spacing(1.25),
    boxShadow: '1px 2px 5px 0 rgba(152, 152, 152, 0.1)'
    //backgroundColor: theme.palette.tertiary.main
  },
  header: {
    marginBottom: theme.spacing(1.25)
  },
  description: {
    marginBottom: theme.spacing(2),
    lineHeight: 1.36
  },
  caution: {
    verticalAlign: 'middle',
    marginLeft: '2px',
    marginTop: '3px'
  },
  wrapper: {
    borderRadius: theme.spacing(1.25),
    border: 'dashed 1px #afafaf',
    backgroundColor: 'transparent',
    padding: theme.spacing(2.5),
    marginTop: theme.spacing(2.5)
  },
  document: {
    lineHeight: 1.5
  },
  title: {
    marginBottom: theme.spacing(1.25)
  },
  text: {
    color: '#939598'
  },
  noticeText: {
    fontStyle: 'italic',
    color: '#f37321'
  },
  uploadButton: {
    textTransform: 'capitalize',
    borderRadius: theme.spacing(2.5),
    minWidth: theme.spacing(17.5),
    padding: '3px 16px',
    color: '#01b4de',
    marginTop: theme.spacing(1.5)
  },
  errorText: {
    color: '#f44336',
    marginLeft: theme.spacing(0),
    marginTop: '3px'
  },
  nextbtn: {
    marginTop: '25px',
    height: '40px'
  },
  privacyText: {
    color: '#939598',
    textAlign: 'center',
    margin: '20px'
  },
  gridWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    backgroundColor: '#FFFFFF',
    borderRadius: '10px'
  },
  pHide: {
    display: 'none'
  },
  [theme.breakpoints.up('md')]: {
    root: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly'
    },
    dWrapper: {
      margin: '30px 194px',
      boxShadow: '0 2px 10px 0 rgba(63, 67, 71, 0.08)',
      background: '#fff',
      borderRadius: '3px'
    },
    gridWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      padding: 0,
      justifyContent: 'space-between'
    },
    doucmentItem: {
      border: '1px solid rgba(136, 136, 136, 0.2)',
      flexBasis: '40%',
      margin: '10px',
      borderRadius: '2px',
      padding: '20px'
    },
    dHide: {
      display: 'none'
    },
    pHide: {
      display: 'flex'
    },
    buttonWrapper: {
      flexBasis: '100%'
    },
    nextbtn: {
      width: 'auto',
      margin: '10px auto 0'
    },
    dBlock: {
      display: 'block'
    }
  }
});

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sgipApplication: {},
      activeState: props.isInstaller ? 3 : 2,
      email: '',
      application_id: '',
      utilityDocUploadStatus: 'idle',
      contractDocUploadStatus: 'idle',
      purchaseInvoiceDocUploadStatus: 'idle',
      incomeDocUploadStatus: 'idle',
      utilityDocDeleteStatus: 'idle',
      contractDeleteDocStatus: 'idle',
      purchaseInvoiceDeleteDocStatus: 'idle',
      incomeDocDeleteStatus: 'idle',
      greenDataDocUploadStatus: 'idle',
      greenDataDocDeleteStatus: 'idle',
      taxReturnsUploadStatus: 'idle',
      taxReturnsDocDeleteStatus: 'idle',
      sgipBudgetElligibility: '',
      utilityBillsAllFilesSize: 0,
      wellPumpAllFilesSize: 0,
      contractDocumentFileSize: 0,
      purchaseInvoiceDocumentFileSize: 0,
      utility_bills: [],
      contract_doc: [],
      purchase_invoices_doc: [],
      income_doc: [],
      greenDataDoc: [],
      utilityDocumentError: {
        file_name: {
          error: false,
          msg: ''
        }
      },
      contractDocumentError: {
        file_name: {
          error: false,
          msg: ''
        }
      },
      purchaseInvoiceDocumentError: {
        file_name: {
          error: false,
          msg: ''
        }
      },
      incomeDocumentError: {
        file_name: {
          error: false,
          msg: ''
        }
      },
      greenDataDocumentError: {
        file_name: {
          error: false,
          msg: ''
        }
      },
      utilityBiilFileNames: [],
      site_id: '',
      contractDocumentUploadFlag: false,
      isIncomeProofPopupOpen: false,
      pumpWellDocUploadStatus: 'idle',
      pumpWellDocDeleteStatus: 'idle',
      pumpwell_doc: [],
      taxreturns_doc: [],
      isPumpWellAnswer: '',
      isIncomeQualifierAnswer: false,
      isEquityBudgetAnswer: '',
      pumpWellDocumentError: {
        file_name: {
          error: false,
          msg: ''
        }
      },
      taxReturnsDocumentError: {
        file_name: {
          error: false,
          msg: ''
        }
      }
    };
    this.deleteUploadedFile = this.deleteUploadedFile.bind(this);
    this.handleUtilityFileUpload = this.handleUtilityFileUpload.bind(this);
    this.handleContractDocumentFileUpload = this.handleContractDocumentFileUpload.bind(this);
    this.handleIncomeDocumentFileUpload = this.handleIncomeDocumentFileUpload.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateValues = this.validateValues.bind(this);
    this.handleContractDocumentPopup = this.handleContractDocumentPopup.bind(this);
    this.incomeProofPopup = this.incomeProofPopup.bind(this);
    this.setStateUpdate = this.setStateUpdate.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { siteId } = this.props;
    this.loadApplication(siteId);
  }
  setStateUpdate(obj) {
    this.setState(obj);
  }

  incomeProofPopup() {
    this.setState({ isIncomeProofPopupOpen: !this.state.isIncomeProofPopupOpen });
  }

  loadApplication(siteId) {
    this.props.getSgipApplicationDetailsBySiteId({
      siteId: siteId,
      isInstaller: this.props.isInstaller,
      successCb: () => {
        const { sgip_application, isInstaller } = this.props;
        let budgetElligibility = sgip_application.sgip_budget_information.sgip_budget_elligibility;
        let electricPumpWells =
          sgip_application.sgip_budget_information.q6_rely_on_electric_pump_wells_for_water_supply;
        let qualifyEquityBudget = sgip_application.sgip_budget_information.q1_live_in_low_income_housing_community;
        let taxReturns = sgip_application.sgip_budget_information.q7_income_less_than_80_of_area_median_income;
        let utilityBillsData = [];
        if (sgip_application.application_documents && sgip_application.application_documents.utility_bills) {
          utilityBillsData = sgip_application.application_documents.utility_bills;
        }
        let contractBillsData = [];
        if (sgip_application.application_documents && sgip_application.application_documents.contract_documents) {
          contractBillsData = sgip_application.application_documents.contract_documents;
        }
        let purchaseInvoicesData = [];
        if (sgip_application.application_documents && sgip_application.application_documents.purchase_invoices) {
          purchaseInvoicesData = sgip_application.application_documents.purchase_invoices;
        }

        let incomeBillsData = [];
        if (sgip_application.application_documents && sgip_application.application_documents.income_document) {
          incomeBillsData.push(sgip_application.application_documents.income_document);
        }
        let greenDataBillsData = [];
        if (sgip_application.application_documents && sgip_application.application_documents.green_data_documents) {
          greenDataBillsData.push(sgip_application.application_documents.green_data_documents);
        }

        let pumpwellBillsData = [];
        if (sgip_application.application_documents && sgip_application.application_documents.well_pump_documents) {
          pumpwellBillsData.push(sgip_application.application_documents.well_pump_documents);
        }

        let taxReturnsData = [];
        if (
          sgip_application.application_documents &&
          sgip_application.application_documents.tax_return_documents &&
          sgip_application.application_documents.tax_return_documents !== null
        ) {
          taxReturnsData.push(sgip_application.application_documents.tax_return_documents);
        }

        var utilityUpdatedDataFormat = [];
        if (utilityBillsData) {
          utilityUpdatedDataFormat = utilityBillsData.map(file => {
            let fileExtention = file.original_file_name.split('.').pop();
            file.display_file_name =
              file.original_file_name.length > 20
                ? file.original_file_name.substring(0, 20) + '...' + fileExtention
                : file.original_file_name;
            return file;
          });
        } else {
          utilityUpdatedDataFormat = [];
        }
        var contractUpdatedDataFormat = [];
        if (contractBillsData) {
          contractUpdatedDataFormat = contractBillsData.map(file => {
            let fileExtention = file.original_file_name.split('.').pop();
            file.display_file_name =
              file.original_file_name.length > 20
                ? file.original_file_name.substring(0, 20) + '...' + fileExtention
                : file.original_file_name;

            return file;
          });
        } else {
          contractUpdatedDataFormat = [];
        }

        var incomeUpdatedDataFormat = '';
        if (incomeBillsData.length > 0) {
          incomeUpdatedDataFormat = incomeBillsData.map(file => {
            let fileExtention = file.original_file_name.split('.').pop();
            file.display_file_name =
              file.original_file_name.length > 20
                ? file.original_file_name.substring(0, 20) + '...' + fileExtention
                : file.original_file_name;

            return file;
          });
        } else {
          incomeUpdatedDataFormat = [];
        }

        var greenDataUpdatedFormat = [];
        if (greenDataBillsData.length > 0) {
          if (greenDataBillsData[0].length) {
            greenDataUpdatedFormat = greenDataBillsData[0].map(file => {
              let fileExtention = file.original_file_name.split('.').pop();
              file.display_file_name =
                file.original_file_name.length > 20
                  ? file.original_file_name.substring(0, 20) + '...' + fileExtention
                  : file.original_file_name;

              return file;
            });
          } else {
            greenDataUpdatedFormat = [];
          }
        }
        var pumpwellUpdatedDataFormat = [];

        if (pumpwellBillsData.length > 0) {
          if (pumpwellBillsData[0].length) {
            pumpwellUpdatedDataFormat = pumpwellBillsData[0].map(file => {
              let fileExtention = file.original_file_name.split('.').pop();
              file.display_file_name =
                file.original_file_name.length > 20
                  ? file.original_file_name.substring(0, 20) + '...' + fileExtention
                  : file.original_file_name;

              return file;
            });
          } else {
            pumpwellUpdatedDataFormat = [];
          }
        }

        var taxReturnsUpdatedDataFormat = [];

        if (taxReturnsData.length > 0) {
          if (taxReturnsData[0].length) {
            taxReturnsUpdatedDataFormat = taxReturnsData[0].map(file => {
              let fileExtention = file.original_file_name.split('.').pop();
              file.display_file_name =
                file.original_file_name.length > 20
                  ? file.original_file_name.substring(0, 20) + '...' + fileExtention
                  : file.original_file_name;

              return file;
            });
          } else {
            taxReturnsUpdatedDataFormat = [];
          }
        }

        var purchaseInvoiceUpdatedDataFormat = [];
        if (purchaseInvoicesData) {
          purchaseInvoiceUpdatedDataFormat = purchaseInvoicesData.map(file => {
            let fileExtention = file.original_file_name.split('.').pop();
            file.display_file_name =
              file.original_file_name.length > 20
                ? file.original_file_name.substring(0, 20) + '...' + fileExtention
                : file.original_file_name;

            return file;
          });
        } else {
          purchaseInvoiceUpdatedDataFormat = [];
        }

        const stepCompleted = applicationState(sgip_application.stepCompleted);
        this.setState({
          sgipApplication: sgip_application,
          sgipBudgetElligibility: budgetElligibility,
          utility_bills: utilityUpdatedDataFormat,
          contract_doc: contractUpdatedDataFormat,
          purchase_invoices_doc: purchaseInvoiceUpdatedDataFormat,
          income_doc: incomeUpdatedDataFormat,
          greenDataDoc: greenDataUpdatedFormat,
          activeState: isInstaller ? stepCompleted + 1 : stepCompleted,
          isPumpWellAnswer: electricPumpWells,
          isIncomeQualifierAnswer: taxReturns,
          isEquityBudgetAnswer: qualifyEquityBudget,
          pumpwell_doc: pumpwellUpdatedDataFormat,
          taxreturns_doc: taxReturnsUpdatedDataFormat
        });
      },
      failureCb: () => {}
    });
  }

  componentDidUpdate() {}

  validateValues() {
    let isIncomeDocValid = false;
    let isContractDocValid = false;
    let isUtilityBillsValid = false;
    let isGreenDataBillsValid = false;
    let isValid = false;
    let isPumpWellDocValid = false;
    let isTaxReturnsDocValid = false;
    let isPurchaseInvoiceDocValid = false;

    let iDE = this.state.incomeDocumentError;
    if (
      this.state.sgipBudgetElligibility !== 'Equity Budget' &&
      this.state.isEquityBudgetAnswer &&
      this.state.income_doc.length === 0
    ) {
      iDE.file_name.error = true;
      iDE.file_name.msg = 'Please upload the income proof';
      isIncomeDocValid = false;
    } else {
      iDE.file_name.error = false;
      iDE.file_name.msg = '';
      isIncomeDocValid = true;
    }
    this.setState({ incomeDocumentError: iDE });

    let cDE = this.state.contractDocumentError;
    if (this.state.contract_doc.length === 0 && isEmpty(this.state.sgipApplication.homeowner_code)) {
      cDE.file_name.error = true;
      cDE.file_name.msg = 'Please upload the contract document';
      isContractDocValid = false;
    } else {
      cDE.file_name.error = false;
      cDE.file_name.msg = '';
      isContractDocValid = true;
    }
    this.setState({ contractDocumentError: cDE });

    let uDE = this.state.utilityDocumentError;
    if (this.state.utility_bills.length === 0) {
      uDE.file_name.error = true;
      uDE.file_name.msg = 'Please upload the utility bills';
      isUtilityBillsValid = false;
    } else {
      uDE.file_name.error = false;
      uDE.file_name.msg = '';
      isUtilityBillsValid = true;
    }
    this.setState({ utilityDocumentError: uDE });

    let gDE = this.state.greenDataDocumentError;
    if (
      this.state.greenDataDoc.length === 0 &&
      this.state.sgipApplication.storage_capacity > 24 &&
      this.state.sgipApplication.utility_company_name !== SOUTHERN_CALIFORNIA_GAS_COMPANY &&
      this.state.sgipBudgetElligibility !== SMALL_RESIDENTIAL
    ) {
      gDE.file_name.error = true;
      gDE.file_name.msg = 'Please upload the green button data';
      isGreenDataBillsValid = false;
    } else {
      gDE.file_name.error = false;
      gDE.file_name.msg = '';
      isGreenDataBillsValid = true;
    }
    this.setState({ greenDataDocumentError: gDE });

    let pDE = this.state.pumpWellDocumentError;
    if (
      this.state.sgipApplication.sgip_budget_information.q6_rely_on_electric_pump_wells_for_water_supply &&
      this.state.pumpwell_doc.length === 0 &&
      this.state.sgipBudgetElligibility === EQUITY_RESILIENCY
    ) {
      pDE.file_name.error = true;
      pDE.file_name.msg = 'Please upload the electric well pump photos';
      isPumpWellDocValid = false;
    } else {
      pDE.file_name.error = false;
      pDE.file_name.msg = '';
      isPumpWellDocValid = true;
    }
    this.setState({ pumpWellDocumentError: pDE });

    let tDE = this.state.taxReturnsDocumentError;
    if (
      this.state.sgipApplication.sgip_budget_information.q7_income_less_than_80_of_area_median_income &&
      this.state.taxreturns_doc.length === 0
    ) {
      tDE.file_name.error = true;
      tDE.file_name.msg = 'Please upload the tax return';
      isTaxReturnsDocValid = false;
    } else {
      tDE.file_name.error = false;
      tDE.file_name.msg = '';
      isTaxReturnsDocValid = true;
    }
    this.setState({ taxReturnsDocumentError: tDE });

    let pIDE = this.state.purchaseInvoiceDocumentError;
    if (!isEmpty(this.state.sgipApplication.homeowner_code) && this.state.purchase_invoices_doc.length === 0) {
      pIDE.file_name.error = true;
      pIDE.file_name.msg = 'Please upload the purchase invoice';
      isPurchaseInvoiceDocValid = false;
    } else {
      pIDE.file_name.error = false;
      pIDE.file_name.msg = '';
      isPurchaseInvoiceDocValid = true;
    }
    this.setState({ taxReturnsDocumentError: tDE });

    if (
      !isIncomeDocValid ||
      !isContractDocValid ||
      !isUtilityBillsValid ||
      !isGreenDataBillsValid ||
      !isPumpWellDocValid ||
      !isTaxReturnsDocValid ||
      !isPurchaseInvoiceDocValid
    ) {
      isValid = false;
    } else {
      isValid = true;
    }
    return isValid;
  }

  handleSubmit() {
    const { siteId } = this.props;
    if (this.validateValues()) {
      let application_documents = {
        email: this.state.email,
        utility_bills: this.state.utility_bills,
        contract_documents: this.state.contract_doc,
        income_document: this.state.income_doc[0],
        //waiting for backend
        green_data_documents: this.state.greenDataDoc,
        sgip_budget_elligibility: this.state.sgipBudgetElligibility,
        site_id: siteId,
        well_pump_documents: this.state.pumpwell_doc,
        tax_return_documents: this.state.taxreturns_doc,
        purchase_invoices: this.state.purchase_invoices_doc
      };
      this.props.postSgipApplicationDocuments({
        application_documents: application_documents,
        successCb: () => {
          trackEvents('triggerSgipPageView', { pageTitle: 'Pay and Submit' });
          forwardTo('serviceCharge');
        }
      });
    }
  }

  setDocumentError(documentError, obj, cb) {
    documentError.file_name.error = obj.error;
    documentError.file_name.msg = obj.msg;
    cb(documentError);
  }

  handleUtilityFileUpload(event) {
    let documentError = this.state.utilityDocumentError;
    this.setDocumentError(documentError, { error: false, msg: '' }, errorObj => {
      this.setState({ utilityDocumentError: errorObj });
    });

    let utility_bills = this.state.utility_bills;
    if (!document.getElementById('utilityBill')) {
      return;
    }

    const setStatus = status => {
      this.setState({ utilityDocUploadStatus: status });
    };

    const setFilesSize = uTotalFizeSize => {
      this.setState({ utilityBillsAllFilesSize: uTotalFizeSize });
    };

    const details = {
      event,
      setStatus,
      setFilesSize,
      documentType: BILLS_TYPE.UTILITY_BILLS,
      isIOS: this.props.isIOS,
      billsFileSize: this.state.utilityBillsAllFilesSize,
      app_id: this.props.sgip_application.application_id,
      bills: utility_bills,
      fileUploadSize: FILE_UPLOAD_SIZE,
      getSgipDocumentPresignedURL: this.props.getSgipDocumentPresignedURL,
      putSgipDocumentToS3: this.props.putSgipDocumentToS3,
      setDocumentError: (errorDetails, status) => {
        let documentError = this.state.utilityDocumentError;
        this.setDocumentError(documentError, errorDetails, errorObj => {
          this.setState({ utilityDocumentError: errorObj });
          status && setStatus(status);
        });
      }
    };

    uploadFileDetails(details, bills => {
      this.setState({ utility_bills: bills });
      if (document.getElementById('utilityBill')) {
        document.getElementById('utilityBill').value = '';
        return;
      }
    });
  }

  handlePurchaseInvoiceFileUpload(event) {
    let documentError = this.state.purchaseInvoiceDocumentError;
    this.setDocumentError(documentError, { error: false, msg: '' }, errorObj => {
      this.setState({ purchaseInvoiceDocumentError: errorObj });
    });

    let purchase_invoices_doc = this.state.purchase_invoices_doc;
    if (!document.getElementById('purchaseInvoice')) {
      return;
    }

    const setStatus = status => {
      this.setState({ purchaseInvoiceDocUploadStatus: status });
    };

    const setFilesSize = pTotalFizeSize => {
      this.setState({ purchaseInvoiceDocumentFileSize: pTotalFizeSize });
    };

    const details = {
      event,
      setStatus,
      setFilesSize,
      documentType: BILLS_TYPE.PURCHASE_INVOICE,
      isIOS: this.props.isIOS,
      billsFileSize: this.state.purchaseInvoiceDocumentFileSize,
      app_id: this.props.sgip_application.application_id,
      bills: purchase_invoices_doc,
      fileUploadSize: FILE_UPLOAD_SIZE,
      getSgipDocumentPresignedURL: this.props.getSgipDocumentPresignedURL,
      putSgipDocumentToS3: this.props.putSgipDocumentToS3,
      setDocumentError: (errorDetails, status) => {
        let documentError = this.state.purchaseInvoiceDocumentError;
        this.setDocumentError(documentError, errorDetails, errorObj => {
          this.setState({ purchaseInvoiceDocumentError: errorObj });
          status && setStatus(status);
        });
      }
    };

    uploadFileDetails(details, bills => {
      this.setState({ purchase_invoices_doc: bills });
      if (document.getElementById('purchaseInvoice')) {
        document.getElementById('purchaseInvoice').value = '';
        return;
      }
    });
  }

  handleContractDocumentFileUpload(event) {
    this.setState({ contractDocumentUploadFlag: !this.state.contractDocumentUploadFlag });
    //Clear error message
    let documentError = this.state.contractDocumentError;
    this.setDocumentError(documentError, { error: false, msg: '' }, errorObj => {
      this.setState({ contractDocumentError: errorObj });
    });

    let contract_doc = this.state.contract_doc;
    if (!document.getElementById('contractDocument')) {
      return;
    }

    const setStatus = status => {
      this.setState({ contractDocUploadStatus: status });
    };

    const setFilesSize = cTotalFizeSize => {
      this.setState({ contractDocumentFileSize: cTotalFizeSize });
    };

    const details = {
      event,
      setStatus,
      setFilesSize,
      documentType: BILLS_TYPE.CONTRACT_DOCUMENTS,
      isIOS: this.props.isIOS,
      app_id: this.props.sgip_application.application_id,
      bills: contract_doc,
      billsFileSize: this.state.contractDocumentFileSize,
      fileUploadSize: FILE_UPLOAD_SIZE,
      getSgipDocumentPresignedURL: this.props.getSgipDocumentPresignedURL,
      putSgipDocumentToS3: this.props.putSgipDocumentToS3,
      setDocumentError: (errorDetails, status) => {
        let documentError = this.state.contractDocumentError;
        this.setDocumentError(documentError, errorDetails, errorObj => {
          this.setState({ contractDocumentError: errorObj });
          status && setStatus(status);
        });
      }
    };

    uploadFileDetails(details, bills => {
      this.setState({ contract_doc: bills });
      if (document.getElementById('contractDocument')) {
        document.getElementById('contractDocument').value = '';
        return;
      }
    });
  }

  handleIncomeDocumentFileUpload(event) {
    //Clear error message
    let documentError = this.state.incomeDocumentError;
    this.setDocumentError(documentError, { error: false, msg: '' }, errorObj => {
      this.setState({ incomeDocumentError: errorObj });
    });

    let income_doc = this.state.income_doc;
    if (!document.getElementById('incomeDocument')) {
      return;
    }

    const setStatus = status => {
      this.setState({ incomeDocUploadStatus: status });
    };

    const details = {
      event,
      setStatus,
      documentType: BILLS_TYPE.INCOME_DOCUMENTS,
      isIOS: this.props.isIOS,
      app_id: this.props.sgip_application.application_id,
      bills: income_doc,
      fileUploadSize: FILE_UPLOAD_SIZE,
      getSgipDocumentPresignedURL: this.props.getSgipDocumentPresignedURL,
      putSgipDocumentToS3: this.props.putSgipDocumentToS3,
      setDocumentError: (errorDetails, status) => {
        let documentError = this.state.incomeDocumentError;
        this.setDocumentError(documentError, errorDetails, errorObj => {
          this.setState({ incomeDocumentError: errorObj });
          status && setStatus(status);
        });
      }
    };

    uploadFileDetails(details, bills => {
      this.setState({ income_doc: bills });
      if (document.getElementById('incomeDocument')) {
        document.getElementById('incomeDocument').value = '';
        return;
      }
    });
  }

  deleteUploadedFile(display_file_name, file_name, file_type, file_size) {
    window.scrollTo(0, 0);
    if (file_type === 'utility') {
      //Clear error message
      let documentError = this.state.utilityDocumentError;
      documentError.file_name.error = false;
      documentError.file_name.msg = '';
      this.setState({ utilityDocumentError: documentError });
    }
    if (file_type === 'pumpwell') {
      let documentError = this.state.pumpWellDocumentError;
      documentError.file_name.error = false;
      documentError.file_name.msg = '';
      this.setState({ pumpWellDocumentError: documentError });
    }
    this.props.deleteSgipDocumentFromS3({
      file_name: file_name,
      successCb1: () => {
        if (file_type === 'income') {
          this.state.income_doc.length = 0;
          Object.assign(this.state, { imageDoc: '' });
          this.setState({ incomeDocDeleteStatus: 'success' });
          if (document.getElementById('contractDocument')) {
            document.getElementById('contractDocument').value = '';
            return;
          }
        } else if (file_type === 'pumpwell') {
          this.setState({ pumpWellDocDeleteStatus: 'success' });
          //Substract the file size from total utility file size
          let pTotalFizeSize = parseFloat(this.state.wellPumpAllFilesSize);
          let pFileSize = parseFloat(file_size);
          if (pTotalFizeSize >= pFileSize) {
            pTotalFizeSize = pTotalFizeSize - parseFloat(file_size);
            this.setState({ wellPumpAllFilesSize: pTotalFizeSize });
          }
          //Remove the selected file from array
          let pumpWellBiills = this.state.pumpwell_doc.filter(pFile => pFile.display_file_name !== display_file_name);
          this.setState({ pumpwell_doc: pumpWellBiills });
          Object.assign(this.state, { pumpwell_doc: pumpWellBiills });

          if (document.getElementById('pumpWellDocument')) {
            document.getElementById('pumpWellDocument').value = '';
            return;
          }
        } else if (file_type === 'contract') {
          let cTotalFizeSize = parseFloat(this.state.contractDocumentFileSize);
          let cFileSize = parseFloat(file_size);
          if (cTotalFizeSize >= cFileSize) {
            cTotalFizeSize = cTotalFizeSize - parseFloat(file_size);
            this.setState({ contractDocumentFileSize: cTotalFizeSize });
          }
          //Remove the selected file from array
          let contractDocuments = this.state.contract_doc.filter(
            uFile => uFile.display_file_name !== display_file_name
          );
          this.setState({ contract_doc: contractDocuments });
          Object.assign(this.state, { contract_doc: contractDocuments });

          if (document.getElementById('contractDocument')) {
            document.getElementById('contractDocument').value = '';
            return;
          }
        } else if (file_type === 'utility') {
          this.setState({ utilityDocDeleteStatus: 'success' });
          //Substract the file size from total utility file size
          let uTotalFizeSize = parseFloat(this.state.utilityBillsAllFilesSize);
          let uFileSize = parseFloat(file_size);
          if (uTotalFizeSize >= uFileSize) {
            uTotalFizeSize = uTotalFizeSize - parseFloat(file_size);
            this.setState({ utilityBillsAllFilesSize: uTotalFizeSize });
          }
          //Remove the selected file from array
          let utilityBiills = this.state.utility_bills.filter(uFile => uFile.display_file_name !== display_file_name);
          this.setState({ utility_bills: utilityBiills });
          Object.assign(this.state, { utility_bills: utilityBiills });

          if (document.getElementById('utilityBill')) {
            document.getElementById('utilityBill').value = '';
            return;
          }
        } else if (file_type === 'greenData') {
          let greenDocBills = this.state.greenDataDoc.filter(uFile => uFile.display_file_name !== display_file_name);
          this.setState({ greenDataDoc: greenDocBills });
          Object.assign(this.state, { greenDataDoc: greenDocBills });
          this.setState({ greenDataDocDeleteStatus: 'success' });
          if (document.getElementById('greenDataDocument')) {
            document.getElementById('greenDataDocument').value = '';
            return;
          }
        } else if (file_type === 'purchaseInvoiceDoc') {
          this.setState({ purchaseInvoiceDeleteDocStatus: 'success' });
          //Substract the file size from total purchas Invoice file size
          let pTotalFizeSize = parseFloat(this.state.purchaseInvoiceDocumentFileSize);
          let pFileSize = parseFloat(file_size);
          if (pTotalFizeSize >= pFileSize) {
            pTotalFizeSize = pTotalFizeSize - parseFloat(file_size);
            this.setState({ purchaseInvoiceDocumentFileSize: pTotalFizeSize });
          }
          //Remove the selected file from array
          let purchaseInvoiceBills = this.state.purchase_invoices_doc.filter(
            pFile => pFile.display_file_name !== display_file_name
          );
          this.setState({ purchase_invoices_doc: purchaseInvoiceBills });
          Object.assign(this.state, { purchase_invoices_doc: purchaseInvoiceBills });

          if (document.getElementById('purchaseInvoice')) {
            document.getElementById('purchaseInvoice').value = '';
            return;
          }
        } else if (file_type === 'taxReturns') {
          let taxReturnsDoc = this.state.taxreturns_doc.filter(uFile => uFile.display_file_name !== display_file_name);
          this.setState({ taxreturns_doc: taxReturnsDoc });
          Object.assign(this.state, { taxreturns_doc: taxReturnsDoc });
          this.setState({ taxReturnsDocDeleteStatus: 'success' });
          if (document.getElementById('taxReturnsDocument')) {
            document.getElementById('taxReturnsDocument').value = '';
            return;
          }
        }
      },
      failureCb1: () => {
        let documentError = this.state.incomeDocumentError;
        documentError.file_name.error = true;
        documentError.file_name.msg = 'We are facing some issue with file delete, you can try after sometime.';
        if (file_type === 'income') {
          this.setState({ incomeDocumentError: documentError, incomeDocDeleteStatus: 'idle' });
        } else if (file_type === 'contract') {
          this.setState({ contractDocDeleteStatus: 'idle' });
        } else if (file_type === 'utility') {
          this.setState({ utilityDocDeleteStatus: 'idle' });
        } else if (file_type === 'greenData') {
          this.setState({ greenDataDocDeleteStatus: 'idle' });
        } else if (file_type === 'taxReturns') {
          this.setState({ taxReturnsDocDeleteStatus: 'idle' });
        } else if (file_type === 'purchaseInvoiceDoc') {
          this.setState({ purchaseInvoiceDeleteDocStatus: 'idle' });
        }
      }
    });
  }

  handleContractDocumentPopup() {
    this.setState({ contractDocumentUploadFlag: !this.state.contractDocumentUploadFlag });
  }

  render() {
    const { classes, currentlySending, isInstaller, isIOS } = this.props;
    const { isIncomeProofPopupOpen } = this.state;
    const {
      utilityDocUploadStatus,
      contractDocUploadStatus,
      incomeDocUploadStatus,
      greenDataDocUploadStatus,
      taxReturnsUploadStatus,
      contractDocumentUploadFlag,
      pumpWellDocUploadStatus,
      purchaseInvoiceDocUploadStatus
    } = this.state;

    return (
      <div className={classes.dWrapper}>
        <StatusTracking
          activeState={this.state.activeState}
          pageNumber={isInstaller ? '4' : '3'}
          isInstaller={isInstaller}
        />
        {utilityDocUploadStatus === 'processing' && <Loader loading={true} />}
        {contractDocUploadStatus === 'processing' && <Loader loading={true} />}
        {incomeDocUploadStatus === 'processing' && <Loader loading={true} />}
        {greenDataDocUploadStatus === 'processing' && <Loader loading={true} />}
        {pumpWellDocUploadStatus === 'processing' && <Loader loading={true} />}
        {taxReturnsUploadStatus === 'processing' && <Loader loading={true} />}
        {purchaseInvoiceDocUploadStatus === 'processing' && <Loader loading={true} />}
        {currentlySending && <Loader loading={currentlySending} />}
        {contractDocumentUploadFlag && (
          <ContractDocumentDialog
            handleClose={this.handleContractDocumentPopup}
            handleSelectFile={this.handleContractDocumentFileUpload}
            isInstaller={isInstaller}
          />
        )}
        <div className={classes.root}>
          {isIncomeProofPopupOpen && <IncomeProofDialog incomeProofPopup={this.incomeProofPopup.bind(this)} />}
          <Grid className={classes.gridWrapper}>
            <div className={classes.doucmentItem}>
              <Title className={clsx(classes.header, classes.dHide)}>Upload Documents</Title>
              <Title className={classes.title}>Latest Utility Bill</Title>

              <Typography component="div" variant="caption" className={classes.text}>
                Upload one full utility bill from the last 3 months in the PDF/PNG/JPEG formats (size up to 20MB).
                <br />
                {!isInstaller ? 'Your' : 'System Owner’s'} First Name, Last Name and the Battery Installation Site
                Address should match the utility bill.
              </Typography>
              {
                (this.uDoc = this.state.utility_bills.map((uDoc, k) => (
                  <FileAttachementBar
                    classes={classes}
                    key={uDoc.display_file_name + k}
                    id={uDoc.display_file_name}
                    fileType="utility"
                    display_file_name={uDoc.display_file_name}
                    fileName={uDoc.file_name}
                    fileSize={uDoc.file_size}
                    onClose={this.deleteUploadedFile}
                  />
                )))
              }
              {this.state.utilityDocumentError.file_name.msg !== '' ? (
                <div>
                  <Typography className={classes.errorText}>{this.state.utilityDocumentError.file_name.msg}</Typography>
                </div>
              ) : (
                <div />
              )}
              <Button
                className={clsx(classes.chooseFileButton, classes.button, classes.uploadButton)}
                variant="outlined"
                color="secondary"
                disableElevation
                component="label"
                disabled={this.state.utility_bills.length > 5}
              >
                Choose File
                <input
                  type="file"
                  className={classes.hideSection}
                  id="utilityBill"
                  name="utilityBill"
                  multiple
                  //accept="application/pdf,image/*"
                  onChange={e => this.handleUtilityFileUpload(e)}
                />
              </Button>
              <Divider className={clsx(classes.dHide, classes.divider)} />
            </div>
            {isEmpty(this.state.sgipApplication.homeowner_code) && (
              <div className={classes.doucmentItem}>
                <Title className={classes.title}>Contract Documents</Title>
                <Typography component="div" variant="caption" className={classes.text}>
                  {!isInstaller ? (
                    'Upload the contract documents received from your installer in PDF/PNG/JPEG format (size upto 20MB).'
                  ) : (
                    'Upload the contract documents in PDF/PNG/JPEG format (size upto 20MB).'
                  )}
                </Typography>
                {
                  (this.cDoc = this.state.contract_doc.map((cDoc, k) => (
                    <FileAttachementBar
                      classes={classes}
                      key={cDoc.display_file_name + k}
                      id={cDoc.display_file_name}
                      fileType="contract"
                      display_file_name={cDoc.display_file_name}
                      fileName={cDoc.file_name}
                      fileSize={cDoc.file_size}
                      onClose={this.deleteUploadedFile}
                    />
                  )))
                }
                {this.state.contractDocumentError.file_name.msg !== '' ? (
                  <div>
                    <Typography className={classes.errorText}>
                      {this.state.contractDocumentError.file_name.msg}
                    </Typography>
                  </div>
                ) : (
                  <div />
                )}
                <Button
                  className={clsx(classes.chooseFileButton, classes.button, classes.uploadButton)}
                  variant="outlined"
                  color="secondary"
                  disableElevation
                  id="contractDocumentButton"
                  disabled={this.state.contract_doc.length > 5}
                  component="label"
                  onClick={this.handleContractDocumentPopup}
                >
                  Choose File
                </Button>
                <Divider className={clsx(classes.dHide, classes.divider)} />
              </div>
            )}

            {!isEmpty(this.state.sgipApplication.homeowner_code) && (
              <div className={classes.doucmentItem}>
                <Title className={classes.title}>Purchase Invoice/Receipt</Title>
                <Typography component="div" variant="caption" className={classes.text}>
                  {'Upload the Purchase Invoice/ Receipt of the equipments in PDF/PNG/JPEG format (size upto 20MB).'}
                </Typography>
                {
                  (this.pDoc = this.state.purchase_invoices_doc.map((pDoc, k) => (
                    <FileAttachementBar
                      classes={classes}
                      key={pDoc.display_file_name + k}
                      id={pDoc.display_file_name}
                      fileType="purchaseInvoiceDoc"
                      display_file_name={pDoc.display_file_name}
                      fileName={pDoc.file_name}
                      fileSize={pDoc.file_size}
                      onClose={this.deleteUploadedFile}
                    />
                  )))
                }
                {this.state.purchaseInvoiceDocumentError.file_name.msg !== '' ? (
                  <div>
                    <Typography className={classes.errorText}>
                      {this.state.purchaseInvoiceDocumentError.file_name.msg}
                    </Typography>
                  </div>
                ) : (
                  <div />
                )}
                <Button
                  className={clsx(classes.chooseFileButton, classes.button, classes.uploadButton)}
                  variant="outlined"
                  color="secondary"
                  disableElevation
                  id="purchaseInvoiceButton"
                  disabled={this.state.purchase_invoices_doc.length > 5}
                  component="label"
                >
                  <input
                    type="file"
                    className={classes.hideSection}
                    id="purchaseInvoice"
                    name="purchaseInvoice"
                    multiple
                    //accept="application/pdf,image/*"
                    onChange={e => this.handlePurchaseInvoiceFileUpload(e)}
                  />
                  Choose File
                </Button>
                <Divider className={clsx(classes.dHide, classes.divider)} />
              </div>
            )}

            {this.state.sgipBudgetElligibility !== 'Equity Budget' && this.state.isEquityBudgetAnswer ? (
              <React.Fragment>
                <div className={clsx(classes.doucmentItem, classes.dBlock)}>
                  <Fragment>
                    <Title className={classes.title}>
                      Income Proof
                      <IconButton
                        aria-label="caution"
                        size="small"
                        edge="end"
                        className={classes.caution}
                        onClick={() => this.incomeProofPopup()}
                        disableRipple={true}
                      >
                        <HelpOutlineIcon width="16" height="16" />
                      </IconButton>
                    </Title>

                    <Typography component="div" variant="caption" className={classes.text}>
                      {!isInstaller ? (
                        'Upload your income proof in PDF/PNG/JPEG format (size upto 20MB).'
                      ) : (
                        'Upload the System Owner’s income proof documents in PDF/PNG/JPEG format (size upto 20MB).'
                      )}
                    </Typography>
                    {
                      (this.iDoc = this.state.income_doc.map((iDoc, k) => (
                        <FileAttachementBar
                          classes={classes}
                          key={iDoc.display_file_name + k}
                          id={iDoc.display_file_name}
                          fileType="income"
                          display_file_name={iDoc.display_file_name}
                          fileName={iDoc.file_name}
                          fileSize={iDoc.file_size}
                          onClose={this.deleteUploadedFile}
                        />
                      )))
                    }
                    {this.state.incomeDocumentError.file_name.msg !== '' ? (
                      <div>
                        <Typography className={classes.errorText}>
                          {this.state.incomeDocumentError.file_name.msg}
                        </Typography>
                      </div>
                    ) : (
                      <div />
                    )}
                    <Button
                      className={clsx(classes.chooseFileButton, classes.button, classes.uploadButton)}
                      variant="outlined"
                      color="secondary"
                      disableElevation
                      disabled={this.state.income_doc.length > 0}
                      component="label"
                    >
                      Choose File
                      <input
                        type="file"
                        className={classes.hideSection}
                        id="incomeDocument"
                        name="incomeDocument"
                        //accept="application/pdf,image/*"
                        onChange={this.handleIncomeDocumentFileUpload}
                      />
                    </Button>
                    <Divider className={clsx(classes.dHide, classes.divider)} />
                  </Fragment>
                </div>
                <div className={classes.dBlock} />
              </React.Fragment>
            ) : (
              ''
            )}

            <GreenButtonFileUpload
              storageCapacity={this.state.sgipApplication.storage_capacity}
              utilityCompanyName={this.state.sgipApplication.utility_company_name}
              optedBudgetCategory={this.state.sgipBudgetElligibility}
              isIOS={isIOS}
              greenDataDoc={this.state.greenDataDoc}
              deleteUploadedFile={this.deleteUploadedFile}
              greenDataDocumentErrorFileNameMessage={this.state.greenDataDocumentError.file_name.msg}
              //  handleGreenButtonFileUpload={this.handleGreenButtonFileUpload}
              setState={this.setStateUpdate}
              greenDataDocumentError={this.state.greenDataDocumentError}
              greenDataDocUploadStatus={this.state.greenDataDocUploadStatus}
              applicationId={this.props.sgip_application.application_id}
              getSgipDocumentPresignedURL={this.props.getSgipDocumentPresignedURL}
              putSgipDocumentToS3={this.props.putSgipDocumentToS3}
            />

            <PumpWellFileUpload
              isPumpWellAnswer={this.state.isPumpWellAnswer}
              sgipBudgetElligibility={this.state.sgipBudgetElligibility}
              pumpwell_doc={this.state.pumpwell_doc}
              deleteUploadedFile={this.deleteUploadedFile}
              pumpWellDocumentErrorFileNameMessage={this.state.pumpWellDocumentError.file_name.msg}
              setState={this.setStateUpdate}
              pumpWellDocumentError={this.state.pumpWellDocumentError}
              greenDataDocUploadStatus={this.state.greenDataDocUploadStatus}
              applicationId={this.props.sgip_application.application_id}
              getSgipDocumentPresignedURL={this.props.getSgipDocumentPresignedURL}
              putSgipDocumentToS3={this.props.putSgipDocumentToS3}
              wellPumpAllFilesSize={this.state.wellPumpAllFilesSize}
            />

            <TaxReturnsFileUpload
              isIncomeQualifierAnswer={this.state.isIncomeQualifierAnswer}
              taxreturns_doc={this.state.taxreturns_doc}
              deleteUploadedFile={this.deleteUploadedFile}
              taxReturnsDocumentErrorFileNameMessage={this.state.taxReturnsDocumentError.file_name.msg}
              setState={this.setStateUpdate}
              taxReturnsDocumentError={this.state.taxReturnsDocumentError}
              taxReturnsUploadStatus={this.state.taxReturnsUploadStatus}
              applicationId={this.props.sgip_application.application_id}
              getSgipDocumentPresignedURL={this.props.getSgipDocumentPresignedURL}
              putSgipDocumentToS3={this.props.putSgipDocumentToS3}
            />

            <Box mx="auto" className={classes.dHide}>
              <FilledButton className={classes.nextbtn} onClick={e => this.handleSubmit()}>
                Next
              </FilledButton>
            </Box>
          </Grid>
          <Box mx="auto" className={clsx(classes.pHide, classes.buttonWrapper)}>
            <FilledButton className={classes.nextbtn} onClick={e => this.handleSubmit()}>
              Next
            </FilledButton>
          </Box>
          <PrivacyPolicy />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getSgipDocumentPresignedURL: payload => dispatch(getSgipDocumentPresignedURL(payload)),
  putSgipDocumentToS3: payload => dispatch(putSgipDocumentToS3(payload)),
  deleteSgipDocumentFromS3: payload => dispatch(deleteSgipDocumentFromS3(payload)),
  postSgipApplicationDocuments: payload => dispatch(postSgipApplicationDocuments(payload)),
  getSgipApplicationDetailsBySiteId: payload => dispatch(getSgipApplicationDetailsBySiteId(payload))
});

const mapStateToProps = state => ({
  preSignedS3Url: state.sgipAppReducer.getSgipDocumentPresignedURL,
  siteId: state.appReducer.siteId,
  sgip_application: state.sgipAppReducer.sgip_application,
  currentlySending: state.sgipAppReducer.currentlySending,
  isInstaller: state.appReducer.isInstaller,
  isIOS: state.appReducer.isIOS
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(index));
