import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import {
  Typography,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Chip,
  DialogActions,
  Divider
} from '@material-ui/core';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import DescriptionIcon from '@material-ui/icons/Description';
import get from 'lodash/get';
import moment from 'moment';
import {
  getPresingedURL,
  getFileFromS3,
  uploadToS3,
  uploadSgipDocument,
  downloadAllDocs
} from '../../../containers/Admin/actions';
import Snackbar from '../../SnakBar';
import { isEmpty, isEqual } from '../../../utils/lodash';
import { FILE_UPLOAD_SIZE_LIMIT } from '../../../containers/Admin/constants';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.dark
  },
  fontBold: {
    fontWeight: 'bold'
  },
  dataHeader: {
    color: '#246AB0'
  },
  panelHead: {
    background: '#f1f1f1',
    color: theme.palette.primary.mainText,
    minHeight: '40px !important',
    height: '40px',
    cursor: 'unset !important'
  },
  panelHeadContent: {
    display: 'flex',
    alignItems: 'center'
  },
  panelDetails: {
    flexDirection: 'column'
  },
  heading: {
    fontWeight: 'bold',
    flexBasis: '90%',
    flexShrink: 0
  },
  headingInfo: {
    color: 'darkgray !important'
  },
  dataSubHeader: {
    fontSize: '16px',
    color: 'grey'
  },
  listStyle: {
    width: '100%',
    maxHeight: '500px',
    overflow: 'auto'
  },
  uploadButton: {
    width: '100%',
    backgroundColor: '#00000061',
    color: '#ffffff',
    textTransform: 'none',
    marginTop: theme.spacing(1)
  },
  divider: {
    marginTop: theme.spacing(1)
  },
  expansionPanel: {
    flexDirection: 'column',
    minHeight: '518px',
    maxHeight: '518px'
  },
  dateWrapper: {
    display: 'flex',
    marginTop: '24px'
  },
  uploadErrorStyle: {
    color: 'red'
  },
  chipStyle: {
    fontSize: '11px',
    color: 'grey'
  },
  fileButton: {
    textTransform: 'none'
  }
});

export class ApplicationDocuments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appDocs: [],
      appDocsLoading: false,
      downLoadError: false,
      downLoadErrorMsgs: [],
      fileDownloadInProgress: false,
      showUploadSgipDoc: false,
      fileUploadStatus: 'idle',
      uploadingFileName: '',
      uploadError: false,
      uploadErrorMsg: '',
      uploadingFileDetails: {}
    };
  }

  getApplicationDocuments(appDetails) {
    this.setState({ appDocsLoading: true });
    let docs = [];
    if (get(appDetails, 'application_documents.utility_bills')) {
      let uBills = get(appDetails, 'application_documents.utility_bills');
      let fNames = [];
      for (let i = 0; i < uBills.length; i++) {
        const bill = uBills[i];
        fNames.push(bill.file_name);
      }
      docs.push({
        name: 'Utility Bills',
        sub: uBills.length + ' file(s)',
        file_names: fNames,
        downloadInProgress: false,
        id: 1
      });
    }
    if (get(appDetails, 'application_documents.well_pump_documents')) {
      let wDocs = get(appDetails, 'application_documents.well_pump_documents');
      if (wDocs.length > 0) {
        let fNames = [];
        for (let i = 0; i < wDocs.length; i++) {
          const wDoc = wDocs[i];
          fNames.push(wDoc.file_name);
        }
        docs.push({
          name: 'Well Pump Documents',
          sub: wDocs.length + ' file(s)',
          file_names: fNames,
          downloadInProgress: false,
          id: 2
        });
      }
    }
    if (get(appDetails, 'application_documents.contract_documents')) {
      let cDocs = get(appDetails, 'application_documents.contract_documents');
      if (cDocs.length > 0) {
        let fNames = [];
        for (let i = 0; i < cDocs.length; i++) {
          const cDoc = cDocs[i];
          fNames.push(cDoc.file_name);
        }
        docs.push({
          name: 'Contract Document',
          sub: cDocs.length + ' file(s)',
          file_names: fNames,
          downloadInProgress: false,
          id: 3
        });
      }
    }
    if (get(appDetails, 'application_documents.income_document')) {
      let iDoc = get(appDetails, 'application_documents.income_document');
      let rOn = '';
      if (iDoc.uploaded_date) {
        rOn = 'Received on ' + moment(iDoc.uploaded_date).format('MMM Do YYYY');
      }
      docs.push({
        name: 'Income Document',
        sub: rOn,
        file_names: [iDoc.file_name],
        downloadInProgress: false,
        id: 4
      });
    }
    if (get(appDetails, 'application_documents.doctor_note_document')) {
      let dnDoc = get(appDetails, 'application_documents.doctor_note_document');
      let rOn = '';
      if (dnDoc.uploaded_date) {
        rOn = 'Received on ' + moment(dnDoc.uploaded_date).format('MMM Do YYYY');
      }
      docs.push({
        name: 'Doctor Note Document',
        sub: rOn,
        file_names: [dnDoc.file_name],
        downloadInProgress: false,
        id: 5
      });
    }
    if (get(appDetails, 'application_documents.attestation_form_document')) {
      let afDoc = get(appDetails, 'application_documents.attestation_form_document');
      let rOn = '';
      if (afDoc.uploaded_date) {
        rOn = 'Received on ' + moment(afDoc.uploaded_date).format('MMM Do YYYY');
      }
      docs.push({
        name: 'Attestation Form Document',
        sub: rOn,
        file_names: [afDoc.file_name],
        downloadInProgress: false,
        id: 6
      });
    }
    if (get(appDetails, 'application_documents.baseline_customer_document')) {
      let bcDoc = get(appDetails, 'application_documents.baseline_customer_document');
      let rOn = '';
      if (bcDoc.uploaded_date) {
        rOn = 'Received on ' + moment(bcDoc.uploaded_date).format('MMM Do YYYY');
      }
      docs.push({
        name: 'Baseline Customer Document',
        sub: rOn,
        file_names: [bcDoc.file_name],
        downloadInProgress: false,
        id: 7
      });
    }
    if (get(appDetails, 'application_documents.green_data_documents')) {
      let gdDocs = get(appDetails, 'application_documents.green_data_documents');
      if (gdDocs.length > 0) {
        let fNames = [];
        for (let i = 0; i < gdDocs.length; i++) {
          const gDoc = gdDocs[i];
          fNames.push(gDoc.file_name);
        }
        docs.push({
          name: 'Green Data Documents',
          sub: gdDocs.length + ' file(s)',
          file_names: fNames,
          downloadInProgress: false,
          id: 8
        });
      }
    }
    if (get(appDetails, 'application_documents.tax_return_documents')) {
      let tdDocs = get(appDetails, 'application_documents.tax_return_documents');
      if (tdDocs.length > 0) {
        let fNames = [];
        fNames = tdDocs.map(tDoc => tDoc.file_name);
        docs.push({
          name: 'Tax Return Documents',
          sub: tdDocs.length + ' file(s)',
          file_names: fNames,
          downloadInProgress: false,
          id: 9
        });
      }
    }
    if (get(appDetails, 'application_documents.questionaire_document')) {
      let qDoc = get(appDetails, 'application_documents.questionaire_document');
      let rOn = '';
      if (qDoc.uploaded_date) {
        rOn = 'Received on ' + moment(qDoc.uploaded_date).format('MMM Do YYYY');
      }
      docs.push({
        name: 'Questionnaire Response',
        sub: rOn,
        file_names: [qDoc.file_name],
        downloadInProgress: false,
        id: 10
      });
    }
    if (get(appDetails, 'application_documents.purchase_invoices')) {
      let pDocs = get(appDetails, 'application_documents.purchase_invoices');
      if (pDocs.length > 0) {
        let fNames = [];
        for (let i = 0; i < pDocs.length; i++) {
          const pDoc = pDocs[i];
          fNames.push(pDoc.file_name);
        }
        docs.push({
          name: 'Purchase Invoice',
          sub: pDocs.length + ' file(s)',
          file_names: fNames,
          downloadInProgress: false,
          id: 11
        });
      }
    }
    let uploadedDocs = get(appDetails, 'documents_uploaded');
    if (uploadedDocs) {
      for (let i = 0; i < uploadedDocs.length; i++) {
        const uDoc = uploadedDocs[i];
        let rOn = '';
        if (uDoc.uploaded_date) {
          rOn = 'Received on ' + moment(uDoc.uploaded_date).format('MMM Do YYYY');
        }
        docs.push({
          name: uDoc.file_name,
          sub: rOn,
          file_names: [uDoc.file_name],
          downloadInProgress: false,
          id: 11 + i
        });
      }
    }
    this.setState({ appDocs: docs, appDocsLoading: false });
  }

  componentDidMount() {
    const { appDetails } = this.props;
    this.getApplicationDocuments(appDetails);
  }

  componentDidUpdate(prevProps) {
    const { appDetails } = this.props;
    if (prevProps.appDetails !== appDetails) {
      this.getApplicationDocuments(appDetails);
    }
  }

  downloadFile(fName) {
    return new Promise((resolve, reject) => {
      this.props.getPresingedURL({
        file_name: fName,
        http_method: 'GET',
        successCb: presignedURL => {
          // this.props.getFileFromS3({
          //   preSignedS3Url: presignedURL,
          //   successCbS3: data => {
          //     resolve(data.url);
          //   },
          //   failureCbS3: () => {
          //     resolve(false);
          //   }
          // });
          resolve(presignedURL);
        },
        failureCb: () => {
          resolve(false);
        }
      });
    });
  }

  async downloadFiles(doc) {
    let aDocs = this.state.appDocs;
    let idxOfDownloadingDoc = 0;
    for (let i = 0; i < aDocs.length; i++) {
      if (aDocs[i].id === doc.id) {
        aDocs[i].downloadInProgress = true;
        idxOfDownloadingDoc = i;
      }
    }
    this.setState({ appDocs: aDocs, fileDownloadInProgress: true });
    for (let i = 0; i < doc.file_names.length; i++) {
      const fName = doc.file_names[i];
      let fUrl = await this.downloadFile(fName);
      if (fUrl) {
        window.open(fUrl, '_blank');
      } else {
        let eMsgs = this.state.downLoadErrorMsgs;
        eMsgs.push(`Download Failed - ${fName}`);
        this.setState({ downLoadError: true, downLoadErrorMsgs: eMsgs });
      }
    }
    aDocs[idxOfDownloadingDoc].downloadInProgress = false;
    this.setState({ appDocs: aDocs, fileDownloadInProgress: false });
  }

  getIconName(doc) {
    if (doc.id === 1) {
      return 'pdfIcon.svg';
    } else {
      let fName = doc.file_names[0];
      let fTypeArray = fName.split('.');
      let fType = fTypeArray[fTypeArray.length - 1];
      switch (fType.toLowerCase()) {
        case 'pdf':
          return 'pdfIcon.svg';
        case 'jpg':
          return 'jpegIcon.svg';
        case 'jpeg':
          return 'jpegIcon.svg';
        case 'png':
          return 'pngIcon.svg';
        default:
          return '';
      }
    }
  }

  handleFileUpload(event) {
    this.setState({ fileUploadStatus: 'in_progress' });
    let qPDF = event.target.files[0];
    let fTypeArray = qPDF.name.split('.');
    let fType = fTypeArray[fTypeArray.length - 1];

    let fSizeInMb = qPDF.size / 1024 / 1024;
    const fileTag = 'ADMIN_DOCS';
    let file_name = `${this.props.appDetails.application_id}_${fileTag}_${moment().format('DDMMYYYY-hhmmss')}.${fType}`;
    if (fSizeInMb > FILE_UPLOAD_SIZE_LIMIT) {
      this.setState({
        uploadError: true,
        uploadErrorMsg: `Max file size should be ${FILE_UPLOAD_SIZE_LIMIT} Mb`,
        fileUploadStatus: 'idle'
      });
    } else {
      this.props.getPresingedURL({
        file_name: file_name,
        http_method: 'PUT',
        successCb: presignedURL => {
          this.props.uploadToS3({
            preSignedS3Url: presignedURL,
            fName: file_name,
            fileObj: qPDF,
            successCbS3: () => {
              this.setState({ fileUploadStatus: 'success', uploadingFileName: file_name, uploadingFileDetails: qPDF });
            },
            failureCbS3: () => {
              this.setState({
                uploadError: true,
                uploadErrorMsg: 'We are facing some issues with file upload, please try later.',
                fileUploadStatus: 'idle'
              });
            }
          });
        },
        failureCb: () => {
          this.setState({
            uploadError: true,
            uploadErrorMsg: 'We are facing some issues with file upload, please try later.',
            fileUploadStatus: 'idle'
          });
        }
      });
    }
  }

  removeUploadedFile() {
    this.setState({ fileUploadStatus: 'in_progress' });
    this.props.getPresingedURL({
      file_name: this.state.uploadingFileName,
      http_method: 'DELETE',
      successCb: presignedURL => {
        this.setState({ fileUploadStatus: 'idle', uploadingFileName: '', uploadingFileDetails: {} });
      },
      failureCb: () => {
        this.setState({ fileUploadStatus: 'idle', uploadingFileName: '', uploadingFileDetails: {} });
      }
    });
  }

  uploadSgipDocument() {
    let date = new Date();
    let dUpload = [
      {
        file_name: this.state.uploadingFileName,
        original_file_name: this.state.uploadingFileDetails.name,
        file_size: this.state.uploadingFileDetails.size,
        status: 'Uploaded',
        uploaded_date: moment(date).set({
          hour: date.getHours(),
          minute: date.getMinutes(),
          second: date.getSeconds()
        })
      }
    ];
    this.props.uploadSgipDocument({ site_id: this.props.appDetails.site_id, docs: dUpload });
  }

  downloadAllFiles() {
    this.setState({ fileDownloadInProgress: true }, () => {
      this.props.downloadAllDocs({
        id: this.props.appDetails.application_id,
        successCbk: () => {
          this.setState({ fileDownloadInProgress: false });
        },
        failureCbk: () => {
          this.setState(prevState => {
            return {
              ...prevState,
              fileDownloadInProgress: false,
              downLoadError: true,
              downLoadErrorMsgs: [...prevState.downLoadErrorMsgs, 'Download failed, please try later']
            };
          });
        }
      });
    });
  }

  render() {
    const { classes, isFromAdmin = false } = this.props;
    const {
      appDocsLoading,
      appDocs,
      downLoadError,
      downLoadErrorMsgs,
      fileDownloadInProgress,
      showUploadSgipDoc,
      fileUploadStatus,
      uploadingFileName,
      uploadError,
      uploadErrorMsg,
      uploadingFileDetails
    } = this.state;
    return (
      <Fragment>
        {' '}
        <Grid container direction="row">
          <Grid item xs={12}>
            <ExpansionPanel expanded={true}>
              <ExpansionPanelSummary
                aria-controls="sDetails-content"
                id="sDetails"
                className={classes.panelHead}
                classes={{ content: classes.panelHeadContent }}
              >
                <Typography className={classes.heading}>Application Documents</Typography>
                <IconButton edge="end" onClick={() => this.downloadAllFiles()} disabled={fileDownloadInProgress}>
                  {fileDownloadInProgress ? <CircularProgress size={30} /> : <SystemUpdateAltIcon />}
                </IconButton>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.expansionPanel}>
                {appDocsLoading === true ? (
                  <div style={{ textAlign: 'center', minHeight: '518px' }}>
                    {' '}
                    <br />
                    <br />
                    <br />
                    <CircularProgress size={15} />
                  </div>
                ) : appDocs.length > 0 ? (
                  <Fragment>
                    <List className={classes.listStyle}>
                      {appDocs.map((doc, idx) => {
                        return (
                          <ListItem key={idx}>
                            <ListItemAvatar>
                              {doc.id === 1 ? (
                                <Avatar variant="square" style={{ background: 'none' }}>
                                  <DescriptionIcon color="primary" style={{ width: '100%', height: '100%' }} />
                                </Avatar>
                              ) : isEmpty(this.getIconName(doc)) ? (
                                <Avatar variant="square" style={{ background: 'none' }}>
                                  <DescriptionIcon color="primary" style={{ width: '100%', height: '100%' }} />
                                </Avatar>
                              ) : (
                                <Avatar src={require(`./images/${this.getIconName(doc)}`)} variant="square" />
                              )}
                            </ListItemAvatar>
                            <ListItemText
                              primary={doc.name}
                              secondary={doc.sub}
                              primaryTypographyProps={{
                                style: {
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  fontWeight: 'bold',
                                  fontSize: '14px'
                                }
                              }}
                              secondaryTypographyProps={{ style: { fontSize: '12px' } }}
                            />
                            <ListItemSecondaryAction>
                              <IconButton
                                edge="end"
                                aria-label="download"
                                onClick={() => this.downloadFiles(doc)}
                                disabled={doc.downloadInProgress || fileDownloadInProgress}
                              >
                                {doc.downloadInProgress === true ? (
                                  <CircularProgress size={30} />
                                ) : (
                                  <SystemUpdateAltIcon />
                                )}
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        );
                      })}
                    </List>
                    {downLoadError &&
                      downLoadErrorMsgs.map((msg, idx) => {
                        return <Snackbar key={idx} severity={'error'} message={msg} />;
                      })}
                  </Fragment>
                ) : (
                  <div style={{ textAlign: 'center', minHeight: '518px' }}>
                    <br />
                    <br />
                    <br />
                    <Typography variant="caption">** No Documents Available **</Typography>
                  </div>
                )}
                {isFromAdmin && (
                  <Fragment>
                    <Divider className={classes.divider} />
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => this.setState({ showUploadSgipDoc: true })}
                      className={classes.uploadButton}
                    >
                      Upload Document
                    </Button>
                  </Fragment>
                )}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
        </Grid>
        <Dialog
          onClose={() => this.setState({ showUploadSgipDoc: false })}
          open={showUploadSgipDoc}
          maxWidth="xs"
          fullWidth
          id="successMsg"
        >
          <DialogTitle id="customized-dialog-title" onClose={() => this.setState({ showUploadSgipDoc: false })}>
            Upload Document
          </DialogTitle>
          <DialogContent>
            {fileUploadStatus === 'in_progress' ? (
              <CircularProgress size={30} />
            ) : fileUploadStatus === 'idle' ? (
              <Button variant="outlined" component="label" className={classes.fileButton} color="primary">
                Choose File
                <input
                  type="file"
                  style={{ display: 'none' }}
                  id="fileUpload"
                  onChange={e => this.handleFileUpload(e)}
                />
              </Button>
            ) : (
              <Chip
                label={uploadingFileName}
                onDelete={() => this.removeUploadedFile()}
                color="primary"
                variant="outlined"
                size="small"
                className={classes.chipStyle}
              />
            )}
            {uploadError && (
              <Typography variant="caption" className={classes.uploadErrorStyle}>
                <br />
                {uploadErrorMsg}
              </Typography>
            )}
            <div className={classes.dateWrapper}>
              <span>Date Of Completion : </span>&nbsp;&nbsp;&nbsp;
              <Typography variant="caption">{moment().format('MM/DD/YYYY')}</Typography>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ showUploadSgipDoc: false })} variant="contained">
              Close
            </Button>
            <Button
              onClick={() => this.uploadSgipDocument()}
              color="primary"
              variant="contained"
              className={classes.acceptButton}
              disabled={isEqual(uploadingFileDetails, {})}
            >
              Upload
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getPresingedURL: payload => dispatch(getPresingedURL(payload)),
  getFileFromS3: payload => dispatch(getFileFromS3(payload)),
  uploadToS3: payload => dispatch(uploadToS3(payload)),
  uploadSgipDocument: payload => dispatch(uploadSgipDocument(payload)),
  downloadAllDocs: payload => dispatch(downloadAllDocs(payload))
});

const mapStateToProps = state => ({
  currentlySending: state.adminReducer.currentlySending
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ApplicationDocuments));
