import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Details from '../../../components/SgipApp/AttestationForm/index.js';
import StatusTracking from '../../../components/SgipApp/StatusTracking';
import { postSgipAttestationForm, getSgipApplicationDetailsBySiteId } from '../actions';
import { forwardTo, addInputEventListener, trackEvents } from '../../../utils/helper';
import Loader from '../../../components/Loader';
import { isNull } from '../../../utils/lodash.js';

const styles = theme => ({
  root: {
    margin: theme.spacing(2.5, 2.5, 0, 2.5),
    display: 'flex',
    flexDirection: 'column'
  },
  alert: {
    fontSize: '12px',
    fontWeight: 'normal',
    boxShadow: '1px 2px 10px 0 rgba(63, 67, 71, 0.08)',
    backgroundColor: '#ff5959',
    borderRadius: '0px'
  },
  MuiDisabled: {
    backgroundColor: 'red'
  },
  [theme.breakpoints.up('md')]: {
    dWrapper: {
      margin: '30px 194px',
      boxShadow: '0 2px 10px 0 rgba(63, 67, 71, 0.08)',
      background: '#fff',
      borderRadius: '3px'
    }
  }
});

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeState: 2,
      attestationFormDetailsError: {},
      attestationFormDetails: {
        siteId: '',
        averageLoad: '',
        criticalLoad: '',
        batteryCapacity: '',
        backType: ''
      }
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { siteId } = this.props;
    addInputEventListener();
    let pD = this.state.attestationFormDetails;
    pD.siteId = siteId;
    this.setState({ attestationFormDetails: pD });
    this.loadApplication(siteId);
  }

  loadApplication(siteId) {
    this.props.getSgipApplicationDetailsBySiteId({
      siteId: siteId,
      isInstaller: this.props.isInstaller,
      successCb: () => {
        //this.setState({ sgipApplicationFound: 'success' });
        const { sgip_application } = this.props;
        const attestationFormData = this.state.attestationFormDetails;
        if (!isNull(sgip_application.sgip_attestation_form)) {
          attestationFormData.averageLoad = sgip_application.sgip_attestation_form.averageLoad || '';
          attestationFormData.criticalLoad = sgip_application.sgip_attestation_form.criticalLoad || '';
          attestationFormData.backType = sgip_application.sgip_attestation_form.backType || '';
        }
        attestationFormData.batteryCapacity = sgip_application.storage_capacity || '';
        const siteId = this.props.siteId;
        attestationFormData.siteId = siteId;

        this.setState({ attestationFormDetails: attestationFormData, activeState: sgip_application.stepCompleted });
      },
      failureCb: () => {
        this.setState({ sgipApplicationFound: 'failed' });
      }
    });
  }

  validateAttestationFormDetails() {
    let fValidation = {
      eFound: false,
      pDE: {
        averageLoad: { error: false, msg: '' },
        criticalLoad: { error: false, msg: '' },
        backType: { error: false, msg: '' }
      }
    };
    if (this.state.attestationFormDetails.averageLoad === '') {
      fValidation.eFound = true;
      fValidation.pDE.averageLoad.error = true;
      fValidation.pDE.averageLoad.msg = 'Average Load required';
    } else {
      fValidation.pDE.averageLoad.error = false;
      fValidation.pDE.averageLoad.msg = '';
    }
    if (this.state.attestationFormDetails.criticalLoad === '') {
      fValidation.eFound = true;
      fValidation.pDE.criticalLoad.error = true;
      fValidation.pDE.criticalLoad.msg = 'Critical Load required';
    } else {
      fValidation.pDE.criticalLoad.error = false;
      fValidation.pDE.criticalLoad.msg = '';
    }

    if (this.state.attestationFormDetails.backType === '') {
      fValidation.eFound = true;
      fValidation.pDE.backType.error = true;
      fValidation.pDE.backType.msg = 'Backup Configuration required';
    } else {
      fValidation.pDE.backType.error = false;
      fValidation.pDE.backType.msg = '';
    }

    return fValidation;
  }

  validateValues() {
    let pDE = this.state.attestationFormDetailsError;
    let eFound = false;
    let fValidation = this.validateAttestationFormDetails();
    pDE = fValidation.pDE;
    eFound = fValidation.eFound;
    this.setState({ attestationFormDetailsError: pDE });
    if (eFound) {
      return false;
    }
    return true;
  }

  handleSubmit() {
    let pD = this.state.attestationFormDetails;
    if (pD.backType === 'Complete') {
      pD.criticalLoad = pD.averageLoad;
    } else if (pD.backType === 'Partial') {
      pD.criticalLoad = pD.averageLoad * 0.5;
    }
    this.setState({ attestationFormDetails: pD });
    if (this.validateValues()) {
      this.props.postSgipAttestationForm({
        attestationFormDetails: this.state.attestationFormDetails,
        successCb: () => {
          trackEvents('triggerSgipPageView', { pageTitle: 'Upload Documents' });
          forwardTo('uploadDocuments');
        },
        failureCb: () => {}
      });
    }
  }

  averageLoad = (event, key) => {
    let pData = this.state.attestationFormDetails;
    let updateValue = parseFloat(event.target.value).toFixed(2);
    pData.averageLoad = parseFloat(updateValue);
    this.setState({ attestationFormDetails: pData });
  };

  handleBackTypeChange = backType => {
    let pData = this.state.attestationFormDetails;
    pData.backType = backType;
    pData.criticalLoad = backType === 'Partial' ? '' : pData.averageLoad;
    this.setState({ attestationFormDetails: pData });
  };

  render() {
    const { classes, currentlySending, sgip_application, isInstaller, isIOS } = this.props;

    return (
      <div className={classes.dWrapper}>
        <StatusTracking activeState={this.state.activeState} pageNumber="3" isInstaller={isInstaller} />
        {currentlySending && <Loader loading={currentlySending} />}
        <div className={classes.root}>
          <Details
            averageLoad={this.averageLoad}
            criticalLoad={this.criticalLoad}
            handleSubmit={this.handleSubmit}
            sgip_application={sgip_application}
            average_load_value={this.state.attestationFormDetails.averageLoad}
            critical_load_value={this.state.attestationFormDetails.criticalLoad}
            back_type_value={this.state.attestationFormDetails.backType}
            isInstaller={isInstaller}
            isIOS={isIOS}
            handleBackTypeChange={this.handleBackTypeChange}
            attestationFormDetailsError={this.state.attestationFormDetailsError}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  postSgipAttestationForm: payload => dispatch(postSgipAttestationForm(payload)),
  getSgipApplicationDetailsBySiteId: payload => dispatch(getSgipApplicationDetailsBySiteId(payload))
});

const mapStateToProps = state => ({
  message: state.message,
  siteId: state.appReducer.siteId,
  currentlySending: state.sgipAppReducer.currentlySending,
  sgip_application: state.sgipAppReducer.sgip_application,
  isInstaller: state.appReducer.isInstaller,
  isIOS: state.appReducer.isIOS
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(index));
