import React, { Fragment } from 'react';
import { Box, Grid, Typography, IconButton } from '@material-ui/core';
import useStyles from './style';
import { BoxLayout } from '../../Common';
import { Title, Text } from '../../Texts';
import { Button, FilledButton } from '../../Buttons';
import { Link } from '@material-ui/core';
import HelpOutlineIcon from '../../Header/Icons/HelpOutlineIcon';
import { SGIP_OVERVIEW_HO, SGIP_OVERVIEW_INSTALLER } from '../../../config/constants';
const ApplyIncentive = props => {
  const { requiredDocument, serviceFeePopup, isInstaller = true, isIOS, createNewApplication } = props;
  const classes = useStyles();
  const learnMoreURL = isInstaller ? SGIP_OVERVIEW_INSTALLER : SGIP_OVERVIEW_HO;

  return (
    <Fragment>
      <Grid className={classes.top}>
        <BoxLayout>
          <Title className={classes.header}>Apply for SGIP Incentive</Title>
          {!isInstaller ? (
            <Text className={classes.description}>
              System Owners in California, who are planning or have purchased an Enphase Battery for their home
              can apply for SGIP
            </Text>
          ) : (
            <Text className={classes.description}>
              System Owners in California, who are planning or have purchased an Enphase Battery are eligible
              for SGIP.​
            </Text>
          )}
          <Box>
            <FilledButton style={{ width: 'auto' }} onClick={e => createNewApplication()}>
              Apply Now
            </FilledButton>
            <Button>
              <Typography variant="body2" className={classes.label}>
                {isIOS ? (
                  <a
                    style={{ color: '#01b4de', textDecoration: 'none' }}
                    href={'enphase://user?url=' + learnMoreURL.toString()}
                    target="_self"
                  >
                    {` Learn More`}
                  </a>
                ) : (
                  <Link
                    style={{ color: '#01b4de' }}
                    target="_blank"
                    rel="noreferrer"
                    underline="none"
                    onClick={() => window.open(learnMoreURL, '_blank')}
                  >
                    {` Learn More`}
                  </Link>
                )}
              </Typography>
            </Button>
          </Box>
          <br />
          <Box className={classes.labelWrapper}>
            <Typography className={classes.label} id="condition" style={{ width: 'auto', margin: 0 }}>
              * Service fee may apply
            </Typography>
            <IconButton
              size="small"
              aria-label="help"
              disableRipple={true}
              onClick={() => serviceFeePopup()}
              className={classes.caution}
            >
              <HelpOutlineIcon width="12" heigth="12" />
            </IconButton>
          </Box>

          <Typography className={classes.label}>
            * You may be contacted by an Enphase representative via call/email
          </Typography>
        </BoxLayout>
      </Grid>

      <Box className={classes.requiredDocumentWrapper}>
        <Text className={classes.description}>Following documents are required to apply for SGIP Incentive:</Text>
        {requiredDocument.map((document, index) => (
          <Text key={`${index}.${document}`} className={classes.document}>{`${index + 1}. ${document}`}</Text>
        ))}
        <Typography className={classes.label}>
          * Additional documents may be required before final incentive payout
        </Typography>
      </Box>
    </Fragment>
  );
};

export default ApplyIncentive;
