import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: theme.spacing(1.25),
    boxShadow: '1px 2px 5px 0 rgba(152, 152, 152, 0.1)',
    backgroundColor: theme.palette.tertiary.main
  },
  top: {
    marginTop: theme.spacing(2.25)
  },
  header: {
    marginBottom: theme.spacing(1.25)
  },
  description: {
    marginBottom: theme.spacing(2),
    lineHeight: 1.36
  },
  requiredDocumentWrapper: {
    borderRadius: theme.spacing(1.25),
    border: 'dashed 1px #afafaf',
    backgroundColor: 'transparent',
    padding: theme.spacing(2.5),
    marginTop: theme.spacing(2.5)
  },
  document: {
    lineHeight: 1.5
  },
  filledbutton: {
    width: 'auto'
  },
  label: {
    color: '#939598',
    marginTop: theme.spacing(0.75)
  },
  caution: {
    width: '12px',
    verticalAlign: 'text-top',
    cursor: 'pointer',
    padding: '0 12px',
    marginLeft: '4px'
  },
  labelWrapper: {
    display: 'flex',
    marginTop: '10px'
  }
}));

export default useStyles;
