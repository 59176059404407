import {
  GET_SGIP_REQUESTS_REQUEST,
  GET_SGIP_STATISTICS_REQUEST,
  GET_MSG_HISTORY_REQUEST,
  GET_SGIP_REQUEST_DETAILS_REQUEST,
  UPDATE_APP_ID_REQUEST,
  UPDATE_STAGE_STATUS_REQUEST,
  SEND_MESSAGE_REQUEST,
  GET_NOTIFICATIONS_REQUEST,
  GET_PRESIGNED_URL,
  FILE_UPLOAD_TO_S3_REQUEST,
  GET_PDF_FILE_FROM_S3_REQUEST,
  SEND_TO_HO_FOR_APPROVAL_REQUEST,
  SITE_SEARCH_REQUEST,
  RESET_SITE_SEARCH_RESULT_REQUEST,
  GET_ACCOUNT_DETAILS_REQUEST,
  UPDATE_CHAT_STATUS_REQUEST,
  GET_APP_NOTES_REQUEST,
  UPDATE_APP_NOTE_REQUEST,
  CREATE_APP_NOTE_REQUEST,
  DELETE_APP_NOTE_REQUEST,
  UPDATE_LAST_MESSAGE_SEEN_REQUEST,
  GET_APPLICATIONS_SITE_LIST_REQUEST,
  GET_COMPANY_INFO_REQUEST,
  GET_DIN_INSTALLERS_LIST_REQUEST,
  UPDATE_DIN_INSTALLERS_LIST_REQUEST,
  DELETE_DIN_INSTALLERS_LIST_REQUEST,
  GET_USER_TYPE_REQUEST,
  UPLOAD_SGIP_DOCS_REQUEST,
  BLOCK_UNBLOCK_SGIP_CONFIG_PARAMS_REQUEST,
  UPDATE_INCENTIVE_RATE_SGIP_CONFIG_PARAMS_REQUEST,
  GET_BATTERY_REPORT_DOWNLOAD_REQUEST,
  SAVE_INSTALLER_SECONDARY_EMAIL_REQUEST,
  DOWNLOAD_ALL_APP_DOCS_REQUEST
} from './constants';

export function getSGIPRequests(payload) {
  return { type: GET_SGIP_REQUESTS_REQUEST, payload };
}
export function getSGIPStatistics(payload) {
  return { type: GET_SGIP_STATISTICS_REQUEST, payload };
}
export function getMessageHistoryForRequest(payload) {
  return { type: GET_MSG_HISTORY_REQUEST, payload };
}
export function getSGIPRequestDetails(payload) {
  return { type: GET_SGIP_REQUEST_DETAILS_REQUEST, payload };
}
export function updateAppId(payload) {
  return { type: UPDATE_APP_ID_REQUEST, payload };
}
export function updateStageStatus(payload) {
  return { type: UPDATE_STAGE_STATUS_REQUEST, payload };
}
export function sendMessage(payload) {
  return { type: SEND_MESSAGE_REQUEST, payload };
}
export function getNotifications(payload) {
  return { type: GET_NOTIFICATIONS_REQUEST, payload };
}
export function getPresingedURL(payload) {
  return { type: GET_PRESIGNED_URL, payload };
}
export function uploadToS3(payload) {
  return { type: FILE_UPLOAD_TO_S3_REQUEST, payload };
}
export function getFileFromS3(payload) {
  return { type: GET_PDF_FILE_FROM_S3_REQUEST, payload };
}
export function sendForApproval(payload) {
  return { type: SEND_TO_HO_FOR_APPROVAL_REQUEST, payload };
}

export function getSearchResult(payload) {
  return { type: SITE_SEARCH_REQUEST, payload };
}

export function resetSiteSearchResult() {
  return { type: RESET_SITE_SEARCH_RESULT_REQUEST };
}

export function getAccountDetails(payload) {
  return { type: GET_ACCOUNT_DETAILS_REQUEST, payload };
}

export function getComapnyInfo(payload) {
  return { type: GET_COMPANY_INFO_REQUEST, payload };
}

export function updateChatStatus(payload) {
  return { type: UPDATE_CHAT_STATUS_REQUEST, payload };
}

export function getAppNotes(payload) {
  return { type: GET_APP_NOTES_REQUEST, payload };
}

export function updateAppNote(payload) {
  return { type: UPDATE_APP_NOTE_REQUEST, payload };
}

export function createAppNote(payload) {
  return { type: CREATE_APP_NOTE_REQUEST, payload };
}

export function deleteAppNote(payload) {
  return { type: DELETE_APP_NOTE_REQUEST, payload };
}

export function updateLastMessageSeen(payload) {
  return { type: UPDATE_LAST_MESSAGE_SEEN_REQUEST, payload };
}

export function getApplicationsSiteList() {
  return { type: GET_APPLICATIONS_SITE_LIST_REQUEST };
}

export function getUserType(payload) {
  return { type: GET_USER_TYPE_REQUEST, payload };
}

export function getSGIPDinInstallersList(payload) {
  return { type: GET_DIN_INSTALLERS_LIST_REQUEST, payload };
}

export function updateSGIPDinInstallerList(payload) {
  return { type: UPDATE_DIN_INSTALLERS_LIST_REQUEST, payload };
}

export function deleteSGIPDinInstaller(payload) {
  return { type: DELETE_DIN_INSTALLERS_LIST_REQUEST, payload };
}

export function uploadSgipDocument(payload) {
  return { type: UPLOAD_SGIP_DOCS_REQUEST, payload };
}

export function downloadAllDocs(payload) {
  return { type: DOWNLOAD_ALL_APP_DOCS_REQUEST, payload };
}

export function blockSGIPConfigParams(payload) {
  return { type: BLOCK_UNBLOCK_SGIP_CONFIG_PARAMS_REQUEST, payload };
}

export function updateIncentiveRateSGIPConfigParams(payload) {
  return { type: UPDATE_INCENTIVE_RATE_SGIP_CONFIG_PARAMS_REQUEST, payload };
}

export function downloadBatteryReport(payload) {
  return { type: GET_BATTERY_REPORT_DOWNLOAD_REQUEST, payload };
}

export function saveInstallerSecondaryEmail(payload) {
  return { type: SAVE_INSTALLER_SECONDARY_EMAIL_REQUEST, payload };
}
