import ua from 'ganalytics';
import TagManager from 'react-gtm-module';
import settings from './settings';
import { analyticCode, measurementId } from './helper';

const tagManager = 'GTM-5STZGC7';

export default class Analytic {
  constructor() {
    if (Analytic.instance) {
      return Analytic.instance;
    }

    this.environments = settings.getConfig();
    this.gtmCode = tagManager;
    this.analyticCode = analyticCode[this.environments.env] || analyticCode['development'];
    this.measurementId = measurementId[this.environments.env] || measurementId['development'];
    if (this.analyticCode !== '') {
      this.ua = ua(this.analyticCode);
    }
    if (this.measurementId !== '') {
      this.ua4 = ua(this.measurementId);
    }

    Analytic.instance = this;
    return this;
  }

  getAnalytics() {
    return this.ua;
  }

  getAnalytics4() {
    return this.ua4;
  }

  setUserId(userId) {
    this.ua = ua(this.analyticCode, { uid: userId });
    const tagManagerArgs = {
      gtmId: this.gtmCode,
      dataLayer: {
        userId,
        measurementId: this.measurementId,
        trackingId: this.analyticCode
      }
    };
    TagManager.initialize(tagManagerArgs);
  }

  setPage(pageTitle, pagePath) {
    this.getAnalytics4().send('pageview', { dt: pageTitle, dp: pagePath });
  }

  triggerEvent(events, dataLayer) {
    const tagManagerArgs = {
      gtmId: this.gtmCode,
      events,
      dataLayer
    };
    TagManager.initialize(tagManagerArgs);
  }
}
