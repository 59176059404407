import React, { Fragment } from 'react';
import {
  Box,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  Checkbox,
  FormControlLabel,
  Link
} from '@material-ui/core';
import { useMobileStyles, useDesktopStyles } from '../style';
import { BoxLayout } from '../../../Common';
import { Title } from '../../../Texts';
import InstallersDropdown from '../../InstallersDropdown';

const UtilityInstaller = props => {
  const {
    isMobileView = true,
    setSelectedInstaller,
    setSelectedUtility,
    installer_name_error,
    installers,
    installerNameText,
    utilityCompanyName,
    utilityInstaller,
    onSelectInstaller,
    utilityError,
    installerError,
    isInstaller,
    installerEmail,
    handleEmailChange,
    installerEmailError,
    primaryInstallers,
    onSelectPrimaryInstaller,
    shouldDisplayInstallersDropDown,
    utilityPrimaryInstallers,
    homeownerCode,
    handleHomeownerCodeChange,
    homeownerCodeError,
    homeownerCheckbox,
    handleHomeownerCheckboxChange
  } = props;

  const mobileClasses = useMobileStyles();
  const desktopClasses = useDesktopStyles();
  const classes = isMobileView ? mobileClasses : desktopClasses;
  const sgipUtilityCompany = [
    { utility_company_name: 'Pacific Gas & Electric Company', id: 1 },
    { utility_company_name: 'San Diego Gas & Electric Company', id: 2 },
    { utility_company_name: 'Southern California Edison', id: 3 },
    { utility_company_name: 'Southern California Gas Company', id: 4 }
  ];

  return (
    <div className={classes.wrapper}>
      <BoxLayout>
        <Title className={classes.header}>Utility & Installer</Title>
        <Box>
          {!isInstaller ? (
            <InputLabel className={classes.label} id="utility_company_label">
              Name of your Electricity Utility
            </InputLabel>
          ) : (
            <InputLabel className={classes.label} id="utility_company_label">
              Electricity Utility
            </InputLabel>
          )}
          <Select
            inputProps={{
              name: 'age',
              id: 'utility_company_label'
            }}
            MenuProps={{ disableScrollLock: true }}
            value={utilityCompanyName}
            onChange={setSelectedUtility}
            style={{ width: '100%' }}
          >
            {sgipUtilityCompany.map(option => (
              <MenuItem disableRipple key={option.id} value={option.utility_company_name}>
                {option.utility_company_name}
              </MenuItem>
            ))}
          </Select>
          {utilityError && <FormHelperText className={classes.error}>{utilityError}</FormHelperText>}
          {!isInstaller ? (
            !homeownerCheckbox && (
              <InputLabel className={classes.label} id="installer_name">
                Name of your Installer
              </InputLabel>
            )
          ) : (
            <InputLabel className={classes.label} id="installer_name">
              Installer
            </InputLabel>
          )}

          {!isInstaller ? (
            !homeownerCheckbox && (
              <Select
                inputProps={{
                  name: 'installerName',
                  id: 'installer_name'
                }}
                MenuProps={{ disableScrollLock: true }}
                value={utilityPrimaryInstallers}
                onChange={onSelectPrimaryInstaller}
                style={{ width: '100%' }}
              >
                {primaryInstallers.map(option => (
                  <MenuItem disableRipple key={option.company_id} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            )
          ) : (
            <InstallersDropdown
              installer_name_error={installer_name_error}
              setSelectedInstaller={setSelectedInstaller}
              installers={installers}
              installerNameText={installerNameText}  
              utilityInstaller={utilityInstaller}
              onSelectInstaller={onSelectInstaller}
              isInstaller={isInstaller}
              primaryInstallers={primaryInstallers}
            />
          )}
          {!isInstaller && (
            <Fragment>
              <FormControlLabel
                className={classes.checkboxWrapper}
                control={<Checkbox checked={homeownerCheckbox} onChange={e => handleHomeownerCheckboxChange(e)} />}
                label="I have installed the system myself"
              />
              <div>
                <Link
                  className={classes.homeownerLink}
                  variant="body1"
                  target="_blank"
                  href="https://www.selfgenca.com/home/dev_register/#:~:text=Your application will be evaluated,your company as the Developer"
                >
                  Click Here to register as an SGIP developer
                </Link>
              </div>
            </Fragment>
          )}

          {shouldDisplayInstallersDropDown &&
            !isInstaller &&
            (!homeownerCheckbox && (
              <Box>
                <InputLabel className={classes.label} id="installer_name">
                  Type your installer’s name
                </InputLabel>
                <InstallersDropdown
                  installer_name_error={installer_name_error}
                  setSelectedInstaller={setSelectedInstaller}
                  installers={installers}
                  utilityInstaller={utilityInstaller}
                  onSelectInstaller={onSelectInstaller}
                  isInstaller={isInstaller}
                  primaryInstallers={primaryInstallers}
                />
              </Box>
            ))}
          {installerError && <FormHelperText className={classes.error}>{installerError}</FormHelperText>}
          {!isInstaller ? (
            !homeownerCheckbox && (
              <Fragment>
                <InputLabel className={classes.label} id="installation_poc">
                  Installer's Email Id
                </InputLabel>
                <TextField fullWidth name="email_id" value={installerEmail} onChange={handleEmailChange} />
              </Fragment>
            )
          ) : (
            ''
          )}
          {installerEmailError &&
          !isInstaller && <FormHelperText className={classes.error}>{installerEmailError}</FormHelperText>}
          {!isInstaller &&
          homeownerCheckbox && (
            <Fragment>
              <InputLabel className={classes.label} id="homeowner_code">
                SGIP Developer Key
              </InputLabel>
              <TextField fullWidth name="homeowner_code" value={homeownerCode} onChange={handleHomeownerCodeChange} />
              {homeownerCodeError && <FormHelperText className={classes.error}>{homeownerCodeError}</FormHelperText>}
            </Fragment>
          )}
          {/* {installerCheckStatus && <FormHelperText className={classes.errorInstaller}>{' Your installer is not registered. To apply, your installer must be registered as an SGIP developer.'}</FormHelperText>} */}
        </Box>
      </BoxLayout>
    </div>
  );
};

export default UtilityInstaller;
