import {
  Avatar,
  Box,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TableBody,
  Tooltip,
  Typography
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import InfoIcon from '@material-ui/icons/Info';
import clsx from 'clsx';
import React, { Fragment } from 'react';
import { BoxLayout } from '../../../Common';
import { Title } from '../../../Texts';
import { useDesktopStyles, useMobileStyles } from '../style';
const SystemDetails = props => {
  const {
    isMobileView = true,
    unit_of_encharge3,
    unit_of_encharge10,
    unit_of_iq_battery5p,
    increaseUnitsOfEncharge3,
    increaseUnitsOfEncharge10,
    decreaseUnitsOfEncharge3,
    decreaseUnitsOfEncharge10,
    increaseUnitsOfIQBattery5P,
    decreaseUnitsOfIQBattery5P,
    totalEncharge,
    totalEncharge3LimitFlag,
    totalEncharge10LimitFlag,
    totalEncharge5PLimitFlag,
    solarSize,
    solar_size_value,
    solarSizeCheck,
    solarSizeCheckError,
    systemDetailsError,
    batteryRebatePreviously
  } = props;
  const mobileClasses = useMobileStyles();
  const desktopClasses = useDesktopStyles();

  const classes = isMobileView ? mobileClasses : desktopClasses;
  return (
    <Fragment>
      <div className={clsx(classes.wrapper, classes.showBorder)}>
        <br className={classes.dHide} />
        <BoxLayout style={{ boxShadow: 'none', border: 0 }}>
          <Title className={classes.header}>System Details</Title>
          {batteryRebatePreviously === 'true' && (
            <FormHelperText>Please add the details of additional Battery(s) installed on the site only.</FormHelperText>
          )}
          {totalEncharge3LimitFlag && totalEncharge10LimitFlag && (
            <InputLabel id="utility_company_label">
              <Typography variant="caption">Max capacity allowed is 40 kWh</Typography>
            </InputLabel>
          )}
          {totalEncharge5PLimitFlag && (
            <InputLabel id="utility_company_label">
              <Typography variant="caption">Max capacity allowed is 80 kWh</Typography>
            </InputLabel>
          )}

          <Table className={classes.table} aria-label="System Details">
            <TableBody>
              <TableRow>
                <TableCell style={{ padding: '0px' }} className={classes.text}>
                  <Typography variant="body1">
                    Units of IQ Battery 3 / Units of IQ Battery 3T
                    <Tooltip title="Formerly known as Encharge 3">
                      <IconButton disableFocusRipple size="small">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Avatar
                    className={unit_of_encharge3 > 0 && unit_of_iq_battery5p === 0 ? classes.blue : classes.lightblue}
                    onClick={e => decreaseUnitsOfEncharge3(e, 'unit_of_encharge3')}
                  >
                    -
                  </Avatar>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">{unit_of_encharge3}</Typography>
                </TableCell>
                <TableCell align="right" className={classes.details}>
                  <Typography component={'div'}>
                    <Avatar
                      className={totalEncharge3LimitFlag || unit_of_iq_battery5p > 0 ? classes.lightblue : classes.blue}
                      onClick={e => increaseUnitsOfEncharge3(e, 'unit_of_encharge3')}
                    >
                      +
                    </Avatar>
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ padding: '0px' }} className={classes.text}>
                  <Typography variant="body1">
                    Units of IQ Battery 10 / Units of IQ Battery 10T{' '}
                    <Tooltip title="Formerly known as Encharge 10">
                      <IconButton disableFocusRipple size="small">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography component={'div'}>
                    <Avatar
                      className={
                        unit_of_encharge10 > 0 && unit_of_iq_battery5p === 0 ? classes.blue : classes.lightblue
                      }
                      onClick={e => decreaseUnitsOfEncharge10(e, 'unit_of_encharge10')}
                    >
                      -
                    </Avatar>
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography variant="subtitle1">{unit_of_encharge10}</Typography>
                </TableCell>
                <TableCell className={classes.details}>
                  <Typography component={'div'}>
                    <Avatar
                      className={
                        totalEncharge10LimitFlag || unit_of_iq_battery5p > 0 ? classes.lightblue : classes.blue
                      }
                      onClick={e => increaseUnitsOfEncharge10(e, 'unit_of_encharge10')}
                    >
                      +
                    </Avatar>
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ padding: '0px' }} className={classes.text}>
                  <Typography variant="body1">Units of IQ Battery 5P </Typography>
                </TableCell>
                <TableCell>
                  <Typography component={'div'}>
                    <Avatar
                      className={
                        unit_of_iq_battery5p > 0 && (unit_of_encharge3 === 0 || unit_of_encharge10 === 0)
                          ? classes.blue
                          : classes.lightblue
                      }
                      onClick={e => decreaseUnitsOfIQBattery5P(e, 'unit_of_iq_battery5p')}
                    >
                      -
                    </Avatar>
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography variant="subtitle1">{unit_of_iq_battery5p}</Typography>
                </TableCell>

                <TableCell className={classes.details}>
                  <Typography component={'div'}>
                    <Avatar
                      className={
                        totalEncharge5PLimitFlag || unit_of_encharge3 > 0 || unit_of_encharge10 > 0
                          ? classes.lightblue
                          : classes.blue
                      }
                      onClick={e => increaseUnitsOfIQBattery5P(e, 'unit_of_iq_battery5p')}
                    >
                      +
                    </Avatar>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {/* <div className={classes.pHide}>
            {errorMessage && (
              <FormHelperText className={classes.error}>{'Please select all the options to proceed'}</FormHelperText>
            )}</div> */}
          {systemDetailsError && <FormHelperText className={classes.error}>{systemDetailsError}</FormHelperText>}
        </BoxLayout>
        <br />
        <BoxLayout style={{ border: 0, boxShadow: 'none' }}>
          <div>
            <Box className={classes.nextbtn}>
              <Title className={classes.header}>Solar Details</Title>

              <InputLabel variant="body1" className={classes.label} id="solar_size">
                Solar Size (Optional)
              </InputLabel>
              <Input
                fullWidth
                name="solar_size"
                type="number"
                value={solar_size_value}
                onChange={e => solarSize(e)}
                endAdornment={
                  <InputAdornment position="start" className={classes.adornment}>
                    <Typography variant="caption">kW</Typography>
                  </InputAdornment>
                }
              />

              {solarSizeCheck && <FormHelperText>{'Please enter two decimal values'}</FormHelperText>}
              {solarSizeCheckError && <FormHelperText>{'Value should not be greater than 999.99 kW'}</FormHelperText>}
              {totalEncharge > 40 ? (
                <FormHelperText className={classes.error}>
                  {'Capacity exceeded permitted value of 40 kWh'}
                </FormHelperText>
              ) : (
                <FormHelperText className={classes.error}>{''}</FormHelperText>
              )}
            </Box>
          </div>
        </BoxLayout>
      </div>
    </Fragment>
  );
};

export default SystemDetails;
