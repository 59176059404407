import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  isEqual,
  get,
  toString,
  includes,
  debounce,
  isEmpty,
} from '../../../utils/lodash';
import Loader from '../../../components/Loader';
import settings from '../../../utils/settings';
import { getEnlightenOrigin, trackEvents } from '../../../utils/helper';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Grid,
  Link,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Button,
  Select,
  MenuItem,
  Input,
  Box,
  Tabs,
  Tab,
  Badge,
} from '@material-ui/core';

import {
  getSGIPRequests,
  getSGIPStatistics,
  getSearchResult,
  resetSiteSearchResult,
  getApplicationsSiteList,
  getComapnyInfo,
  getUserType,
  blockSGIPConfigParams,
  updateIncentiveRateSGIPConfigParams,
} from '../actions';
import {
  REQUEST_LISTING_COLUMNS,
  CREATE_NEW_APPLICATION,
  OPENING_EXISTING_APPLICATION,
} from '../constants';
import {
  EQUITY,
  SMALL_RESIDENTIAL,
  EQUITY_RESILIENCY,
  LARGE_SCALE_STORAGE,
  PACIFIC_GAS_AND_ELECTRIC_COMPANY,
  SAN_DIEGO_GAS_AND_ELECTRIC_COMPANY,
  SOUTHERN_CALIFORNIA_EDISON,
  SOUTHERN_CALIFORNIA_GAS_COMPANY,
} from '../../SgipApp/constants';
import Snackbar from '../../../components/SnakBar';
import moment from 'moment';
import { Doughnut } from 'react-chartjs-2';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getFullName } from '../helper';
import DinInstallerList from '../../../components/SgipApp/DinInstallerList';
import { getSgipConfigs, getSgipIncentiveRates } from '../../SgipApp/actions';
import UtilityTable from '../../../components/SgipApp/UtilityTable';
import SnakBar from '../../../components/SnakBar';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.dark,
  },
  button: {
    color: theme.palette.primary.main,
    fontSize: 10,
  },
  container: {
    maxHeight: 440,
    minHeight: 440,
  },
  tableHeader: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.yellow,
    border: 'none',
    verticalAlign: 'text-top',
  },
  fadedContent: {
    color: theme.palette.primary.lightBlack,
  },
  bolderText: {
    fontWeight: 'bold',
  },
  expiry: {
    color: 'green',
    fontSize: 'x-small',
  },
  acceptButton: {
    color: theme.palette.primary.white,
  },
  buttonDiv: {
    paddingBottom: '10px',
    float: 'right',
    paddingRight: 'inherit',
    paddingTop: '10px',
  },
  optInInfo: {
    background: '#eff0f1',
    padding: '10px',
  },
  dHeader: {
    paddingLeft: '15px',
    paddingTop: '15px',
    fontWeight: 'bold',
    color: 'grey',
  },
  headerButton: {
    color: theme.palette.primary.yellow,
    textTransform: 'uppercase',
    fontSize: '12px',
    height: '30px',
  },
  content: {
    border: '1px solid #7070704D',
    margin: '0 20px 20px 20px',
    padding: '15px',
  },
  cLink: {
    color: '#F3731F',
    cursor: 'pointer',
  },
  dialogHeader: {
    color: '#335977',
    fontSize: '29px',
    fontWeight: 'Bold',
  },
  rejectButton: {
    color: '#919191',
  },
  warnTableCell: {
    color: theme.palette.primary.main,
  },
  createNewButton: {
    border: '1px solid #F37320',
    borderRadius: '2px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
    },
  },
  createNewLabel: {
    color: '#F37320',
  },
  installerListLabel: {
    fontSize: '16px',
    color: '#016AE3',
    textTransform: 'capitalize',
  },
  dropdown: {
    '&:before': {
      borderBottom: 'none !important',
    },
  },
  input: {
    height: '1.5em',
    backgroundColor: '#fff',
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  selectDropdown: {
    backgroundColor: 'white',
    outline: 'none',
    border: 'none',
    height: theme.spacing(3.25),
    borderRadius: 0,
    appearance: 'none',
  },
  displayFlex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dialogTitle: {
    justifyContent: 'space-between',
  },
  configureText: {
    letterSpacing: '0px',
    color: '#335977',
    opacity: 1,
    fontWeight: 'bold',
  },
  tabWrapper: {
    '& .MuiTabs-flexContainer': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  setFontSize: {
    fontSize: '35px',
  },
  paperWrapper: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 0px 6px #00000029',
    margin: theme.spacing(2.5, 0, 5, 0),
  },
  selectBoxWrapper: {
    padding: theme.spacing(3, 3, 0, 3),
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(4, 3, 3, 3),
  },
  yesNoButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
  },
  justifyContentBetween: {
    justifyContent: 'space-between',
  },

  justifyContentCenter: {
    justifyContent: 'center',
  },
  paginationWrapper: {
    '& .MuiTablePagination-caption': {
      fontWeight: 400,
    },
  },
  blockUnblockButton: {
    width: '107px',
    height: '48px',
    background: '#F37320 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    opacity: 1,
    color: 'white',
    textTransform: 'none',
  },
  outlineButton: {
    height: 'fit-content',
  },
  cancelButton: {
    width: '110px',
    height: '48px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #F37320',
    borderRadius: '4px',
    opacity: 1,
    textTransform: 'none',
  },
  updateButton: {
    marginRight: '28px',
    height: '40px',
  },
  displayName: {
    color: '#43425D',
    fontWeight: 'bold',
  },
  headerDiv: {
    display: 'flex',
    justifyContent: 'space-between',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  textFieldWrapper: {
    [theme.breakpoints.down('xs')]: {
      '& .MuiInputLabel-formControl': {
        position: 'relative',
      },
    },
  },
  chatBadge: {
    top: theme.spacing(0.375),
    height: theme.spacing(2.25),
    minWidth: theme.spacing(22),
    '& .MuiBadge-badge': {
      color: theme.palette.primary.white,
      borderRadius: theme.spacing(0.375),
      top: theme.spacing(-1),
      right: theme.spacing(2),
    },
  },
  slightlyLeft: {
    '& .MuiBadge-badge': {
      right: theme.spacing(1.6),
    },
  },
  smallFont: {
    fontSize: theme.spacing(1.25),
  },
  close: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    opacity: 0.23,
    cursor: 'pointer',
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function SelectDropdown(props) {
  const {
    value,
    label,
    optionsList,
    handleConfigureParametersChange,
    classes,
    isDropdown = true,
    error,
    stateName,
    stateErrorName,
    disabled = false,
  } = props;
  let displayName;
  if (label === 'Select Incentive Category') {
    displayName = 'Select Category';
  } else if (label === 'Encharge3') {
    displayName = 'IQ Battery 3 / IQ Battery 3T Rate ($)';
  } else if (label === 'Encharge10') {
    displayName = 'IQ Battery 10 / IQ Battery 10T Rate ($)';
  } else if (label === 'IQBattery5P') {
    displayName = 'IQ Battery 5P Rate ($)';
  } else {
    displayName = label;
  }
  return (
    <Fragment>
      <Box className={classes.selectBoxWrapper}>
        <Typography className={classes.displayName}> {displayName}</Typography>
        <TextField
          value={value}
          select={isDropdown}
          fullWidth
          onChange={e =>
            handleConfigureParametersChange(e, label, stateName, stateErrorName)
          }
          error={error}
          helperText={error && 'This field is required'}
          type={
            label === 'Encharge3' ||
            label === 'Encharge10' ||
            label === 'IQBattery5P'
              ? 'number'
              : 'string'
          }
          disabled={disabled}
        >
          {isDropdown &&
            optionsList.map((option, index) => {
              return (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              );
            })}
        </TextField>
      </Box>
    </Fragment>
  );
}

const Chart = props => {
  return (
    <Grid item xs={12} md={3}>
      <Paper
        style={{
          height: '100%',
        }}
      >
        <Grid container direction="row">
          <Grid item xs={12}>
            <Typography variant="h6" className={props.dHeader}>
              Overall Application Status
            </Typography>
            <br />
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12}>
            {props.getSGIPStatisticsInProgress === true ? (
              <div
                style={{
                  textAlign: 'center',
                  paddingTop: '20vh',
                }}
              >
                <CircularProgress size={15} />
              </div>
            ) : (
              <Doughnut
                id="dChart"
                data={{
                  datasets: [
                    {
                      data: props.sgip_statistics.map(st => st.total),
                      backgroundColor: [
                        '#819BE7',
                        '#4B3FB5',
                        '#55D8FE',
                        '#7A72F3',
                        '#ACDEE4',
                      ],
                    },
                  ],
                  labels: props.sgip_statistics.map(st => st.id),
                }}
                options={{
                  legend: {
                    display: true,
                    position: 'bottom',
                  },
                  maintainAspectRatio: false,
                }}
                width={100}
                height={400}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export class StatusDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10,
      sort_column: 'last_modified_date',
      sort_order: 'desc',
      applicationId: '',
      applicationNumber: '',
      systemId: '',
      systemName: '',
      homeownerName: '',
      installerName: '',
      status: 'All',
      applicationSubmittedBy: 'All',
      openCreateNewRequest: false,
      searchValue: '',
      selectedSite: {},
      errMsg: '',
      showErr: false,
      isNotAbleToCreateNewRequest: false,
      selectedType: { label: 'Applications with YSM', key: 'ysm' },
      showDinInstallerList: false,
      isEnphaseAdmin: false,
      isAdmin: false,
      configurationParameterDialog: false,
      tabValue: 0,
      configureParameterUtility: '',
      configureParameterCategory: '',
      configureParameterResiliency: 'Not Applicable',
      configureParameterIncentiveCategory: '',
      configureParameterIncentiveUtility: '',
      configureParameterITCClaimed: '',
      configureParameterEncharge3Rate: '',
      configureParameterEncharge10Rate: '',
      configureParameterIQBattery5PRate: '',
      configureConfirmationDialog: false,
      configureConfirmationMessage: '',
      configureParameterUtilityError: false,
      configureParameterCategoryError: false,
      configureParameterResiliencyError: false,
      configureParameterIncentiveCategoryError: false,
      configureParameterIncentiveUtilityError: false,
      configureParameterITCClaimedError: false,
      configureParameterEncharge3RateError: false,
      configureParameterEncharge10RateError: false,
      configureParameterIQBattery5PRateError: false,
      configureParameterType: '',
      blockedUtilityTableData: [],
      updateIncentiveRatesTableData: [],
      hideChart: false,
    };
    this.LIST_FILTER = [
      { label: 'Applications with DIN', key: 'din' },
      { label: 'Applications with YSM', key: 'ysm' },
    ];
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleDinInstallerList = this.handleDinInstallerList.bind(this);
    this.handleApplicationSearch = this.handleApplicationSearch.bind(this);
    this.searchValues = debounce(this.searchValues, 1000);

    this.sgipUtilityCompany = [
      PACIFIC_GAS_AND_ELECTRIC_COMPANY,
      SAN_DIEGO_GAS_AND_ELECTRIC_COMPANY,
      SOUTHERN_CALIFORNIA_EDISON,
      SOUTHERN_CALIFORNIA_GAS_COMPANY,
    ];
    this.CONFIGURE_UPDATE_ACTIONS = ['Block', 'Unblock', 'Update'];
    this.sgipBugetCategories = [
      EQUITY,
      SMALL_RESIDENTIAL,
      EQUITY_RESILIENCY,
      LARGE_SCALE_STORAGE,
    ];
    this.sgipResiliencyITCClaimedOptions = ['YES', 'NO', 'Not Applicable'];
    this.blockedUtilityTableHeadings = [
      'Utility',
      'Category',
      'Resiliency',
      'Blocked',
    ];
    this.updateIncentiveRatesTableHeadings = [
      'Utility',
      'Category',
      'ITC Claimed',
      'IQ Battery 3 / IQ Battery 3T ($)',
      'IQ Battery 10 / IQ Battery 10T ($)',
      'IQ Battery 5P Rate ($)',
    ];
    this.origin = getEnlightenOrigin();
  }

  getAdminType(selectedType) {
    return this.state.isEnphaseAdmin === true ? selectedType.key : '';
  }

  toggleChart() {
    this.setState(prevState => ({
      ...prevState,
      hideChart: !prevState.hideChart,
    }));
  }

  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  handleConfigureParametersChange = (e, label, key, errorKey) => {
    if (
      label === 'Encharge10' ||
      label === 'Encharge3' ||
      label === 'IQBattery5P'
    ) {
      e.target.value = Number(
        Math.max(0, parseInt(e.target.value, 10)).toString().slice(0, 5)
      );
    }
    this.filterConfigureParameters(key, e.target.value);
    this.setState({ [key]: e.target.value, [errorKey]: false });
    if (
      (label === 'Encharge10' ||
        label === 'Encharge3' ||
        label === 'IQBattery5P') &&
      isEmpty(e.target.value)
    ) {
      this.setState({ [errorKey]: true });
    }
  };

  filterConfigureParameters = (key, value) => {
    if (key === 'configureParameterCategory') {
      if (
        value === 'Equity Budget' ||
        value === 'Equity Resiliency Budget' ||
        value === 'Large Scale Storage'
      ) {
        this.sgipResiliencyITCClaimedOptions = ['Not Applicable'];
      } else {
        this.sgipResiliencyITCClaimedOptions = ['YES', 'NO'];
      }
    }
    if (key === 'configureParameterIncentiveCategory') {
      if (value === 'Large Scale Storage') {
        this.sgipResiliencyITCClaimedOptions = ['YES', 'NO'];
        this.setState({ configureParameterITCClaimed: '' });
      } else {
        this.sgipResiliencyITCClaimedOptions = ['Not Applicable'];
        this.setState({
          configureParameterITCClaimed: 'Not Applicable',
          configureParameterITCClaimedError: false,
        });
      }
    }
  };

  handleChangeRowsPerPage(event) {
    const { installerView } = this.props;
    const { selectedType } = this.state;
    if (event.target.value) {
      this.props.getSGIPRequests({
        pageNum: 0,
        recordCount: event.target.value,
        sort_column: this.state.sort_column,
        sort_order: this.state.sort_order,
        installerView,
        adminType: this.getAdminType(selectedType),
        application_id: this.state.applicationId,
        application_number: this.state.applicationNumber,
        system_id: this.state.systemId,
        system_name: this.state.systemName,
        homeowner_name: this.state.homeownerName,
        installer_name: this.state.installerName,
        application_status: this.state.status,
        application_submitted_by: this.state.applicationSubmittedBy,
      });
      this.setState({ rowsPerPage: event.target.value, page: 0 });
    }
  }

  handleChangePage(event, newPage) {
    const { installerView } = this.props;
    const { selectedType } = this.state;
    this.props.getSGIPRequests({
      pageNum: newPage,
      recordCount: this.state.rowsPerPage,
      sort_column: this.state.sort_column,
      sort_order: this.state.sort_order,
      installerView,
      adminType: this.getAdminType(selectedType),
      application_id: this.state.applicationId,
      application_number: this.state.applicationNumber,
      system_id: this.state.systemId,
      system_name: this.state.systemName,
      homeowner_name: this.state.homeownerName,
      installer_name: this.state.installerName,
      application_status: this.state.status,
      application_submitted_by: this.state.applicationSubmittedBy,
    });
    this.setState({ page: newPage });
  }

  sortData(column_id) {
    const { installerView } = this.props;
    const { selectedType } = this.state;
    let sOrder = '';
    if (this.state.sort_column === column_id) {
      if (this.state.sort_order === 'asc') {
        sOrder = 'desc';
      } else {
        sOrder = 'asc';
      }
      this.props.getSGIPRequests({
        pageNum: 0,
        recordCount: this.state.rowsPerPage,
        sort_column: this.state.sort_column,
        sort_order: sOrder,
        installerView,
        adminType: this.getAdminType(selectedType),
        application_id: this.state.applicationId,
        application_number: this.state.applicationNumber,
        system_id: this.state.systemId,
        system_name: this.state.systemName,
        homeowner_name: this.state.homeownerName,
        installer_name: this.state.installerName,
        application_status: this.state.status,
        application_submitted_by: this.state.applicationSubmittedBy,
      });
      this.setState({ sort_order: sOrder });
    } else {
      this.props.getSGIPRequests({
        pageNum: 0,
        recordCount: this.state.rowsPerPage,
        sort_column: column_id,
        sort_order: 'asc',
        installerView,
        adminType: this.getAdminType(selectedType),
        application_id: this.state.applicationId,
        application_number: this.state.applicationNumber,
        system_id: this.state.systemId,
        system_name: this.state.systemName,
        homeowner_name: this.state.homeownerName,
        installer_name: this.state.installerName,
        application_status: this.state.status,
        application_submitted_by: this.state.applicationSubmittedBy,
      });
      this.setState({ sort_column: column_id, sort_order: 'asc' });
    }
  }

  siteSelection = (e, selectedSite) => {
    if (selectedSite) {
      this.setState({ selectedSite, searchValue: selectedSite.label });
    }
  };

  handleClose = () =>
    this.setState({
      openCreateNewRequest: false,
      selectedSite: {},
      newRequestFrom: '',
      searchValue: '',
    });

  loadApplication(isEnphaseAdmin) {
    const { installerView } = this.props;
    const { selectedType } = this.state;
    const adminType = isEnphaseAdmin ? selectedType.key : '';
    this.props.getSGIPRequests({
      pageNum: 0,
      recordCount: 10,
      sort_column: this.state.sort_column,
      sort_order: this.state.sort_order,
      installerView: installerView,
      adminType,
      application_id: this.state.applicationId,
      application_number: this.state.applicationNumber,
      system_id: this.state.systemId,
      system_name: this.state.systemName,
      homeowner_name: this.state.homeownerName,
      installer_name: this.state.installerName,
      application_status: this.state.status,
      application_submitted_by: this.state.applicationSubmittedBy,
    });
    this.props.getSGIPStatistics({
      adminType,
    });
  }

  componentDidMount() {
    const { installerView } = this.props;
    this.props.getSearchResult({
      searchValue: '_ALL_SITES_',
      searchType: 'all_stages',
    });
    this.props.getApplicationsSiteList();
    this.props.getUserType({
      successCb: response => {
        const { role } = response;
        const isEnphaseAdmin = role === 'enphase_admin';
        this.loadApplication(isEnphaseAdmin);
        this.setState({ isEnphaseAdmin });
        if (!installerView && !isEnphaseAdmin) {
          this.setState({ hideChart: true });
        }
      },
      failureCb: () => {
        this.loadApplication(false);
      },
    });
    this.props.getSgipConfigs({
      utilityId: '',
      successCb: () => {
        this.createBlockUtilityTableData();
      },
    });
    this.props.getSgipIncentiveRates({
      successCb: () => {
        this.createUpdaeIncentiveRateTableData();
      },
    });
  }

  createBlockUtilityTableData = () => {
    let data = [];
    this.props.utilityBlockInfo.map(utilityBlocked => {
      if (utilityBlocked.block === true) {
        let tableObj = [
          utilityBlocked.utility_name,
          utilityBlocked.budget_category_name,
          utilityBlocked.resiliency,
          'Blocked',
        ];
        data.push(tableObj);
      }
      return null;
    });
    this.setState({ blockedUtilityTableData: data });
  };

  createUpdaeIncentiveRateTableData = () => {
    let data = [];
    this.props.incentiveRates.map(incentiveRate => {
      let tableObj = [
        incentiveRate.utility_name,
        incentiveRate.budget_category,
        incentiveRate.itc_claimed,
        incentiveRate.encharge3_rate,
        incentiveRate.encharge10_rate,
        incentiveRate.iq_battery5p_rate,
      ];
      data.push(tableObj);

      return null;
    });
    this.setState({ updateIncentiveRatesTableData: data });
  };

  componentDidUpdate(prevProps) {
    const { accountDetails } = this.props;
    if (!isEqual(accountDetails, prevProps.accountDetails)) {
      const isAdmin =
        accountDetails.company_id === 5 &&
        accountDetails.roles.includes('admin');
      this.setState({ isAdmin });
    }
  }

  handleSearch = event => {
    if (event) {
      const newValue = event.target.value;
      this.setState({ searchValue: newValue });
      const searchValue =
        newValue && newValue.length > 0 ? newValue : '_ALL_SITES_';
      this.props.getSearchResult({ searchValue, searchType: 'all_stages' });
    }
  };

  redirectToCreateSgip() {
    // this.handleClose();
    trackEvents('triggerCreateSGIPInstaller');
    let sId = this.state.selectedSite.id;
    const createPath = this.props.isInstaller
      ? `/?siteId=${sId}&utype=inst`
      : `/?siteId=${sId}`;
    this.props.history.push(createPath);
    window.parent.postMessage(
      { message: CREATE_NEW_APPLICATION, siteId: sId },
      this.origin
    );
  }

  handleOptionDisabled(option) {
    return (
      option.state !== 'CA' ||
      includes(this.props.applicationSiteIds, toString(option.id))
    );
  }

  createOrShowMsgOnCreateNewRequest() {
    this.props.getComapnyInfo({
      company_id: this.props.accountDetails.company_id,
      successCb: result => {
        const { installers } = result;
        let cInfo = installers.length > 0 ? installers[0] : {};
        if (
          get(cInfo, 'is_sgip_developer') &&
          get(cInfo, 'sgip_developer_key')
        ) {
          this.setState({ openCreateNewRequest: true });
        } else {
          this.setState({ isNotAbleToCreateNewRequest: true });
        }
      },
      failureCb: () => {},
    });
  }

  handleClose2() {
    this.setState({ isNotAbleToCreateNewRequest: false });
  }

  handleFilterChange(event) {
    const { installerView } = this.props;
    const value = event.target.value;
    const selectedType = this.LIST_FILTER.find(obj => obj.label === value);
    this.props.getSGIPRequests({
      pageNum: 0,
      recordCount: 10,
      sort_column: 'application_id',
      sort_order: 'desc',
      installerView: installerView,
      adminType: this.getAdminType(selectedType),
      application_id: '',
      application_number: '',
      system_id: '',
      system_name: '',
      homeowner_name: '',
      installer_name: '',
      application_status: '',
      application_submitted_by: '',
    });
    this.props.getSGIPStatistics({
      adminType: this.getAdminType(selectedType),
    });
    this.setState({
      selectedType,
      page: 0,
      sort_column: 'application_id',
      sort_order: 'desc',
      rowsPerPage: 10,
      applicationId: '',
      applicationNumber: '',
      systemId: '',
      systemName: '',
      homeownerName: '',
      status: 'All',
      applicationSubmittedBy: 'All',
    });
  }

  handleDinInstallerList() {
    this.setState({ showDinInstallerList: !this.state.showDinInstallerList });
  }

  handleConfigurationParametersDialog = isDialog => {
    this.setState({ configurationParameterDialog: isDialog });
    if (
      !isDialog &&
      !this.CONFIGURE_UPDATE_ACTIONS.includes(this.state.configureParameterType)
    ) {
      this.clearConfigureParameters();
    }
  };

  handleBlockUnblockButtonClick = (e, label) => {
    if (isEmpty(this.state.configureParameterUtility)) {
      this.setState({ configureParameterUtilityError: true });
    }
    if (isEmpty(this.state.configureParameterCategory)) {
      this.setState({ configureParameterCategoryError: true });
    }
    if (isEmpty(this.state.configureParameterResiliency)) {
      this.setState({ configureParameterResiliencyError: true });
    }

    if (
      !isEmpty(this.state.configureParameterUtility) &&
      !isEmpty(this.state.configureParameterCategory) &&
      !isEmpty(this.state.configureParameterResiliency)
    ) {
      this.setState({
        configureConfirmationDialog: true,
        configureParameterUtilityError: false,
        configureParameterCategoryError: false,
        configureParameterResiliencyError: false,
      });
      if (label === 'Block') {
        this.setState(
          {
            configureConfirmationMessage:
              'Are you sure you want to block the journey?',
            configureParameterType: 'Block',
          },
          () => {
            this.handleConfigurationParametersDialog(false);
          }
        );
      } else if (label === 'Unblock') {
        this.setState(
          {
            configureConfirmationMessage:
              'Are you sure you want to unblock the journey?',
            configureParameterType: 'Unblock',
          },
          () => {
            this.handleConfigurationParametersDialog(false);
          }
        );
      }
    }
  };

  handelUpdateButtonClick = e => {
    if (isEmpty(this.state.configureParameterIncentiveUtility)) {
      this.setState({ configureParameterIncentiveUtilityError: true });
    }
    if (isEmpty(this.state.configureParameterIncentiveCategory)) {
      this.setState({ configureParameterIncentiveCategoryError: true });
    }
    if (isEmpty(this.state.configureParameterITCClaimed)) {
      this.setState({ configureParameterITCClaimedError: true });
    }
    if (isEmpty(this.state.configureParameterEncharge3Rate)) {
      this.setState({ configureParameterEncharge3RateError: true });
    }
    if (isEmpty(this.state.configureParameterEncharge10Rate)) {
      this.setState({ configureParameterEncharge10RateError: true });
    }
    if (isEmpty(this.state.configureParameterIQBattery5PRate)) {
      this.setState({ configureParameterIQBattery5PRateError: true });
    }

    if (
      !isEmpty(this.state.configureParameterIncentiveUtility) &&
      !isEmpty(this.state.configureParameterIncentiveCategory) &&
      !isEmpty(this.state.configureParameterITCClaimed) &&
      !isEmpty(this.state.configureParameterEncharge3Rate) &&
      !isEmpty(this.state.configureParameterEncharge10Rate) &&
      !isEmpty(this.state.configureParameterIQBattery5PRate)
    ) {
      this.setState(
        {
          configureConfirmationDialog: true,
          configureParameterIncentiveUtilityError: false,
          configureParameterIncentiveCategoryError: false,
          configureParameterITCClaimedError: false,
          configureParameterEncharge3RateError: false,
          configureParameterEncharge10RateError: false,
          configureParameterIQBattery5PRateError: false,
          configureConfirmationMessage:
            'Are you sure you want to update the incentive rates?',
          configureParameterType: 'Update',
        },
        () => {
          this.handleConfigurationParametersDialog(false);
        }
      );
    }
  };

  handleYesClick = e => {
    if (
      this.state.configureParameterType === 'Block' ||
      this.state.configureParameterType === 'Unblock'
    ) {
      this.props.blockSGIPConfigParams({
        body: {
          utility_id:
            this.sgipUtilityCompany.indexOf(
              this.state.configureParameterUtility
            ) + 1,
          utility_name: this.state.configureParameterUtility,
          budget_category_id:
            this.sgipBugetCategories.indexOf(
              this.state.configureParameterCategory
            ) + 1,
          budget_category_name: this.state.configureParameterCategory,
          // send NA in case of NOT APPLICABLE
          resiliency:
            this.state.configureParameterResiliency === 'Not Applicable'
              ? 'NA'
              : this.state.configureParameterResiliency,
          block: this.state.configureParameterType === 'Block' ? true : false,
        },
        successCb: () => {
          this.props.getSgipConfigs({
            utilityId: '',
            successCb: () => {
              this.createBlockUtilityTableData();
            },
          });
        },
      });
      this.setState({
        configureConfirmationDialog: false,
        configureParameterUtility: '',
        configureParameterResiliency: 'Not Applicable',
        configureParameterCategory: '',
        configureParameterType: '',
      });
    } else {
      this.props.updateIncentiveRateSGIPConfigParams({
        body: {
          utility_id:
            this.sgipUtilityCompany.indexOf(
              this.state.configureParameterIncentiveUtility
            ) + 1,
          utility_name: this.state.configureParameterIncentiveUtility,
          budget_id:
            this.sgipBugetCategories.indexOf(
              this.state.configureParameterIncentiveCategory
            ) + 1,
          budget_category: this.state.configureParameterIncentiveCategory,
          // send NA in case of NOT APPLICABLE
          itc_claimed:
            this.state.configureParameterITCClaimed === 'Not Applicable'
              ? 'NA'
              : this.state.configureParameterITCClaimed,
          encharge3_rate: this.state.configureParameterEncharge3Rate,
          encharge10_rate: this.state.configureParameterEncharge10Rate,
          iq_battery5p_rate: this.state.configureParameterIQBattery5PRate,
        },
        successCb: () => {
          this.props.getSgipIncentiveRates({
            successCb: () => {
              this.createUpdaeIncentiveRateTableData();
            },
          });
        },
      });
      this.setState({
        configureConfirmationDialog: false,
        configureParameterIncentiveUtility: '',
        configureParameterIncentiveCategory: '',
        configureParameterITCClaimed: '',
        configureParameterEncharge3Rate: '',
        configureParameterEncharge10Rate: '',
        configureParameterIQBattery5PRate: '',
        configureParameterType: '',
        tabValue: 0,
      });
    }
  };

  clearConfigureParameters = () => {
    this.setState({
      configureParameterUtility: '',
      configureParameterCategory: '',
      configureParameterResiliency: 'Not Applicable',
      configureParameterIncentiveUtility: '',
      configureParameterIncentiveCategory: '',
      configureParameterITCClaimed: '',
      configureParameterEncharge3Rate: '',
      configureParameterEncharge10Rate: '',
      configureParameterIQBattery5PRate: '',
      tabValue: 0,
    });
  };

  searchValues = obj => {
    this.props.getSGIPRequests(obj);
  };

  handleApplicationSearch(value, id) {
    const { installerView } = this.props;
    const details = {
      pageNum: 0,
      recordCount: this.state.rowsPerPage,
      sort_column: this.state.sort_column,
      sort_order: this.state.sort_order,
      installerView,
      adminType: this.getAdminType(this.state.selectedType),
      application_id: this.state.applicationId,
      application_number: this.state.applicationNumber,
      system_id: this.state.systemId,
      system_name: this.state.systemName,
      homeowner_name: this.state.homeownerName,
      installer_name: this.state.installerName,
      application_status: this.state.status,
      application_submitted_by: this.state.applicationSubmittedBy,
    };

    switch (id) {
      case 'application_id':
        details.application_id = value;
        break;
      case 'application_number':
        details.application_number = value;
        break;
      case 'site_id':
        details.system_id = value;
        break;
      case 'site_name':
        details.system_name = value;
        break;
      case 'homeowner_name':
        details.homeowner_name = value;
        break;
      case 'installer_name':
        details.installer_name = value;
        break;
      case 'application_submitted_by':
        details.application_submitted_by = value;
        break;
      default:
        details.application_status = value;
    }

    this.setState({
      applicationId: details.application_id,
      applicationNumber: details.application_number,
      systemId: details.system_id,
      systemName: details.system_name,
      homeownerName: details.homeowner_name,
      installerName: details.installer_name,
      status: details.application_status,
      applicationSubmittedBy: details.application_submitted_by,
    });
    this.searchValues(details);
  }

  getSearchValue = id => {
    switch (id) {
      case 'application_id':
        return this.state.applicationId;
      case 'application_number':
        return this.state.applicationNumber;
      case 'site_name':
        return this.state.systemName;
      case 'site_id':
        return this.state.systemId;
      case 'homeowner_name':
        return this.state.homeownerName;
      case 'installer_name':
        return this.state.installerName;
      default:
        return this.state.status;
    }
  };

  render() {
    const {
      classes,
      sgip_requests,
      totalElements,
      currentlySending,
      message,
      messageType,
      sgip_statistics,
      getSGIPRequetsInProgress,
      getSGIPStatisticsInProgress,
      installerView,
      searchResults,
      siteSearchInProgress,
    } = this.props;
    const {
      page,
      rowsPerPage,
      sort_column,
      sort_order,
      openCreateNewRequest,
      searchValue,
      selectedSite,
      showErr,
      errMsg,
      isNotAbleToCreateNewRequest,
      selectedType,
      isEnphaseAdmin,
    } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.headerDiv}>
          <Typography variant="h5" component="h5" className={classes.header}>
            {'SGIP Status Dashboard'}
          </Typography>
          {isEnphaseAdmin && (
            <Fragment>
              <Select
                labelId="installer_type_sel"
                value={selectedType.label}
                onChange={e => {
                  this.handleFilterChange(e);
                }}
                inputProps={{ underline: classes.dropdown }}
                style={{ color: '#EA6318', fontSize: '16px' }}
                disableUnderline
              >
                {this.LIST_FILTER.map((obj, idx) => {
                  return (
                    <MenuItem value={obj.label} key={idx}>
                      {obj.label}
                    </MenuItem>
                  );
                })}
              </Select>
              <Box className={classes.displayFlex}>
                <Button
                  disableRipple={false}
                  classes={{ label: classes.installerListLabel }}
                  onClick={e => this.handleConfigurationParametersDialog(true)}
                >
                  Configure SGIP Parameters
                </Button>
                <Button
                  disableRipple={false}
                  classes={{ label: classes.installerListLabel }}
                  onClick={this.handleDinInstallerList}
                >
                  DIN Installer List
                </Button>
              </Box>
            </Fragment>
          )}

          {installerView && (
            <div style={{ paddingRight: '10px' }}>
              <Button
                size="small"
                disableRipple={true}
                style={{
                  fontSize: '15px',
                  color: '#3B86FF',
                  textTransform: 'none',
                }}
                onClick={() => this.createOrShowMsgOnCreateNewRequest()}
                classes={{
                  root: classes.createNewButton,
                  label: classes.createNewLabel,
                }}
              >
                Create New Request
              </Button>
              <br />
              {showErr && (
                <Typography variant="caption" style={{ color: 'red' }}>
                  {errMsg}
                </Typography>
              )}
            </div>
          )}
          {/* only visible to din or ysm admins */}
          {!installerView && !isEnphaseAdmin && (
            <Button
              size="small"
              variant="outlined"
              color="primary"
              className={classes.outlineButton}
              onClick={() => this.toggleChart()}
            >
              {!this.state.hideChart
                ? 'Hide Chart'
                : 'Overall Application Status'}
            </Button>
          )}
        </div>
        {currentlySending === true ? (
          <Loader loading={currentlySending} />
        ) : (
          <Fragment>
            <Grid container direction="row">
              <Grid
                item
                xs={12}
                md={!this.state.hideChart ? 9 : 12}
                style={{ paddingRight: '10px' }}
              >
                <Paper>
                  <TableContainer className={classes.container}>
                    <Table stickyHeader style={{ borderCollapse: 'collapse' }}>
                      <TableHead>
                        <TableRow>
                          {REQUEST_LISTING_COLUMNS.map(
                            column =>
                              !(
                                installerView &&
                                (column.id === 'installer_name' ||
                                  column.id === 'application_submitted_by')
                              ) && (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{
                                    minWidth: column.minWidth,
                                    padding: '10px',
                                  }}
                                  className={classes.tableHeader}
                                >
                                  <Box className={classes.headerWrapper}>
                                    {column.sortable === true ? (
                                      <IconButton
                                        size="small"
                                        className={classes.headerButton}
                                        disableRipple={true}
                                        onClick={() => this.sortData(column.id)}
                                      >
                                        {column.label}
                                        {sort_column === column.id &&
                                          (sort_order === 'asc' ? (
                                            <KeyboardArrowUp />
                                          ) : (
                                            <KeyboardArrowDown />
                                          ))}
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        size="small"
                                        className={classes.headerButton}
                                        disabled={true}
                                        style={{ color: '#FFE785' }}
                                        disableRipple={true}
                                      >
                                        {column.label}
                                      </IconButton>
                                    )}
                                    {column.searchable &&
                                      column.label !== 'Status' &&
                                      column.label !== 'Submitted By' && (
                                        <Input
                                          className={classes.input}
                                          disableUnderline
                                          value={this.getSearchValue(column.id)}
                                          inputProps={{
                                            maxLength: 25,
                                          }}
                                          onChange={event =>
                                            this.handleApplicationSearch(
                                              event.target.value,
                                              column.id
                                            )
                                          }
                                        />
                                      )}
                                    {column.searchable &&
                                      column.label === 'Status' && (
                                        <Select
                                          variant="outlined"
                                          className={classes.selectDropdown}
                                          value={this.state.status}
                                          onChange={event =>
                                            this.handleApplicationSearch(
                                              event.target.value,
                                              column.id
                                            )
                                          }
                                        >
                                          <MenuItem value="All">All</MenuItem>
                                          <MenuItem value="0">
                                            {' '}
                                            Application Incomplete
                                          </MenuItem>
                                          <MenuItem value="1">
                                            {' '}
                                            New Application Received
                                          </MenuItem>
                                          <MenuItem value="2">
                                            {' '}
                                            Rebate Reservation Form
                                          </MenuItem>
                                          <MenuItem value="3">
                                            {' '}
                                            Site Activation Documents
                                          </MenuItem>
                                          <MenuItem value="4">
                                            {' '}
                                            Incentive Claim Form
                                          </MenuItem>
                                        </Select>
                                      )}
                                    {column.searchable &&
                                      column.label === 'Submitted By' && (
                                        <Select
                                          variant="outlined"
                                          className={classes.selectDropdown}
                                          value={
                                            this.state.applicationSubmittedBy
                                          }
                                          onChange={event =>
                                            this.handleApplicationSearch(
                                              event.target.value,
                                              column.id
                                            )
                                          }
                                        >
                                          <MenuItem value="All">All</MenuItem>
                                          <MenuItem value="installer">
                                            Installer
                                          </MenuItem>
                                          <MenuItem value="homeowner">
                                            Homeowner
                                          </MenuItem>
                                        </Select>
                                      )}
                                  </Box>
                                </TableCell>
                              )
                          )}
                        </TableRow>
                      </TableHead>
                      {getSGIPRequetsInProgress === true ? (
                        <TableBody>
                          <TableRow>
                            <TableCell
                              colSpan={REQUEST_LISTING_COLUMNS.length}
                              style={{
                                textAlign: 'center',
                                border: 'none',
                                padding: '10px',
                              }}
                            >
                              <br />
                              <br />
                              <br />
                              <CircularProgress size={15} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : isEqual(sgip_requests, []) === true ? (
                        <TableBody>
                          <TableRow>
                            <TableCell
                              colSpan={REQUEST_LISTING_COLUMNS.length}
                              style={{ textAlign: 'center' }}
                            >
                              <Typography variant="caption">
                                ** No Requests Available **
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : (
                        <TableBody>
                          {sgip_requests.map((row, index) => {
                            const stageName = get(
                              row,
                              'current_application_status.stage_name'
                            );
                            const statusCellClassName = !stageName
                              ? classes.warnTableCell
                              : '';
                            const unreadMessagesCount = installerView
                              ? get(row, 'unread_msg_count_installer', 0)
                              : get(row, 'unread_msg_count_admin', 0);
                            return (
                              <TableRow key={index}>
                                <TableCell>
                                  {get(row, 'application_id') &&
                                    (installerView ? (
                                      <Link
                                        variant="body2"
                                        onClick={() => {
                                          if (installerView) {
                                            this.props.history.push(
                                              `/?siteId=${get(
                                                row,
                                                'site_id'
                                              )}&utype=inst`
                                            );
                                            window.parent.postMessage(
                                              {
                                                message:
                                                  OPENING_EXISTING_APPLICATION,
                                                siteId: get(row, 'site_id'),
                                              },
                                              this.origin
                                            );
                                          }
                                        }}
                                        style={{ color: '#3B86FF' }}
                                      >
                                        {' '}
                                        {row.application_id}
                                      </Link>
                                    ) : (
                                      <Link
                                        href={`/request/${get(
                                          row,
                                          'application_id'
                                        )}`}
                                        variant="body2"
                                        style={{ color: '#3B86FF' }}
                                      >
                                        {row.application_id}
                                      </Link>
                                    ))}
                                  {!get(row, 'application_id') && 'NA'}
                                </TableCell>
                                <TableCell>
                                  {get(row, 'application_number') || 'NA'}
                                </TableCell>
                                <TableCell>
                                  {get(row, 'site_id') || 'NA'}
                                </TableCell>
                                <TableCell>
                                  {get(row, 'site_name') || 'NA'}
                                </TableCell>
                                <TableCell>{getFullName(row)}</TableCell>
                                {!installerView && (
                                  <TableCell>
                                    {get(row, 'installer_name') || 'NA'}
                                  </TableCell>
                                )}
                                <TableCell className={statusCellClassName}>
                                  {unreadMessagesCount > 0 ? (
                                    <Badge
                                      badgeContent={
                                        unreadMessagesCount > 1 ? (
                                          <span className={classes.smallFont}>
                                            {unreadMessagesCount + ' Messages'}
                                          </span>
                                        ) : (
                                          <span className={classes.smallFont}>
                                            {unreadMessagesCount + ' Message'}
                                          </span>
                                        )
                                      }
                                      color="primary"
                                      className={clsx(
                                        classes.chatBadge,
                                        unreadMessagesCount === 1 &&
                                          classes.slightlyLeft
                                      )}
                                    >
                                      {stageName || 'Application Incomplete'}
                                    </Badge>
                                  ) : (
                                    <Fragment>
                                      {stageName || 'Application Incomplete'}
                                    </Fragment>
                                  )}
                                </TableCell>
                                {!installerView && (
                                  <TableCell>
                                    {(
                                      get(row, 'application_submitted_by') ||
                                      'NA'
                                    )
                                      .charAt(0)
                                      .toUpperCase() +
                                      (
                                        get(row, 'application_submitted_by') ||
                                        'NA'
                                      ).slice(1)}
                                  </TableCell>
                                )}
                                <TableCell>
                                  {get(row, 'application_date') !== null
                                    ? moment(
                                        get(row, 'application_date')
                                      ).format('DD MMM YYYY hh:mm A')
                                    : 'NA'}{' '}
                                </TableCell>
                                <TableCell>
                                  {get(row, 'last_modified_date') !== null
                                    ? moment(
                                        get(row, 'last_modified_date')
                                      ).format('DD MMM YYYY hh:mm A')
                                    : 'NA'}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                  <TablePagination
                    className={classes.paginationWrapper}
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={totalElements}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={(e, newPage) =>
                      this.handleChangePage(e, newPage)
                    }
                    onChangeRowsPerPage={e => this.handleChangeRowsPerPage(e)}
                  />
                </Paper>
              </Grid>
              {!this.state.hideChart && (
                <Chart
                  dHeader={classes.dHeader}
                  sgip_statistics={sgip_statistics}
                  getSGIPStatisticsInProgress={getSGIPStatisticsInProgress}
                ></Chart>
              )}{' '}
            </Grid>
            <Dialog
              onClose={this.handleClose2}
              open={isNotAbleToCreateNewRequest}
              id="notAbleToCreate"
            >
              <DialogContent>
                You need to update your SGIP developer code in your company
                profile on Enlighten before you can apply for SGIP for your
                customers.{' '}
                <Link
                  className={classes.cLink}
                  onClick={() => {
                    window.open(
                      settings.enlightenUrl(
                        'manager/dashboard/company#installation-details'
                      ),
                      '_blank'
                    );
                  }}
                >
                  Click here
                </Link>{' '}
                to update.
              </DialogContent>
              <DialogActions style={{ justifyContent: 'flex-end' }}>
                <Button
                  autoFocus
                  color="primary"
                  variant="contained"
                  className={classes.acceptButton}
                  onClick={() => this.handleClose2()}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              onClose={this.handleClose}
              aria-labelledby="customized-dialog-title"
              open={openCreateNewRequest}
              // aria-labelledby="customized-dialog-title"
              id="confirmation"
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={this.handleClose}
              >
                <Typography className={classes.dialogHeader}>
                  New SGIP Application Request
                  <CloseIcon
                    onClick={this.handleClose}
                    className={classes.close}
                    size="large"
                  />
                </Typography>
              </DialogTitle>
              <DialogContent className={classes.content}>
                {/* <Typography gutterBottom style={{ fontWeight: 'bold' }}>
                  Please select the Enlighten site
                </Typography> */}
                <br />
                <Autocomplete
                  autoComplete
                  getOptionLabel={option =>
                    typeof option === 'string' ? option : option.label
                  }
                  filterOptions={x => x}
                  options={
                    searchResults.total_count ? searchResults.result : []
                  }
                  getOptionDisabled={option =>
                    this.handleOptionDisabled(option)
                  }
                  value={searchValue}
                  getOptionSelected={(option, value) => option.label === value}
                  onChange={this.siteSelection}
                  onInputChange={this.handleSearch}
                  loading={siteSearchInProgress}
                  renderInput={params => (
                    <TextField
                      {...params}
                      className={classes.textFieldWrapper}
                      required
                      label="Please enter the system name (in California only)"
                    />
                  )}
                  renderOption={option => (
                    <Grid container alignItems="center">
                      <Grid item xs>
                        <Typography variant="body1" color="textPrimary">
                          {option.id} - {option.label}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                />
                <br />
                <Typography className={classes.info}>
                  Don’t see your system? Ensure it is in California or Create
                  a&nbsp;
                  <Link
                    className={classes.cLink}
                    onClick={() => {
                      window.open(
                        settings.enlightenUrl('activations/new'),
                        '_blank'
                      );
                    }}
                  >
                    new system activation
                  </Link>
                  &nbsp;to proceed. Application has already been submitted for
                  systems which are greyed.
                </Typography>
              </DialogContent>
              <DialogActions
                style={{ padding: '20px', justifyContent: 'flex-start' }}
              >
                <Button
                  onClick={this.handleClose}
                  className={classes.rejectButton}
                >
                  Cancel
                </Button>
                <Button
                  autoFocus
                  color="primary"
                  variant="contained"
                  className={classes.acceptButton}
                  disabled={!selectedSite.id}
                  onClick={() => this.redirectToCreateSgip()}
                >
                  Proceed
                </Button>
              </DialogActions>
            </Dialog>
          </Fragment>
        )}
        {message && messageType && (
          <Snackbar severity={messageType} message={message} />
        )}
        {isEnphaseAdmin && (
          <DinInstallerList
            showDialog={this.state.showDinInstallerList}
            onClose={this.handleDinInstallerList}
            isInstaller={this.props.isInstaller}
            isAdmin={this.state.isAdmin}
          />
        )}
        {this.state.configurationParameterDialog && (
          <Dialog
            open={this.state.configurationParameterDialog}
            onClose={e => {
              this.handleConfigurationParametersDialog(false);
            }}
            fullWidth
            maxWidth="lg"
          >
            <DialogTitle>
              <Box className={clsx(classes.displayFlex, classes.dialogTitle)}>
                <Typography
                  className={clsx(classes.configureText, classes.setFontSize)}
                >
                  Configuration Parameters
                </Typography>
                <IconButton
                  onClick={e => {
                    this.handleConfigurationParametersDialog(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent>
              <Tabs
                value={this.state.tabValue}
                indicatorColor="primary"
                textColor="primary"
                onChange={this.handleTabChange}
                className={classes.tabWrapper}
              >
                <Tab label="Block/Unblock Journey" />
                <Tab label="Change Incentive Rates" />
              </Tabs>
              <TabPanel value={this.state.tabValue} index={0}>
                <Paper className={classes.paperWrapper}>
                  <SelectDropdown
                    label="Select Utility"
                    stateName="configureParameterUtility"
                    stateErrorName="configureParameterUtilityError"
                    value={this.state.configureParameterUtility}
                    optionsList={this.sgipUtilityCompany}
                    handleConfigureParametersChange={
                      this.handleConfigureParametersChange
                    }
                    classes={classes}
                    error={this.state.configureParameterUtilityError}
                  />

                  <SelectDropdown
                    label="Select Category"
                    stateName="configureParameterCategory"
                    stateErrorName="configureParameterCategoryError"
                    value={this.state.configureParameterCategory}
                    optionsList={this.sgipBugetCategories}
                    handleConfigureParametersChange={
                      this.handleConfigureParametersChange
                    }
                    classes={classes}
                    error={this.state.configureParameterCategoryError}
                  />

                  <SelectDropdown
                    label="Resiliency"
                    stateName="configureParameterResiliency"
                    stateErrorName="configureParameterResiliencyError"
                    value={this.state.configureParameterResiliency}
                    optionsList={this.sgipResiliencyITCClaimedOptions}
                    handleConfigureParametersChange={
                      this.handleConfigureParametersChange
                    }
                    classes={classes}
                    error={this.state.configureParameterResiliencyError}
                    disabled={this.sgipResiliencyITCClaimedOptions.length === 1}
                  />
                  <Box
                    className={clsx(
                      classes.buttonWrapper,
                      classes.justifyContentBetween
                    )}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      className={classes.blockUnblockButton}
                      onClick={e => {
                        this.handleBlockUnblockButtonClick(e, 'Block');
                      }}
                      disabled={!this.state.isAdmin}
                    >
                      Block
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      className={classes.blockUnblockButton}
                      onClick={e => {
                        this.handleBlockUnblockButtonClick(e, 'Unblock');
                      }}
                      disabled={!this.state.isAdmin}
                    >
                      Unblock
                    </Button>
                    <Button
                      color="primary"
                      variant="outlined"
                      className={classes.cancelButton}
                      onClick={e => {
                        this.handleConfigurationParametersDialog(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                  <UtilityTable
                    tableData={this.state.blockedUtilityTableData}
                    tableHeadings={this.blockedUtilityTableHeadings}
                  />
                </Paper>
              </TabPanel>
              <TabPanel value={this.state.tabValue} index={1}>
                <Paper className={classes.paperWrapper}>
                  <SelectDropdown
                    label="Select Utility"
                    stateName="configureParameterIncentiveUtility"
                    stateErrorName="configureParameterIncentiveUtilityError"
                    value={this.state.configureParameterIncentiveUtility}
                    optionsList={[...this.sgipUtilityCompany, 'All']}
                    handleConfigureParametersChange={
                      this.handleConfigureParametersChange
                    }
                    classes={classes}
                    error={this.state.configureParameterIncentiveUtilityError}
                  />
                  <SelectDropdown
                    label="Select Incentive Category"
                    stateName="configureParameterIncentiveCategory"
                    stateErrorName="configureParameterIncentiveCategoryError"
                    value={this.state.configureParameterIncentiveCategory}
                    optionsList={this.sgipBugetCategories}
                    handleConfigureParametersChange={
                      this.handleConfigureParametersChange
                    }
                    classes={classes}
                    error={this.state.configureParameterIncentiveCategoryError}
                  />
                  <SelectDropdown
                    label="ITC Claimed"
                    stateName="configureParameterITCClaimed"
                    stateErrorName="configureParameterITCClaimedError"
                    value={this.state.configureParameterITCClaimed}
                    optionsList={this.sgipResiliencyITCClaimedOptions}
                    handleConfigureParametersChange={
                      this.handleConfigureParametersChange
                    }
                    classes={classes}
                    error={this.state.configureParameterITCClaimedError}
                    disabled={this.sgipResiliencyITCClaimedOptions.length === 1}
                  />
                  <Box className={classes.displayFlex}>
                    <SelectDropdown
                      label="Encharge3"
                      stateName="configureParameterEncharge3Rate"
                      stateErrorName="configureParameterEncharge3RateError"
                      value={this.state.configureParameterEncharge3Rate}
                      isDropdown={false}
                      handleConfigureParametersChange={
                        this.handleConfigureParametersChange
                      }
                      classes={classes}
                      error={this.state.configureParameterEncharge3RateError}
                    />
                    <SelectDropdown
                      label="Encharge10"
                      stateName="configureParameterEncharge10Rate"
                      stateErrorName="configureParameterEncharge10RateError"
                      value={this.state.configureParameterEncharge10Rate}
                      isDropdown={false}
                      handleConfigureParametersChange={
                        this.handleConfigureParametersChange
                      }
                      classes={classes}
                      error={this.state.configureParameterEncharge10RateError}
                    />
                    <SelectDropdown
                      label="IQBattery5P"
                      stateName="configureParameterIQBattery5PRate"
                      stateErrorName="configureParameterIQBattery5PRateError"
                      value={this.state.configureParameterIQBattery5PRate}
                      isDropdown={false}
                      handleConfigureParametersChange={
                        this.handleConfigureParametersChange
                      }
                      classes={classes}
                      error={this.state.configureParameterIQBattery5PRateError}
                    />
                  </Box>
                  <Box
                    className={clsx(
                      classes.buttonWrapper,
                      classes.justifyContentCenter
                    )}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      className={clsx(
                        classes.blockUnblockButton,
                        classes.updateButton
                      )}
                      onClick={e => {
                        this.handelUpdateButtonClick();
                      }}
                      disabled={!this.state.isAdmin}
                    >
                      Update
                    </Button>
                    <Button
                      color="primary"
                      variant="outlined"
                      className={classes.cancelButton}
                      onClick={e => {
                        this.handleConfigurationParametersDialog(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                  <UtilityTable
                    tableData={this.state.updateIncentiveRatesTableData}
                    tableHeadings={this.updateIncentiveRatesTableHeadings}
                  />
                </Paper>
              </TabPanel>
            </DialogContent>
          </Dialog>
        )}

        {this.state.configureConfirmationDialog && (
          <Dialog open={this.state.configureConfirmationDialog}>
            <DialogTitle>{this.state.configureConfirmationMessage}</DialogTitle>
            <DialogActions
              className={clsx(
                classes.yesNoButtonWrapper,
                classes.justifyContentCenter
              )}
            >
              <Button
                color="primary"
                variant="contained"
                className={clsx(
                  classes.blockUnblockButton,
                  classes.updateButton
                )}
                onClick={e => this.handleYesClick()}
              >
                Yes
              </Button>
              <Button
                color="primary"
                variant="outlined"
                className={clsx(classes.cancelButton, classes.updateButton)}
                onClick={e =>
                  this.setState({
                    configureConfirmationDialog: false,
                    configurationParameterDialog: true,
                    configureParameterType: '',
                  })
                }
              >
                No
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {this.props.isUnauthorizedUser && (
          <SnakBar severity={'error'} message={'Unauthorized User'} />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getSGIPRequests: payload => dispatch(getSGIPRequests(payload)),
  getSGIPStatistics: payload => dispatch(getSGIPStatistics(payload)),
  getSearchResult: payload => dispatch(getSearchResult(payload)),
  resetSiteSearchResult: () => dispatch(resetSiteSearchResult()),
  getApplicationsSiteList: () => dispatch(getApplicationsSiteList()),
  getUserType: payload => dispatch(getUserType(payload)),
  getComapnyInfo: payload => dispatch(getComapnyInfo(payload)),
  blockSGIPConfigParams: payload => dispatch(blockSGIPConfigParams(payload)),
  updateIncentiveRateSGIPConfigParams: payload =>
    dispatch(updateIncentiveRateSGIPConfigParams(payload)),
  getSgipConfigs: payload => dispatch(getSgipConfigs(payload)),
  getSgipIncentiveRates: payload => dispatch(getSgipIncentiveRates(payload)),
});

const mapStateToProps = state => ({
  sgip_requests: state.adminReducer.sgip_requests,
  totalElements: state.adminReducer.totalElements,
  currentlySending: state.adminReducer.currentlySending,
  getSGIPStatisticsInProgress: state.adminReducer.getSGIPStatisticsInProgress,
  getSGIPRequetsInProgress: state.adminReducer.getSGIPRequetsInProgress,
  sgip_statistics: state.adminReducer.sgip_statistics,
  message: state.adminReducer.message,
  messageType: state.adminReducer.messageType,
  searchResults: state.adminReducer.searchResults,
  siteSearchInProgress: state.adminReducer.siteSearchInProgress,
  isInstaller: state.appReducer.isInstaller,
  applicationSiteIds: state.adminReducer.applicationSiteIds,
  accountDetails: state.adminReducer.accountDetails,
  utilityBlockInfo: state.sgipAppReducer.utilityBlockInfo,
  incentiveRates: state.sgipAppReducer.incentiveRates,
  isUnauthorizedUser: state.sgipAppReducer.isUnauthorizedUser,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(StatusDashboard));
