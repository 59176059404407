import React, { Component } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import InjectedCheckoutForm from './InjectedCheckoutForm';
import { getStripePublishableKey } from '../../../../containers/SgipApp/actions';
import { isEmpty } from '../../../../utils/lodash';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
	root: {
		borderRadius: theme.spacing(1.25),
		boxShadow: '1px 2px 5px 0 rgba(152, 152, 152, 0.1)',
		backgroundColor: theme.palette.tertiary.main
	}
});

class PaymentElement extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		this.loadStripePublishableKey();
	}

	loadStripePublishableKey() {
		this.props.getStripePublishableKey({
			successCb: () => {},
			failureCb: () => {}
		});
	}

	render() {
		const { stripe_publishable_key, serviceFee } = this.props;
		if (isEmpty(stripe_publishable_key)) {
			return '';
		}
		const stripePromise = loadStripe(stripe_publishable_key);
		return (
			<Elements stripe={stripePromise}>
				<InjectedCheckoutForm stripePromise serviceFee={serviceFee} />
			</Elements>
		);
	}
}
const mapDispatchToProps = (dispatch) => ({
	getStripePublishableKey: (payload) => dispatch(getStripePublishableKey(payload))
});

const mapStateToProps = (state) => ({
	emailId: state.appReducer.emailId,
	currentlySending: state.sgipAppReducer.currentlySending,
	message: state.sgipAppReducer.message,
	stripe_publishable_key: state.sgipAppReducer.stripe_publishable_key
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PaymentElement));
