import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import {
  forwardTo,
  setToken,
  getToken,
  parseParams,
  isValidToken,
} from '../../utils/helper';
import {
  setMobileView,
  setEnlightenManagerToken,
  setEnlightenManagerUserType,
  setSiteIdValue,
  setUserOperatingSystem
} from './actions';
import Breadcrubs from '../../components/Breadcrubs';
import { getAccountDetails } from '../Admin/actions';
import analytics from '../../utils/analytics';
import settings from '../../utils/settings';
import { getValue, setValue } from '../../utils/localStorage';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    height: 'calc(100% - 220px)'
  }
}));

let isTokenMissing = false;

const Layout = ({ children, isMobileView, loading, matchProps, ...rest }) => {
  const classes = useStyles();
  return (
    <Fragment>
      <Breadcrubs matchProps={matchProps} />
      <Box className={classes.root}>{children}</Box>
    </Fragment>
  );
};

class InstallerLayout extends React.Component {
  constructor(props) {
    super(props);
    const { search, pathname } = props.location;
    let { token } = parseParams(search);
    const { os } = parseParams(search);
    const { siteId } = parseParams(search);
    const { utype } = parseParams(search);
    const cookieName =settings.getTokenCookieName();
    let cookieToken = getToken(cookieName);
    if(!isValidToken(cookieToken) && (cookieName === 'enlighten_manager_token_qa2' || cookieName === 'enlighten_manager_token_rel')) {
      if (cookieName === 'enlighten_manager_token_qa2') cookieToken = getToken('enlighten_manager_token_rel')
      if (cookieName === 'enlighten_manager_token_rel') cookieToken = getToken('enlighten_manager_token_qa2')
    }
    //if (utype) setUserType(utype);
    //if (siteId) setSiteId(siteId);
    if (siteId) this.props.setSiteIdValue(siteId);
    if (os) this.props.setUserOperatingSystem(os);

    if (isValidToken(cookieToken)) {
      token = cookieToken;
    }
    if (token) {
      if (!getValue('referrer')) {
        setValue('referrer', document.referrer);
      }
      this.props.setEnlightenManagerToken(token); // redux
    }
    if (utype) this.props.setEnlightenManagerUserType(utype);

    if (token && pathname) {
      setToken(token);
      forwardTo(pathname);
    }
    if (!isValidToken(token)) {
      isTokenMissing = true;
    }

    this.gaAnalytics = new analytics();
    if (!isTokenMissing) {
      props.getAccountDetails({
        successCbk: accountDetail => {
          this.gaAnalytics.setUserId(accountDetail.user_id);
        }
      });
    }
  }

  UNSAFE_componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    const isMobileView = window.innerWidth <= 1200;
    this.props.setMobileView(isMobileView);
  };

  componentDidMount = () => {
    this.handleWindowSizeChange();
  };

  render() {
    const { component: Component, isMobileView, loading, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={matchProps => (
          <Layout isMobileView={isMobileView} loading={loading} matchProps={matchProps}>
            <Component {...matchProps} installerView={true} />
          </Layout>
        )}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setMobileView: isMobileView => dispatch(setMobileView(isMobileView)),
  setEnlightenManagerToken: tokenVal => dispatch(setEnlightenManagerToken(tokenVal)),
  getAccountDetails: payload => dispatch(getAccountDetails(payload)),
  setEnlightenManagerUserType: userType => dispatch(setEnlightenManagerUserType(userType)),
  setSiteIdValue: siteId => dispatch(setSiteIdValue(siteId)),
  setUserOperatingSystem: os => dispatch(setUserOperatingSystem(os))
});

const mapStateToProps = state => ({
  isMobileView: state.appReducer.isMobileView,
  loading: state.appReducer.loading
});

export default connect(mapStateToProps, mapDispatchToProps)(InstallerLayout);
