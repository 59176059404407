import React, { Component, Fragment } from 'react';
import {
  Box,
  InputLabel,
  Typography,
  Input,
  InputAdornment,
  Avatar,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Link
} from '@material-ui/core';
import { BoxLayout } from '../../Common';
import { Title } from '../../Texts';
import { FilledButton } from '../../Buttons';
import TickPromo from '../../../components/Payment/tickmark-promo.png';
import IconButton from '@material-ui/core/IconButton';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  getStripePublishableKey,
  verifyPromocode,
  postSgipServicePromocodePayment,
  getSgipApplicationDetailsBySiteId,
  addToCart
} from '../../../containers/SgipApp/actions';
import PaymentElement from './Payment/PaymentElement';
import { loadStripe } from '@stripe/stripe-js';
import { get } from '../../../utils/lodash';
import closeIcon from '../../../components/Payment/close-small-white.png';
import { forwardTo, numberWithCommas, addInputEventListener, trackEvents } from '../../../utils/helper';
import clsx from 'clsx';
import HelpOutlineIcon from '../../Header/Icons/HelpOutlineIcon';
import { PAYMENT_METHOD } from '../../../utils/constants';
import StorePayment from './StorePayment';

const styles = theme => ({
  root: {
    borderRadius: theme.spacing(1.25),
    boxShadow: '1px 2px 5px 0 rgba(152, 152, 152, 0.1)',
    backgroundColor: theme.palette.tertiary.main
  },
  header: {
    marginBottom: theme.spacing(1.25),
    marginTop: theme.spacing(0)
  },
  header2: {
    marginBottom: theme.spacing(1.25)
  },
  description: {
    marginBottom: theme.spacing(2),
    lineHeight: 1
  },
  field: {
    marginTop: theme.spacing(1)
  },

  boxWrapper: {
    borderRadius: theme.spacing(1.25),
    border: 'solid 1.0px #e2e3e4',
    backgroundColor: 'transparent',
    padding: theme.spacing(1.5),
    marginTop: theme.spacing(1.25)
  },

  boxPlain: {
    borderRadius: theme.spacing(1.25),
    backgroundColor: 'transparent'
  },

  text: {
    paddingBottom: '10px',
    color: '#545456'
  },
  privacyText: {
    paddingBottom: '10px',
    cursor: 'default'
  },
  policyText: {
    marginRight: '0px'
  },
  text2: {
    marginTop: theme.spacing(2.5)
  },
  comment: {
    marginTop: theme.spacing(2)
  },
  currency: {
    height: '40px'
  },
  closeicon: {
    width: theme.spacing(1.75),
    height: theme.spacing(1.75)
  },
  adornment: {
    margin: '0px'
  },
  promotext: {
    width: '100%',
    marginLeft: '0px'
  },
  tickmark: {
    width: '100%',
    marginLeft: '0px',
    padding: '16px 0px',
    cursor: 'default'
  },
  margin: {
    margin: theme.spacing(1)
  },
  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  textField: {
    width: '25ch'
  },
  ticktext: {
    width: '100%',
    paddingLeft: '10px',
    color: '#f37321'
  },
  controlwidth: {
    width: '100%',
    color: '#01b4de',
    fontWeight: 500
  },
  top: {
    marginTop: theme.spacing(2.25)
  },
  error: {
    color: 'red'
  },
  information: {
    color: '#939598',
    marginBottom: theme.spacing(2)
  },
  dConatiner: {
    width: '100%',
    margin: 'auto',
    marginTop: theme.spacing(1)
  },
  note: {
    color: '#939598',
    marginTop: '10px',
    cursor: 'default'
  },
  caution: {
    marginTop: 3
  },
  displayFlex: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  [theme.breakpoints.up('md')]: {
    dConatiner: {
      flexBasis: '45%',
      margin: '20px auto 0'
    },
    serviceCharge: {
      flexBasis: '100%'
    },
    wavierMessage: {
      margin: '0 0 0 20px',
      flexBasis: '50%',
      height: '100%'
    },
    text: {
      paddingBottom: '0'
    }
  }
});

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promoCode: '',
      applyPromoCodeFlag: false,
      stripe_publishable_key: '',
      stripePromise: '',
      errorMesage: false,
      invalidPromoCode: false,
      invalidPromoCodeMessage: 'Invalid Promo Code',
      disablePromoCodeField: false,
      termsPolicyCheck: false,
      homeOwnerPermissionCheck: false
    };
    this.handleClickShowPromo = this.handleClickShowPromo.bind(this);
    this.handleClickShowApply = this.handleClickShowApply.bind(this);
    this.handlePromoCodeChange = this.handlePromoCodeChange.bind(this);
    this.handleSubmitApplicationByPromoCode = this.handleSubmitApplicationByPromoCode.bind(this);
    this.handleTermsPolicyCheckBox = this.handleTermsPolicyCheckBox.bind(this);
    this.handleHomeOwnerPermissionCheck = this.handleHomeOwnerPermissionCheck.bind(this);
  }

  componentDidMount() {
    const { siteId } = this.props;
    this.loadStripePublishableKey();
    this.loadApplication(siteId);
    addInputEventListener();
    window.scrollTo(0, 0);
  }

  loadApplication(siteId) {
    this.props.getSgipApplicationDetailsBySiteId({
      siteId: siteId,
      isInstaller: this.props.isInstaller,
      successCb: () => {
        this.setState({ sgipApplicationFound: 'success' });
      },
      failureCb: () => {
        this.setState({ sgipApplicationFound: 'failed' });
      }
    });
  }

  loadStripePublishableKey() {
    this.props.getStripePublishableKey({
      successCb: () => {
        const { stripe_publishable_key } = this.props;
        this.setState({ stripe_publishable_key: stripe_publishable_key });
        this.setState({ currentlySending: false });
        const stripePromise = loadStripe(stripe_publishable_key);
        this.setState({ stripePromise: stripePromise });
      },
      failureCb: () => {
        this.setState({ currentlySending: false });
      }
    });
  }

  handlePromoCodeChange(e) {
    this.setState({ promoCode: e.target.value });
    this.setState({ invalidPromoCode: false });
    this.setState({ errorMesage: false });
  }

  handleClickShowApply() {
    window.scrollTo(0, 0);
    this.setState({
      applyPromoCodeFlag: false,
      errorMesage: false,
      promoCode: '',
      disablePromoCodeField: false,
      termsPolicyCheck: false,
      homeOwnerPermissionCheck: false
    });
  }

  handleSubmitApplicationByPromoCode() {
    let promoCode = this.state.promoCode;
    const { siteId } = this.props;
    this.props.postSgipServicePromocodePayment({
      promoCode,
      siteId,
      application_submitted_by: this.props.isInstaller ? 'installer' : 'homeowner',
      successCb: () => {
        trackEvents('triggerSgipPageView', { pageTitle: 'Application Status' });
        forwardTo('applicationTrack');
      },
      failureCb: () => {
        this.setState({ invalidPromoCode: true });
        this.setState({ errorMesage: 'Invalid Promo Code' });
      }
    });
  }

  handleClickShowPromo() {
    window.scrollTo(0, 0);
    if (this.state.promoCode.length === 0) {
      this.setState({ errorMesage: true });
      this.setState({ invalidPromoCode: true });
      this.setState({ invalidPromoCodeMessage: 'Please enter Promo Code' });
      return;
    }
    let promoCode = this.state.promoCode;
    this.props.verifyPromocode({
      promoCode,
      successCb: () => {
        this.setState({ applyPromoCodeFlag: true, errorMesage: false, promoCode: '', invalidPromoCode: false });
        this.setState({ invalidPromoCode: false });
        this.setState({ promoCode: promoCode });
        this.setState({ disablePromoCodeField: true });
      },
      failureCb: () => {
        this.setState({ invalidPromoCode: true });
        this.setState({ invalidPromoCodeMesage: 'Invalid Promo Code' });
      }
    });
  }

  handleTermsPolicyCheckBox() {
    this.setState({ termsPolicyCheck: !this.state.termsPolicyCheck });
  }

  handleHomeOwnerPermissionCheck() {
    this.setState({ homeOwnerPermissionCheck: !this.state.homeOwnerPermissionCheck });
  }

  render() {
    const { classes, sgip_application, serviceFeePopup, isInstaller, isIOS, addToCart, siteId } = this.props;
    let serviceFee = get(sgip_application, 'service_fee');
    const sgipBudgetElligibility = get(sgip_application, 'sgip_budget_information.sgip_budget_elligibility', '') || '';
    const applicationId = get(sgip_application, 'application_id', '') || '';
    let storage_capacity = '',
      incentive = '';
    if (sgip_application && sgip_application.hasOwnProperty('sgip_budget_information')) {
      if (sgip_application.sgip_budget_information && sgip_application.sgip_budget_information.incentive !== null) {
        incentive = sgip_application.sgip_budget_information.incentive;
      }
    }
    if (sgip_application && sgip_application.hasOwnProperty('storage_capacity')) {
      if (sgip_application.storage_capacity && sgip_application.storage_capacity !== null) {
        storage_capacity = sgip_application.storage_capacity;
      }
    }
    return (
      <Fragment>
        <BoxLayout style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Box className={clsx(classes.boxPlain, classes.dConatiner, classes.serviceCharge)}>
            <Title className={classes.header}>SGIP Service Charge</Title>
            <Typography className={classes.information} style={{ color: '#545456' }}>
              Based on your responses, {!isInstaller ? 'your' : ' the'} system capacity is{' '}
              {storage_capacity ? <b>{storage_capacity} kWh</b> : ''} and{' '}
              {!isInstaller ? 'you are' : 'the System Owner is'} eligible for an <b>estimated</b> incentive up to{' '}
              {incentive ? <b>${numberWithCommas(incentive)}</b> : ''}.{' '}
              <IconButton
                aria-label="caution"
                size="small"
                edge="end"
                className={classes.caution}
                onClick={() => serviceFeePopup()}
                disableRipple={true}
              >
                <HelpOutlineIcon width="16" height="16" />
              </IconButton>
              <br />
            </Typography>
            <Typography className={classes.text}>
              Please note that this is an indicative amount. The actual incentive amount and whether it will be approved
              will be determined by the program administrators. Enphase is only providing an application processing
              service.
            </Typography>
          </Box>
          {PAYMENT_METHOD === 'STORE' && (
            <StorePayment
              sgipBudgetElligibility={sgipBudgetElligibility}
              serviceFee={serviceFee}
              addToCart={addToCart}
              applicationId={applicationId}
              siteId={siteId}
            />
          )}
          {PAYMENT_METHOD === 'STRIPE' && (
            <Box className={classes.displayFlex}>
              <div className={classes.dConatiner}>
                <Title className={classes.header2}>Promo Code</Title>
                <Box className={classes.boxWrapper}>
                  <FormControl className={classes.controlwidth}>
                    <InputLabel className={classes.promotext} htmlFor="promo_code">
                      Enter Promo Code
                    </InputLabel>

                    <Input
                      className={classes.promotext}
                      id="promo_code"
                      onChange={this.handlePromoCodeChange}
                      value={this.state.promoCode}
                      endAdornment={
                        <InputAdornment position="start" className={classes.adornment}>
                          {!this.state.applyPromoCodeFlag ? (
                            <IconButton
                              aria-label="close"
                              style={{ padding: '0px' }}
                              onClick={this.handleClickShowPromo}
                            >
                              <Typography className={classes.controlwidth}>Apply</Typography>
                            </IconButton>
                          ) : (
                            <IconButton
                              aria-label="close"
                              style={{ padding: '0px' }}
                              onClick={this.handleClickShowApply}
                            >
                              <Avatar variant="square" alt="close" src={closeIcon} className={classes.closeicon} />
                            </IconButton>
                          )}
                        </InputAdornment>
                      }
                      disabled={this.state.disablePromoCodeField ? true : false}
                    />
                    {!this.state.promoCode.length > 0 && this.state.errorMesage ? (
                      <FormHelperText className={classes.error}>{'Please enter promo code'}</FormHelperText>
                    ) : this.state.invalidPromoCode ? (
                      <div>
                        <FormHelperText className={classes.error}>{this.state.invalidPromoCodeMesage}</FormHelperText>
                      </div>
                    ) : (
                      ''
                    )}
                  </FormControl>
                </Box>
              </div>

              {this.state.applyPromoCodeFlag && <div className={classes.dConatiner} />}
              {this.state.applyPromoCodeFlag && (
                <div className={clsx(classes.dConatiner, classes.wavierMessage)}>
                  <FormControlLabel
                    className={classes.tickmark}
                    control={
                      <Avatar
                        variant="square"
                        alt="caution image"
                        src={TickPromo}
                        className={classes.large}
                        style={{ alignSelf: 'center' }}
                      />
                    }
                    label={
                      <div>
                        <Typography className={classes.ticktext}>
                          <b>Your SGIP service fee is Waived Off.</b>
                        </Typography>
                        <Typography className={classes.ticktext} style={{ fontWeight: 300 }}>
                          Tap Submit to complete the application.
                        </Typography>
                      </div>
                    }
                  />

                  {isInstaller && (
                    <Fragment>
                      <Typography className={classes.note}>
                        Note: The system owner will be notified about this application and any future communications
                        regarding it.
                      </Typography>
                      <br />
                    </Fragment>
                  )}

                  {isInstaller ? (
                    <FormControlLabel
                      className={false ? classes.textGreyed : classes.privacyText}
                      control={
                        <Checkbox
                          name="privacy_terms"
                          checked={this.state.homeOwnerPermissionCheck}
                          onChange={this.handleHomeOwnerPermissionCheck}
                        />
                      }
                      label={
                        <Typography className={classes.policyText}>
                          I have System Owner's consent to submit this application
                        </Typography>
                      }
                    />
                  ) : (
                    ''
                  )}
                  <FormControlLabel
                    className={false ? classes.textGreyed : classes.privacyText}
                    control={
                      <Checkbox
                        name="privacy_terms"
                        checked={this.state.termsPolicyCheck}
                        onChange={this.handleTermsPolicyCheckBox}
                      />
                    }
                    label={
                      <Typography className={classes.policyText}>
                        I have read and understood Enphase’s
                        {isIOS ? (
                          <a
                            style={{ color: '#01B4DE', fontWeight: 'bold', textDecoration: 'none' }}
                            href="enphase://user?url=https://enphase.com/en-us/legal/privacy-policy"
                            target="_self"
                          >
                            {` Privacy Policy`}
                          </a>
                        ) : (
                          <Link
                            style={{ color: '#01b4de', fontWeight: 'bold', marginLeft: '5px', cursor: 'pointer' }}
                            target="_blank"
                            rel="noreferrer"
                            underline="none"
                            onClick={() => window.open('https://enphase.com/en-us/legal/privacy-policy', '_blank')}
                          >
                            Privacy Policy
                          </Link>
                        )}{' '}
                        and
                        {isIOS ? (
                          <a
                            style={{ color: '#01B4DE', fontWeight: 'bold', textDecoration: 'none' }}
                            href="enphase://user?url=https://enphase.com/en-us/legal/SGIP-incentive-assistance"
                            target="_self"
                          >
                            {` Terms of Service.`}
                          </a>
                        ) : (
                          <Link
                            style={{ color: '#01b4de', fontWeight: 'bold', marginLeft: '5px', cursor: 'pointer' }}
                            target="_blank"
                            rel="noreferrer"
                            underline="none"
                            onClick={() =>
                              window.open('https://enphase.com/en-us/legal/SGIP-incentive-assistance', '_blank')}
                          >
                            Terms of Service.
                          </Link>
                        )}
                      </Typography>
                    }
                  />
                  {!isInstaller ? (
                    <FilledButton
                      disabled={!this.state.termsPolicyCheck}
                      onClick={this.handleSubmitApplicationByPromoCode}
                      className={classes.currency}
                    >
                      Submit
                    </FilledButton>
                  ) : (
                    <FilledButton
                      disabled={!this.state.termsPolicyCheck || !this.state.homeOwnerPermissionCheck}
                      onClick={this.handleSubmitApplicationByPromoCode}
                      className={classes.currency}
                    >
                      Submit
                    </FilledButton>
                  )}
                </div>
              )}

              {!this.state.applyPromoCodeFlag && (
                <div className={classes.dConatiner}>
                  <Title className={classes.header2}>Credit Card Details</Title>
                  <Box className={classes.boxWrapper}>
                    <PaymentElement serviceFee={serviceFee} />
                  </Box>
                </div>
              )}
            </Box>
          )}
        </BoxLayout>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getStripePublishableKey: payload => dispatch(getStripePublishableKey(payload)),
  getSgipApplicationDetailsBySiteId: payload => dispatch(getSgipApplicationDetailsBySiteId(payload)),
  verifyPromocode: payload => dispatch(verifyPromocode(payload)),
  postSgipServicePromocodePayment: payload => dispatch(postSgipServicePromocodePayment(payload)),
  addToCart: payload => dispatch(addToCart(payload))
});

const mapStateToProps = state => ({
  siteId: state.appReducer.siteId,
  message: state.sgipAppReducer.message,
  sgip_application: state.sgipAppReducer.sgip_application,
  isInstaller: state.appReducer.isInstaller,
  isIOS: state.appReducer.isIOS
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(index));
