import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, StepContent, Box, Badge, Paper } from '@material-ui/core';
import { BoxLayout } from '../../../components/Common';
import { Title } from '../../../components/Texts';
import { Button, FilledButton } from '../../../components/Buttons';
import clsx from 'clsx';
import { forwardTo, downloadFile } from '../../../utils/helper';
import Loader from '../../../components/Loader';
import { FILE_UPLOAD_SIZE } from '../constants';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import { isEmpty, get, includes, last } from '../../../utils/lodash';
import {
  getSgipDocumentPresignedURL,
  postApplicantNotification,
  putSgipDocumentToS3,
  postSignedDocument,
  postFieldChangeAcceptNotification,
  postFieldChangeRejectNotification,
  downloadFileFromS3,
  getUploadedDocumentsPresignedURLs,
  getSgipApplicationDetailsBySiteId,
  getSgipNpsFeedback
} from '../actions';
import Moment from 'moment';
import SgipLiveStatus from '../../../containers/SgipApp/SgipLiveStatus';
import AppDetailsRejectDialog from '../../../components/SgipApp/AppDetailsRejectDialog';
import ApplicationTrackUploadDialog from '../../../containers/SgipApp/ApplicationTrackUploadDialog';
import DocumentUploadDialog from '../../../components/SgipApp/DocumentsUploadDialog';
import { STATUS } from '../../Admin/constants';
import InprogressIcon from '../../../components/Common/Icons/InprogressIcon';
import CompletedIcon from '../../../components/Common/Icons/CompletedIcon';
import RejectedIcon from '../../../components/Common/Icons/RejectedIcon';
import NpsFeedbackDialog from '../../../components/SgipApp/NpsFeedbackDialog';
import Chats from '../../Admin/Chats';
import ChatRoundedIcon from '@material-ui/icons/ChatRounded';
import { cloneDeep } from 'lodash';

const styles = theme => ({
  root: {
    borderRadius: '10px',
    boxShadow: '1px 2px 5px 0 rgba(152, 152, 152, 0.1)',
    backgroundColor: '#ffffff',
    padding: theme.spacing(2.5)
  },

  top: {
    margin: theme.spacing(2.25)
  },
  label: {
    color: '#939598',
    marginTop: '5px'
  },
  value: {
    color: '#545456',
    marginTop: '15px',
    letterSpacing: 'normal',
    marginRight: theme.spacing(5)
  },
  link: {
    color: '#01b4de',
    textTransform: 'none',
    marginLeft: theme.spacing(1)
  },
  stepperContent: {
    color: '#939393',
    display: 'flex'
  },
  appdetailsheading: {
    color: '#f27320',
    marginTop: theme.spacing(2.5)
  },
  appdetaillabel: {
    color: '#939598',
    marginTop: theme.spacing(1.75)
  },
  appdetailsub: {
    color: '#545456'
  },
  applicationItem: {
    margin: '18px'
  },
  pHide: {
    display: 'none'
  },
  dHide: {
    display: 'block'
  },
  chatSection: {
    display: 'flex',
    color: '#01B4DE'
  },
  chatSectionContent: {
    marginLeft: '6px',
    fontWeight: 500,
    cursor: 'pointer'
  },
  badgeBox: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(3),
    '& .MuiBadge-badge': {
      color: 'white'
    }
  },
  statusIcons: {
    width: '15px',
    height: '15px',
    marginRight: '4px'
  },
  date: {
    marginLeft: '2px'
  },
  stageLabel: {
    color: '#0A0A0A',
    marginRight: '2px',
    width: '40%'
  },
  rightSection: {
    marginLeft: 0,
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2.25)
    }
  },
  chatBoxWrapper: {
    height: theme.spacing(60)
  },
  chatBox: {
    height: '100%',
    marginTop: theme.spacing(2.25)
  },
  dMargin: {
    margin: '18px 0'
  },
  [theme.breakpoints.up('sm')]: {
    date: {
      marginLeft: '2px'
    },
    stageLabel: {
      width: 'auto'
    }
  },
  [theme.breakpoints.up('md')]: {
    container: {
      display: 'flex',
      justifyContent: 'center'
    },
    apllicationNumber: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      marginTop: '15px'
    },
    applicationDetails: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      margin: '10px  194px 30px',
      boxShadow: '1px 2px 5px 0 rgba(152, 152, 152, 0.1)',
      borderRadius: '3px',
      backgroundColor: '#fff'
    },
    top: {
      flexBasis: '45%'
    },
    dHide: {
      display: 'none'
    },
    pHide: {
      display: 'block'
    },
    value: {
      marginRight: theme.spacing(15)
    }
  }
});

const QontoConnector = withStyles({
  line: {
    minHeight: '10px'
  }
})(StepConnector);
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: '',
      rejectFlag: false,
      docsUpload: false,
      docsUploadType: '',
      appId: '',
      messageId: '',
      email: '',
      application_stages: [
        {
          id: 1,
          stage_name: 'Application Details',
          status_name: '',
          last_updated_date: '',
          sub_stages: [
            { id: 1, stage_name: 'Submitted', status_name: '', last_updated_date: '' },
            { id: 2, stage_name: 'Verification', status_name: '', last_updated_date: '' }
          ]
        },
        {
          id: 2,
          stage_name: 'Rebate Reservation Form',
          status_name: '',
          last_updated_date: '',
          sub_stages: [
            { id: 1, stage_name: 'Generation', status_name: '', last_updated_date: '' },
            { id: 2, stage_name: 'Signature By All parties', status_name: '', last_updated_date: '' },
            { id: 3, stage_name: 'Submission to Utility', status_name: '', last_updated_date: '' },
            { id: 4, stage_name: 'Approval from Utility', status_name: '', last_updated_date: '' }
          ]
        },
        {
          id: 3,
          stage_name: 'Site activation Documents',
          status_name: '',
          last_updated_date: '',
          sub_stages: [
            { id: 1, stage_name: 'Request to Installer', status_name: '', last_updated_date: '' },
            { id: 2, stage_name: 'Submission from Installer', status_name: '', last_updated_date: '' },
            { id: 3, stage_name: 'Documents Verification', status_name: '', last_updated_date: '' }
          ]
        },
        {
          id: 4,
          stage_name: 'Incentive Claim Form',
          status_name: '',
          last_updated_date: '',
          sub_stages: [
            { id: 1, stage_name: 'Generation', status_name: '', last_updated_date: '' },
            { id: 2, stage_name: 'Signature By All parties', status_name: '', last_updated_date: '' },
            { id: 3, stage_name: 'Submission to Utility', status_name: '', last_updated_date: '' },
            { id: 4, stage_name: 'Site Inspection', status_name: '', last_updated_date: '' },
            { id: 5, stage_name: 'Approval from Utility', status_name: '', last_updated_date: '' }
          ]
        }
      ],
      signedDocumentUploadStatus: '',
      signedDocument: [],
      signedDocumentError: {
        file_name: {
          error: false,
          msg: ''
        }
      },
      applicationDetailsMessageId: '',
      applicationDetailsRejectReason: '',
      documentRequestedPopupFlag: false,
      shouldNpsFeedback: false,
      applicationStage: ''
    };
    this.handleSignedDocumentFileUpload = this.handleSignedDocumentFileUpload.bind(this);
    this.rejectApplication = this.rejectApplication.bind(this);
    this.documentsUpload = this.documentsUpload.bind(this);
    this.cancelRejectApplication = this.cancelRejectApplication.bind(this);
    this.cancelDocumentsUpload = this.cancelDocumentsUpload.bind(this);
    this.acceptApplicationDetailsNotifiction = this.acceptApplicationDetailsNotifiction.bind(this);
    this.loadApplication = this.loadApplication.bind(this);
    this.rejectApplicationDetailsNotifiction = this.rejectApplicationDetailsNotifiction.bind(this);
    this.handleDocumentUploadPopupClose = this.handleDocumentUploadPopupClose.bind(this);
    this.handleDocumentUploadSuccess = this.handleDocumentUploadSuccess.bind(this);
  }

  componentDidMount() {
    const { sgip_application, siteId } = this.props;
    this.loadApplication(siteId);
    this.setState({ sgipApplication: sgip_application });
  }

  downloadAllUtilityFiles(urls) {
    if (urls.length === 0) return;
    for (var i = 0; i < urls.length; i++) {
      downloadFile(urls[i], this.props.isIOS, this.props.isAndroid);
    }
  }

  async handleFileDownloads(messageID) {
    const { siteId } = this.props;
    this.props.getUploadedDocumentsPresignedURLs({
      messageID: messageID,
      fileType: 's3',
      successCb: () => {
        const { documents_urls } = this.props;
        this.downloadAllUtilityFiles(documents_urls);
      },
      failureCb: () => {
        this.loadApplication(siteId);
        console.log('We are facing some issue with file download, you can try after sometime.');
      }
    });
    this.loadApplication(siteId);
  }

  handleDownloadFile(event, messageID) {
    this.setState({
      downloadInProgress: true
    });
    this.handleFileDownloads(messageID);
  }

  loadApplication(siteId) {
    this.props.getSgipApplicationDetailsBySiteId({
      siteId: siteId,
      isInstaller: this.props.isInstaller,
      successCb: sgipApplication => {
        this.setState({ sgipApplicationFound: 'success' });
        let applicationStage = cloneDeep(this.state.application_stages);
        this.setState({ activeStep: sgipApplication.application_stages.length, email: get(sgipApplication, 'email') });
        let i = 1;
        sgipApplication.application_stages.map((stage, k) => {
          applicationStage[i - 1].last_updated_date = stage.last_updated_date;
          stage.substages.map((subStage, index) => {
            applicationStage[i - 1].sub_stages[index].last_updated_date = subStage.last_updated_date;
            applicationStage[i - 1].sub_stages[index].status_name = subStage.status_name;
            if (
              ['Site Inspection', 'Approval from Utility'].includes(subStage.substage_name) &&
              stage.stage_name === 'Incentive Claim Form' &&
              subStage.status_name !== 'In Progress'
            ) {
              applicationStage[i - 1].sub_stages[index].stage_name =
                subStage.substage_name + ' (' + subStage.status_name + ')';
            } else {
              applicationStage[i - 1].sub_stages[index].stage_name = subStage.substage_name || subStage.status_name;
            }
          });
          ++i;
        });
        this.props.getSgipNpsFeedback({
          applicationId: sgipApplication.application_id,
          successCb: response => {
            let feedback = [];
            let displayFeedback = false;
            const { current_application_status } = sgipApplication;
            const currentStageId = get(current_application_status, 'id');

            if (currentStageId >= 4) {
              if (currentStageId === 4) {
                const substages = get(current_application_status, 'substages');
                displayFeedback = substages.some(obj => obj.substage_id === 3 && obj.status_name === 'Completed');
                if (displayFeedback) {
                  feedback = response.find(obj => obj.application_stage === 4);
                  displayFeedback = feedback
                    ? feedback.rating < 0 && feedback.source === 'email'
                      ? true
                      : false
                    : true;
                }
              } else {
                feedback = response.find(obj => obj.application_stage === 4);
                displayFeedback = feedback ? (feedback.rating < 0 && feedback.source === 'email' ? true : false) : true;
              }

              this.setState({ shouldNpsFeedback: displayFeedback, applicationStage: 4 });
            } else if (currentStageId >= 2) {
              if (currentStageId === 2) {
                const substages = get(current_application_status, 'substages');
                displayFeedback = substages.some(obj => obj.substage_id === 3 && obj.status_name === 'Completed');
                if (displayFeedback) {
                  feedback = response.find(obj => obj.application_stage === 2);
                  displayFeedback = feedback
                    ? feedback.rating < 0 && feedback.source === 'email'
                      ? true
                      : false
                    : true;
                }
              } else {
                feedback = response.find(obj => obj.application_stage === 2);
                displayFeedback = feedback ? (feedback.rating < 0 && feedback.source === 'email' ? true : false) : true;
              }

              this.setState({ shouldNpsFeedback: displayFeedback, applicationStage: 2 });
            }
          }
        });
        this.setState({ application_stages: applicationStage });
      },
      failureCb: () => {
        this.setState({ sgipApplicationFound: 'failed' });
      }
    });
  }

  handleSignedDocumentFileUpload(message_id) {
    const { siteId } = this.props;
    this.setState({ signedDocumentUploadStatus: 'processing' });
    const { sgip_application } = this.props;
    let signedDocument = this.state.signedDocument;
    let fileData = document.getElementById('signedDocument').files[0];
    if (fileData === null) {
      return;
    }
    let file_name = document.getElementById('signedDocument').files[0].name;
    let fileSizeInMb = fileData.size / 1024 / 1024;
    fileSizeInMb = fileSizeInMb.toFixed(2);

    let app_id = get(sgip_application, 'application_id', '');

    let updatedFileName = app_id + '_signed_document' + '.' + file_name.split('.').pop();
    let uploadDate = Moment().format('YYYY-MM-DD');
    let uploadStatus = 'Uploaded';

    if (fileSizeInMb > FILE_UPLOAD_SIZE) {
      let documentError = this.state.signedDocumentError;
      documentError.file_name.error = true;
      documentError.file_name.msg = `Max file size should be ${FILE_UPLOAD_SIZE} Mb`;
      this.setState({ signedDocumentError: documentError, signedDocumentUploadStatus: 'idle' });
    } else {
      this.props.getSgipDocumentPresignedURL({
        file_name: updatedFileName,
        http_method: 'PUT',
        fileType: 's3',
        successCb: getSgipDocumentPresignedS3Url => {
          this.props.putSgipDocumentToS3({
            fileObj: fileData,
            preSignedS3Url: getSgipDocumentPresignedS3Url,
            fName: updatedFileName,
            successCbS3: () => {
              //Save signedDocument Name in Sgip Application Documents database
              let sDocument = {
                file_name: updatedFileName,
                status: uploadStatus,
                uploaded_date: uploadDate,
                message_id: message_id
              };
              this.props.postSignedDocument({
                signedDocument: sDocument,
                successCb4: () => {
                  this.setState({ signedDocumentUploadStatus: 'success' });
                  this.loadApplication(siteId);
                },
                failureCb4: () => {
                  this.setState({ signedDocumentUploadStatus: 'failed' });
                  this.loadApplication(siteId);
                }
              });
              signedDocument.push({
                display_file_name: file_name,
                file_name: updatedFileName,
                file_size: fileSizeInMb,
                uploaded_date: uploadDate,
                status: uploadStatus
              });
            },
            failureCbS3: () => {
              let documentError = this.state.signedDocumentError;
              documentError.file_name.error = true;
              documentError.file_name.msg = 'We are facing some issue with file upload, you can try after sometime.';
              this.setState({ signedDocumentError: documentError, signedDocumentUploadStatus: 'idle' });
              this.loadApplication(siteId);
            }
          });
        },
        failureCb: () => {
          let documentError = this.state.signedDocumentError;
          documentError.file_name.error = true;
          documentError.file_name.msg = 'We are facing some issue with file upload, you can try after sometime.';
          this.setState({ signedDocumentError: documentError, signedDocumentUploadStatus: 'idle' });
          this.loadApplication(siteId);
        }
      });
    }
  }

  cancelDocumentsUpload() {
    this.setState({ docsUpload: !this.state.docsUpload, docsUploadType: '', appId: '', messageId: '' });
  }

  documentsUpload(appId, msgId, subject) {
    this.setState({ docsUpload: !this.state.docsUpload, docsUploadType: subject, appId: appId, messageId: msgId });
  }

  cancelRejectApplication() {
    this.setState({ rejectFlag: !this.state.rejectFlag });
  }

  rejectApplication(applicationDetailsMessageId) {
    this.setState({ rejectFlag: !this.state.rejectFlag });
    this.setState({ applicationDetailsMessageId: applicationDetailsMessageId });
  }

  acceptApplicationDetailsNotifiction(messageId) {
    const { siteId } = this.props;
    this.props.postFieldChangeAcceptNotification({
      messageId: messageId,
      successCb: sgipApplication => {
        this.loadApplication(siteId);
      },
      failureCb: () => {
        this.loadApplication(siteId);
      }
    });
  }

  rejectApplicationDetailsNotifiction(messageId, rejectReason) {
    const { siteId } = this.props;
    this.props.postFieldChangeRejectNotification({
      messageId: messageId,
      rejectReason: rejectReason,
      successCb: sgipApplication => {
        this.setState({ rejectFlag: !this.state.rejectFlag });
        this.setState({ applicationDetailsMessageId: '' });
        this.loadApplication(siteId);
      },
      failureCb: () => {
        console.log('There was problem in Rejecting Application details changes');
        this.setState({ rejectFlag: !this.state.rejectFlag });
        this.setState({ applicationDetailsMessageId: '' });
        this.loadApplication(siteId);
      }
    });
  }

  handleDocumentUploadPopupClose() {
    this.setState({ documentRequestedPopupFlag: !this.state.documentRequestedPopupFlag });
  }

  handleDocumentUploadSuccess() {
    this.setState({ documentRequestedPopupFlag: !this.state.documentRequestedPopupFlag });
  }

  renderStatusIcon = status => {
    const { classes } = this.props;

    switch (status) {
      case STATUS.NOT_STARTED:
      case STATUS.WAITLISTED:
      case STATUS.INPROGRESS:
        return <InprogressIcon width="11px" height="11px" className={classes.statusIcons} />;
      case STATUS.APPROVED:
      case STATUS.COMPLETED:
      case STATUS.NOT_REQUIRED:
        return <CompletedIcon width="11px" height="11px" className={classes.statusIcons} />;
      case STATUS.REJECTED:
        return <RejectedIcon width="11px" height="11px" className={classes.statusIcons} />;
      default:
        return <InprogressIcon width="11px" height="11px" className={classes.statusIcons} />;
    }
  };

  render() {
    const {
      classes,
      sgip_application,
      currentlySending,
      message_list,
      unreadMessageCount,
      isInstaller,
      ...remainingProps
    } = this.props;
    const {
      rejectFlag,
      docsUpload,
      docsUploadType,
      messageId,
      appId,
      documentRequestedPopupFlag,
      shouldNpsFeedback,
      applicationStage,
      email
    } = this.state;
    let messageList = isEmpty(message_list) ? [] : message_list;
    const adminMessageList = messageList.filter(message =>
      includes(['enphase_admin', 'sgip_admin'], message.send_by_user_type)
    );
    let stage_name = '',
      last_updated_date = '',
      status_name = '',
      stage_id = '',
      currentStageId = '',
      currentSubStageId = 1,
      currentStageName = '',
      currentSubStageName = '';

    if (sgip_application.hasOwnProperty('current_application_status')) {
      if (sgip_application.current_application_status !== null) {
        stage_name = sgip_application.current_application_status.stage_name;
        last_updated_date = sgip_application.current_application_status.last_updated_date;
        stage_id = sgip_application.current_application_status.id;
        currentStageId = stage_id;
        currentSubStageId = last(sgip_application.current_application_status.substages).substage_id;
        currentStageName = sgip_application.current_application_status.stage_name;
        currentSubStageName = last(sgip_application.current_application_status.substages).substage_name;
        status_name = last(sgip_application.current_application_status.substages).status_name || STATUS.INPROGRESS;

        const currentSubStages = currentStageId ? this.state.application_stages[currentStageId - 1].sub_stages : [];

        if (currentSubStages.length > 0) {
          currentSubStageId =
            currentSubStages.findIndex(list => list.status_name === '') !== -1
              ? currentSubStages.findIndex(list => list.status_name === '') + 1
              : 0;
          currentStageId = [STATUS.COMPLETED, STATUS.NOT_REQUIRED, STATUS.APPROVED].includes(
            currentSubStages[currentSubStages.length - 1].status_name
          )
            ? stage_id + 1
            : stage_id;

          currentSubStageName = last(last(sgip_application.application_stages).substages).substage_name;
        } else {
          currentStageId = stage_id;
        }
      }
    }

    let stageNameIndex = this.state.application_stages.filter(stage => stage.id === stage_id)[0];

    if (stageNameIndex && currentSubStageId) {
      if (this.state.application_stages[currentStageId - 1].sub_stages.length > 0) {
        status_name =
          this.state.application_stages[currentStageId - 1].sub_stages[currentSubStageId - 1].status_name ||
          STATUS.INPROGRESS;
        currentSubStageId =
          status_name === STATUS.INPROGRESS
            ? this.state.application_stages[currentStageId - 1].sub_stages[currentSubStageId - 1].id
            : this.state.application_stages[currentStageId - 1].sub_stages[currentSubStageId - 1].id + 1 >
              this.state.application_stages[currentStageId - 1].sub_stages.length
            ? 1
            : this.state.application_stages[currentStageId - 1].sub_stages[currentSubStageId - 1].id + 1;
      } else {
        status_name = STATUS.INPROGRESS;
        currentSubStageId = 1;
      }
    }

    return (
      <div className={classes.container}>
        {shouldNpsFeedback && (
          <NpsFeedbackDialog
            applicationStage={applicationStage}
            applicationId={get(sgip_application, 'application_id')}
            email={email}
          />
        )}
        <div>
          {rejectFlag && (
            <AppDetailsRejectDialog
              handleClose={this.cancelRejectApplication.bind(this)}
              rejectApplicationDetailsNotifiction={this.rejectApplicationDetailsNotifiction.bind(this)}
              applicationDetailsMessageId={this.state.applicationDetailsMessageId}
            />
          )}
          {docsUpload && (
            <ApplicationTrackUploadDialog
              messageList={messageList}
              sgip_application={sgip_application}
              handleClose={this.cancelDocumentsUpload.bind(this)}
              docsUploadType={docsUploadType}
              messageId={messageId}
              appId={appId}
              loadApplication={this.loadApplication.bind(this)}
              documentRequestedPopupFlag={this.handleDocumentUploadSuccess}
            />
          )}
          {currentlySending && <Loader loading={currentlySending} />}
          {documentRequestedPopupFlag && (
            <DocumentUploadDialog
              handleClose={this.handleDocumentUploadPopupClose}
              handleOK={this.handleDocumentUploadSuccess}
            />
          )}
          <Fragment>
            <div className={classes.apllicationNumber}>
              <Typography className={classes.value} style={{ marginLeft: '25px' }}>
                Application No:{' '}
                {sgip_application.application_number === 'NA'
                  ? 'Not Available Yet'
                  : sgip_application.application_number}
              </Typography>
              <Typography>
                <Button className={classes.link} onClick={e => forwardTo('applicationDetails')}>
                  {'View your application details >'}
                </Button>
              </Typography>
            </div>
            <div className={classes.applicationDetails}>
              <Grid className={clsx(classes.top, classes.applicationItem)}>
                <BoxLayout>
                  <Title>Application Status</Title>
                  <Typography className={classes.value} style={{ marginTop: '5px' }}>
                    {/* {stage_name === 'New Application Received' ? 'Application under Review' : stage_name} */}
                    {`${currentStageName} - ${currentSubStageName} ${
                      status_name !== STATUS.COMPLETED &&
                      status_name !== STATUS.NOT_REQUIRED &&
                      status_name !== STATUS.APPROVED
                        ? `: ${status_name}`
                        : ''
                    }`}
                  </Typography>
                  {last_updated_date ? (
                    <Typography className={classes.label}>{Moment(last_updated_date).format('DD MMM YYYY')}</Typography>
                  ) : (
                    ''
                  )}
                </BoxLayout>
                <Box className={classes.dHide}>
                  <BoxLayout
                    style={{
                      marginTop: '18px',
                      padding: '10px 20px'
                    }}
                  >
                    <Box className={classes.chatSection}>
                      <ChatRoundedIcon />

                      <Typography
                        variant={!isInstaller ? 'subtitle1' : 'body1'}
                        className={classes.chatSectionContent}
                        onClick={() => this.props.history.push(`/request/${sgip_application.application_id}/chats`)}
                      >
                        Communicate with Enphase
                      </Typography>

                      <Box className={classes.badgeBox}>
                        <Badge badgeContent={unreadMessageCount} color="primary" />
                      </Box>
                    </Box>
                  </BoxLayout>
                </Box>
                <Box>
                  {adminMessageList.map((message, ikey) => {
                    return (
                      <Grid className={clsx(classes.top, classes.dMargin)} key={ikey}>
                        {(message.subject === 'Application details updated' || message.document_requested) && (
                          <BoxLayout>
                            <Fragment>
                              <Title className={classes.header}>{message.subject}</Title>
                              <Box>
                                <Typography variant="caption" className={classes.label}>
                                  {message.body_content}
                                </Typography>
                                {message.document_requested && (
                                  <div>
                                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                      {message.document_requested && (
                                        <FilledButton
                                          className={clsx(
                                            classes.chooseFileButton,
                                            classes.button,
                                            classes.uploadButton
                                          )}
                                          variant="contained"
                                          color="secondary"
                                          disableElevation
                                          component="label"
                                          style={{ width: 'auto' }}
                                          onClick={e =>
                                            this.documentsUpload(message.application_id, message.id, message.subject)
                                          }
                                        >
                                          Upload
                                        </FilledButton>
                                      )}
                                    </div>
                                  </div>
                                )}
                                {message.subject === 'Application details updated' &&
                                  message.fields_changed.changed_fields.map((field, k) => {
                                    return (
                                      <div key={k}>
                                        <Typography
                                          variant={isInstaller ? 'body2' : 'subtitle2'}
                                          className={classes.appdetailsheading}
                                        >{`From`}</Typography>
                                        <Typography className={classes.appdetaillabel}>
                                          {get(field, 'display_field_name', '')}
                                        </Typography>
                                        <Typography className={classes.appdetailsub}>
                                          {get(field, 'from_value', '')}
                                        </Typography>
                                        <Typography
                                          variant={isInstaller ? 'body2' : 'subtitle2'}
                                          className={classes.appdetailsheading}
                                        >{`To`}</Typography>
                                        <Typography className={classes.appdetaillabel}>
                                          {get(field, 'display_field_name', '')}
                                        </Typography>
                                        <Typography className={classes.appdetailsub}>
                                          {get(field, 'to_value', '')}
                                        </Typography>
                                        {k === message.fields_changed.changed_fields.length - 1 ? (
                                          <div style={{ marginTop: '20px' }}>
                                            <FilledButton
                                              style={{ width: 'auto' }}
                                              onClick={e => this.acceptApplicationDetailsNotifiction(message.id)}
                                            >
                                              Accept
                                            </FilledButton>
                                            <Button onClick={e => this.rejectApplication(message.id)}>Reject</Button>
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                    );
                                  })}
                              </Box>
                            </Fragment>
                          </BoxLayout>
                        )}
                      </Grid>
                    );
                  })}
                </Box>
                <BoxLayout style={{ marginTop: '18px' }}>
                  <Title className={classes.header}> Track Status </Title>
                  <Stepper
                    id="appStepper"
                    style={{ padding: '0px 24px 0px 24px' }}
                    activeStep={Number(this.state.activeStep - 1)}
                    orientation="vertical"
                    connector={<QontoConnector />}
                  >
                    {this.state.application_stages.map(stage => (
                      <Step
                        key={stage.id}
                        expanded={true}
                        active={stage.id === currentStageId}
                        completed={
                          stage.sub_stages.length > 0 &&
                          (stage.sub_stages[stage.sub_stages.length - 1].status_name === STATUS.COMPLETED ||
                            stage.sub_stages[stage.sub_stages.length - 1].status_name === STATUS.NOT_REQUIRED ||
                            stage.sub_stages[stage.sub_stages.length - 1].status_name === STATUS.APPROVED)
                        }
                      >
                        <StepLabel icon={stage.id === currentStageId ? <InprogressIcon /> : stage.id}>
                          <Typography variant={isInstaller ? 'body1' : 'subtitle1'}>{stage.stage_name}</Typography>
                        </StepLabel>
                        {stage.id <= currentStageId &&
                          stage.sub_stages &&
                          stage.sub_stages.map(substage => (
                            <StepContent style={{ marginTop: '5px' }}>
                              <Typography
                                variant={isInstaller ? 'caption' : 'body1'}
                                className={classes.stepperContent}
                              >
                                <span>{this.renderStatusIcon(substage.status_name)}</span>
                                <span className={classes.stageLabel}>
                                  {substage.stage_name === '' ? '' : substage.stage_name}
                                </span>
                                {substage.status_name === STATUS.COMPLETED ||
                                substage.status_name === STATUS.NOT_REQUIRED ||
                                substage.status_name === STATUS.REJECTED ||
                                substage.status_name === STATUS.APPROVED ||
                                substage.status_name === STATUS.WAITLISTED ? (
                                  substage.last_updated_date === '' ? (
                                    <span>{''}</span>
                                  ) : (
                                    <Fragment>
                                      <span>{':'}</span>
                                      <span className={classes.date}>{`${Moment(substage.last_updated_date).format(
                                        'MMM Do YYYY'
                                      )}`}</span>
                                    </Fragment>
                                  )
                                ) : substage.status_name === '' && substage.id !== currentSubStageId ? (
                                  <span>{''}</span>
                                ) : substage.id === currentSubStageId ? (
                                  <Fragment>
                                    <span>{':'}</span>
                                    <span className={classes.date}>{`${STATUS.INPROGRESS}`}</span>
                                  </Fragment>
                                ) : (
                                  <Fragment>
                                    <span>{':'}</span>
                                    <span className={classes.date}>{`${substage.status_name}`}</span>
                                  </Fragment>
                                )}
                              </Typography>
                            </StepContent>
                          ))}
                      </Step>
                    ))}
                  </Stepper>
                </BoxLayout>
              </Grid>
              <Grid className={clsx(classes.top, classes.applicationItem, classes.rightSection)}>
                <div className={classes.sgipLiveStatus}>
                  <SgipLiveStatus isInstaller={isInstaller} />
                </div>
                {sgip_application.application_id && (
                  <Box className={clsx(classes.pHide, classes.chatBoxWrapper)}>
                    <Paper className={classes.chatBox}>
                      <Chats isFromApplicationStatus {...remainingProps} appId={sgip_application.application_id} />
                    </Paper>
                  </Box>
                )}
              </Grid>
            </div>
          </Fragment>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getSgipDocumentPresignedURL: payload => dispatch(getSgipDocumentPresignedURL(payload)),
  postApplicantNotification: payload => dispatch(postApplicantNotification(payload)),
  putSgipDocumentToS3: payload => dispatch(putSgipDocumentToS3(payload)),
  postSignedDocument: payload => dispatch(postSignedDocument(payload)),
  postFieldChangeAcceptNotification: payload => dispatch(postFieldChangeAcceptNotification(payload)),
  postFieldChangeRejectNotification: payload => dispatch(postFieldChangeRejectNotification(payload)),
  downloadFileFromS3: payload => dispatch(downloadFileFromS3(payload)),
  getUploadedDocumentsPresignedURLs: payload => dispatch(getUploadedDocumentsPresignedURLs(payload)),
  getSgipApplicationDetailsBySiteId: payload => dispatch(getSgipApplicationDetailsBySiteId(payload)),
  getSgipNpsFeedback: payload => dispatch(getSgipNpsFeedback(payload))
});

const mapStateToProps = state => ({
  currentlySending: state.sgipAppReducer.currentlySending,
  sgip_application: state.sgipAppReducer.sgip_application,
  message_list: state.sgipAppReducer.message_list,
  siteId: state.appReducer.siteId,
  documents_urls: state.sgipAppReducer.documents_urls,
  isInstaller: state.appReducer.isInstaller,
  unreadMessageCount: state.sgipAppReducer.unreadMessageCount,
  isIOS: state.appReducer.isIOS,
  isAndroid: state.appReducer.isAndroid
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(index));
