import { Box, Link, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import { useStyles } from './styles';

const QuestionPersonalDetails = props => {
  const classes = useStyles();
  const { questionForHO, questionForInstaller, quesRelatedLink, linkText, isInstaller, isIOS } = props;

  return (
    <Fragment>
      <Box className={classes.qa}>
        <Box>
          <Typography display="inline">{!isInstaller ? questionForHO : questionForInstaller}</Typography>
          {isIOS ? (
            <a className={classes.iosLink} href={quesRelatedLink} target="_self">
              <Typography component="span" variant="body2">
                {` ${linkText}`}
              </Typography>
            </a>
          ) : (
            <Link
              className={classes.link}
              variant="body2"
              target="_blank"
              rel="noreferrer"
              underline="none"
              onClick={() => window.open(quesRelatedLink, '_blank')}
            >
              {` ${linkText}`}
            </Link>
          )}
        </Box>
      </Box>
    </Fragment>
  );
};

export default QuestionPersonalDetails;
