import React, { Component, Fragment } from 'react';
import { withStyles, styled } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { Grid, StepButton, Typography } from '@material-ui/core';
import StepConnector from '@material-ui/core/StepConnector';
import { forwardTo, trackEvents } from '../../../utils/helper';
const styles = theme => ({
  root: {
    width: '100%'
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    marginBottom: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  txtColor: {
    color: 'green'
  },
  current: {
    color: '#f37321'
  },
  paddingWrapper: {
    padding: theme.spacing(3, 1, 3)
  },
  [theme.breakpoints.down('xs')]: {
    stepLabelWrapper: { width: theme.spacing(7.5) }
  }
});

const StyledStepper = styled(Stepper)({
  '& .MuiStep-horizontal': {
    padding: '0px'
  }
});

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 11.5px)',
    right: 'calc(50% + 11.5px)'
  },
  horizontal: {
    padding: '0px'
  },
  active: {
    '& $line': {
      borderColor: '#f37321'
    }
  },
  completed: {
    '& $line': {
      borderColor: '#f37321'
    }
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 1,
    borderRadius: 1
  }
})(StepConnector);

class StatusTracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 1
    };
  }

  setStep(nextStep) {
    this.activeStep = nextStep;
  }

  getSteps(isInstaller) {
    if (isInstaller) {
      return [
        {
          step: 'Primary Details',
          link: '/primaryDetails',
          pageNumber: '1'
        },
        {
          step: 'Personal Details',
          link: '/personalDetails',
          pageNumber: '2'
        },
        { step: 'Attestation Form', link: '/attestationForm', pageNumber: '3' },
        { step: 'Upload Documents', link: '/uploadDocuments', pageNumber: '4' },
        { step: 'Pay and Submit', link: '/serviceCharge', pageNumber: '5' }
      ];
    }
    return [
      {
        step: 'Primary Details',
        link: '/primaryDetails',
        pageNumber: '1'
      },
      {
        step: 'Personal Details',
        link: '/personalDetails',
        pageNumber: '2'
      },
      { step: 'Upload Documents', link: '/uploadDocuments', pageNumber: '3' },
      { step: 'Pay and Submit', link: '/serviceCharge', pageNumber: '4' }
    ];
  }

  render() {
    const { classes, activeState, pageNumber, isInstaller } = this.props;
    const steps = this.getSteps(isInstaller);
    return (
      <Fragment>
        <Grid>
          <Paper>
            <div>
              <StyledStepper
                activeStep={Number(pageNumber - 1)}
                className={classes.paddingWrapper}
                alternativeLabel
                connector={<QontoConnector />}
              >
                {steps.map(stepper => (
                  <Step key={stepper.step}>
                    <StepButton
                      disabled={stepper.pageNumber > activeState}
                      onClick={() => {
                        trackEvents('triggerSgipPageView', { pageTitle: stepper.step });
                        forwardTo(stepper.link);
                      }}
                    >
                      <StepLabel className={classes.stepLabelWrapper}>
                        <Typography
                          variant="body2"
                          display="inline"
                          className={stepper.pageNumber === pageNumber && classes.current}
                        >
                          {stepper.step}
                        </Typography>
                      </StepLabel>
                    </StepButton>
                  </Step>
                ))}
              </StyledStepper>
            </div>
          </Paper>
        </Grid>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = state => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(StatusTracking));
