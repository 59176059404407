import React from 'react';
import DefaultText from './DefaultText';

const Title = props => {
  const { children, className, ...remainingProps } = props;
  return (
    <DefaultText component="div" variant="subtitle2" className={className} {...remainingProps}>
      {children}
    </DefaultText>
  );
};

export default Title;
