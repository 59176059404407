import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import { Box, Typography, Button, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { numberWithCommas } from '../../../utils/helper';
import { Title } from '../../Texts';

const styles = theme => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2.5)
    },
    borderRadius: '10px'
  },
  cancelbutton: {
    textTransform: 'none',
    color: '#01b4de',
    padding: '18px 0px'
  },
  title: {
    padding: '20px'
  },
  heading: {
    color: '#545456'
  },
  tdborder: {
    border: 'none',
    padding: '18px 0px'
  },
  formControl: {
    width: '100%'
  },
  actions: {
    justifyContent: 'center'
  },
  dropdownStyle: {
    marginTop: '42px'
  },
  listWrapper: {
    maxHeight: '250px'
  },
  formLabel: {
    color: '#545456'
  },
  content: {
    color: '#545456',
    marginTop: '20px'
  },
  [theme.breakpoints.up('md')]: {}
});

const IncomeQualifierDialog = props => {
  const { classes, handleClose, user_selection_data } = props;
  const median_income_county = user_selection_data.median_income_county || [];
  const [ selectedCountryDetails, setCountryDetails ] = useState({});
  useEffect(
    () => {
      if (median_income_county.length > 0) {
        setCountryDetails(median_income_county[0]);
      }
    },
    [ median_income_county ]
  );
  const scroll = 'paper';

  const handleOnChange = (event, props) => {
    const { median_income_county } = props.user_selection_data;
    const country = event.target.value;
    const selectedCountry = median_income_county.find(obj => obj.county_name === country);
    setCountryDetails(selectedCountry);
  };

  return (
    <div>
      <Dialog
        open={true}
        onClose={props.handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        style={{ borderRadius: '10px' }}
        classes={{ root: 'rounded' }}
      >
        <DialogTitle className={classes.title} id="customized-dialog-title" onClose={handleClose}>
          <Title className={classes.heading}>Income Qualifier</Title>
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'} className={classes.root}>
          <Box>
            <Typography
              variant="subtitle1"
              className={classes.content}
              style={{ marginTop: '0', marginBottom: '20px' }}
            >
              Please select your county to proceed
            </Typography>
            <FormControl className={classes.formControl}>
              <InputLabel id="country_sel" className={classes.formLabel}>
                County
              </InputLabel>
              <Select
                labelId="country_sel"
                MenuProps={{ classes: { paper: classes.dropdownStyle, list: classes.listWrapper } }}
                value={selectedCountryDetails.county_name || ''}
                onChange={e => handleOnChange(e, props)}
              >
                {median_income_county.map((obj, idx) => {
                  return (
                    <MenuItem disableRipple value={obj.county_name} key={idx}>
                      {obj.county_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <Typography variant="body1" className={classes.content}>
              Your income needs to be less than or equal to
              <span style={{ fontWeight: 'bold', margin: '0 4px' }}>
                ${selectedCountryDetails.amount_to_qualify &&
                  numberWithCommas(selectedCountryDetails.amount_to_qualify)}
              </span>
              to qualify for a single-family household with an income less than or equal to 80% of your area's median
              income
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={props.handleClose} className={classes.cancelbutton}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(IncomeQualifierDialog);
