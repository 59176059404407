import { Box, FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import React from 'react';
import { BoxLayout } from '../../../components/Common';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const PrimaryAdditionalDetails = props => {
  const {
    ptoSelectedDate,
    batteryRebatePreviously,
    hasBatteryPTO,
    onBatteryRebateClaimedChange: handleBatteryRebateClaimed,
    onBatteryPTOChange: handleBatteryPTO,
    onPTODateChange,
    errorMessage
  } = props;

  return (
    <BoxLayout>
      <Box>
        <FormControl required>
          <FormLabel id="batteryRebatePreviously">Have you received SGIP Rebate previously on Battery(s)?</FormLabel>
          <RadioGroup
            value={batteryRebatePreviously}
            name="batteryRebatePreviously"
            row
            onChange={handleBatteryRebateClaimed}
          >
            <FormControlLabel value="true" control={<Radio />} label="Yes" />
            <FormControlLabel value="false" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </Box>
      <FormControl required>
        <FormLabel id="hasBatteryPTO">Do you have Battery PTO (Permission to Operate)?</FormLabel>
        <RadioGroup value={hasBatteryPTO} name="hasBatteryPTO" row onChange={handleBatteryPTO}>
          <FormControlLabel value="true" control={<Radio />} label="Yes" />
          <FormControlLabel value="false" control={<Radio />} label="No" />
        </RadioGroup>
        {hasBatteryPTO === 'true' && (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              required
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="pto-date-picker"
              label="Please provide the PTO Date"
              value={ptoSelectedDate}
              onChange={onPTODateChange}
              autoOk={true}
            ></KeyboardDatePicker>
          </MuiPickersUtilsProvider>
        )}
        {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
      </FormControl>
    </BoxLayout>
  );
};

export default PrimaryAdditionalDetails;
