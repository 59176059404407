import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import ApplyIncentive from '../../../components/SgipApp/ApplyIncentive';
import { getSgipApplicationDetailsBySiteId, createSgipApplication } from '../actions';
import { applicationStage, applicationPageTitle, trackEvents } from '../../../utils/helper';
import ServiceFeeDialog from '../../../components/SgipApp/serviceFeeCalculation';
import { isNull } from '../../../utils/lodash';
import Loader from '../../../components/Loader';

const styles = theme => ({
  root: {
    padding: theme.spacing(2.5)
  }
});

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stepCompleted: '',
      serviceFeeFlag: false
    };
    this.requiredDocument = [ 'Latest Utility Bills (At least one bill from the last 3 months)', 'Contract Document' ];
    this.serviceFeePopup = this.serviceFeePopup.bind(this);
  }

  componentDidMount() {
    const { siteId } = this.props;
    this.loadApplication(siteId);
  }
  serviceFeePopup() {
    this.setState({ serviceFeeFlag: !this.state.serviceFeeFlag });
  }

  loadApplication(siteId) {
    this.props.getSgipApplicationDetailsBySiteId({
      siteId: siteId,
      isInstaller: this.props.isInstaller,
      successCb: res => {
        const { sgip_application } = this.props;
        const stepCompleted = sgip_application.stepCompleted;
        this.setState({ stepCompleted }, () => this.startApplication(false));
      },
      failureCb: () => {}
    });
  }

  startApplication = (isApplyNowTriggered = false) => {
    let path = '';
    const { sgip_application, isInstaller } = this.props;
    const { sgip_attestation_form } = sgip_application;
    if (isApplyNowTriggered) {
      const eventName = isInstaller ? 'triggerApplyNowSGIPInstaller' : 'triggerApplyNowSGIPHomeowner';
      trackEvents(eventName);
    }
    if (this.state.stepCompleted) {
      if (
        this.state.stepCompleted >= 3 &&
        isInstaller &&
        isNull(sgip_attestation_form) &&
        this.state.stepCompleted !== 5
      ) {
        trackEvents('triggerSgipPageView', { pageTitle: 'Attestation Form' });
        path = '/attestationForm';
      } else {
        trackEvents('triggerSgipPageView', { pageTitle: applicationPageTitle(this.state.stepCompleted) });
        path = applicationStage(this.state.stepCompleted, this.props.paymentStatus);
      }
    } else {
      trackEvents('triggerSgipPageView', { pageTitle: 'Primary Details' });
      path = '/primaryDetails';
    }
    this.props.history.push(path);
  };

  createNewApplication = () => {
    this.props.createSgipApplication({
      siteId: this.props.siteId,
      successCb: () => {
        this.startApplication(true);
      }
    });
  };

  render() {
    const { classes, isInstaller, isIOS, currentlySending } = this.props;
    const { serviceFeeFlag } = this.state;
    return (
      <div className={classes.root}>
        {serviceFeeFlag && <ServiceFeeDialog serviceFeePopup={this.serviceFeePopup.bind(this)} />}
        {currentlySending && <Loader loading={currentlySending} />}
        {!currentlySending && (
          <ApplyIncentive
            requiredDocument={this.requiredDocument}
            serviceFeePopup={this.serviceFeePopup}
            isInstaller={isInstaller}
            isIOS={isIOS}
            createNewApplication={this.createNewApplication}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getSgipApplicationDetailsBySiteId: payload => dispatch(getSgipApplicationDetailsBySiteId(payload)),
  createSgipApplication: payload => dispatch(createSgipApplication(payload))
});

const mapStateToProps = state => ({
  siteId: state.appReducer.siteId,
  sgip_application: state.sgipAppReducer.sgip_application,
  isInstaller: state.appReducer.isInstaller,
  isIOS: state.appReducer.isIOS,
  currentlySending: state.sgipAppReducer.currentlySending,
  paymentStatus: state.appReducer.paymentStatus
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(index));
