import {
  GET_SGIP_REQUESTS_REQUEST,
  GET_SGIP_SUCCESS,
  GET_SGIP_STATISTICS_SUCCESS,
  GET_MSG_HISTORY_REQUEST,
  GET_MSG_HISTORY_SUCCESS,
  GET_SGIP_STATISTICS_REQUEST,
  GET_SGIP_REQUEST_DETAILS_REQUEST,
  GET_SGIP_REQUEST_DETAILS_SUCCESS,
  UPDATE_APP_ID_REQUEST,
  UPDATE_APP_ID_SUCCESS,
  UPDATE_STAGE_STATUS_SUCCESS,
  UPDATE_STAGE_STATUS_REQUEST,
  SEND_MESSAGE_REQUEST,
  SEND_MESSAGE_SUCCESS,
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  GET_REQUEST_FAILURE,
  GET_NOTIFICATIONS_FAILURE,
  GET_SGIP_REQUESTS_FAILURE,
  GET_SGIP_STATISTICS_FAILURE,
  SEND_TO_HO_FOR_APPROVAL_REQUEST,
  SEND_TO_HO_FOR_APPROVAL_SUCCESS,
  SITE_SEARCH_REQUEST,
  SITE_SEARCH_REQUEST_SUCCESS,
  RESET_SITE_SEARCH_RESULT_REQUEST,
  SITE_SEARCH_REQUEST_FAILURE,
  GET_ACCOUNT_DETAILS_SUCCESS,
  UPDATE_CHAT_STATUS_SUCCESS,
  GET_APP_NOTES_REQUEST,
  GET_APP_NOTES_SUCCESS,
  GET_APPLICATIONS_SITE_LIST_SUCCESS,
  GET_COMPANY_INFO_SUCCESS,
  GET_DIN_INSTALLERS_LIST_SUCCESS,
  UPDATE_DIN_INSTALLERS_LIST_REQUEST,
  UPDATE_DIN_INSTALLERS_LIST_SUCCESS,
  DELETE_DIN_INSTALLERS_LIST_REQUEST,
  DELETE_DIN_INSTALLERS_LIST_SUCCESS,
  GET_USER_TYPE_REQUEST,
  GET_USER_TYPE_SUCCESS,
  GET_COMPANY_INFO_FAILURE,
  UPLOAD_SGIP_DOCS_REQUEST,
  UPLOAD_SGIP_DOCS_SUCCESS,
  BLOCK_UNBLOCK_SGIP_CONFIG_PARAMS_REQUEST,
  BLOCK_UNBLOCK_SGIP_CONFIG_PARAMS_SUCCESS,
  BLOCK_UNBLOCK_SGIP_CONFIG_PARAMS_FAILURE,
  UPDATE_INCENTIVE_RATE_SGIP_CONFIG_PARAMS_REQUEST,
  UPDATE_INCENTIVE_RATE_SGIP_CONFIG_PARAMS_SUCCESS,
  UPDATE_INCENTIVE_RATE_SGIP_CONFIG_PARAMS_FAILURE,
  GET_BATTERY_REPORT_DOWNLOAD_FAILURE,
  GET_BATTERY_REPORT_DOWNLOAD_SUCCESS,
  GET_BATTERY_REPORT_DOWNLOAD_REQUEST,
  SAVE_INSTALLER_SECONDARY_EMAIL_REQUEST,
  SAVE_INSTALLER_SECONDARY_EMAIL_SUCCESS,
  SAVE_INSTALLER_SECONDARY_EMAIL_FAILURE
} from './constants';

let initialState = {
  error: '',
  currentlySending: false,
  getSGIPRequetsInProgress: false,
  getSGIPStatisticsInProgress: false,
  getNotificationsInProgress: false,
  sgip_requests: [],
  sgip_statistics: [],
  totalElements: 0,
  message: '',
  messageTitle: '',
  messageType: '',
  msg_histories: [],
  sgip_application: {},
  showMessageDialog: false,
  notifications: [],
  duplicate_app_number_present: false,
  siteSearchInProgress: false,
  searchResults: { total_count: 0, result: [] },
  accountDetails: { email: '' },
  companyInfo: {},
  unreadMessageCount: 0,
  app_notes_inprogress: false,
  app_notes: [],
  unread_msg_count: 0,
  applicationSiteIds: [],
  dinInstallers: [],
  totalDinInstallersCount: 0
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_SGIP_REQUESTS_REQUEST:
      return { ...state, getSGIPRequetsInProgress: true, currentlySending: true };
    case GET_SGIP_STATISTICS_REQUEST:
      return { ...state, getSGIPStatisticsInProgress: true };
    case GET_MSG_HISTORY_REQUEST:
      return { ...state };
    case GET_SGIP_REQUEST_DETAILS_REQUEST:
      return { ...state, currentlySending: true };
    case UPDATE_APP_ID_REQUEST:
      return { ...state, currentlySending: true };
    case UPDATE_STAGE_STATUS_REQUEST:
      return { ...state, currentlySending: true };
    case SEND_MESSAGE_REQUEST:
      return { ...state };
    case GET_NOTIFICATIONS_REQUEST:
      return { ...state, getNotificationsInProgress: true };
    case GET_REQUEST_FAILURE:
      return { ...state, currentlySending: false };
    case GET_NOTIFICATIONS_FAILURE:
      return { ...state, getNotificationsInProgress: false };
    case SEND_TO_HO_FOR_APPROVAL_REQUEST:
      return { ...state, currentlySending: true };
    case GET_APP_NOTES_REQUEST:
      return { ...state, app_notes_inprogress: true };
    case GET_APP_NOTES_SUCCESS:
      return { ...state, app_notes: action.app_notes, app_notes_inprogress: false };
    case GET_SGIP_REQUESTS_FAILURE:
      return {
        ...state,
        getSGIPRequetsInProgress: false,
        messageType: 'error',
        message: action.message,
        currentlySending: false
      };
    case GET_SGIP_STATISTICS_FAILURE:
      return { ...state, getSGIPStatisticsInProgress: false, messageType: 'error', message: action.message };
    case GET_SGIP_SUCCESS:
      return {
        ...state,
        sgip_requests: action.sgip_requests,
        totalElements: action.totalElements,
        getSGIPRequetsInProgress: false,
        messageType: 'success',
        message: action.message,
        currentlySending: false
      };
    case GET_SGIP_STATISTICS_SUCCESS:
      return {
        ...state,
        sgip_statistics: action.sgip_statistics,
        messageType: 'success',
        message: action.message,
        getSGIPStatisticsInProgress: false
      };
    case GET_MSG_HISTORY_SUCCESS:
      return {
        ...state,
        msg_histories: action.msg_histories,
        messageType: 'success',
        unreadMessageCount: action.unreadMessageCount
      };
    case GET_SGIP_REQUEST_DETAILS_SUCCESS:
      return {
        ...state,
        sgip_application: action.sgip_application,
        duplicate_app_number_present: action.duplicate_app_number_present,
        messageType: 'success',
        message: action.message,
        currentlySending: false,
        showMessageDialog: false,
        unread_msg_count: action.unread_msg_count
      };
    case UPDATE_APP_ID_SUCCESS:
      return {
        ...state,
        messageType: 'success',
        message: action.message,
        messageTitle: action.messageTitle,
        currentlySending: false,
        showMessageDialog: action.showMessageDialog
      };
    case UPDATE_STAGE_STATUS_SUCCESS:
      return {
        ...state,
        messageType: 'success',
        message: action.message,
        messageTitle: action.messageTitle,
        currentlySending: false,
        showMessageDialog: action.showMessageDialog
      };
    case SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        messageType: 'success',
        message: action.message,
        messageTitle: action.messageTitle,
        currentlySending: false,
        showMessageDialog: action.showMessageDialog,
        msg_histories: action.messageHistory,
        unreadMessageCount: action.unreadMessageCount
      };
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        messageType: 'success',
        getNotificationsInProgress: false,
        notifications: action.notifications
      };
    case SEND_TO_HO_FOR_APPROVAL_SUCCESS:
      return {
        ...state,
        messageType: 'success',
        message: action.message,
        messageTitle: action.messageTitle,
        currentlySending: false,
        showMessageDialog: action.showMessageDialog
      };
    case SITE_SEARCH_REQUEST:
      return { ...state, siteSearchInProgress: true };
    case SITE_SEARCH_REQUEST_SUCCESS:
      return {
        ...state,
        siteSearchInProgress: false,
        searchResults: action.searchList
      };
    case SITE_SEARCH_REQUEST_FAILURE:
      return {
        ...state,
        siteSearchInProgress: false,
        messageType: 'error',
        message: action.error
      };
    case GET_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        siteSearchInProgress: false,
        accountDetails: action.accountDetails
      };
    case GET_COMPANY_INFO_SUCCESS:
      return {
        ...state,
        companyInfo: action.companyInfo
      };
    case GET_COMPANY_INFO_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: 'There is a problem while creating new request, please try later.'
      };
    case RESET_SITE_SEARCH_RESULT_REQUEST:
      return { ...state, searchResults: initialState.searchResults };
    case UPDATE_CHAT_STATUS_SUCCESS:
      return { ...state, unreadMessageCount: 0 };
    case GET_APPLICATIONS_SITE_LIST_SUCCESS:
      return { ...state, applicationSiteIds: action.applicationSiteIds };
    case GET_DIN_INSTALLERS_LIST_SUCCESS:
      return {
        ...state,
        dinInstallers: action.dinInstallers && action.dinInstallers.content ? action.dinInstallers.content : [],
        totalDinInstallersCount:
          action.dinInstallers && action.dinInstallers.totalElements ? action.dinInstallers.totalElements : 0
      };
    case UPDATE_DIN_INSTALLERS_LIST_REQUEST:
      return { ...state, currentlySending: true };
    case GET_USER_TYPE_REQUEST:
      return { ...state, currentlySending: true };
    case GET_USER_TYPE_SUCCESS:
      return { ...state, currentlySending: false };
    case UPDATE_DIN_INSTALLERS_LIST_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        messageType: 'success',
        message: action.message,
        messageTitle: action.messageTitle,
        showMessageDialog: action.showMessageDialog
      };
    case DELETE_DIN_INSTALLERS_LIST_REQUEST:
      return { ...state, currentlySending: true };
    case DELETE_DIN_INSTALLERS_LIST_SUCCESS:
      return { ...state, currentlySending: false };
    case UPLOAD_SGIP_DOCS_REQUEST:
      return { ...state, currentlySending: true };
    case UPLOAD_SGIP_DOCS_SUCCESS:
      return {
        ...state,
        messageType: 'success',
        message: action.message,
        messageTitle: action.messageTitle,
        currentlySending: false,
        showMessageDialog: action.showMessageDialog
      };
    case BLOCK_UNBLOCK_SGIP_CONFIG_PARAMS_REQUEST:
      return { ...state, currentlySending: true };
    case BLOCK_UNBLOCK_SGIP_CONFIG_PARAMS_SUCCESS:
      return { ...state, currentlySending: false };
    case BLOCK_UNBLOCK_SGIP_CONFIG_PARAMS_FAILURE:
      return { ...state, currentlySending: false, message: action.message, messageType: 'error' };
    case UPDATE_INCENTIVE_RATE_SGIP_CONFIG_PARAMS_REQUEST:
      return { ...state, currentlySending: true };
    case UPDATE_INCENTIVE_RATE_SGIP_CONFIG_PARAMS_SUCCESS:
      return { ...state, currentlySending: false };
    case UPDATE_INCENTIVE_RATE_SGIP_CONFIG_PARAMS_FAILURE:
      return { ...state, currentlySending: false, message: action.message, messageType: 'error' };
    case GET_BATTERY_REPORT_DOWNLOAD_REQUEST:
      return { ...state, currentlySending: true };
    case GET_BATTERY_REPORT_DOWNLOAD_SUCCESS:
      return { ...state, currentlySending: false, message: action.message, messageType: 'success' };
    case GET_BATTERY_REPORT_DOWNLOAD_FAILURE:
      return { ...state, currentlySending: false, message: action.message, messageType: 'error' };
    case SAVE_INSTALLER_SECONDARY_EMAIL_REQUEST:
      return { ...state, currentlySending: true };
    case SAVE_INSTALLER_SECONDARY_EMAIL_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        sgip_application: action.sgip_application
      };
    case SAVE_INSTALLER_SECONDARY_EMAIL_FAILURE:
      return { ...state, currentlySending: false, message: action.message, messageType: 'error' };
    default:
      return state;
  }
}

export default reducer;
