import { assign } from 'lodash';
import { getValue } from '../utils/localStorage';

const development = {
  api: 'https://sgip-stg.enphaseenergy.com',
  enlighten_url: 'https://qa2.enphaseenergy.com',
  sgip_core_url: 'https://sgip-stg.enphaseenergy.com',
  enlighten_token: 'enlighten_manager_token_qa4',
};


const test = {
  api: 'https://sgip-dev.enphaseenergy.com',
  enlighten_url: 'https://enlighten-qa4.enphaseenergy.com',
  sgip_core_url: 'https://sgip-dev.enphaseenergy.com',
  enlighten_token: 'enlighten_manager_token_qa4',
};

const qa2 = {
  api: 'https://sgip-stg.enphaseenergy.com',
  enlighten_url: 'https://qa2.enphaseenergy.com',
  sgip_core_url: 'https://sgip-stg.enphaseenergy.com',
  enlighten_token: 'enlighten_manager_token_qa2',
};

const rel = {
  api: 'https://sgip-stg.enphaseenergy.com',
  enlighten_url: 'https://enlighten-rel.enphaseenergy.com',
  sgip_core_url: 'https://sgip-stg.enphaseenergy.com',
  enlighten_token: 'enlighten_manager_token_rel',
};

const qa4 = {
  api: 'https://sgip-dev.enphaseenergy.com',
  enlighten_url: 'https://enlighten-qa4.enphaseenergy.com',
  sgip_core_url: 'https://sgip-dev.enphaseenergy.com',
  enlighten_token: 'enlighten_manager_token_qa4',
};

const preprod = {
  api: 'https://sgip-preprod.enphaseenergy.com',
  enlighten_url: 'https://enlighten-preprod.enphaseenergy.com',
  sgip_core_url: 'https://sgip-preprod.enphaseenergy.com',
  enlighten_token: 'enlighten_manager_token_preprod',
};

const production = {
  api: 'https://sgip.enphaseenergy.com',
  enlighten_url: 'https://enlighten.enphaseenergy.com',
  sgip_core_url: 'https://sgip.enphaseenergy.com',
  enlighten_token: 'enlighten_manager_token_prod',
};

const DEFAULT = {};

const CONFIG = {
  development,
  production,
  preprod,
  test,
  rel,
  qa2,
  qa4,
};

const getConfig = env => {
  let hostname;
  let referrer;
  if (typeof window !== 'undefined') {
    hostname = window.location.hostname;
    referrer = document.referrer;
    if (
      hostname.search('enlighten.enphaseenergy') >= 0 ||
      hostname.search('sgip.enphaseenergy') >= 0
    ) {
      env = 'production';
    } else if (
      hostname.search('enlighten-preprod.enphaseenergy') >= 0 ||
      hostname.search('sgip-preprod.enphaseenergy') >= 0
    ) {
      env = 'preprod';
    } else if (hostname.search('qa2.enphaseenergy') >= 0) {
      env = 'qa2';
    } else if (hostname.search('enlighten-rel.enphaseenergy') >= 0) {
      env = 'rel';
    } else if (hostname.search('sgip-stg') >= 0) {
      if (
        getValue('referrer')?.includes('https://enlighten-rel.enphaseenergy.com') ||
        referrer?.includes('https://enlighten-rel.enphaseenergy.com')
      ) {
        env = 'rel';
      } else {
        env = 'qa2';
      }
    } else if (
      hostname.search('enlighten-qa4') >= 0 ||
      hostname.search('sgip-dev') >= 0
    ) {
      env = 'qa4';
    } else if (window.location.port === '8000') {
      env = 'development';
    } else {
      env = 'development';
    }
  } else if (env === 'test') {
    env = test;
  }
  console.log('App running environment is:', env);
  const envConfig = CONFIG[env];
  envConfig['env'] = env;
  if (envConfig) {
    return assign({}, DEFAULT, envConfig);
  }
  throw new Error('Unknown environment : ' + env);
};

export default getConfig;
