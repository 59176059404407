import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { getMessageHistoryForRequest, sendMessage, getPresingedURL, uploadToS3, updateChatStatus } from '../actions';
import ChatBox from './ChatBox';

const styles = theme => ({});

export class MessageHistory extends React.Component {
  componentDidMount() {
    this.fecthAllChats();
  }

  fecthAllChats() {
    const { request_id } = this.props.match.params;
    this.props.getMessageHistoryForRequest({ request_id: request_id || this.props.appId });
  }

  handleClose() {
    window.history.back();
  }

  render() {
    const {
      msg_histories,
      accountDetails,
      sendMessage,
      getPresingedURL,
      uploadToS3,
      updateChatStatus,
      unreadMessageCount,
      isIOS,
      isAndroid,
      isMobileView,
      isFromAdmin,
      isFromApplicationStatus = false,
      appId = ''
    } = this.props;
    const { request_id } = this.props.match.params;
    return (
      <ChatBox
        messages={msg_histories}
        currentUserEmail={accountDetails.email}
        sendMessage={sendMessage}
        appId={request_id || appId}
        fetchAllChats={this.fecthAllChats.bind(this)}
        getPresingedURL={getPresingedURL}
        uploadToS3={uploadToS3}
        handleClose={this.handleClose.bind(this)}
        updateChatStatus={updateChatStatus}
        unreadMessageCount={unreadMessageCount}
        isIOS={isIOS}
        isAndroid={isAndroid}
        isMobileView={isMobileView}
        isFromAdmin={isFromAdmin}
        isFromApplicationStatus={isFromApplicationStatus}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getMessageHistoryForRequest: payload => dispatch(getMessageHistoryForRequest(payload)),
  sendMessage: payload => dispatch(sendMessage(payload)),
  getPresingedURL: payload => dispatch(getPresingedURL(payload)),
  uploadToS3: payload => dispatch(uploadToS3(payload)),
  updateChatStatus: payload => dispatch(updateChatStatus(payload))
});

const mapStateToProps = state => ({
  msg_histories: state.adminReducer.msg_histories,
  accountDetails: state.adminReducer.accountDetails,
  unreadMessageCount: state.adminReducer.unreadMessageCount,
  isIOS: state.appReducer.isIOS,
  isAndroid: state.appReducer.isAndroid,
  isMobileView: state.appReducer.isMobileView
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MessageHistory));
