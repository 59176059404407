import React, { Component, Fragment } from 'react';
import {
  Avatar,
  Typography,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
  ClickAwayListener,
  DialogTitle
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import logo1 from './images/image.png';
import HelpOutlineIcon from '../../Header/Icons/HelpOutlineIcon';
import { includes } from 'lodash';
import clsx from 'clsx';
import { parseParams } from '../../../utils/helper';

const CustomTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 1px 6px #00000029',
    padding: theme.spacing(1.5, 2),
    // minWidth: 200,
    maxWidth: props => (props.windowWidth ? props.windowWidth - 200 : window.innerWidth - 200),
    maxHeight: props => (props.windowHeight ? props.windowHeight : window.innerHeight - 400),
    margin: 0,
    marginTop: '5px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#fafafa'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '3px',
      backgroundColor: '#21212169'
    }
  },
  popper: {
    pointerEvents: 'auto'
  },
  [theme.breakpoints.up('md')]: {
    popper: {
      marginLeft: '194px'
    }
  }
}))(Tooltip);

const styles = theme => ({
  setSize: {
    height: 16,
    marginTop: 2,
    width: 16,
    marginLeft: 4
  },
  setSizeITK: {
    height: 16,
    width: 16,
    marginLeft: 4,
    marginBottom: -2
  },
  img: {
    width: '100%',
    height: '100%'
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  closeIcon: {
    '&:hover': {
      backgroundColor: theme.palette.primary.white
    }
  },
  root: {
    maxHeight: '420px'
  },
  header: {
    color: '#545456'
  },

  [theme.breakpoints.up('md')]: {
    img: {
      width: '100%',
      height: '100%'
    }
  },
  setIconSize: {
    height: theme.spacing(3),
    width: theme.spacing(3)
  }
});

class Info extends Component {
  state = {
    showTooltip: false,
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowReSize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowReSize);
  }

  handleWindowReSize = () => {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  };

  toggleTooltip = value => {
    this.setState({ showTooltip: value });
  };

  getTooltipContent = img => {
    if (this.state.showTooltip) {
      if (this.props.name === 'capacity_question') {
        return (
          <Box>
            <Typography>
              {`Less than favourable conditions refers to if an outage occurs when the battery has been discharged or during the winter. Typically this number is around 30-40% of the total capacity`}
            </Typography>
          </Box>
        );
      }
      if (this.props.name === 'critical_question') {
        return (
          <Box>
            <Typography
            >{`Critical loads are essential loads that require power when the grid is out, as designated by the system owner. Please provide an estimate of the critical load size in an hourly interval.`}</Typography>
          </Box>
        );
      }
      if (this.props.name === 'average_load') {
        return (
          <Box>
            <Typography>{`Utility bill may contain daily usage data of previous 12 months.`}</Typography>
          </Box>
        );
      }
      if (this.props.name === 'paragraph') {
        return (
          <Box>
            <Avatar key={'logo1'} variant="square" alt="Screenshots" src={logo1} className={img} />
          </Box>
        );
      }
    }
  };

  render() {
    const { classes } = this.props;
    const windowHeight = this.state.windowHeight >= 800 ? this.state.windowHeight - 400 : this.state.windowHeight * 0.5;
    const classNames =
      this.props.name !== 'paragraph'
        ? { tooltip: classes.tooltip, popper: classes.popper, arrow: classes.arrow }
        : { tooltip: classes.tooltip };
    const { search } = window.location;
    const { platform = '' } = parseParams(search);
    const fromITK = platform === 'itk' || includes(window.location.href, 'platform=itk');

    return (
      <span style={{ verticalAlign: 'middle' }}>
        {this.props.name === 'paragraph' ? (
          <Fragment>
            <IconButton
              aria-label="help"
              size="small"
              edge="end"
              className={clsx(!fromITK ? classes.setSize : classes.setSizeITK)}
              onClick={() => this.toggleTooltip(true)}
              disableRipple={true}
            >
              <HelpOutlineIcon className={classes.setIconSize} />
            </IconButton>

            <Dialog
              onClose={() => this.toggleTooltip(false)}
              aria-labelledby="customized-dialog-title"
              open={this.state.showTooltip}
              maxWidth={'md'}
              id="successMsg"
            >
              <DialogTitle>
                <Box className={classes.dialogTitle}>
                  <Typography variant="subtitle2" className={classes.header}>
                    Sample Attestation Form
                  </Typography>
                  <IconButton
                    classes={{ root: classes.closeIcon }}
                    edge="start"
                    color="inherit"
                    onClick={() => {}}
                    aria-label="close"
                    size="small"
                    disableRipple={true}
                  >
                    <CloseIcon onClick={() => this.toggleTooltip(false)} />
                  </IconButton>
                </Box>
              </DialogTitle>
              <DialogContent dividers className={classes.root}>
                {this.getTooltipContent(classes.img)}
              </DialogContent>
            </Dialog>
          </Fragment>
        ) : (
          <ClickAwayListener onClickAway={() => this.setState({ showTooltip: false })}>
            <CustomTooltip
              title={this.getTooltipContent(classes.img)}
              classes={classNames}
              open={this.state.showTooltip}
              placement="bottom"
              disableFocusListener
              disableHoverListener
              disableTouchListener
              windowWidth={this.state.windowWidth - 500}
              windowHeight={windowHeight}
            >
              <IconButton
                aria-label="help"
                size="small"
                edge="end"
                className={classes.setSize}
                onClick={() => this.toggleTooltip(true)}
                onMouseOver={() => this.props.name !== 'paragraph' && this.toggleTooltip(true)}
                onMouseOut={() => this.props.name !== 'paragraph' && this.toggleTooltip(false)}
                disableRipple={true}
              >
                <HelpOutlineIcon width="16" height="16" />
              </IconButton>
            </CustomTooltip>
          </ClickAwayListener>
        )}
      </span>
    );
  }
}

export default withStyles(styles)(Info);
