import { orange, grey, blue } from '@material-ui/core/colors';

let commonThemeObj = {
  typography: {
    useNextVariants: true,
    fontFamily: 'Roboto'
  },
  palette: {
    primary: {
      main: '#F3731F',
      dark: '#335977',
      yellow: '#FFE785',
      faded: '#C9C9C9',
      lightBlack: '#5C5C5C',
      dataBlack: '#999999',
      white: '#FFFFFF',
      mainText: '#545456',
      subText: '#939598',
      disableBg: '#F6F6F6',
      blue: '#0000FF',
      ...orange
    },
    secondary: {
      main: '#01B4DE',
      link: '#2679f6',
      ...blue
    },
    tertiary: {
      main: '#FFFFFF',
      light: '#FAFAFA',
      dark: '#7E7E7E',
      background: '#F6F7FB',
      lighter: grey[100],
      ...grey
    }
  }
};

export default commonThemeObj;
