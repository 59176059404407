import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Grid,
  Button,
  TableBody,
  TableRow,
  TableCell,
  Table,
  TableContainer
} from '@material-ui/core';

const styles = theme => ({
  dialogTitle: {
    padding: 0,
    textAlign: 'right'
  },
  root: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2.5)
    },
    borderRadius: '10px'
  },
  button: {
    color: theme.palette.secondary.main,
    textTransform: 'none'
  },
  cancelbutton: {
    textTransform: 'none'
  },
  title: {
    padding: '20px'
  },
  heading: {
    color: '#545456'
  },
  boxWrapper: {
    border: 'solid 1px #c6c8ca',
    borderRadius: '10px',
    padding: '20px'
  },
  tdborder: {
    borderBottom: 'none',
    borderRight: '1px solid #d8dcde',
    textTransform: 'none',
    width: '50%',
    padding: theme.spacing(1.75, 0)
  },
  tdnoborder: {
    borderBottom: '0px',
    width: '50%',
    padding: theme.spacing(1.75, 0)
  },
  proceed: {
    color: '#01B4DE'
  },
  actions: {
    padding: '0px'
  },
});

const SGIPDeveloperKeyDialog = props => {
  const scroll = 'paper';
  const { classes, handleClose, handleProceed, installerName } = props;
  return (
    <div>
      <Dialog
        open={true}
        onClose={props.handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        style={{ borderRadius: '10px' }}
        classes={{ root: 'rounded' }}
      >
        <DialogTitle className={classes.title} id="customized-dialog-title" onClose={handleClose}>
          <Typography variant="subtitle2" className={classes.heading}>
            Important Notification
          </Typography>
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'} className={classes.root}>
          <Box>
            <Grid>
              <Typography>
                Your installer has not updated the SGIP related information in their Enphase profile, which is required
                to process the application. Would you like us to notify them on this email address-{' '}
                <Typography display="inline" variant="body2">
                  {installerName}
                </Typography>{' '}
                - to update their profile?
              </Typography>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.tdnoborder} align="center">
                    <Button onClick={handleClose} className={classes.cancelbutton}>
                      No
                    </Button>
                  </TableCell>
                  <TableCell align="center" className={classes.tdborder}>
                    <Button onClick={handleProceed} className={classes.proceed}>
                      Yes
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(SGIPDeveloperKeyDialog);
