import {
  GET_SGIP_INSTALLERS_REQUEST,
  GET_SGIP_PRIMARY_INSTALLERS_REQUEST,
  GET_APPLICATION_NUMBER_REQUEST,
  GET_SGIP_DOC_S3_PRE_SIGNED_URL_REQUEST,
  PUT_SGIP_DOC_UPLOAD_TO_S3_REQUEST,
  DELETE_SGIP_DOC_FILE_FROM_S3_REQUEST,
  GET_SGIP_DOC_FILE_FROM_S3_REQUEST,
  POST_SGIP_PRIMARY_DETAILS_REQUEST,
  POST_DEVELOPER_KEY_UPDATE_MAIL_REQUEST,
  POST_SGIP_NOTIFY_ME_DETAILS_REQUEST,
  POST_SGIP_PERSONAL_DETAILS_REQUEST,
  POST_SGIP_QUESTIONNAIRE_REQUEST,
  POST_SGIP_APPLICATION_DOCUMENTS_REQUEST,
  GET_SGIP_APPLICATION_DETAILS_BY_SITEID_REQUEST,
  POST_SGIP_PAYMENT_INTENT_REQUEST,
  GET_FILE_FROM_S3_REQUEST,
  POST_SGIP_PAYMENT_UPDATE_REQUEST,
  GET_SGIP_STRIPE_PUBLISHABLE_KEY_REQUEST,
  GET_UTILITY_BILLS_PRE_SIGNED_URL_REQUEST,
  GET_DOCUMENTS_TYPE_PRE_SIGNED_URL_REQUEST,
  GET_UPLOADED_DOCUMENTS_PRE_SIGNED_URL_REQUEST,
  POST_APPLICANT_NOTIFICATION_REQUEST,
  POST_SIGNED_DOCUMENTS_REQUEST,
  POST_FIELD_CHANGED_ACCEPT_NOTIFICATION_REQUEST,
  POST_FIELD_CHANGED_REJECT_NOTIFICATION_REQUEST,
  POST_SGIP_APPLICATION_TRACKER_UPLOAD_DOCUMENTS_REQUEST,
  POST_PROMOCODE_REQUEST,
  POST_SGIP_PROMOCODE_PAYMENT_REQUEST,
  POST_SGIP_ATTESTATION_FORM_REQUEST,
  GET_SGIP_USER_SELECTION_REQUEST,
  POST_SGIP_NPS_FEEDBACK_REQUEST,
  GET_SGIP_NPS_FEEDBACK_REQUEST,
  GET_SITE_INFO_REQUEST,
  GET_INCENTIVE_RATES_SGIP_REQUEST,
  GET_SGIP_CONFIGS_REQUEST,
  CREATE_SGIP_APPLICATION_REQUEST,
  ADD_TO_CART_REQUEST,
  GET_CPUC_URL_REQUEST
} from './constants';

export function getSGIPInstallers(payload) {
  return { type: GET_SGIP_INSTALLERS_REQUEST, payload };
}

export function getSGIPPrimaryInstallers(payload) {
  return { type: GET_SGIP_PRIMARY_INSTALLERS_REQUEST, payload };
}

export function getApplicationNumber(payload) {
  return { type: GET_APPLICATION_NUMBER_REQUEST, payload };
}

export function getSgipDocumentPresignedURL(payload) {
  return { type: GET_SGIP_DOC_S3_PRE_SIGNED_URL_REQUEST, payload };
}

export function getSgipDocumentFromS3(payload) {
  return { type: GET_SGIP_DOC_FILE_FROM_S3_REQUEST, payload };
}

export function putSgipDocumentToS3(payload) {
  return { type: PUT_SGIP_DOC_UPLOAD_TO_S3_REQUEST, payload };
}

export function postSgipPrimaryDetails(payload) {
  return { type: POST_SGIP_PRIMARY_DETAILS_REQUEST, payload };
}

export function postDeveloperKeyUpdateMail(payload) {
  return { type: POST_DEVELOPER_KEY_UPDATE_MAIL_REQUEST, payload };
}

export function postNotifyMeDetails(payload) {
  return { type: POST_SGIP_NOTIFY_ME_DETAILS_REQUEST, payload };
}

export function postSgipPersonalDetails(payload) {
  return { type: POST_SGIP_PERSONAL_DETAILS_REQUEST, payload };
}

export function postSgipBudgetQuestionnaires(payload) {
  return { type: POST_SGIP_QUESTIONNAIRE_REQUEST, payload };
}

export function postSgipApplicationDocuments(payload) {
  return { type: POST_SGIP_APPLICATION_DOCUMENTS_REQUEST, payload };
}

export function postSgipApplicationTrackUploadDocuments(payload) {
  return {
    type: POST_SGIP_APPLICATION_TRACKER_UPLOAD_DOCUMENTS_REQUEST,
    payload
  };
}

export function deleteSgipDocumentFromS3(payload) {
  return { type: DELETE_SGIP_DOC_FILE_FROM_S3_REQUEST, payload };
}

export function getSgipApplicationDetailsBySiteId(payload) {
  return { type: GET_SGIP_APPLICATION_DETAILS_BY_SITEID_REQUEST, payload };
}

export function downloadFileFromS3(payload) {
  return { type: GET_FILE_FROM_S3_REQUEST, payload };
}

export function postSgipServicePaymentUpdate(payload) {
  return { type: POST_SGIP_PAYMENT_UPDATE_REQUEST, payload };
}

export function postSgipPaymentIntent(payload) {
  return { type: POST_SGIP_PAYMENT_INTENT_REQUEST, payload };
}

export function getStripePublishableKey(payload) {
  return { type: GET_SGIP_STRIPE_PUBLISHABLE_KEY_REQUEST, payload };
}

export function getUtilityBillsPresignedURLs(payload) {
  return { type: GET_UTILITY_BILLS_PRE_SIGNED_URL_REQUEST, payload };
}

export function getDocumentsTypePresignedURLs(payload) {
  return { type: GET_DOCUMENTS_TYPE_PRE_SIGNED_URL_REQUEST, payload };
}

export function getUploadedDocumentsPresignedURLs(payload) {
  return { type: GET_UPLOADED_DOCUMENTS_PRE_SIGNED_URL_REQUEST, payload };
}

export function postApplicantNotification(payload) {
  return { type: POST_APPLICANT_NOTIFICATION_REQUEST, payload };
}

export function postSignedDocument(payload) {
  return { type: POST_SIGNED_DOCUMENTS_REQUEST, payload };
}

export function postFieldChangeAcceptNotification(payload) {
  return { type: POST_FIELD_CHANGED_ACCEPT_NOTIFICATION_REQUEST, payload };
}

export function postFieldChangeRejectNotification(payload) {
  return { type: POST_FIELD_CHANGED_REJECT_NOTIFICATION_REQUEST, payload };
}

export function verifyPromocode(payload) {
  return { type: POST_PROMOCODE_REQUEST, payload };
}

export function postSgipServicePromocodePayment(payload) {
  return { type: POST_SGIP_PROMOCODE_PAYMENT_REQUEST, payload };
}

export function postSgipAttestationForm(payload) {
  return { type: POST_SGIP_ATTESTATION_FORM_REQUEST, payload };
}

export function getSgipUserSelectionData(payload) {
  return { type: GET_SGIP_USER_SELECTION_REQUEST, payload };
}

export function postSgipNpsFeedback(payload) {
  return { type: POST_SGIP_NPS_FEEDBACK_REQUEST, payload };
}

export function getSgipNpsFeedback(payload) {
  return { type: GET_SGIP_NPS_FEEDBACK_REQUEST, payload };
}

export function getSiteInfo(payload) {
  return { type: GET_SITE_INFO_REQUEST, payload };
}

export function getSgipIncentiveRates(payload) {
  return { type: GET_INCENTIVE_RATES_SGIP_REQUEST, payload };
}

export function getSgipConfigs(payload) {
  return { type: GET_SGIP_CONFIGS_REQUEST, payload };
}

export function getCpucURL(payload) {
  return { type: GET_CPUC_URL_REQUEST, payload };
}

export function createSgipApplication(payload) {
  return { type: CREATE_SGIP_APPLICATION_REQUEST, payload };
}

export function addToCart(payload) {
  return { type: ADD_TO_CART_REQUEST, payload };
}
