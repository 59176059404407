import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { STATUS } from '../../../containers/Admin/constants';

import {
  Typography,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  TextField
} from '@material-ui/core';
import { getStatusDetails } from '../../../containers/Admin/helper';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { updateStageStatus, updateAppId } from '../../../containers/Admin/actions';
import InprogressIcon from '../../Common/Icons/InprogressIcon';
import CompletedIcon from '../../Common/Icons/CompletedIcon';
import RejectedIcon from '../../Common/Icons/RejectedIcon';
import { isEmpty, isEqual } from 'lodash';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.dark
  },
  fontBold: {
    fontWeight: 'bold'
  },
  dataHeader: {
    color: '#246AB0'
  },
  panelHead: {
    background: '#f1f1f1',
    color: theme.palette.primary.mainText,
    minHeight: '40px !important',
    height: '40px',
    cursor: 'unset !important'
  },
  panelDetails: {
    flexDirection: 'column'
  },
  heading: {
    fontWeight: 'bold',
    flexBasis: '90%',
    flexShrink: 0
  },
  headingInfo: {
    color: 'darkgray !important'
  },
  dataSubHeader: {
    fontSize: '16px',
    color: 'grey'
  },
  dataDiv: {
    minHeight: '517px'
  },
  acceptButton: {
    color: theme.palette.primary.white
  },
  formControl: {
    width: '100%'
  },
  statusIcons: {
    width: '15px',
    height: '15px',
    marginRight: '4px'
  },
  dialogBox: {
    display: 'flex',
    alignItems: 'center'
  },
  dialogInputField: {
    marginTop: theme.spacing(1.75)
  }
});

const ICF_SITE_INSPECTION = 'Site Inspection';
const ICF_APPROVAL_FROM_UTILITY = 'Approval from Utility';
export class StatusDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sDetails: {
        sArray: [],
        currentStage: 0
      },
      showUpdateStatus: false,
      status_levels: [],
      sStatus: '',
      stageId: 1,
      sDate: moment().format('MM/DD/YYYY'),
      stageForStatusUpdate: {},
      minDate: moment().format('MM/DD/YYYY'),
      maxDate: moment().format('MM/DD/YYYY'),
      applicationNumber: '',
      appNumError: false,
      appNumHelpText: "Characters Allowed [A-Z][a-z][0-9] and '-'"
    };
  }

  componentDidMount() {
    const { appDetails } = this.props;
    let sDetails = getStatusDetails(appDetails);
    this.setState({ sDetails: sDetails });
  }

  hideUpdateStatus() {
    this.setState({
      showUpdateStatus: false,
      status_levels: [],
      sStatus: '',
      sDate: moment().format('MM/DD/YYYY'),
      stageForStatusUpdate: {}
    });
  }
  setApplicationNumber(event) {
    let err = /[^0-9a-zA-Z-]/.test(event.target.value);
    this.setState({ applicationNumber: event.target.value, appNumError: err });
  }

  viewUpdateStatus(stageForStatusUpdate, stageID) {
    const { appDetails } = this.props;
    let sLevels = [];
    for (let i = 0; i < stageForStatusUpdate.status_levels.length; i++) {
      const sL = stageForStatusUpdate.status_levels[i];
      let newSL = sL;

      switch (stageForStatusUpdate.status) {
        case STATUS.NOT_STARTED: {
          newSL.disabled = sL.label === stageForStatusUpdate.status;
          break;
        }
        case STATUS.REJECTED:
        case STATUS.INPROGRESS: {
          newSL.disabled = sL.label === stageForStatusUpdate.status || sL.label === STATUS.NOT_STARTED;
          break;
        }
        default:
          newSL.disabled = sL.label === stageForStatusUpdate.status;
      }

      sLevels.push(newSL);
    }
    //update date
    this.setState({
      showUpdateStatus: true,
      status_levels: sLevels,
      stageId: stageID || '',
      stageForStatusUpdate: stageForStatusUpdate,
      minDate: moment(appDetails.current_application_status.last_updated_date).format('MM/DD/YYYY'),
      maxDate: moment().format('MM/DD/YYYY')
    });
  }

  statusSelUpdate(event) {
    this.setState({ sStatus: event.target.value });
  }

  dateSelUdpate(date) {
    let selDate = moment(date).startOf('day');
    this.setState({ sDate: selDate.format('MM/DD/YYYY') });
  }

  updateStatus(callback, isRRFSignedStage) {
    const { appDetails } = this.props;
    //let dOfCompletion = moment(this.state.sDate).startOf('day');
    let dOC = moment(new Date(this.state.sDate));
    const time = moment();
    dOC.set({ hour: time.get('hour'), minute: time.get('minute'), second: time.get('second') });

    if (this.state.stageId.id) {
      let statusName = '';
      if (this.state.sStatus === STATUS.REQUIRED) {
        // when users selects Required for Site Inspection then in payload status name should be "In Progress"
        statusName = STATUS.INPROGRESS;
      } else if (this.state.sStatus === '') {
        statusName = STATUS.COMPLETED;
      } else {
        statusName = this.state.sStatus;
      }
      this.props.updateStageStatus({
        app_id: appDetails.application_id,
        stage_id: this.state.stageId.id,
        substage_id: this.state.stageForStatusUpdate.id,
        status_name: statusName,
        updatedDate: dOC.format('YYYY-MM-DDTHH:mm:ssZZ'),
        isRRFSignedStage: isRRFSignedStage,
        successCbk: callback
      });
    } else {
      this.props.updateStageStatus({
        app_id: appDetails.application_id,
        stage_id: this.state.stageForStatusUpdate.id,
        substage_id: this.state.sStatus === STATUS.NOT_REQUIRED ? 2 : 1,
        status_name: this.state.sStatus === STATUS.REQUIRED ? STATUS.INPROGRESS : this.state.sStatus,
        updatedDate: dOC.format('YYYY-MM-DDTHH:mm:ssZZ'),
        isRRFSignedStage: isRRFSignedStage,
        successCbk: callback
      });
    }
  }

  updateAppId(isRRFSignedState = false, showMsg) {
    if (isRRFSignedState) {
      this.setState({ viewUpdateAppId: false });
      this.props.updateAppId({
        show_msg: showMsg,
        app_id: this.props.appDetails.application_id,
        app_number: this.state.applicationNumber,
        isRRFSignedState: isRRFSignedState
      });
    }
  }

  renderIcon = status => {
    const { classes } = this.props;

    switch (status) {
      case STATUS.NOT_STARTED:
      case STATUS.WAITLISTED:
      case STATUS.INPROGRESS:
        return <InprogressIcon width="11px" height="11px" className={classes.statusIcons} />;
      case STATUS.APPROVED:
      case STATUS.COMPLETED:
      case STATUS.NOT_REQUIRED:
        return <CompletedIcon width="11px" height="11px" className={classes.statusIcons} />;
      case STATUS.REJECTED:
        return <RejectedIcon width="11px" height="11px" className={classes.statusIcons} />;
      default:
        return <InprogressIcon width="11px" height="11px" className={classes.statusIcons} />;
    }
  };

  renderContent = (substage, status) => {
    const { installerView } = this.props;

    const isSubstageCompleted = () => {
      return (
        substage.status === STATUS.COMPLETED ||
        substage.status === STATUS.REQUIRED ||
        substage.status === STATUS.NOT_REQUIRED ||
        substage.status === STATUS.APPROVED ||
        substage.status === STATUS.REJECTED ||
        substage.status === STATUS.WAITLISTED
      );
    };

    const getSubstepLabel = () => {
      let label = '';
      if (isEmpty(substage.status) && !substage.actions.updateStatus) {
        label = '';
      } else {
        if (
          substage.actions.updateStatus &&
          !(substage.label === ICF_SITE_INSPECTION && isEmpty(substage.status)) && // if site inspection status is empty don't show in progress against it
          ![STATUS.REJECTED, STATUS.WAITLISTED].includes(substage.status)
        ) {
          label = <span>{`: ${STATUS.INPROGRESS}`}</span>;
        } else {
          if (isSubstageCompleted()) {
            if (substage.label === ICF_SITE_INSPECTION || substage.label === ICF_APPROVAL_FROM_UTILITY) {
              label = `(${substage.status})`;
            }
            label = label + `: ${substage.completedOn}`;
          } else {
            label = substage.status && <span>{`: ${substage.status}`}</span>;
          }
        }
      }
      return <span>{label}</span>;
    };

    return (
      <StepContent key={`${substage.label}.${status.label}`}>
        <Typography style={{ fontSize: '12px', color: '#0A0A0A', display: 'flex', alignItems: 'center' }}>
          {this.renderIcon(substage.status)}
          {substage.label}
          <span style={{ color: '##939393', marginLeft: '2px' }}>{getSubstepLabel()}</span>
        </Typography>
        {substage.actions.updateStatus && !installerView && (
          <Button size="small" onClick={() => this.viewUpdateStatus(substage, status)}>
            <Link style={{ fontSize: '12px', textTransform: 'none', color: '#3685D1' }}>Update Status</Link>
          </Button>
        )}
      </StepContent>
    );
  };

  render() {
    const { classes } = this.props;
    const {
      sDetails,
      showUpdateStatus,
      status_levels,
      sStatus,
      sDate,
      stageForStatusUpdate,
      minDate,
      maxDate,
      applicationNumber,
      appNumError,
      appNumHelpText
    } = this.state;

    const isRRFSignedStage =
      this.state.sDetails.currentStage === 2 &&
      this.state.sDetails.sArray[1]?.sub_stages.find(substage => isEmpty(substage.completedOn))?.id === 3;

    const isAppNumEmpty = () => {
      return isRRFSignedStage && (applicationNumber === null || applicationNumber === '' || appNumError === true);
    };

    const updateStatusAndAppNum = () => {
      this.updateStatus(() => {
        this.updateAppId(isRRFSignedStage, false);
      }, isRRFSignedStage);
    };

    return (
      <Fragment>
        {' '}
        <Grid container direction="row">
          <Grid item xs={12}>
            <ExpansionPanel expanded={true}>
              <ExpansionPanelSummary aria-controls="sDetails-content" id="sDetails" className={classes.panelHead}>
                <Typography className={classes.heading}>Status Details</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails
                style={{
                  flexDirection: 'column'
                }}
              >
                <div
                  style={{
                    maxHeight: '516px',
                    minHeight: '516px',
                    overflow: 'auto'
                  }}
                >
                  <Stepper
                    activeStep={sDetails.currentStage}
                    orientation="vertical"
                    style={{ width: '100%', padding: '10px' }}
                  >
                    {sDetails.sArray.map(status => (
                      <Step
                        key={status.id}
                        expanded={true}
                        active={status.id === sDetails.currentStage}
                        completed={
                          status.sub_stages.length > 0 &&
                          (status.sub_stages[status.sub_stages.length - 1].status === STATUS.COMPLETED ||
                            status.sub_stages[status.sub_stages.length - 1].status === STATUS.NOT_REQUIRED ||
                            status.sub_stages[status.sub_stages.length - 1].status === STATUS.APPROVED)
                        }
                      >
                        {/* <StepIcon icon={<InprogressIcon />} /> */}
                        <StepLabel icon={status.id === sDetails.currentStage ? <InprogressIcon /> : status.id}>
                          <Typography style={{ fontWeight: 'bold', fontSize: '13px' }}>{status.label}</Typography>
                        </StepLabel>
                        {status.isStageActive &&
                          status.sub_stages.map(substage => this.renderContent(substage, status))}
                      </Step>
                    ))}
                  </Stepper>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
        </Grid>
        <Dialog
          onClose={() => this.hideUpdateStatus()}
          aria-labelledby="customized-dialog-title"
          open={showUpdateStatus}
          maxWidth={'md'}
          id="successMsg"
        >
          <DialogTitle id="customized-dialog-title" onClose={() => this.hideUpdateStatus()}>
            Update Status
          </DialogTitle>
          <DialogContent>
            <Typography style={{ fontWeight: 'bold', fontSize: '13px' }}>{stageForStatusUpdate.label}</Typography>
            {(stageForStatusUpdate.label !== ICF_SITE_INSPECTION &&
              stageForStatusUpdate.label !== ICF_APPROVAL_FROM_UTILITY &&
              stageForStatusUpdate.label !== 'Approval' &&
              stageForStatusUpdate.label !== STATUS.WAITLISTED &&
              stageForStatusUpdate.label !== STATUS.REJECTED) ||
            (stageForStatusUpdate.label === ICF_SITE_INSPECTION &&
              stageForStatusUpdate.status === STATUS.INPROGRESS) ? (
              <Typography style={{ padding: '10px 0px', fontSize: '16px' }}>
                Are you sure you want to update the status ?
              </Typography>
            ) : (
              <Fragment>
                <FormControl className={classes.formControl}>
                  <InputLabel id="status_sel">Status</InputLabel>
                  <Select labelId="status_sel" value={sStatus} onChange={e => this.statusSelUpdate(e)}>
                    {status_levels.map((sL, idx) => {
                      return (
                        <MenuItem value={sL.label} key={idx} disabled={sL.disabled}>
                          {sL.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <br />
                <br />
              </Fragment>
            )}
            <br />
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              Date Of Completion : &nbsp;&nbsp;&nbsp;
              <MuiPickersUtilsProvider libInstance={moment} utils={DateFnsUtils}>
                <DatePicker
                  value={sDate}
                  format="MM/dd/yyyy"
                  onChange={e => this.dateSelUdpate(e)}
                  minDate={minDate}
                  maxDate={maxDate}
                  disabled={true}
                />
              </MuiPickersUtilsProvider>
            </Box>

            {isRRFSignedStage && (
              <Box className={classes.dialogBox}>
                Application Number : {'\u00A0\u00A0\u00A0'}
                <TextField
                  id="appId"
                  placeholder="Application Number"
                  value={applicationNumber}
                  onChange={e => {
                    this.setApplicationNumber(e);
                  }}
                  inputProps={{
                    maxLength: 25
                  }}
                  error={appNumError}
                  helperText={appNumHelpText}
                  className={classes.dialogInputField}
                />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.hideUpdateStatus()} variant="contained">
              Close
            </Button>
            {this.showUpdateButton(stageForStatusUpdate, sStatus) && (
              <Button
                onClick={() => updateStatusAndAppNum()}
                color="primary"
                variant="contained"
                className={classes.acceptButton}
                disabled={isAppNumEmpty()}
              >
                Update
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }

  showUpdateButton = (stageForStatusUpdate, sStatus) => {
    return (
      (stageForStatusUpdate.label !== ICF_SITE_INSPECTION &&
        stageForStatusUpdate.label !== ICF_APPROVAL_FROM_UTILITY &&
        stageForStatusUpdate.label !== 'Approval' &&
        stageForStatusUpdate.label !== STATUS.WAITLISTED &&
        stageForStatusUpdate.label !== STATUS.REJECTED) ||
      sStatus !== '' ||
      (stageForStatusUpdate.label === ICF_SITE_INSPECTION && stageForStatusUpdate.status === STATUS.INPROGRESS)
    );
  };
}

const mapDispatchToProps = dispatch => ({
  updateAppId: payload => dispatch(updateAppId(payload)),
  updateStageStatus: payload => dispatch(updateStageStatus(payload))
});
const mapStateToProps = state => ({
  currentlySending: state.adminReducer.currentlySending
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(StatusDetails));
