import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Title } from '../../../components/Texts';
import { BILLS_TYPE } from '../../../utils/constants';
import uploadFileDetails from '../../../utils/uploadFile';
import { Typography, Button, Divider } from '@material-ui/core';
import { FileAttachementBar } from '../../../components/FileAttachmentBar';

const styles = theme => ({
  title: {
    marginBottom: theme.spacing(1.25),
    fontWeight: 500
  },
  text: {
    color: '#939598',
    fontWeight: 500
  },
  divider: {
    marginTop: '25px',
    marginBottom: '20px'
  },
  fileCard: {
    backgroundColor: '#5a5a5c',
    paddingTop: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  cardFileNameText: {
    color: '#ffffff',
    flexDirection: 'row',
    textAlign: 'left',
    marginTop: '0px',
    display: 'flex'
  },
  cardFileSizeText: {
    color: '#ffffff'
  },
  cardFileAttachmentIcon: {
    marginRight: theme.spacing(0.5),
    width: 25,
    height: 30,
    color: '#ffffff',
    flexDirection: 'row',
    marginLeft: '5px'
  },
  cardFileCloseIcon: {
    marginRight: theme.spacing(1),
    height: 20,
    color: '#ffffff',
    float: 'right',
    flexDirection: 'row',
    width: '20px',
    marginLeft: 'auto'
  },
  chooseFileButton: {
    padding: theme.spacing(0, 0.5, 0, 0.5),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1)
  },
  chooseFile: {
    border: '1px solid #bbb',
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.secondary.main,
    marginTop: theme.spacing(1),
    display: 'flex'
  },
  uploadButton: {
    textTransform: 'capitalize',
    borderRadius: theme.spacing(2.5),
    minWidth: theme.spacing(17.5),
    padding: '3px 16px',
    color: '#01b4de',
    marginTop: theme.spacing(1.5)
  },
  errorText: {
    color: '#f44336',
    marginLeft: theme.spacing(0),
    marginTop: '3px'
  },
  [theme.breakpoints.up('md')]: {
    dBlock: {
      display: 'block'
    },
    doucmentItem: {
      border: '1px solid rgba(136, 136, 136, 0.2)',
      flexBasis: '40%',
      margin: '10px',
      borderRadius: '2px',
      padding: '20px'
    },
    dHide: {
      display: 'none'
    }
  }
});

const TaxReturnsFileUpload = props => {
  const {
    classes,
    isIncomeQualifierAnswer,
    taxreturns_doc,
    isIOS,
    deleteUploadedFile,
    taxReturnsDocumentErrorFileNameMessage,
    taxReturnsDocumentError,
    putSgipDocumentToS3,
    applicationId,
    getSgipDocumentPresignedURL,
    setState
  } = props;

  const setDocumentError = (documentError, obj, cb) => {
    documentError.file_name.error = obj.error;
    documentError.file_name.msg = obj.msg;
    cb(documentError);
  };

  const handleTaxReturnsFileUpload = event => {
    let documentError = taxReturnsDocumentError;
    setDocumentError(documentError, { error: false, msg: '' }, errorObj => {
      setState({ taxReturnsDocumentError: errorObj });
    });

    let taxreturns_doc = props.taxreturns_doc;
    if (!document.getElementById('taxReturnsDocument')) {
      return;
    }

    const setStatus = status => {
      setState({ taxReturnsUploadStatus: status });
    };

    const details = {
      event,
      setStatus,
      documentType: BILLS_TYPE.TAX_RETURNS,
      isIOS,
      app_id: applicationId,
      bills: taxreturns_doc,
      fileUploadSize: 20,
      getSgipDocumentPresignedURL,
      putSgipDocumentToS3,
      setDocumentError: (errorDetails, status) => {
        let documentError = taxReturnsDocumentError;
        setDocumentError(documentError, errorDetails, errorObj => {
          setState({ taxReturnsDocumentError: errorObj });
          status && setStatus(status);
        });
      }
    };

    uploadFileDetails(details, bills => {
      setState({ taxreturns_doc: bills });
      if (document.getElementById('taxReturnsDocument')) {
        document.getElementById('taxReturnsDocument').value = '';
        return;
      }
    });
  };

  return (
    <React.Fragment>
      {isIncomeQualifierAnswer ? (
        <React.Fragment>
          <div className={clsx(classes.doucmentItem, classes.dBlock)}>
            <Title className={classes.title}> Tax Return </Title>
            <Typography className={classes.text}>
              Upload your most recent tax return (required as you have indicated that your income is less than or equal
              to 80% of your area’s median income)
            </Typography>
            {taxreturns_doc.map((tDoc, k) => (
              <FileAttachementBar
                classes={classes}
                key={tDoc.display_file_name}
                id={tDoc.display_file_name}
                fileType="taxReturns"
                display_file_name={tDoc.display_file_name}
                fileName={tDoc.file_name}
                fileSize={tDoc.file_size}
                onClose={deleteUploadedFile}
              />
            ))}
            {taxReturnsDocumentErrorFileNameMessage !== '' ? (
              <div>
                <Typography className={classes.errorText}>{taxReturnsDocumentErrorFileNameMessage}</Typography>
              </div>
            ) : (
              <div />
            )}
            <Button
              className={clsx(classes.chooseFileButton, classes.button, classes.uploadButton)}
              variant="outlined"
              color="secondary"
              disableElevation
              disabled={taxreturns_doc.length >= 6}
              component="label"
            >
              Choose File
              <input
                type="file"
                style={{ display: 'none' }}
                id="taxReturnsDocument"
                name="taxReturnsDocument"
                onChange={e => handleTaxReturnsFileUpload(e)}
                multiple
              />
            </Button>
            <Divider className={clsx(classes.dHide, classes.divider)} />
          </div>
        </React.Fragment>
      ) : (
        ''
      )}
    </React.Fragment>
  );
};

export default withStyles(styles)(TaxReturnsFileUpload);
