import { makeStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';

const useMobileStyles = makeStyles(theme => ({
  root: {
    borderRadius: theme.spacing(1.25),
    boxShadow: '1px 2px 5px 0 rgba(152, 152, 152, 0.1)',
    backgroundColor: theme.palette.tertiary.main
  },
  top: {
    marginTop: theme.spacing(2.25)
  },
  header: {
    letterSpacing: '0px'
  },
  headerLeased: {
    opacity: 0.5
  },
  label: {
    color: '#939598'
  },
  text: {
    marginTop: '5px'
  },
  siteText: {
    fontWeight: 'normal',
    color: '#545456'
  },
  btn: {
    height: '40px'
  },
  textGreyed: {
    opacity: 0.5,
    marginTop: '5px'
  },
  field: {
    marginTop: theme.spacing(3)
  },
  description: {
    marginBottom: theme.spacing(2),
    lineHeight: 1.36
  },

  blue: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500]
  },
  error: {
    color: 'red'
  },
  sameAsBattery: {
    marginTop: '5px'
  },
  dshow: {
    display: 'none'
  },
  [theme.breakpoints.up('md')]: {
    root: {
      display: 'flex',
      flexDirection: 'row'
    },
    text: {
      display: 'block'
    },
    dCustomBorder: {
      borderTop: '1px solid rgba(136, 136, 136, 0.2)',
      borderRadius: '3px'
    },
    personalForm: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    formField: {
      flexBasis: '30%',
      '&:nth-child(3n - 1)': {
        margin: '0 20px'
      }
    },
    dHide: {
      display: 'none'
    },
    dshow: {
      display: 'block',
      flex: '0 0 70%',
      marginTop: theme.spacing(5)
    },
    formFieldAddress: {
      flexBasis: '70%'
    },
    textGreyed: {
      flexBasis: '100%'
    },
    sameAsBattery: {
      flexBasis: '100%'
    },
    input: {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      }
    }
  }
}));

const useDesktopStyles = makeStyles(theme => ({
  root: {}
}));

export { useMobileStyles, useDesktopStyles };
