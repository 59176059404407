import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Title } from '../../../components/Texts';
import { BILLS_TYPE } from '../../../utils/constants';
import uploadFileDetails from '../../../utils/uploadFile';
import { Typography, Button, Link, Divider } from '@material-ui/core';
import { FileAttachementBar } from '../../../components/FileAttachmentBar';
import { links } from '../../../utils/helper';
import { SMALL_RESIDENTIAL, SOUTHERN_CALIFORNIA_GAS_COMPANY } from '../../../containers/SgipApp/constants';

const styles = theme => ({
  title: {
    marginBottom: theme.spacing(1.25)
  },
  text: {
    color: '#939598'
  },
  divider: {
    marginTop: '25px',
    marginBottom: '20px'
  },
  fileCard: {
    backgroundColor: '#5a5a5c',
    paddingTop: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  cardFileNameText: {
    color: '#ffffff',
    flexDirection: 'row',
    textAlign: 'left',
    marginTop: '0px',
    display: 'flex'
  },
  cardFileSizeText: {
    color: '#ffffff'
  },
  cardFileAttachmentIcon: {
    marginRight: theme.spacing(0.5),
    width: 25,
    height: 30,
    color: '#ffffff',
    flexDirection: 'row',
    marginLeft: '5px'
  },
  cardFileCloseIcon: {
    marginRight: theme.spacing(1),
    height: 20,
    color: '#ffffff',
    float: 'right',
    flexDirection: 'row',
    width: '20px',
    marginLeft: 'auto'
  },
  chooseFileButton: {
    padding: theme.spacing(0, 0.5, 0, 0.5),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1)
  },
  chooseFile: {
    border: '1px solid #bbb',
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.secondary.main,
    marginTop: theme.spacing(1),
    display: 'flex'
  },
  uploadButton: {
    textTransform: 'capitalize',
    borderRadius: theme.spacing(2.5),
    minWidth: theme.spacing(17.5),
    padding: '3px 16px',
    color: '#01b4de',
    marginTop: theme.spacing(1.5)
  },
  errorText: {
    color: '#f44336',
    marginLeft: theme.spacing(0),
    marginTop: '3px'
  },
  [theme.breakpoints.up('md')]: {
    dBlock: {
      display: 'block'
    },
    doucmentItem: {
      border: '1px solid rgba(136, 136, 136, 0.2)',
      flexBasis: '40%',
      margin: '10px',
      borderRadius: '2px',
      padding: '20px'
    },
    dHide: {
      display: 'none'
    }
  }
});

const greenDataFileUpload = props => {
  const {
    classes,
    storageCapacity,
    utilityCompanyName,
    greenDataDoc,
    isIOS,
    deleteUploadedFile,
    greenDataDocumentErrorFileNameMessage,
    greenDataDocumentError,
    putSgipDocumentToS3,
    applicationId,
    getSgipDocumentPresignedURL,
    optedBudgetCategory,
    setState
  } = props;

  const setDocumentError = (documentError, obj, cb) => {
    documentError.file_name.error = obj.error;
    documentError.file_name.msg = obj.msg;
    cb(documentError);
  };

  const handleGreenButtonFileUpload = event => {
    let documentError = greenDataDocumentError;
    setDocumentError(documentError, { error: false, msg: '' }, errorObj => {
      setState({ greenDataDocumentError: errorObj });
    });

    let greenDataDoc = props.greenDataDoc;
    if (!document.getElementById('greenDataDocument')) {
      return;
    }

    const setStatus = status => {
      setState({ incomeDocUploadStatus: status });
    };

    const details = {
      event,
      setStatus,
      documentType: BILLS_TYPE.GREEN_BUTTON,
      isIOS,
      app_id: applicationId,
      bills: greenDataDoc,
      fileUploadSize: 20,
      getSgipDocumentPresignedURL,
      putSgipDocumentToS3,
      setDocumentError: (errorDetails, status) => {
        let documentError = greenDataDocumentError;
        setDocumentError(documentError, errorDetails, errorObj => {
          setState({ greenDataDocumentError: errorObj });
          status && setStatus(status);
        });
      }
    };

    uploadFileDetails(details, bills => {
      setState({ greenDataDoc: bills });
      if (document.getElementById('greenDataDocument')) {
        document.getElementById('greenDataDocument').value = '';
        return;
      }
    });
  };

  let utilityCompanyNameUrl = '';
  if (links[utilityCompanyName]) {
    const encodedUrl = encodeURIComponent(links[utilityCompanyName]);
    utilityCompanyNameUrl = `enphase://user?url=` + encodedUrl;
  }

  return (
    <React.Fragment>
      {storageCapacity > 24 &&
      utilityCompanyName !== SOUTHERN_CALIFORNIA_GAS_COMPANY &&
      optedBudgetCategory !== SMALL_RESIDENTIAL ? (
        <React.Fragment>
          <div className={clsx(classes.doucmentItem, classes.dBlock)}>
            <Title className={classes.title}>Green Button Data </Title>
            {utilityCompanyName !== SOUTHERN_CALIFORNIA_GAS_COMPANY ? (
              <Typography className={classes.text}>
                Upload your Green Button Data for the last 12 months (required as the output power of your system
                exceeds 10 kW). You can find steps to generate the Green Button Data{` `}
                {isIOS ? (
                  <a style={{ color: '#01B4DE', textDecoration: 'none' }} href={utilityCompanyNameUrl} target="_self">
                    <Typography variant="body2">{`here`}</Typography>
                  </a>
                ) : (
                  <Link
                    style={{ color: '#01b4de', cursor: 'pointer' }}
                    variant="body2"
                    target="_blank"
                    rel="noreferrer"
                    underline="none"
                    onClick={() => window.open(links[utilityCompanyName], '_blank')}
                  >
                    here
                  </Link>
                )}.
              </Typography>
            ) : (
              <Typography className={classes.text}>
                SGIP administrators would also need your Green Button Data for the last 12 months. This data can be
                obtained from your electricity provider.
              </Typography>
            )}
            {greenDataDoc.map((gDoc, k) => (
              <FileAttachementBar
                classes={classes}
                key={gDoc.display_file_name}
                id={gDoc.display_file_name}
                fileType="greenData"
                display_file_name={gDoc.display_file_name}
                fileName={gDoc.file_name}
                fileSize={gDoc.file_size}
                onClose={deleteUploadedFile}
              />
            ))}
            {greenDataDocumentErrorFileNameMessage !== '' ? (
              <div>
                <Typography className={classes.errorText}>{greenDataDocumentErrorFileNameMessage}</Typography>
              </div>
            ) : (
              <div />
            )}
            <Button
              className={clsx(classes.chooseFileButton, classes.button, classes.uploadButton)}
              variant="outlined"
              color="secondary"
              disableElevation
              disabled={greenDataDoc.length >= 3}
              component="label"
            >
              Choose File
              <input
                type="file"
                style={{ display: 'none' }}
                id="greenDataDocument"
                name="greenDataDocument"
                onChange={e => handleGreenButtonFileUpload(e)}
                multiple
              />
            </Button>
            <Divider className={clsx(classes.dHide, classes.divider)} />
          </div>
        </React.Fragment>
      ) : (
        ''
      )}
    </React.Fragment>
  );
};

export default withStyles(styles)(greenDataFileUpload);
