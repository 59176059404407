import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { compact } from '../../../utils/lodash';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { getSGIPInstallers } from '../../../containers/SgipApp/actions';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  noOptions: {
    color: 'red',
  },
  listbox: {
    overflowX: 'hidden',
  },
}));

const InstallersDropdown = props => {
  const classes = useStyles();
  const { isInstaller, onSelectInstaller, installers, installerNameText, primaryInstallers = [] } = props;
  let primaryInstallerIds = primaryInstallers.map(pInst => pInst.company_id);
  let options = compact(installers.map(installer => {
    if (!primaryInstallerIds.includes(installer.company_id)) {
      return installer.name;
    } return null;
  }));

  return (
    <Autocomplete
      id="installers_dropdown"
      onInputChange={(event, value, reason) => {
        onSelectInstaller(value, reason);
      }}
      freeSolo
      options={options}
      inputValue={installerNameText}
      renderInput={params => <TextField {...params} placeholder={isInstaller ? '' : "Enter installer's initials"} />}
      disabled={isInstaller}

      classes={{ noOptions: classes.noOptions, listbox: classes.listbox }}
    />
  );
};

const mapDispatchToProps = dispatch => ({
  getSGIPInstallers: payload => dispatch(getSGIPInstallers(payload)),
});

const mapStateToProps = state => ({
  installers: state.sgipAppReducer.sgip_installers,
});

export default connect(mapStateToProps, mapDispatchToProps)(InstallersDropdown);
