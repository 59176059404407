import { take, put, call, select } from 'redux-saga/effects';
import { get, post, patchMethod, deleteMethod } from '../../utils/api';
import settings from '../../utils/settings';
import { isArray } from '../../utils/lodash';
import {
  GET_SGIP_REQUESTS_REQUEST,
  GET_SGIP_SUCCESS,
  GET_REQUEST_FAILURE,
  GET_SGIP_STATISTICS_REQUEST,
  GET_SGIP_STATISTICS_SUCCESS,
  GET_MSG_HISTORY_REQUEST,
  GET_MSG_HISTORY_SUCCESS,
  GET_SGIP_REQUEST_DETAILS_SUCCESS,
  GET_SGIP_REQUEST_DETAILS_REQUEST,
  UPDATE_APP_ID_REQUEST,
  UPDATE_APP_ID_SUCCESS,
  UPDATE_STAGE_STATUS_REQUEST,
  UPDATE_STAGE_STATUS_SUCCESS,
  SEND_MESSAGE_REQUEST,
  SEND_MESSAGE_SUCCESS,
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  GET_SGIP_REQUESTS_FAILURE,
  GET_SGIP_STATISTICS_FAILURE,
  GET_NOTIFICATIONS_FAILURE,
  GET_PRESIGNED_URL,
  FILE_UPLOAD_TO_S3_REQUEST,
  GET_PDF_FILE_FROM_S3_REQUEST,
  SEND_TO_HO_FOR_APPROVAL_REQUEST,
  SEND_TO_HO_FOR_APPROVAL_SUCCESS,
  SITE_SEARCH_REQUEST,
  SITE_SEARCH_REQUEST_SUCCESS,
  SITE_SEARCH_REQUEST_FAILURE,
  GET_ACCOUNT_DETAILS_REQUEST,
  GET_ACCOUNT_DETAILS_SUCCESS,
  GET_ACCOUNT_DETAILS_FAILURE,
  UPDATE_CHAT_STATUS_REQUEST,
  UPDATE_CHAT_STATUS_SUCCESS,
  UPDATE_CHAT_STATUS_FAILURE,
  GET_APP_NOTES_REQUEST,
  GET_APP_NOTES_FAILURE,
  CREATE_APP_NOTE_REQUEST,
  GET_APP_NOTES_SUCCESS,
  CREATE_APP_NOTE_SUCCESS,
  UPDATE_APP_NOTE_REQUEST,
  UPDATE_APP_NOTE_SUCCESS,
  UPDATE_APP_NOTE_FAILURE,
  DELETE_APP_NOTE_REQUEST,
  DELETE_APP_NOTE_SUCCESS,
  DELETE_APP_NOTE_FAILURE,
  UPDATE_LAST_MESSAGE_SEEN_REQUEST,
  GET_APPLICATIONS_SITE_LIST_REQUEST,
  GET_APPLICATIONS_SITE_LIST_SUCCESS,
  GET_APPLICATIONS_SITE_LIST_FAILURE,
  GET_COMPANY_INFO_REQUEST,
  GET_COMPANY_INFO_SUCCESS,
  GET_DIN_INSTALLERS_LIST_REQUEST,
  GET_DIN_INSTALLERS_LIST_SUCCESS,
  UPDATE_DIN_INSTALLERS_LIST_REQUEST,
  UPDATE_DIN_INSTALLERS_LIST_SUCCESS,
  DELETE_DIN_INSTALLERS_LIST_REQUEST,
  DELETE_DIN_INSTALLERS_LIST_SUCCESS,
  GET_USER_TYPE_REQUEST,
  GET_USER_TYPE_SUCCESS,
  GET_COMPANY_INFO_FAILURE,
  UPLOAD_SGIP_DOCS_REQUEST,
  UPLOAD_SGIP_DOCS_SUCCESS,
  DOWNLOAD_ALL_APP_DOCS_REQUEST,
  BLOCK_UNBLOCK_SGIP_CONFIG_PARAMS_REQUEST,
  BLOCK_UNBLOCK_SGIP_CONFIG_PARAMS_SUCCESS,
  BLOCK_UNBLOCK_SGIP_CONFIG_PARAMS_FAILURE,
  UPDATE_INCENTIVE_RATE_SGIP_CONFIG_PARAMS_REQUEST,
  UPDATE_INCENTIVE_RATE_SGIP_CONFIG_PARAMS_SUCCESS,
  UPDATE_INCENTIVE_RATE_SGIP_CONFIG_PARAMS_FAILURE,
  GET_BATTERY_REPORT_DOWNLOAD_REQUEST,
  GET_BATTERY_REPORT_DOWNLOAD_SUCCESS,
  GET_BATTERY_REPORT_DOWNLOAD_FAILURE,
  RDS_BATTERY_DEVICE_TYPE,
  SAVE_INSTALLER_SECONDARY_EMAIL_REQUEST,
  SAVE_INSTALLER_SECONDARY_EMAIL_SUCCESS,
  SAVE_INSTALLER_SECONDARY_EMAIL_FAILURE,
  BATTERY_REPORT_TIME_INTERVAL,
} from './constants';
import { getToken } from '../../utils/helper';

export function* getSGIPRequestsFlow() {
  while (true) {
    const request = yield take(GET_SGIP_REQUESTS_REQUEST);
    const {
      pageNum,
      recordCount,
      sort_column,
      sort_order,
      installerView,
      adminType,
      application_id,
      application_number,
      system_id,
      system_name,
      homeowner_name,
      installer_name,
      application_status,
      application_submitted_by,
    } = request.payload;
    try {
      let response;
      let applicationStatus;
      let applicationSubmittedBy;
      if (application_status === 'All') {
        applicationStatus = '';
      } else {
        applicationStatus = application_status;
      }
      if (application_submitted_by === 'All') {
        applicationSubmittedBy = '';
      } else {
        applicationSubmittedBy = application_submitted_by;
      }
      const source = installerView === true ? 'installer' : 'admin';
      response = yield call(get, {
        url: settings.sgipCoreUrl(
          `sgip/application/paginated?pageNumber=${pageNum}&pageSize=${recordCount}&column=${sort_column}&order=${sort_order}&adminType=${adminType}&source=${source}&application_id=${application_id}&application_number=${application_number}&system_id=${system_id}&system_name=${system_name}&status=${applicationStatus.toString()}&homeowner_name=${homeowner_name}&installer_name=${installer_name}&application_submitted_by=${applicationSubmittedBy}`
        ),
        withBearer: true
      });

      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_SGIP_SUCCESS,
          sgip_requests: result.sgip_applications.content,
          totalElements: result.sgip_applications.totalElements,
          currentlySending: false,
          message: ''
        });
      } else {
        yield put({ type: GET_SGIP_REQUESTS_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: GET_SGIP_REQUESTS_FAILURE, message: error });
    }
  }
}

export function* getSGIPStatisticsFlow() {
  while (true) {
    const request = yield take(GET_SGIP_STATISTICS_REQUEST);
    const { adminType } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.sgipCoreUrl(`sgip/application/statistics?adminType=${adminType}`),
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_SGIP_STATISTICS_SUCCESS,
          sgip_statistics: result.in_progress_applications,
          currentlySending: false,
          message: ''
        });
      } else {
        yield put({ type: GET_SGIP_STATISTICS_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: GET_SGIP_STATISTICS_FAILURE, message: error });
    }
  }
}

export function* getSGIPRequestDetailsFlow() {
  while (true) {
    const request = yield take(GET_SGIP_REQUEST_DETAILS_REQUEST);
    const { application_id } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.sgipCoreUrl(`sgip/application/${application_id}`),
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_SGIP_REQUEST_DETAILS_SUCCESS,
          sgip_application: result.sgip_application,
          duplicate_app_number_present: result.duplicate_app_number_present || false,
          unread_msg_count: result.unread_msg_count,
          currentlySending: false,
          message: ''
        });
      } else {
        yield put({ type: GET_REQUEST_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: GET_REQUEST_FAILURE, message: error });
    }
  }
}

export function* getMsgHistoryFlow() {
  while (true) {
    const request = yield take(GET_MSG_HISTORY_REQUEST);
    const { request_id, successCb } = request.payload;

    try {
      let response;
      response = yield call(get, {
        url: settings.sgipCoreUrl(`sgip/applicant/message/history/${request_id}?sort_by=message_date&sort_order=asc`),
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_MSG_HISTORY_SUCCESS,
          msg_histories: result.message_history,
          currentlySending: false,
          unreadMessageCount: result.unread_msg_count
        });
        successCb();
      } else {
        yield put({ type: GET_REQUEST_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: GET_REQUEST_FAILURE, message: error });
    }
  }
}

export function* updateApplicationNumberFlow() {
  while (true) {
    const request = yield take(UPDATE_APP_ID_REQUEST);
    const { show_msg = true, app_id, app_number, isRRFSignedState } = request.payload;
    try {
      let response;
      response = yield call(post, {
        url: settings.sgipCoreUrl(`sgip/admin/application/number`),
        body: {
          application_id: app_id,
          application_number: app_number
        },
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        if (isRRFSignedState) {
          yield put({
            type: UPDATE_STAGE_STATUS_SUCCESS,
            messageTitle: 'Confirmation',
            message: 'Application status updated successfully.',
            showMessageDialog: true
          });
        } else {
          yield put({
            type: UPDATE_APP_ID_SUCCESS,
            messageTitle: 'Confirmation',
            message: 'Application number updated successfully.',
            showMessageDialog: show_msg
          });
        }
      } else {
        yield put({ type: GET_REQUEST_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: GET_REQUEST_FAILURE, message: error });
    }
  }
}

export function* updateStageStatusUpdateFlow() {
  while (true) {
    const request = yield take(UPDATE_STAGE_STATUS_REQUEST);
    const { app_id, stage_id, status_name, updatedDate, substage_id, isRRFSignedStage, successCbk } = request.payload;
    try {
      let response;
      response = yield call(post, {
        url: settings.sgipCoreUrl(`sgip/application/status`),
        body: {
          application_id: app_id,
          substage_id: substage_id,
          stage_id: stage_id,
          status_name: status_name,
          last_updated_date: updatedDate
        },
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        successCbk();
        if (!isRRFSignedStage) {
          yield put({
            type: UPDATE_STAGE_STATUS_SUCCESS,
            messageTitle: 'Confirmation',
            message: 'Application status updated successfully.',
            showMessageDialog: true
          });
        }
      } else {
        yield put({ type: GET_REQUEST_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: GET_REQUEST_FAILURE, message: error });
    }
  }
}

export function* sendMessageFlow() {
  while (true) {
    const request = yield take(SEND_MESSAGE_REQUEST);
    const {
      app_id,
      mObj,
      fileName,
      returnMsgHistory = false,
      successCb = () => {},
      failureCb = () => {}
    } = request.payload;
    try {
      let response;
      response = yield call(post, {
        url: settings.sgipCoreUrl(`sgip/applicant/message`),
        body: {
          application_id: app_id,
          subject: mObj.mSubject,
          body_content: mObj.mBody,
          file_names: fileName === '' ? [] : isArray(fileName) ? fileName : [fileName],
          document_requested: mObj.requested_doc,
          return_msg_history: returnMsgHistory
        },
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: SEND_MESSAGE_SUCCESS,
          messageTitle: 'Confirmation',
          message: 'Message sent to System Owner successfully.',
          showMessageDialog: false,
          messageHistory: result.message_history,
          unreadMessageCount: result.unread_msg_count
        });
        successCb();
      } else {
        yield put({ type: GET_REQUEST_FAILURE, message: result.message || result.messages });
        failureCb();
      }
    } catch (error) {
      yield put({ type: GET_REQUEST_FAILURE, message: error });
      failureCb();
    }
  }
}

export function* getNotificationsFlow() {
  while (true) {
    const request = yield take(GET_NOTIFICATIONS_REQUEST);
    const { request_id } = request.payload;

    try {
      let response;
      response = yield call(get, {
        url: settings.sgipCoreUrl(`sgip/applicant/notification/${request_id}`),
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_NOTIFICATIONS_SUCCESS,
          notifications: result.applicant_notifications,
          currentlySending: false,
          message: ''
        });
      } else {
        yield put({ type: GET_NOTIFICATIONS_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: GET_NOTIFICATIONS_FAILURE, message: error });
    }
  }
}
export function* getPresignedURLFlow() {
  while (true) {
    const request = yield take(GET_PRESIGNED_URL);
    const { file_name, http_method, fileType, successCb, failureCb } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.sgipCoreUrl(
          `sgip/presignedurl?file_name=${file_name}&http_method=${http_method}&downloadFormat=${fileType}`
        ),
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        successCb(result.url);
      } else {
        failureCb();
        yield put({ type: GET_REQUEST_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      failureCb();
      yield put({ type: GET_REQUEST_FAILURE, message: error });
    }
  }
}
export function* uploadToS3Flow() {
  while (true) {
    const request = yield take(FILE_UPLOAD_TO_S3_REQUEST);
    const { preSignedS3Url, fName, fileObj, successCbS3, failureCbS3, isDownload = false } = request.payload;
    let contentDisposition = 'attachment; filename=' + fName;
    try {
      var formData = new FormData();
      formData.append('data', fileObj, fName);
      var requestOptions = {
        method: 'PUT',
        body: fileObj,
        headers: {
          'Content-Type': isDownload ? 'application/octet-stream' : fileObj.type,
          'Content-Disposition': contentDisposition
        }
      };
      fetch(preSignedS3Url, requestOptions).then(function (data) {
        const { status } = data;
        if (status === 200) {
          successCbS3();
        } else {
          failureCbS3();
        }
      });
    } catch (error) {
      yield put({ type: GET_REQUEST_FAILURE, message: error });
      failureCbS3();
    }
  }
}

export function* fileDownloadFromS3Flow() {
  while (true) {
    const request = yield take(GET_PDF_FILE_FROM_S3_REQUEST);
    const { preSignedS3Url, successCbS3, failureCbS3 } = request.payload;

    try {
      var requestOptions = {
        method: 'GET'
      };
      fetch(preSignedS3Url, requestOptions).then(function (data) {
        const { status } = data;
        if (status === 200) {
          successCbS3(data);
        } else {
          failureCbS3();
        }
      });
    } catch (error) {
      yield put({ type: GET_REQUEST_FAILURE, message: error });
      failureCbS3();
    }
  }
}
export function* sendToHOForApprovalFlow() {
  while (true) {
    const request = yield take(SEND_TO_HO_FOR_APPROVAL_REQUEST);
    const { fData } = request.payload;

    try {
      let response;
      response = yield call(post, {
        url: settings.sgipCoreUrl(`sgip/applicant/fieldchanges`),
        body: fData,
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: SEND_TO_HO_FOR_APPROVAL_SUCCESS,
          messageTitle: 'Confirmation',
          message:
            'Changes have been sent to the System Owner for approval. The application details would be updated here, once the changes have been approved.',
          showMessageDialog: true,
        });
      } else {
        yield put({
          type: GET_REQUEST_FAILURE,
          message: result.message || result.messages,
        });
      }
    } catch (error) {
      yield put({ type: GET_REQUEST_FAILURE, message: error });
    }
  }
}

export function* getSiteSearchFlow() {
  while (true) {
    const request = yield take(SITE_SEARCH_REQUEST);
    try {
      let response;
      const { searchValue, searchType } = request.payload;
      response = yield call(get, {
        url: settings.enlightenUrl(
          `api_internal/ms/sites/search?search_value=${searchValue}&search_type=${searchType}`
        ),
      });
      if (response.success) {
        yield put({
          type: SITE_SEARCH_REQUEST_SUCCESS,
          searchList: response.result,
        });
      } else {
        yield put({
          type: SITE_SEARCH_REQUEST_FAILURE,
          error: response.result.message,
        });
      }
    } catch (error) {
      yield put({ type: SITE_SEARCH_REQUEST_FAILURE, error: error });
    }
  }
}

export function* getAccountDetailsFlow() {
  while (true) {
    const request = yield take(GET_ACCOUNT_DETAILS_REQUEST);
    try {
      let response;
      const { successCbk = () => {} } = request.payload;
      response = yield call(get, {
        url: settings.enlightenUrl(`api_internal/ms/account`), 
      });
      const { success, result, isUnauthorized } = response;
      if (success) {
        yield put({
          type: GET_ACCOUNT_DETAILS_SUCCESS,
          accountDetails: response.result,
        });
        successCbk(response.result);
      } else {
        yield put({
          type: GET_ACCOUNT_DETAILS_FAILURE,
          error: result.message || result.messages,
          isUnauthorized,
        });
      }
    } catch (error) {
      yield put({ type: GET_ACCOUNT_DETAILS_FAILURE, error });
    }
  }
}

export function* getCompanyInfoFlow() {
  while (true) {
    const request = yield take(GET_COMPANY_INFO_REQUEST);
    try {
      let response;
      const { successCb, failureCb } = request.payload;
      response = yield call(get, {
        url: settings.sgipCoreUrl(`sgip/installer`),
        withBearer: true,
      });
      const { success } = response;
      if (success) {
        yield put({
          type: GET_COMPANY_INFO_SUCCESS,
          companyInfo: response.result,
        });
        successCb(response.result);
      } else {
        yield put({ type: GET_COMPANY_INFO_FAILURE });
        failureCb(response.result);
      }
    } catch (error) {
      yield put({ type: GET_COMPANY_INFO_FAILURE, message: error });
    }
  }
}

export function* chatStatusUpdateFlow() {
  while (true) {
    const request = yield take(UPDATE_CHAT_STATUS_REQUEST);
    const { applicationId, lastMessageId } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.sgipCoreUrl(
          `sgip/msgViewedUpdate/${applicationId}/${lastMessageId}`
        ),
        withBearer: true,
      });
      const { success, result } = response;
      if (success) {
        yield put({ type: UPDATE_CHAT_STATUS_SUCCESS });
      } else {
        yield put({
          type: UPDATE_CHAT_STATUS_FAILURE,
          error: result.message || result.messages,
        });
      }
    } catch (error) {
      yield put({ type: UPDATE_CHAT_STATUS_FAILURE, error });
    }
  }
}

export function* getAppNotesFlow() {
  while (true) {
    const request = yield take(GET_APP_NOTES_REQUEST);
    const { applicationId } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.sgipCoreUrl(`application/notes/${applicationId}`),
        withBearer: true,
      });
      const { success, result } = response;
      if (success) {
        yield put({
          type: GET_APP_NOTES_SUCCESS,
          app_notes: result.application_notes,
        });
      } else {
        yield put({
          type: GET_APP_NOTES_FAILURE,
          error: result.message || result.messages,
        });
      }
    } catch (error) {
      yield put({ type: GET_APP_NOTES_FAILURE, error });
    }
  }
}

export function* createAppNoteFlow() {
  while (true) {
    const request = yield take(CREATE_APP_NOTE_REQUEST);
    const { applicationId, bContent, successCb, failureCb } = request.payload;
    try {
      let response;
      response = yield call(post, {
        url: settings.sgipCoreUrl(`application/note`),
        body: {
          application_id: applicationId,
          body_content: bContent,
        },
        withBearer: true,
      });
      const { success, result } = response;
      if (success) {
        yield put({ type: CREATE_APP_NOTE_SUCCESS });
        successCb();
      } else {
        yield put({
          type: GET_APP_NOTES_FAILURE,
          error: result.message || result.messages,
        });
        failureCb();
      }
    } catch (error) {
      yield put({ type: GET_APP_NOTES_FAILURE, error });
      failureCb();
    }
  }
}

export function* updateAppNoteFlow() {
  while (true) {
    const request = yield take(UPDATE_APP_NOTE_REQUEST);
    const { application_id, noteId, bContent, successCb, failureCb } =
      request.payload;
    try {
      let response;
      response = yield call(patchMethod, {
        url: settings.sgipCoreUrl(`application/notes/${noteId}`),
        body: {
          application_id: application_id,
          body_content: bContent,
          id: noteId,
        },
        withBearer: true,
      });
      const { success, result } = response;
      if (success) {
        yield put({ type: UPDATE_APP_NOTE_SUCCESS });
        successCb();
      } else {
        yield put({
          type: UPDATE_APP_NOTE_FAILURE,
          error: result.message || result.messages,
        });
        failureCb();
      }
    } catch (error) {
      yield put({ type: UPDATE_APP_NOTE_FAILURE, error });
      failureCb();
    }
  }
}

export function* deleteAppNoteFlow() {
  while (true) {
    const request = yield take(DELETE_APP_NOTE_REQUEST);
    const { noteId, successCb, failureCb } = request.payload;
    try {
      let response;
      response = yield call(deleteMethod, {
        url: settings.sgipCoreUrl(`application/notes/${noteId}`),
        withBearer: true,
      });
      const { success, result } = response;
      if (success) {
        yield put({ type: DELETE_APP_NOTE_SUCCESS });
        successCb();
      } else {
        yield put({
          type: DELETE_APP_NOTE_FAILURE,
          error: result.message || result.messages,
        });
        failureCb();
      }
    } catch (error) {
      yield put({ type: DELETE_APP_NOTE_FAILURE, error });
      failureCb();
    }
  }
}

export function* updateLastSeenMessageFlow() {
  while (true) {
    const request = yield take(UPDATE_LAST_MESSAGE_SEEN_REQUEST);
    try {
      let response;
      const { request_id, last_msg_id } = request.payload;
      response = yield call(get, {
        url: settings.sgipCoreUrl(
          `sgip/msgViewedUpdate/${request_id}/${last_msg_id}`
        ),
        withBearer: true,
      });
      if (response.success) {
        // console.log('Updated Success');
      } else {
        // console.log('Udate Failed');
      }
    } catch (error) {
      yield put({ type: GET_REQUEST_FAILURE, error: error });
    }
  }
}

export function* getSgipSiteListFlow() {
  while (true) {
    yield take(GET_APPLICATIONS_SITE_LIST_REQUEST);
    try {
      let response;
      response = yield call(get, {
        url: settings.sgipCoreUrl(`sgip_sites`),
        withBearer: true,
      });
      const { success, result } = response;
      if (success) {
        yield put({
          type: GET_APPLICATIONS_SITE_LIST_SUCCESS,
          applicationSiteIds: result.site_ids,
        });
      } else {
        yield put({
          type: GET_APPLICATIONS_SITE_LIST_FAILURE,
          error: result.message || result.messages,
        });
      }
    } catch (error) {
      yield put({ type: GET_APPLICATIONS_SITE_LIST_FAILURE, error });
    }
  }
}

export function* getSGIPUserTypeFlow() {
  while (true) {
    const request = yield take(GET_USER_TYPE_REQUEST);
    const { successCb, failureCb } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.sgipCoreUrl(`sgip/usertype`),
        withBearer: true,
      });
      const { success, result } = response;
      if (success) {
        yield put({ type: GET_USER_TYPE_SUCCESS });
        successCb(result);
      } else {
        yield put({ type: GET_REQUEST_FAILURE });
        failureCb();
      }
    } catch (error) {
      yield put({ type: GET_REQUEST_FAILURE });
      failureCb();
    }
  }
}

export function* getSGIPDinInstallersListFlow() {
  while (true) {
    const request = yield take(GET_DIN_INSTALLERS_LIST_REQUEST);

    try {
      let response;
      const { pageNumber, pageSize, successCb } = request.payload;
      response = yield call(get, {
        url: settings.sgipCoreUrl(
          `sgip/dininstallers?pageNumber=${pageNumber}&pageSize=${pageSize}`
        ),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_DIN_INSTALLERS_LIST_SUCCESS,
          dinInstallers: result.installers,
          currentlySending: false,
          message: '',
        });
        successCb();
      } else {
        yield put({
          type: GET_REQUEST_FAILURE,
          message: result.message || result.messages,
        });
      }
    } catch (error) {
      yield put({ type: GET_REQUEST_FAILURE, message: error });
    }
  }
}

export function* updateSGIPDinInstallerListFlow() {
  while (true) {
    const request = yield take(UPDATE_DIN_INSTALLERS_LIST_REQUEST);

    try {
      let response;
      const { installerDetails, successCb } = request.payload;
      response = yield call(post, {
        url: settings.sgipCoreUrl(`sgip/dininstaller`),
        body: installerDetails,
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: UPDATE_DIN_INSTALLERS_LIST_SUCCESS,
          currentlySending: false,
          messageTitle: 'Confirmation',
          message: 'Installer added successfully.',
          showMessageDialog: true,
        });
        successCb();
      } else {
        yield put({
          type: GET_REQUEST_FAILURE,
          message: result.message || result.messages,
        });
      }
    } catch (error) {
      yield put({ type: GET_REQUEST_FAILURE, message: error });
    }
  }
}

export function* deleteSGIPDinInstallerListFlow() {
  while (true) {
    const request = yield take(DELETE_DIN_INSTALLERS_LIST_REQUEST);

    try {
      let response;
      const { installer_id, successCb } = request.payload;
      response = yield call(deleteMethod, {
        url: settings.sgipCoreUrl(`sgip/dininstaller/${installer_id}`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: DELETE_DIN_INSTALLERS_LIST_SUCCESS,
          currentlySending: false,
          messageTitle: 'Confirmation',
          message: 'Installer deleted successfully.',
          showMessageDialog: true,
        });
        successCb();
      } else {
        yield put({
          type: GET_REQUEST_FAILURE,
          message: result.message || result.messages,
        });
      }
    } catch (error) {
      yield put({ type: GET_REQUEST_FAILURE, message: error });
    }
  }
}

export function* uploadSgipDocumentFlow() {
  while (true) {
    const request = yield take(UPLOAD_SGIP_DOCS_REQUEST);
    const { site_id, docs, failureCb = () => {} } = request.payload;
    try {
      let response;
      response = yield call(post, {
        url: settings.sgipCoreUrl(
          `sgip/application/${site_id}/upload_documents`
        ),
        body: [...docs],
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: UPLOAD_SGIP_DOCS_SUCCESS,
          messageTitle: 'Confirmation',
          message: 'Document uploaded successfully.',
          showMessageDialog: true,
        });
      } else {
        failureCb();
        yield put({
          type: GET_REQUEST_FAILURE,
          message: result.message || result.messages,
        });
      }
    } catch (error) {
      yield put({ type: GET_REQUEST_FAILURE, message: error });
    }
  }
}

const download = async (id, successCbk, failureCbk) => {
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const res = await fetch(
      settings.sgipCoreUrl(`${id}/allDocs`),
      requestOptions
    );
    if (res.status === 200) {
      const blob = await res.blob();
      const newBlob = new Blob([blob]);
      const newUrl = window.URL.createObjectURL(newBlob);

      const link = document.createElement('a');
      link.href = newUrl;
      let fileName = '';
      fileName = `${id}_documents.zip`;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      successCbk();
    } else {
      console.error(
        'Saga: downloadAllAppDocsFlow Api response status:',
        res.status
      );
      failureCbk();
    }
  } catch (er) {
    console.error('Saga: downloadAllAppDocsFlow: ', er);
    failureCbk();
  }
};

export function* downloadAllFlow() {
  while (true) {
    const request = yield take(DOWNLOAD_ALL_APP_DOCS_REQUEST);
    const { id, successCbk, failureCbk } = request.payload;
    download(id, successCbk, failureCbk);
  }
}

export function* blockUnblockSGIPConfigParamsFlow() {
  while (true) {
    const request = yield take(BLOCK_UNBLOCK_SGIP_CONFIG_PARAMS_REQUEST);
    const { body, successCb = () => {} } = request.payload;
    try {
      let response;
      response = yield call(post, {
        url: settings.sgipCoreUrl(`sgip/quesconfig`),
        body,
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: BLOCK_UNBLOCK_SGIP_CONFIG_PARAMS_SUCCESS,
        });
        successCb();
      } else {
        yield put({
          type: BLOCK_UNBLOCK_SGIP_CONFIG_PARAMS_FAILURE,
          message: result.message || result.messages,
        });
      }
    } catch (error) {
      yield put({
        type: BLOCK_UNBLOCK_SGIP_CONFIG_PARAMS_FAILURE,
        message: error,
      });
    }
  }
}

export function* updateIncentiveRateSGIPConfigParamsFlow() {
  while (true) {
    const request = yield take(
      UPDATE_INCENTIVE_RATE_SGIP_CONFIG_PARAMS_REQUEST
    );
    const { body, successCb = () => {} } = request.payload;
    try {
      let response;
      response = yield call(post, {
        url: settings.sgipCoreUrl(`sgip/incentiverates`),
        body,
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: UPDATE_INCENTIVE_RATE_SGIP_CONFIG_PARAMS_SUCCESS,
        });
        successCb();
      } else {
        yield put({
          type: UPDATE_INCENTIVE_RATE_SGIP_CONFIG_PARAMS_FAILURE,
          message: result.message || result.messages,
        });
      }
    } catch (error) {
      yield put({
        type: UPDATE_INCENTIVE_RATE_SGIP_CONFIG_PARAMS_FAILURE,
        message: error,
      });
    }
  }
}

const getTokenFromReduxStore = state => state.appReducer.enlightenManagerToken;

export function* downloadBatteryReport() {
  while (true) {
    const request = yield take(GET_BATTERY_REPORT_DOWNLOAD_REQUEST);
        const {
      siteId,
      startDate,
      endDate,
      successCb = () => {},
      failureCb = () => {},
    } = request.payload;
    try {   
      const reduxToken = yield select(getTokenFromReduxStore);
      let jwtToken = getToken();
      if (!jwtToken || jwtToken === '' || jwtToken === -1) {
        jwtToken = reduxToken;
      }
      let requestDownloadOptions = {
        method: 'POST',
        headers: {
          Authorization: jwtToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          report: {
            type: RDS_BATTERY_DEVICE_TYPE,
            battery_startdate: startDate,
            battery_enddate: endDate,
            time_interval: BATTERY_REPORT_TIME_INTERVAL,
          },
        }),
      };
      let url = settings.enlightenUrl(
        `api_internal/ms/sites/${siteId}/generate_report`
      );
      fetch(url, requestDownloadOptions).then(async function (res) {
        await res.text().then(function (data) {
          const newBlob = new Blob([data]);
          const blobUrl = window.URL.createObjectURL(newBlob);
          const link = document.createElement('a');
          link.href = blobUrl;
          link.setAttribute('download', `Battery_report_${siteId}.${'csv'}`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          window.URL.revokeObjectURL(newBlob);
          successCb();
        });
      })
      yield put({
        type: GET_BATTERY_REPORT_DOWNLOAD_SUCCESS,
        message: 'Downloaded battery report for past 7 days',
      });
    } catch (error) {
      yield put({
        type: GET_BATTERY_REPORT_DOWNLOAD_FAILURE,
        message: error,
      });
      failureCb();
    }
  }
}

export function* saveInstallerSecondaryEmailFlow() {
  while (true) {
    const request = yield take(SAVE_INSTALLER_SECONDARY_EMAIL_REQUEST);
    const { email = '', applicationId = '' } = request.payload;
    try {
      let response;
      response = yield call(post, {
        url: settings.sgipCoreUrl(
          `secondaryemail?email=${email}&application_id=${applicationId}`
        ),
        withBearer: true,
      });
      const { success, result } = response;
      if (success) {
        yield put({
          type: SAVE_INSTALLER_SECONDARY_EMAIL_SUCCESS,
          sgip_application: result,
        });
      } else {
        yield put({
          type: SAVE_INSTALLER_SECONDARY_EMAIL_FAILURE,
          message: result.message || result.messages,
        });
      }
    } catch (error) {
      yield put({
        type: SAVE_INSTALLER_SECONDARY_EMAIL_FAILURE,
        message: error,
      });
    }
  }
}
