import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Button, TableBody, TableRow, TableCell, Table, TableContainer, Divider } from '@material-ui/core';

const styles = theme => ({
  dialogTitle: {
    padding: 0,
    textAlign: 'right'
  },
  okBtn: {
    color: '#01B4DE',
    fontWeight: 'bold'
  },
  root: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2.5)
    }
  },
  button: {
    color: theme.palette.secondary.main,
    textTransform: 'none'
  },
  title: {
    padding: '20px'
  },
  heading: {
    color: '#545456'
  },
  actions: {
    padding: '0px'
  },
  btn: {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    textTransform: 'none'
  },
  tdnoborder: {
    borderBottom: 0,
    color: theme.palette.primary.mainText,
    width: '50%',
    padding: theme.spacing(1.25, 0)
  },
  tdborder: {
    borderBottom: 'none',
    borderRight: '1px solid #d8dcde',
    textTransform: 'none',
    width: '50%',
    padding: theme.spacing(1.25, 0)
  },
  cancelbutton: {
    textTransform: 'none'
  }
});

const okNotifyMe = props => {
  const scroll = 'paper';
  const { classes, openNotifyMeDialog, handleOkNotifyMe, handleDialogClose, sgipBudget, isInstaller = true } = props;
  return (
    <div>
      <Dialog
        open={openNotifyMeDialog}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        style={{ borderRadius: '10px' }}
        classes={{ root: 'rounded' }}
      >
        <DialogTitle className={classes.title} id="customized-dialog-title" onClose={handleOkNotifyMe}>
          <Typography variant="body1" className={classes.heading}>
            {`Please click "Notify Me" if you would like us to get back (via email) when the ${sgipBudget} Category for your utility is open for receiving new application.`}
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogActions>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell align="center" className={classes.tdborder}>
                    <Button onClick={handleDialogClose} className={classes.cancelbutton}>
                      Cancel
                    </Button>
                  </TableCell>
                  <TableCell className={classes.tdnoborder} align="center">
                    <Button onClick={e => handleOkNotifyMe(sgipBudget)} className={classes.btn}>
                      Notify Me
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(okNotifyMe);
