import React, { Fragment } from 'react';
import { FormControlLabel, RadioGroup, Radio, Grid, Typography, FormHelperText } from '@material-ui/core';
import { useMobileStyles, useDesktopStyles } from '../style';
import { BoxLayout } from '../../../Common';
import { Title } from '../../../Texts';
import Alert from '@material-ui/lab/Alert';

const SiteOwnership = props => {
  const { isMobileView = true, handleSiteOwnerShip, siteLeased, personalDetailsError, isInstaller } = props;
  const mobileClasses = useMobileStyles();
  const desktopClasses = useDesktopStyles();
  const classes = isMobileView ? mobileClasses : desktopClasses;
  return (
    <Fragment>
      {siteLeased === 'leased' ? (
        <Grid>
          <div className={classes.root}>
            <Alert variant="filled" severity="error">
              The SGIP Incentive can only be claimed by the owner of the site. Please contact the site owner to submit
              SGIP Incentive request.
            </Alert>
          </div>
        </Grid>
      ) : (
        ''
      )}

      <Grid>
        <BoxLayout className={classes.dCustomBorder}>
          <Title className={classes.header}>Site Ownership</Title>
          <RadioGroup
            className={classes.text}
            aria-label="siteOwnership"
            name="siteOwnership"
            error={personalDetailsError.site_ownership.error.msg}
            value={siteLeased}
            onChange={handleSiteOwnerShip}
          >
            {!isInstaller ? (
              <FormControlLabel
                value="owner"
                control={<Radio />}
                label={
                  <Typography variant="body1" className={classes.siteText}>
                    I am the owner of the system
                  </Typography>
                }
              />
            ) : (
              <FormControlLabel
                value="owner"
                control={<Radio />}
                label={
                  <Typography variant="body1" className={classes.siteText}>
                    Home Owner is the owner of the system​
                  </Typography>
                }
              />
            )}
            {!isInstaller ? (
              <FormControlLabel
                value="leased"
                control={<Radio />}
                label={
                  <Typography variant="body1" className={classes.siteText}>
                    I have a leased system/ have a Power Purchase agreement
                  </Typography>
                }
              />
            ) : (
              <FormControlLabel
                value="leased"
                control={<Radio />}
                label={
                  <Typography variant="body1" className={classes.siteText}>
                    Home Owner has a leased system/ has a Power Purchase agreement
                  </Typography>
                }
              />
            )}
            {personalDetailsError.site_ownership.error && (
              <FormHelperText className={classes.error}>{personalDetailsError.site_ownership.msg}</FormHelperText>
            )}
          </RadioGroup>
        </BoxLayout>
      </Grid>
    </Fragment>
  );
};

export default SiteOwnership;
