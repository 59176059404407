import React, { Fragment, useEffect } from 'react';
import { useMobileStyles, useDesktopStyles } from './style';
import { Grid, Typography, Box, Avatar } from '@material-ui/core';
import { BoxLayout } from '../../../components/Common';
import { FilledButton } from '../../../components/Buttons';
import CautionImage from '../../../components/Payment/caution-red.png';
import { forwardTo, trackEvents } from '../../../utils/helper';
import { Title } from '../../Texts';

const PaymentFailure = props => {
  const { isMobileView = true } = props;
  const mobileClasses = useMobileStyles();
  const desktopClasses = useDesktopStyles();
  const classes = isMobileView ? mobileClasses : desktopClasses;
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <Fragment>
        <Grid className={classes.top}>
          <BoxLayout noPadding>
            <Grid
              container
              direction="column"
              alignItems="center"
              justify="center"
              style={{ minHeight: '100vh', textAlign: 'center', padding: '20px' }}
            >
              <div>
                <Typography component="span" style={{ justifyContent: 'center', display: 'flex' }}>
                  <Avatar
                    variant="square"
                    alt="caution image"
                    src={CautionImage}
                    className={classes.large}
                    style={{ alignSelf: 'center' }}
                  />{' '}
                </Typography>

                <Title className={classes.value} id="uploadDoc">
                  Payment failed
                </Title>
                <Typography className={classes.label} id="status_label">
                  We couldn't submit your application for the SGIP claim. Please try again.
                </Typography>
                <Box className={classes.nextbtn}>
                  <FilledButton
                    style={{ height: '40px' }}
                    onClick={() => {
                      trackEvents('triggerSgipPageView', { pageTitle: 'Pay and Submit' });
                      forwardTo('serviceCharge');
                    }}
                  >
                    Try Again Now
                  </FilledButton>
                </Box>
              </div>
            </Grid>
          </BoxLayout>
        </Grid>
      </Fragment>
    </div>
  );
};

export default PaymentFailure;
