import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import StatusTracking from '../../../components/SgipApp/StatusTracking';
import PersonalDetailsForm from '../../../components/SgipApp/PersonalDetails/PersonalDetailsForm';
import SiteOwnership from '../../../components/SgipApp/PersonalDetails/SiteOwnership';
import { postSgipPersonalDetails, getSgipApplicationDetailsBySiteId } from '../actions';
import { forwardTo, applicationState, addInputEventListener, trackEvents } from '../../../utils/helper';
import Loader from '../../../components/Loader';
import { get, isEmpty } from '../../../utils/lodash';
import { VALIDATE_EMAIL_REGEX } from '../../../utils/constants';

const styles = theme => ({
  root: {
    height: '100vh',
    margin: theme.spacing(2.5)
  },
  [theme.breakpoints.up('md')]: {
    dWrapper: {
      margin: '30px 194px',
      boxShadow: '0 2px 10px 0 rgba(63, 67, 71, 0.08)',
      background: '#fff',
      borderRadius: '3px'
    },
    root: {
      height: 'auto'
    }
  }
});

class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeState: 1,
      isConfirmationChecked: false,
      personalDetails: {
        email: '',
        site_ownership: '',
        first_name: '',
        last_name: '',
        phone: '',
        city: '',
        battery_installation_site_address: '',
        communication_address: '',
        same_address: false,
        site_id: '',
        submitted_by_installer_id: ''
      },

      personalDetailsError: {
        site_ownership: { error: false, msg: '' },
        first_name: { error: false, msg: '' },
        last_name: { error: false, msg: '' },
        phone: { error: false, msg: '' },
        city: { error: false, msg: '' },
        battery_installation_site_address: { error: false, msg: '' },
        communication_address: { error: false, msg: '' },
        confirmation_details: { error: false, msg: '' },
        email: { error: false, msg: '' }
      }
    };
    this.handleSiteOwnerShip.bind(this);
    this.handleSameAddressCheckBox.bind(this);
    this.handlePersonalDetailsFormChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validatePersonalDetails = this.validatePersonalDetails.bind(this);
    this.validateValues = this.validateValues.bind(this);
    this.handleConfirmationCheckBox.bind(this);
    this.isEditableEmail = true;
    this.isEditablePhone = true;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    addInputEventListener();
    const { siteId } = this.props;
    this.loadApplication(siteId);
  }

  loadApplication(siteId) {
    this.props.getSgipApplicationDetailsBySiteId({
      siteId: siteId,
      isInstaller: this.props.isInstaller,
      successCb: res => {
        const { sgip_application } = this.props;
        const personalDetailsData = this.state.personalDetails;
        let isConfirmationChecked = false;
        personalDetailsData.site_ownership = sgip_application.site_ownership ? sgip_application.site_ownership : '';
        if (sgip_application.stepCompleted < 2) {
          personalDetailsData.email = get(sgip_application.site_info_from_enl, 'email', '');
          if (personalDetailsData.email !== '') {
            this.isEditableEmail = false;
          }
          personalDetailsData.first_name = get(sgip_application.site_info_from_enl, 'first_name', '');
          personalDetailsData.last_name = get(sgip_application.site_info_from_enl, 'last_name', '');
          personalDetailsData.phone = get(sgip_application.site_info_from_enl, 'phone', '');
          personalDetailsData.city = get(sgip_application.site_info_from_enl, 'city', '');
          personalDetailsData.battery_installation_site_address =
            (get(sgip_application.site_info_from_enl, 'address1', '') === null
              ? ''
              : get(sgip_application.site_info_from_enl, 'address1', '')) +
            (get(sgip_application.site_info_from_enl, 'address2', '') === null
              ? ''
              : get(sgip_application.site_info_from_enl, 'address2', ''));
        } else {
          personalDetailsData.email = sgip_application.email ? sgip_application.email : '';
          if (personalDetailsData.email !== '') {
            this.isEditableEmail = false;
          }
          personalDetailsData.first_name = sgip_application.first_name ? sgip_application.first_name : '';
          personalDetailsData.last_name = sgip_application.last_name ? sgip_application.last_name : '';
          personalDetailsData.phone = sgip_application.phone ? sgip_application.phone : '';
          personalDetailsData.city = sgip_application.city ? sgip_application.city : '';
          personalDetailsData.battery_installation_site_address = sgip_application.battery_installation_site_address
              ? sgip_application.battery_installation_site_address
              : '';
          isConfirmationChecked = true;
        }
        personalDetailsData.same_address = sgip_application.same_address ? sgip_application.same_address : false;
        personalDetailsData.communication_address = sgip_application.communication_address
            ? sgip_application.communication_address
            : '';
        const siteId = this.props.siteId;
        personalDetailsData.site_id = siteId;
        personalDetailsData.submitted_by_installer_id = sgip_application.submitted_by_installer_id
            ? sgip_application.submitted_by_installer_id
            : '';
        let stepCompleted = '';
        if (sgip_application.stepCompleted) {
          stepCompleted = applicationState(sgip_application.stepCompleted);
        } else {
          stepCompleted = 0;
        }
        this.setState({
          sgipApplicationFound: 'success',
          personalDetails: personalDetailsData,
          activeState: stepCompleted,
          isConfirmationChecked
        });
      },
      failureCb: () => {
        this.setState({ sgipApplicationFound: 'failed' });
      }
    });
  }

  validatePersonalDetails() {
    let fValidation = {
      eFound: false,
      pDE: {
        site_ownership: { error: false, msg: '' },
        first_name: { error: false, msg: '' },
        last_name: { error: false, msg: '' },
        phone: { error: false, msg: '' },
        city: { error: false, msg: '' },
        battery_installation_site_address: { error: false, msg: '' },
        communication_address: { error: false, msg: '' },
        confirmation_details: { error: false, msg: '' },
        email: { error: false, msg: '' },
      },
    };
    if (this.state.personalDetails.site_ownership === '') {
      fValidation.eFound = true;
      fValidation.pDE.site_ownership.error = true;
      fValidation.pDE.site_ownership.msg = 'Site Ownership selection required';
    } else {
      fValidation.pDE.site_ownership.error = false;
      fValidation.pDE.site_ownership.msg = '';
    }
    const letters = /^([a-zA-Z ]+)$/;
    if (this.state.personalDetails.first_name === '') {
      fValidation.eFound = true;
      fValidation.pDE.first_name.error = true;
      fValidation.pDE.first_name.msg = 'First name is required';
    } else {
      if (
        this.state.personalDetails &&
        this.state.personalDetails.first_name &&
        this.state.personalDetails.first_name.match(letters)
      ) {
        if (this.state.personalDetails.first_name.match(letters)) {
          fValidation.pDE.first_name.error = false;
          fValidation.pDE.first_name.msg = '';
        } else {
          fValidation.eFound = true;
          fValidation.pDE.first_name.error = true;
          fValidation.pDE.first_name.msg = 'Please enter alphabets';
        }
      }
    }
    if (this.state.personalDetails.last_name === '') {
      fValidation.eFound = true;
      fValidation.pDE.last_name.error = true;
      fValidation.pDE.last_name.msg = 'Last name is required';
    } else {
      if (this.state.personalDetails && this.state.personalDetails.last_name) {
        if (this.state.personalDetails.last_name.match(letters)) {
          fValidation.pDE.last_name.error = false;
          fValidation.pDE.last_name.msg = '';
        } else {
          fValidation.eFound = true;
          fValidation.pDE.last_name.error = true;
          fValidation.pDE.last_name.msg = 'Please enter alphabets';
        }
      }
    }
    if (this.state.personalDetails.email === '') {
      fValidation.eFound = true;
      fValidation.pDE.email.error = true;
      fValidation.pDE.email.msg = 'Email is required';
    } else {
      if (this.state.personalDetails && this.state.personalDetails.email) {
        if (this.state.personalDetails.email.match(VALIDATE_EMAIL_REGEX)) {
          fValidation.pDE.email.error = false;
          fValidation.pDE.email.msg = '';
        } else {
          fValidation.eFound = true;
          fValidation.pDE.email.error = true;
          fValidation.pDE.email.msg = 'Please enter valid email address';
        }
      }
    }
    if (
      this.state.personalDetails.phone === '' ||
      this.state.personalDetails.phone === null
    ) {
      fValidation.eFound = true;
      fValidation.pDE.phone.error = true;
      fValidation.pDE.phone.msg = 'Mobile number is required';
    } else if (
      this.state.personalDetails.phone &&
      isNaN(this.state.personalDetails.phone)
    ) {
      fValidation.eFound = true;
      fValidation.pDE.phone.error = true;
      fValidation.pDE.phone.msg = 'This field only accepts numbers.';
    } else {
      fValidation.pDE.phone.error = false;
      fValidation.pDE.phone.msg = '';
    }

    if (isEmpty(this.state.personalDetails.city)) {
      fValidation.eFound = true;
      fValidation.pDE.city.error = true;
      fValidation.pDE.city.msg = 'City is required';
    } else {
      fValidation.pDE.city.error = false;
      fValidation.pDE.city.msg = '';
    }

    if (this.state.personalDetails.battery_installation_site_address === '') {
      fValidation.eFound = true;
      fValidation.pDE.battery_installation_site_address.error = true;
      fValidation.pDE.battery_installation_site_address.msg =
        'Battery installation site address is required';
    } else {
      fValidation.pDE.battery_installation_site_address.error = false;
      fValidation.pDE.battery_installation_site_address.msg = '';
    }

    if (this.state.personalDetails.communication_address === '') {
      fValidation.eFound = true;
      fValidation.pDE.communication_address.error = true;
      fValidation.pDE.communication_address.msg = 'Mailing address is required';
    } else {
      fValidation.pDE.communication_address.error = false;
      fValidation.pDE.communication_address.msg = '';
    }

    if (this.state.isConfirmationChecked === false) {
      fValidation.eFound = true;
      fValidation.pDE.confirmation_details.error = true;
      fValidation.pDE.confirmation_details.msg = '';
    } else {
      fValidation.pDE.confirmation_details.error = false;
      fValidation.pDE.confirmation_details.msg = '';
    }
    return fValidation;
  }

  validateValues() {
    let pDE = this.state.personalDetailsError;
    let eFound = false;
    let fValidation = this.validatePersonalDetails();
    pDE = fValidation.pDE;
    eFound = fValidation.eFound;
    this.setState({ personalDetailsError: pDE });
    if (eFound) {
      return false;
    }
    return true;
  }

  handleSubmit() {
    let pD = this.state.personalDetails;
    this.setState({ personalDetails: pD });

    if (this.validateValues()) {
      this.props.postSgipPersonalDetails({
        personalDetails: this.state.personalDetails,
        successCb: () => {
          trackEvents('triggerSgipPageView', { pageTitle: 'Personal Details Questions' });
          forwardTo('personalDetailsQuestions');
        }
      });
    }
  }

  handleSiteOwnerShip = (event, key) => {
    const personalDetailsData = this.state.personalDetails;
    if (key === 'leased') {
      personalDetailsData.site_ownership = 'leased';
    } else {
      personalDetailsData.site_ownership = 'owner';
    }
    this.setState({ personalDetails: personalDetailsData });
  };

  handleSameAddressCheckBox = (e, key) => {
    const personalDetailsData = this.state.personalDetails;
    if (e.target.checked) {
      personalDetailsData.same_address = true;
      personalDetailsData.communication_address = personalDetailsData.battery_installation_site_address;
      document.getElementById('communication_address').value = personalDetailsData.battery_installation_site_address;
      this.setState({
        personalDetailsError: { ...this.state.personalDetailsError, communication_address: { error: false, msg: '' } }
      });
      this.setState({ personalDetails: personalDetailsData });
    } else {
      personalDetailsData.same_address = false;
    }
    this.setState({ personalDetails: personalDetailsData });
  };

  handleConfirmationCheckBox = (e, key) => {
    if (e.target.checked) {
      this.setState({ isConfirmationChecked: true });
    } else {
      this.setState({ isConfirmationChecked: false });
    }
  };

  handlePersonalDetailsFormChange = (event, key) => {
    var name = event.target.name;
    const personalDetailsData = this.state.personalDetails;
    if (name === 'first_name') {
      personalDetailsData.first_name = event.target.value;
    } else if (name === 'last_name') {
      personalDetailsData.last_name = event.target.value;
    } else if (name === 'phone') {
      this.isEditablePhone = true;
      personalDetailsData.phone = event.target.value;
    } else if (name === 'city') {
      personalDetailsData.city = event.target.value;
    } else if (name === 'battery_installation_site_address') {
      if (personalDetailsData.same_address) {
        personalDetailsData.communication_address = event.target.value;
      }
      personalDetailsData.battery_installation_site_address = event.target.value;
    } else if (name === 'communication_address') {
      personalDetailsData.communication_address = event.target.value;
    } else if (name === 'email_id') {
      personalDetailsData.email = event.target.value;
    }

    var letters = /^([a-zA-Z ]+)$/;
        const personalDetailsErrorData = this.state.personalDetailsError;
    if (personalDetailsData.first_name) {
      if (personalDetailsData.first_name.length > 0) {
        if (personalDetailsData.first_name.match(letters)) {
          personalDetailsErrorData.first_name.error = false;
          personalDetailsErrorData.first_name.msg = '';
        } else {
          personalDetailsErrorData.first_name.error = true;
          personalDetailsErrorData.first_name.msg = 'Please enter alphabets';
        }
      } else {
        personalDetailsErrorData.first_name.error = false;
        personalDetailsErrorData.first_name.msg = '';
      }
    }

    if (personalDetailsData.last_name) {
      if (personalDetailsData.last_name.length > 0) {
        if (personalDetailsData.last_name.match(letters)) {
          personalDetailsErrorData.last_name.error = false;
          personalDetailsErrorData.last_name.msg = '';
        } else {
          personalDetailsErrorData.last_name.error = true;
          personalDetailsErrorData.last_name.msg = 'Please enter alphabets';
        }
      } else {
        personalDetailsErrorData.last_name.error = false;
        personalDetailsErrorData.last_name.msg = '';
      }
    }
    this.setState({ personalDetails: personalDetailsData, personalDetailsError: personalDetailsErrorData });
  };

  render() {
    const { classes, currentlySending, sgip_application, isInstaller, isIOS } = this.props;
    return (
      <div className={classes.dWrapper}>
        <StatusTracking activeState={this.state.activeState} pageNumber="2" isInstaller={isInstaller} />
        {currentlySending && <Loader loading={currentlySending} />}
        <div className={classes.root}>
          <SiteOwnership
            siteLeased={this.state.personalDetails.site_ownership}
            handleSiteOwnerShip={this.handleSiteOwnerShip}
            personalDetailsError={this.state.personalDetailsError}
            sgip_application={sgip_application}
            isInstaller={isInstaller}
          />

          <PersonalDetailsForm
            personalDetails={this.state.personalDetails}
            personalDetailsError={this.state.personalDetailsError}
            handleSameAddressCheckBox={this.handleSameAddressCheckBox}
            handleConfirmationCheckBox={this.handleConfirmationCheckBox}
            handlePersonalDetailsFormChange={this.handlePersonalDetailsFormChange}
            handleSubmit={this.handleSubmit}
            isInstaller={isInstaller}
            isIOS={isIOS}
            isConfirmationChecked={this.state.isConfirmationChecked}
            isEditableEmail={this.isEditableEmail}
            isEditablePhone={this.isEditablePhone}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  postSgipPersonalDetails: payload =>
    dispatch(postSgipPersonalDetails(payload)),
  getSgipApplicationDetailsBySiteId: payload =>
    dispatch(getSgipApplicationDetailsBySiteId(payload)),
});

const mapStateToProps = state => ({
  siteId: state.appReducer.siteId,
  currentlySending: state.sgipAppReducer.currentlySending,
  sgip_application: state.sgipAppReducer.sgip_application,
  isInstaller: state.appReducer.isInstaller,
  isIOS: state.appReducer.isIOS,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(index));
