import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Box, Grid, Typography } from '@material-ui/core';
import { BoxLayout } from '../../../components/Common';
import { Title, Text } from '../../../components/Texts';
import { Link } from '@material-ui/core';
import LanguageIcon from '@material-ui/icons/Language';
import { SvgIcon } from '@material-ui/core';
import copy from 'copy-to-clipboard';
import { getApplicationNumber } from '../actions';
const styles = theme => ({
  root: {
    borderRadius: theme.spacing(1.25),
    boxShadow: '1px 2px 5px 0 rgba(152, 152, 152, 0.1)',
    backgroundColor: theme.palette.tertiary.main,
    padding: theme.spacing(2.5)
  },

  description: {
    color: '#545456'
  },
  header: {
    marginBottom: theme.spacing(1.25)
  },

  applicationIdWrapper: {
    borderRadius: '15px',
    backgroundColor: '#f3f3f3',
    width: 'auto',
    color: '#545456',
    minWidth: '200px'
  },

  copyIconWrapper: {
    borderRadius: '15px',
    border: 'dashed 1px #939598',
    backgroundColor: 'transparent',
    marginTop: '0px',
    marginLeft: '5px',
    width: '70px'
  },
  linkWrapper: {
    margin: theme.spacing(0.5, 0),
    display: 'flex',
    alignItems: 'flex-end'
  },
  langIconWrapper: {
    color: '#01b4de',
    verticalAlign: 'text-bottom',
    margin: theme.spacing(0, 0.25, 0, 0)
  }
});

class SgipLiveStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null
    };
    this.handlePopoverOpen = this.handlePopoverOpen.bind(this);
    this.handlePopoverClose = this.handlePopoverClose.bind(this);
  }

  componentDidMount() {
    const { siteId } = this.props;
    this.props.getApplicationNumber({ siteId: siteId });
  }

  handlePopoverOpen(event) {
    this.setState({ anchorEl: event.currentTarget });
    copy(this.props.application_number);
  }

  handlePopoverClose() {
    this.setState({ anchorEl: null });
  }

  render() {
    const { classes, application_number, isIOS, isInstaller } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <Fragment>
        <Grid>
          <BoxLayout>
            <Title className={classes.header}>Check Live SGIP Status</Title>

            {application_number === 'NA' ? (
              <Text className={classes.description}>
                Enphase is in the process of submitting your document to the utility. Once submitted, we will provide
                you the Application No to check the status.
              </Text>
            ) : (
              <Text className={classes.description}>
                You can check the latest update on SGIP website by using your unique<b> Application No:</b>
              </Text>
            )}
            <Grid container direction="row" style={{ marginTop: '15px' }}>
              <Box className={classes.applicationIdWrapper}>
                <Typography
                  variant={isInstaller ? 'body2' : 'subtitle2'}
                  style={{
                    color: '#545456',
                    padding: '5px 10px'
                  }}
                >
                  {application_number === 'NA' ? 'Not Available' : application_number}
                </Typography>
              </Box>
              {application_number !== 'NA' ? (
                <Typography
                  className={classes.copyIconWrapper}
                  aria-owns={open ? 'mouse-over-popover' : undefined}
                  aria-haspopup="true"
                  component="div"
                  style={{ display: 'flex', alignItems: 'center' }}
                  onClick={e => this.handlePopoverOpen(e)}
                  onMouseLeave={this.handlePopoverClose}
                >
                  <SvgIcon
                    component={svgProps => {
                      return (
                        <svg {...svgProps} style={{ margin: '5px' }}>
                          {React.cloneElement(svgProps.children[0], {
                            fill: '#939598'
                          })}
                        </svg>
                      );
                    }}
                  >
                    <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
                  </SvgIcon>
                  <Text style={{ color: '#939598' }}> Copy </Text>
                </Typography>
              ) : (
                ''
              )}
              {/* <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                  paper: classes.paper
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                onClose={this.handlePopoverClose}
                disableRestoreFocus
              >
                <Typography style={{ padding: '5px' }}>Copied</Typography>
              </Popover> */}
            </Grid>
            <Typography variant="body1" className={classes.linkWrapper}>
              <LanguageIcon className={classes.langIconWrapper} />
              {isIOS ? (
                <a
                  style={{ color: '#01B4DE', fontWeight: 'bold', textDecoration: 'none' }}
                  href="enphase://user?url=https://www.selfgenca.com"
                  target="_self"
                >
                  <Typography>{`Go to SGIP Website`}</Typography>
                </a>
              ) : (
                <Link
                  style={{ color: '#01b4de', fontWeight: 'bold', cursor: 'pointer' }}
                  target="_blank"
                  rel="noreferrer"
                  underline="none"
                  onClick={() => window.open('https://www.selfgenca.com', '_blank', 'noopener,noreferrer')}
                >
                  {' '}
                  Go to SGIP Website
                </Link>
              )}
            </Typography>
          </BoxLayout>
        </Grid>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getApplicationNumber: siteId => dispatch(getApplicationNumber(siteId))
});

const mapStateToProps = state => ({
  application_number: state.sgipAppReducer.application_number,
  siteId: state.appReducer.siteId,
  isIOS: state.appReducer.isIOS
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SgipLiveStatus));
