import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { UtilityInstaller } from '../../../components/SgipApp/PrimaryDetails';
import { SystemDetails } from '../../../components/SgipApp/PrimaryDetails';
import StatusTracking from '../../../components/SgipApp/StatusTracking';
import {
  getSGIPInstallers,
  getSGIPPrimaryInstallers,
  postSgipPrimaryDetails,
  postDeveloperKeyUpdateMail,
  getSgipApplicationDetailsBySiteId,
  getSiteInfo,
} from '../actions';
import {
  forwardTo,
  applicationState,
  addInputEventListener,
  trackEvents,
} from '../../../utils/helper';
import Loader from '../../../components/Loader';
import { isEqual, get, clone, isEmpty, debounce } from '../../../utils/lodash';
import SGIPDeveloperKeyDialog from '../../../components/SgipApp/SGIPDeveloperKeyDialog';
import SGIPDeveloperKeyAlertDialog from '../../../components/SgipApp/SGIPDeveloperKeyAlertDialog';
import moment from 'moment';
import PrimaryAdditionalDetails from '../PrimaryAdditionalDetails';
import { Box, Grid } from '@material-ui/core';
import { FilledButton } from '../../../components/Buttons';
import PrivacyPolicy from '../../../components/SgipApp/PrivacyPolicy';
import { blue } from '@material-ui/core/colors';

const styles = theme => ({
  root: {
    margin: theme.spacing(2.5, 2.5, 0, 2.5),
    //height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  btnblue: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: '#01b4de',
    marginTop: '25px',
    height: '40px',
  },
  alert: {
    boxShadow: '1px 2px 10px 0 rgba(63, 67, 71, 0.08)',
    backgroundColor: '#ff5959',
    borderRadius: '0px',
  },
  MuiDisabled: {
    backgroundColor: 'red',
  },
  [theme.breakpoints.up('md')]: {
    root: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly',
    },
    dWrapper: {
      margin: '30px 194px',
      boxShadow: '0 2px 10px 0 rgba(63, 67, 71, 0.08)',
      background: '#fff',
      borderRadius: '3px',
    },
  },
});

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeState: 0,
      isSGIPDeveloperKeyPopup: false,
      isSGIPDeveloperKeyEmailSentDialog: false,
      isNextButtonDisabled: false,
      shouldDisplayInstallersDropDown: false,
      defaultPrimaryInstaller: '',
      homeownerCheckbox: false,
      primaryDetails: {
        email: '',
        totalEnchargeR2: 0,
        totalEnchargeR3: 0,
        unit_of_encharge3: 0,
        unit_of_encharge10: 0,
        unit_of_iq_battery5p: 0,
        utility_company_name: '',
        installer_name: '',
        installer_code: '',
        site_id: '',
        solar_size: '',
        installation_poc: '',
        homeowner_code: '',
        pto_date: null,
        battery_rebate_claimed: 'false',
        battery_pto: 'false',
      },
      primaryDetailsError: {
        system_details_error: { error: false, msg: '' },
        utility_company_name: { error: false, msg: '' },
        installer_name: { error: false, msg: '' },
        installation_poc: { error: false, msg: '' },
        homeowner_code: { error: false, msg: '' },
        additional_details: { error: false, msg: '' },
      },
      installerNameText: '',
    };
    this.otherLabel = 'Other';
    this.primaryInstallersList = [];
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validatePrimaryDetails = this.validatePrimaryDetails.bind(this);
    this.validateValues = this.validateValues.bind(this);
    this.SGIPDeveloperNo = this.SGIPDeveloperNo.bind(this);
    this.SGIPDeveloperYes = this.SGIPDeveloperYes.bind(this);
    this.SGIPDeveloperAlertOk = this.SGIPDeveloperAlertOk.bind(this);
    this.onSelectInstaller = this.onSelectInstaller.bind(this);
    this.handleInstallerTextChange = debounce(
      this.handleInstallerTextChange,
      1000
    );
  }

  componentDidMount() {
    const { siteId } = this.props;
    addInputEventListener();
    let pD = this.state.primaryDetails;
    pD.site_id = siteId;
    this.loadInstallers(siteId);
    this.setState({ primaryDetails: pD });
  }

  componentDidUpdate(prevProps) {
    const { isInstaller, primaryInstallers = [] } = this.props;
    if (
      !isEqual(primaryInstallers, get(prevProps, 'primaryInstallers')) &&
      isInstaller
    ) {
      let primaryDetails = this.state.primaryDetails;
      primaryDetails.installer_name = get(primaryInstallers[0], 'name');
      this.setState({
        primaryDetails,
        installerNameText: get(primaryInstallers[0], 'name'),
      });
    }
  }

  loadInstallers(siteId) {
    this.props.getSGIPPrimaryInstallers({
      siteId,
      successCb: () => {
        this.loadApplication(siteId);
      },
      failureCb: () => {
        this.loadApplication(siteId);
      },
    });
  }

  loadApplication(siteId) {
    this.props.getSgipApplicationDetailsBySiteId({
      siteId: siteId,
      isInstaller: this.props.isInstaller,
      successCb: () => {
        // this.setState({ sgipApplicationFound: 'success' });
        const { sgip_application } = this.props;
        const primaryDetailsData = this.state.primaryDetails;
        const stepCompleted = applicationState(sgip_application.stepCompleted);
        primaryDetailsData.utility_company_name =
          get(sgip_application, 'utility_company_name', '') || '';
        primaryDetailsData.installer_name = primaryDetailsData.installer_name
          ? primaryDetailsData.installer_name
          : get(sgip_application, 'installer_name', '') || '';
        primaryDetailsData.unit_of_encharge3 =
          get(sgip_application, 'unit_of_encharge3', 0) || 0;
        primaryDetailsData.unit_of_encharge10 =
          get(sgip_application, 'unit_of_encharge10', 0) || 0;
        primaryDetailsData.unit_of_iq_battery5p =
          get(sgip_application, 'unit_of_iq_battery5p', 0) || 0;
        primaryDetailsData.solar_size = sgip_application.solar_size
          ? parseFloat(sgip_application.solar_size).toFixed(2)
          : '';
        primaryDetailsData.installer_code =
          get(sgip_application, 'installer_code', '') || '';
        primaryDetailsData.installer_id =
          get(sgip_application, 'installer_id', '') || '';
        primaryDetailsData.installation_poc =
          sgip_application.installation_poc || '';
        const siteId = this.props.siteId;
        primaryDetailsData.site_id = siteId;
        primaryDetailsData.application_start_date =
          sgip_application.application_start_date || '';
        primaryDetailsData.application_started_by =
          sgip_application.application_started_by || '';
        primaryDetailsData.homeowner_code =
          sgip_application.homeowner_code || '';
        primaryDetailsData.battery_pto = sgip_application.battery_pto !== null ? String(sgip_application.battery_pto) : null;
        primaryDetailsData.battery_rebate_claimed = sgip_application.battery_rebate_claimed !== null ? String(
          sgip_application.battery_rebate_claimed) : 'false';
        primaryDetailsData.pto_date = sgip_application.pto_date || null;
        this.setState(
          {
            primaryDetails: primaryDetailsData,
            activeState: stepCompleted,
            defaultPrimaryInstaller: primaryDetailsData.installer_name,
          },
          () => {
            if (!isEmpty(primaryDetailsData.homeowner_code)) {
              this.setState({ homeownerCheckbox: true });
            }
            this.totalEnchargeCalculation();
          }
        );
      },
      failureCb: () => {
        this.props.getSiteInfo({
          siteId,
          successCb: response => {
            const { siteInfo } = response;
            const primaryDetailsData = this.state.primaryDetails;
            primaryDetailsData.unit_of_encharge3 = get(
              siteInfo,
              'encharge_count'
            );
            this.setState({ primaryDetails: primaryDetailsData }, () =>
              this.totalEnchargeCalculation()
            );
          },
        });
        // this.setState({ sgipApplicationFound: 'failed' });
      },
    });
  }

  validatePrimaryDetails() {
    let fValidation = {
      eFound: false,
      pDE: {
        installer_name: { error: false, msg: '' },
        system_details_error: { error: false, msg: '' },
        utility_company_name: { error: false, msg: '' },
        installation_poc: { error: false, msg: '' },
        homeowner_code: { error: false, msg: '' },
        additional_details: { error: false, msg: '' },
      },
    };
    if (this.state.primaryDetails.utility_company_name === '') {
      fValidation.eFound = true;
      fValidation.pDE.utility_company_name.error = true;
      fValidation.pDE.utility_company_name.msg = 'Utility selection required';
    } else {
      fValidation.pDE.utility_company_name.error = false;
      fValidation.pDE.utility_company_name.msg = '';
    }
    if (
      this.state.primaryDetails.installer_name === '' &&
      !this.state.homeownerCheckbox
    ) {
      fValidation.eFound = true;
      fValidation.pDE.installer_name.error = true;
      fValidation.pDE.installer_name.msg = 'Installer selection required';
    } else {
      fValidation.pDE.installer_name.error = false;
      fValidation.pDE.installer_name.msg = '';
    }

    const emailFormat =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,10}))$/;
    const isValidEmail = get(
      this.state,
      'primaryDetails.installation_poc'
    ).match(emailFormat);
    if (this.state.primaryDetails.installer_code === null) {
      if (
        this.state.primaryDetails.installation_poc === '' &&
        !this.props.isInstaller &&
        !this.state.homeownerCheckbox
      ) {
        fValidation.eFound = true;
        fValidation.pDE.installation_poc.error = true;
        fValidation.pDE.installation_poc.msg =
          "Installer's Email Id is required";
      } else if (
        !isValidEmail &&
        !this.props.isInstaller &&
        !this.state.homeownerCheckbox
      ) {
        fValidation.eFound = true;
        fValidation.pDE.installation_poc.error = true;
        fValidation.pDE.installation_poc.msg =
          'Please provide a valid email address';
      }
    } else {
      fValidation.pDE.installation_poc.error = false;
      fValidation.pDE.installation_poc.msg = '';
    }
    if (
      this.state.primaryDetails.unit_of_iq_battery5p === 0 &&
      this.state.primaryDetails.unit_of_encharge3 === 0 &&
      this.state.primaryDetails.unit_of_encharge10 === 0
    ) {
      fValidation.eFound = true;
      fValidation.pDE.system_details_error.error = true;
      fValidation.pDE.system_details_error.msg = 'System details required';
    }
    if (
      this.state.homeownerCheckbox &&
      isEmpty(this.state.primaryDetails.homeowner_code)
    ) {
      fValidation.eFound = true;
      fValidation.pDE.homeowner_code.error = true;
      fValidation.pDE.homeowner_code.msg = 'SGIP Developer Key is Required';
    } else {
      fValidation.pDE.homeowner_code.error = false;
      fValidation.pDE.homeowner_code.msg = '';
    }
    if (this.state.primaryDetails.battery_rebate_claimed === null) {
      fValidation.eFound = true;
      fValidation.pDE.additional_details = {
        error: true,
        msg: 'Please answer required questions to proceed'
      };
    }
    if (this.state.primaryDetails.battery_pto === null) {
      fValidation.eFound = true;
      fValidation.pDE.additional_details = {
        error: true,
        msg: 'Please answer required questions to proceed'
      };
    }
    if (
      this.state.primaryDetails.battery_pto === 'true' &&
      this.state.primaryDetails.pto_date === null
    ) {
      fValidation.eFound = true;
      fValidation.pDE.additional_details = {
        error: true,
        msg: 'PTO date is mandatory',
      };
    }
    return fValidation;
  }

  validateValues() {
    let pDE = this.state.primaryDetailsError;
    let eFound = false;
    let fValidation = this.validatePrimaryDetails();
    pDE = fValidation.pDE;
    eFound = fValidation.eFound;
    this.setState({ primaryDetailsError: pDE });
    if (eFound) {
      return false;
    }
    return true;
  }

  handleSubmit(shouldDisplayInstallersDropDown) {
    let installerName = this.state.primaryDetails.installer_name;
    let isDeveloperKeyStatus = false;
    let isDeveloperKeyExists = false;
    let pD = this.state.primaryDetails;
    let { installers, isInstaller, primaryInstallers } = this.props;
    let installersList = shouldDisplayInstallersDropDown
      ? installers
      : primaryInstallers;
    if (isInstaller) {
      let installer = primaryInstallers.filter(
        installer => installer.name === this.state.primaryDetails.installer_name
      );
      pD.installer_id = installer.length > 0 ? installer[0].company_id : null;
      pD.installer_code =
        installer.length > 0 ? installer[0].sgip_developer_key : null;
    } else if (pD.installer_code === null || !pD.installer_code) {
      let installer = installersList.filter(
        installer => installer.name === this.state.primaryDetails.installer_name
      );
      pD.installer_id = installer.length > 0 ? installer[0].company_id : null;
      pD.installer_code =
        installer.length > 0 ? installer[0].sgip_developer_key : null;
    }

    if (this.validateValues()) {
      if (installersList.length) {
        var installerCheck = installersList.filter(function (option) {
          return option.name === installerName;
        });
        if (installerCheck && installerCheck.length <= 0 && installerName) {
          isDeveloperKeyStatus = true;
        }
      }
    }

    if (
      installerCheck &&
      installerCheck.length > 0 &&
      installerCheck[0].hasOwnProperty('sgip_developer_key')
    ) {
      if (installerCheck[0].sgip_developer_key) {
        isDeveloperKeyExists = true;
      }
    }
    if (
      (isDeveloperKeyStatus ||
        (!isDeveloperKeyExists &&
          isEmpty(this.state.primaryDetails.homeowner_code))) &&
      this.validateValues() &&
      !isInstaller
    ) {
      trackEvents('triggerSGIPHomeownerDeveloperKeyError');
      this.setState({
        primaryDetails: pD,
        isSGIPDeveloperKeyPopup: !this.state.isSGIPDeveloperKeyPopup,
      });
    } else if (!this.validateValues()) {
      this.setState({ primaryDetails: pD });
    } else {
      pD.application_started_by = pD.application_started_by
        ? pD.application_started_by
        : isInstaller
        ? 'installer'
        : 'homeowner';
      pD.application_start_date = pD.application_start_date
        ? pD.application_start_date
        : moment();
      // send installer_name , installer_code , installation_poc as empty if homeowner code is there
      if (!isEmpty(pD.homeowner_code)) {
        pD.installation_poc = '';
        pD.installer_name = '';
        pD.installer_code = '';
        pD.installer_id = null;
      }
      this.props.postSgipPrimaryDetails({
        primaryDetails: pD,
        successCb: () => {
          trackEvents('triggerSgipPageView', { pageTitle: 'Personal Details' });
          forwardTo('personalDetails');
          this.setState({ primaryDetails: pD });
        },
      });
    }
  }

  solarSize = (event, key) => {
    let pData = this.state.primaryDetails;
    let updateValue = parseFloat(event.target.value).toFixed(2);
    if (
      pData.solar_size <= 999.99 ||
      (pData.solar_size > 999.99 && updateValue <= 999.99)
    ) {
      pData.solar_size = parseFloat(updateValue) || '';
      this.setState({ primaryDetails: pData });
    }
  };
  increaseUnitsOfEncharge3 = (event, key) => {
    if (
      this.state.primaryDetails.totalEnchargeR2 < 40 &&
      !this.state.primaryDetails.totalEncharge3LimitFlag
    ) {
      let pData = this.state.primaryDetails;
      pData.unit_of_encharge3 = pData.unit_of_encharge3 + 1;
      this.setState({ primaryDetails: pData }, () =>
        this.totalEnchargeCalculation()
      );
    }
  };

  decreaseUnitsOfEncharge3 = (event, key) => {
    if (this.state.primaryDetails.unit_of_encharge3 > 0) {
      let pData = this.state.primaryDetails;
      pData.unit_of_encharge3 = pData.unit_of_encharge3 - 1;
      this.setState({ primaryDetails: pData }, () =>
        this.totalEnchargeCalculation()
      );
    }
  };

  increaseUnitsOfEncharge10 = (event, key) => {
    if (
      this.state.primaryDetails.totalEnchargeR2 < 40 &&
      !this.state.primaryDetails.totalEncharge10LimitFlag
    ) {
      let pData = this.state.primaryDetails;
      pData.unit_of_encharge10 = pData.unit_of_encharge10 + 1;
      this.setState({ primaryDetails: pData }, () =>
        this.totalEnchargeCalculation()
      );
    }
  };

  decreaseUnitsOfEncharge10 = (event, key) => {
    if (this.state.primaryDetails.unit_of_encharge10 > 0) {
      let pData = this.state.primaryDetails;
      pData.unit_of_encharge10 = pData.unit_of_encharge10 - 1;
      this.setState({ primaryDetails: pData }, () =>
        this.totalEnchargeCalculation()
      );
    }
  };

  increaseUnitsOfIQBattery5P = (event, key) => {
    this.setState(
      prevState => {
        if (
          prevState.primaryDetails.totalEnchargeR3 < 80 &&
          !prevState.primaryDetails.totalEncharge5PLimitFlag
        ) {
          let pData = { ...prevState.primaryDetails };
          pData.unit_of_iq_battery5p = pData.unit_of_iq_battery5p + 1;
          return { ...prevState, primaryDetails: pData };
        }
      },
      () => {
        this.totalEnchargeCalculation();
      }
    );
  };

  decreaseUnitsOfIQBattery5P = (event, key) => {
    this.setState(
      prevState => {
        if (prevState.primaryDetails.totalEnchargeR3 > 0) {
          let pData = { ...prevState.primaryDetails };
          pData.unit_of_iq_battery5p = pData.unit_of_iq_battery5p - 1;
          return { ...prevState, primaryDetails: pData };
        }
      },
      () => {
        this.totalEnchargeCalculation();
      }
    );
  };

  totalEnchargeCalculation() {
    this.setState(prevState => {
      let pData = { ...prevState.primaryDetails };
      let encharge3Unit = 3.235;
      let encharge10Unit = 9.706;
      let encharge5PUnit = 4.759;
      let totalEnchargeR2 = 0;
      let totalEnchargeR3 = 0;

      totalEnchargeR2 =
        this.state.primaryDetails.unit_of_encharge3 * encharge3Unit +
        this.state.primaryDetails.unit_of_encharge10 * encharge10Unit;

      totalEnchargeR3 =
        this.state.primaryDetails.unit_of_iq_battery5p * encharge5PUnit;

      let totalEncharge3Limit = totalEnchargeR2 + encharge3Unit;
      let totalEncharge10Limit = totalEnchargeR2 + encharge10Unit;
      let totalEncharge5PLimit = totalEnchargeR3 + encharge5PUnit;

      if (totalEncharge3Limit > 40) {
        pData.totalEnchargeR2 = totalEnchargeR2;
        pData.totalEncharge3LimitFlag = true;
      } else {
        pData.totalEnchargeR2 = totalEnchargeR2;
        pData.totalEncharge3LimitFlag = false;
      }
      if (totalEncharge10Limit > 40) {
        pData.totalEnchargeR2 = totalEnchargeR2;
        pData.totalEncharge10LimitFlag = true;
      } else {
        pData.totalEnchargeR2 = totalEnchargeR2;
        pData.totalEncharge10LimitFlag = false;
      }
      if (totalEncharge5PLimit > 80) {
        pData.totalEnchargeR3 = totalEnchargeR3;
        pData.totalEncharge5PLimitFlag = true;
      } else {
        pData.totalEnchargeR3 = totalEnchargeR3;
        pData.totalEncharge5PLimitFlag = false;
      }
      return { ...prevState, primaryDetails: pData };
    });
  }

  setSelectedInstaller = (event, key) => {
    let pData = this.state.primaryDetails;
    if (event.target.value) {
      pData.installer_name = event.target.value;
    } else {
      pData.installer_name = '';
    }

    this.setState({ primaryDetails: pData });
  };

  setSelectedUtility = (event, key) => {
    let pData = this.state.primaryDetails;
    pData.utility_company_name = event.target.value;
    this.setState({ primaryDetails: pData });
  };

  onSelectPrimaryInstaller = event => {
    const value = event.target.value;
    if (value === this.otherLabel) {
      let pData = this.state.primaryDetails;
      pData.installer_name = '';
      pData.installer_code = null;
      pData.installer_id = null;
      pData.installation_poc = '';
      this.setState({
        primaryDetails: pData,
        shouldDisplayInstallersDropDown: true,
        defaultPrimaryInstaller: value,
      });
    } else {
      this.setState({
        shouldDisplayInstallersDropDown: false,
        defaultPrimaryInstaller: value,
      });
      this.onSelectInstaller(value, true);
    }
  };

  handleInstallerTextChange = value => {
    this.props.getSGIPInstallers({
      source: 'CUSTOMER',
      installerName: value,
    });
  };

  onSelectInstaller = (value, isFromPrimaryInstaller) => {
    this.setState({ installerNameText: value });
    if (
      value &&
      (isFromPrimaryInstaller === 'reset' || isFromPrimaryInstaller === true)
    ) {
      var installers =
        isFromPrimaryInstaller === true
          ? this.props.primaryInstallers
          : this.props.installers;
      var sgipDeveloperKey = installers.filter(option => {
        if (option.name === value) {
          return option.sgip_developer_key;
        }
      });
      var installerExist = installers.find(option => option.name === value);
      let pData = this.state.primaryDetails;
      pData.installer_name = value;
      pData.installation_poc = isEmpty(installerExist)
        ? ''
        : installerExist.email !== null
        ? installerExist.email
        : '';
      if (sgipDeveloperKey.length || installerExist) {
        pData.installer_code = sgipDeveloperKey.length
          ? sgipDeveloperKey[0].sgip_developer_key
          : null;
        pData.installer_id = sgipDeveloperKey.length
          ? sgipDeveloperKey[0].company_id
          : installerExist.company_id;
      } else {
        pData.installer_code = null;
        pData.installer_id = null;
      }
      this.setState({ primaryDetails: pData, isNextButtonDisabled: false });
    }
    if (value === '' && isFromPrimaryInstaller === 'reset') {
      return;
    }
    if (isFromPrimaryInstaller === 'input' && value.length >= 3) {
      this.handleInstallerTextChange(value);
    }
  };

  setInstallerEmail = e => {
    let pData = this.state.primaryDetails;
    pData.installation_poc = e.target.value;
    this.setState({ primaryDetails: pData });
  };
  setHomeownerCode = e => {
    let pDE = this.state.primaryDetailsError;
    let pData = this.state.primaryDetails;
    pData.homeowner_code = e.target.value;
    this.setState({ primaryDetails: pData });
    if (e.target.value === '') {
      pDE.homeowner_code.error = true;
      pDE.homeowner_code.msg = 'SGIP Developer Key is Required';
    } else {
      pDE.homeowner_code.error = false;
      pDE.homeowner_code.msg = '';
    }
    this.setState({ primaryDetailsError: pDE });
  };

  handleHomeownerCheckboxChange = e => {
    this.setState({ homeownerCheckbox: e.target.checked });
    // set installer_name, installation_poc, installer_code as  empty when checkbox is checked
    if (e.target.checked === true) {
      let pD = this.state.primaryDetails;
      pD.installation_poc = '';
      pD.installer_code = '';
      pD.installer_name = '';
      pD.installer_id = null;
      this.setState({
        installation_poc: '',
        installer_code: '',
        installer_name: '',
        primaryDetails: pD,
        defaultPrimaryInstaller: '',
      });
    } else {
      let pD = this.state.primaryDetails;
      pD.homeowner_code = '';
      this.setState({ primaryDetails: pD });
    }
  };

  SGIPDeveloperAlertOk() {
    this.setState({
      isSGIPDeveloperKeyEmailSentDialog:
        !this.state.isSGIPDeveloperKeyEmailSentDialog,
    });
  }

  SGIPDeveloperNo() {
    this.setState({
      isSGIPDeveloperKeyPopup: !this.state.isSGIPDeveloperKeyPopup,
    });
  }

  SGIPDeveloperYes() {
    this.setState(
      {
        isSGIPDeveloperKeyPopup: !this.state.isSGIPDeveloperKeyPopup,
        isNextButtonDisabled: !this.state.isNextButtonDisabled,
      },
      () => {
        this.props.postDeveloperKeyUpdateMail({
          primaryDetails: this.state.primaryDetails,
          successCb: () => {
            this.setState({ isSGIPDeveloperKeyEmailSentDialog: true });
          },
        });
      }
    );
  }

  handlePTODateChange = date => {
    this.setState({
      primaryDetails: { ...this.state.primaryDetails, pto_date: date },
    });
  };

  handleBatteryRebate = e => {
    this.setState({
      primaryDetails: {
        ...this.state.primaryDetails,
        battery_rebate_claimed: e.target.value,
      },
    });
  };

  handleBatteryPTO = e => {
    this.setState({
      primaryDetails: {
        ...this.state.primaryDetails,
        battery_pto: e.target.value,
        pto_date: e.target.value === 'false' ? null : this.state.primaryDetails.pto_date,
      },
    });
  };

  render() {
    const {
      classes,
      installers,
      primaryInstallers,
      currentlySending,
      sgip_application,
      isInstaller,
      isIOS,
    } = this.props;
    const {
      primaryDetailsError,
      primaryDetails,
      isSGIPDeveloperKeyPopup,
      isSGIPDeveloperKeyEmailSentDialog,
      isNextButtonDisabled,
    } = this.state;
    const errorMessage =
      primaryDetailsError.installer_name.error ||
      primaryDetailsError.system_details_error.error ||
      primaryDetailsError.utility_company_name.error;
    const sgipPrimaryInstallers = clone(primaryInstallers);
    sgipPrimaryInstallers.push({
      name: this.otherLabel,
    });
    let installerCheckStatus = false;
    let solarSizeCheck = false;
    let solarSizeCheckError = false;
    let defaultPrimaryInstaller = this.state.defaultPrimaryInstaller;
    let shouldDisplayInstallersDropDown =
      this.state.shouldDisplayInstallersDropDown;
    if (primaryDetails.solar_size) {
      if (primaryDetails.solar_size.toString().split('.')[1]) {
        solarSizeCheck =
          primaryDetails.solar_size.toString().split('.')[1].length > 2;
      }
    }

    if (primaryDetails.solar_size > 999.99) {
      solarSizeCheckError = true;
    }

    if (installers.length) {
      var installerCheck = installers.filter(function (option) {
        return option.name === primaryDetails.installer_name;
      });
      if (installerCheck.length <= 0 && primaryDetails.installer_name) {
        installerCheckStatus = true;
      }
    }

    if (
      isEmpty(primaryInstallers) &&
      defaultPrimaryInstaller &&
      defaultPrimaryInstaller !== this.otherLabel
    ) {
      sgipPrimaryInstallers.push({
        name: defaultPrimaryInstaller,
      });
    }
    return (
      <div className={classes.dWrapper}>
        <StatusTracking
          activeState={this.state.activeState}
          pageNumber="1"
          isInstaller={isInstaller}
        />
        {currentlySending && <Loader loading={currentlySending} />}
        {isSGIPDeveloperKeyPopup &&
          isEmpty(this.state.primaryDetails.homeowner_code) && (
            <SGIPDeveloperKeyDialog
              handleClose={this.SGIPDeveloperNo}
              handleProceed={this.SGIPDeveloperYes}
              installerCheckStatus={installerCheckStatus}
              installerName={primaryDetails.installation_poc}
            />
          )}
        {isSGIPDeveloperKeyEmailSentDialog && (
          <SGIPDeveloperKeyAlertDialog
            handleClose={this.SGIPDeveloperAlertOk}
          />
        )}
        <Fragment>
          <div className={classes.root}>
            <Grid container justify="space-evenly" spacing={2}>
              <Grid item xs={12} md={6}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <UtilityInstaller
                      setSelectedInstaller={this.setSelectedInstaller}
                      setSelectedUtility={this.setSelectedUtility}
                      installer_name_error={
                        this.state.primaryDetailsError.installer_name.msg
                      }
                      utilityError={
                        this.state.primaryDetailsError.utility_company_name.msg
                      }
                      installers={installers}
                      installerNameText={this.state.installerNameText}
                      primaryInstallers={sgipPrimaryInstallers}
                      sgip_application={sgip_application}
                      utilityCompanyName={
                        this.state.primaryDetails.utility_company_name
                      }
                      utilityPrimaryInstallers={defaultPrimaryInstaller}
                      utilityInstaller={
                        this.state.primaryDetails.installer_name
                      }
                      onSelectInstaller={this.onSelectInstaller}
                      onSelectPrimaryInstaller={this.onSelectPrimaryInstaller}
                      installerCheckStatus={installerCheckStatus}
                      installerError={
                        this.state.primaryDetailsError.installer_name.msg
                      }
                      isInstaller={isInstaller}
                      installerEmail={
                        this.state.primaryDetails.installation_poc
                      }
                      installerEmailError={
                        this.state.primaryDetailsError.installation_poc.msg
                      }
                      handleEmailChange={this.setInstallerEmail}
                      shouldDisplayInstallersDropDown={
                        shouldDisplayInstallersDropDown
                      }
                      homeownerCode={this.state.primaryDetails.homeowner_code}
                      handleHomeownerCodeChange={this.setHomeownerCode}
                      homeownerCodeError={
                        this.state.primaryDetailsError.homeowner_code.msg
                      }
                      homeownerCheckbox={this.state.homeownerCheckbox}
                      handleHomeownerCheckboxChange={
                        this.handleHomeownerCheckboxChange
                      }
                    />
                  </Grid>
                  <Grid item>
                    <PrimaryAdditionalDetails
                      ptoSelectedDate={this.state.primaryDetails.pto_date}
                      batteryRebatePreviously={
                        this.state.primaryDetails.battery_rebate_claimed
                      }
                      hasBatteryPTO={this.state.primaryDetails.battery_pto}
                      onBatteryPTOChange={this.handleBatteryPTO}
                      onBatteryRebateClaimedChange={this.handleBatteryRebate}
                      onPTODateChange={this.handlePTODateChange}
                      errorMessage={
                        this.state.primaryDetailsError.additional_details.msg
                      }
                    ></PrimaryAdditionalDetails>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <SystemDetails
                  increaseUnitsOfEncharge3={this.increaseUnitsOfEncharge3}
                  decreaseUnitsOfEncharge3={this.decreaseUnitsOfEncharge3}
                  increaseUnitsOfEncharge10={this.increaseUnitsOfEncharge10}
                  decreaseUnitsOfEncharge10={this.decreaseUnitsOfEncharge10}
                  increaseUnitsOfIQBattery5P={this.increaseUnitsOfIQBattery5P}
                  decreaseUnitsOfIQBattery5P={this.decreaseUnitsOfIQBattery5P}
                  solarSize={this.solarSize}
                  unit_of_encharge3={
                    this.state.primaryDetails.unit_of_encharge3
                  }
                  unit_of_encharge10={
                    this.state.primaryDetails.unit_of_encharge10
                  }
                  unit_of_iq_battery5p={
                    this.state.primaryDetails.unit_of_iq_battery5p
                  }
                  handleSubmit={() =>
                    this.handleSubmit(shouldDisplayInstallersDropDown)
                  }
                  system_details_error={
                    this.state.primaryDetailsError.system_details_error.msg
                  }
                  errorMessage={errorMessage}
                  installerCheckStatus={installerCheckStatus}
                  totalEnchargeR2={this.state.primaryDetails.totalEnchargeR2}
                  totalEnchargeR3={this.state.primaryDetails.totalEnchargeR3}
                  totalEncharge3LimitFlag={
                    this.state.primaryDetails.totalEncharge3LimitFlag
                  }
                  totalEncharge10LimitFlag={
                    this.state.primaryDetails.totalEncharge10LimitFlag
                  }
                  totalEncharge5PLimitFlag={
                    this.state.primaryDetails.totalEncharge5PLimitFlag
                  }
                  sgip_application={sgip_application}
                  solar_size_value={this.state.primaryDetails.solar_size}
                  solarSizeCheck={solarSizeCheck}
                  solarSizeCheckError={solarSizeCheckError}
                  systemDetailsError={
                    this.state.primaryDetailsError.system_details_error.msg
                  }
                  isInstaller={isInstaller}
                  isIOS={isIOS}
                  isNextButtonDisabled={isNextButtonDisabled}
                  batteryRebatePreviously={
                    this.state.primaryDetails.battery_rebate_claimed
                  }
                />
              </Grid>
              <Box className={classes.pHide}>
                <FilledButton
                  className={classes.btnblue}
                  disabled={solarSizeCheckError || isNextButtonDisabled}
                  onClick={e => this.handleSubmit()}
                >
                  Next
                </FilledButton>
                <PrivacyPolicy />
              </Box>
            </Grid>
          </div>
        </Fragment>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getSGIPInstallers: payload => dispatch(getSGIPInstallers(payload)),
  getSGIPPrimaryInstallers: payload =>
    dispatch(getSGIPPrimaryInstallers(payload)),
  postSgipPrimaryDetails: payload => dispatch(postSgipPrimaryDetails(payload)),
  postDeveloperKeyUpdateMail: payload =>
    dispatch(postDeveloperKeyUpdateMail(payload)),
  getSgipApplicationDetailsBySiteId: payload =>
    dispatch(getSgipApplicationDetailsBySiteId(payload)),
  getSiteInfo: payload => dispatch(getSiteInfo(payload)),
});

const mapStateToProps = state => ({
  message: state.message,
  accountDetails: state.adminReducer.accountDetails,
  siteId: state.appReducer.siteId,
  installers: state.sgipAppReducer.sgip_installers,
  primaryInstallers: state.sgipAppReducer.sgip_primary_installers,
  currentlySending: state.sgipAppReducer.currentlySending,
  sgip_application: state.sgipAppReducer.sgip_application,
  isInstaller: state.appReducer.isInstaller,
  isIOS: state.appReducer.isIOS,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(index));
